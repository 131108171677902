import { createSelector } from 'reselect';
import { RootState } from '@/app/rootReducer';
import memoize from 'lodash.memoize';
import {
  SiteCameraAlbumsState,
  getDefaultCameraAlbums,
} from '@/slicers/siteCameraAlbums';

export const selectCameraAlbums = createSelector(
  (state: RootState) => state.siteCameraAlbumsState,
  (siteCameraAlbumsState: SiteCameraAlbumsState) =>
    memoize((siteId: number, cameraId: number) => {
      const { siteCameraAlbumsById, ...rest } =
        siteCameraAlbumsState[`${siteId}-${cameraId}`] ||
        getDefaultCameraAlbums();

      return {
        list: Object.values(siteCameraAlbumsById),
        ...rest,
      };
    })
);
