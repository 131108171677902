import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { getResponseSelector } from '@/selector/users';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { UserTypeAgent, User } from '@/slicers/users';
import { useCookies as _useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import { Button as MaterialButton, Box } from '@material-ui/core';
import { EditButton, SaveButton } from '@/components/atoms/Buttons';
import BackButton from '@/components/ecosystem/BackButton';
import ButtonList from '@/components/atoms/ButtonList';
import { useForm } from 'react-hook-form';
import { ApiHeader } from '@/slicers/response';
import { contain } from '@/utils/contain';
import { setFlashSuccess } from '@/slicers/flash';
import { HttpMethod } from '@/slicers/response';
import { initCommonState } from '@/slicers/loading';
import { CompanyUserType } from '@/routes/ClientRoute';

type UseRedirectIfExpectResponseProps = {
  url: string | string[];
  method: HttpMethod;
  status: number | [number] | 'error';
  action: string | Function;
};

/**
 *
 * @param url
 * @param httpMethod
 * @param statusCode errorの場合は200,201以外を期待する
 * @param action urlを渡すとリダイレクトする,メソッドを渡すと実行される
 */
export const useRedirectIfExpectResponse = ({
  action,
  method,
  status,
  url,
}: UseRedirectIfExpectResponseProps) => {
  const response = useSelector((state: RootState) =>
    getResponseSelector(state)
  );
  const history = useHistory();

  const urlList = typeof url === 'string' ? [url] : url;
  const isUrlMatched = urlList.includes(response.url || '');
  const isHtttpMethodMatched = method === response.method;

  let isStatusCodeMatched = false;
  if (status === 'error') {
    isStatusCodeMatched = ![200, 201].includes(response.status);
  } else {
    const statusCodeList = typeof status === 'number' ? [status] : status;
    isStatusCodeMatched = statusCodeList.includes(response.status);
  }

  useEffect(() => {
    if (isUrlMatched && isStatusCodeMatched && isHtttpMethodMatched) {
      if (typeof action === 'string') {
        return history.push(action);
      }
      action();
    }
    // eslint-disable-next-line
  }, [response, history]);
};

/**
 * currentUser取得
 */
export const useGetCurrentUser = (): User | null => {
  const { current_user_id: currentUserId } = useParams<{
    current_user_id: string;
  }>();

  const currentUser = useSelector(
    (state: RootState) => state.usersState.usersById[Number(currentUserId)]
  );

  if (!currentUser) return null;

  if (currentUser && currentUser.type <= UserTypeAgent)
    throw new Error('代理店以上はcurrentUserになれません');

  return currentUser;
};

/**
 * currentUserとcurrentCompanyを取得
 */
export const useGetCurrentUserAndCompany = () => {
  const { current_user: currentUserId, current_company: currentCompanyId } =
    useParams<{
      current_user: string;
      current_company: string;
    }>();

  const { currentCompany, currentUser } = useSelector((state: RootState) => ({
    currentUser: state.usersState.usersById[Number(currentUserId)],
    currentCompany:
      state.companiesState.companiesById[Number(currentCompanyId)],
  }));

  if (currentUser && currentUser.type <= UserTypeAgent)
    throw new Error('代理店以上はcurrentUserになれません');

  return { currentUser, currentCompany };
};

export const useGetUrlPrefix = () => {
  const location = useLocation();
  const [urlPrefix, setUrlPrefix] = useState<string | undefined>();

  console.log(urlPrefix);

  useEffect(() => {
    setUrlPrefix(
      (state) =>
        location.pathname.match(/\/curt_nml_co\/\d*\/gen_mng_usr\/\d*/)?.[0] ||
        location.pathname.match(/\/curt_nml_co\/\d*/)?.[0]
    );
  }, [location.pathname]);

  return urlPrefix;
};

export const useGetClientRouteParam = () => {
  const location = useLocation();

  const companyId = location.pathname.match(
    /\/curt_nml_co\/(\d*)/
  )?.[1] as string;

  const siteId = location.pathname.match(
    /\/curt_nml_co\/\d*\/sites\/(\d*)/
  )?.[1] as string | undefined;

  return { companyId, siteId };
};

export const useCompanyUserType = (): keyof typeof CompanyUserType => {
  const location = useLocation();
  return (location.pathname.match(/\/.+\/[0-9]+\/users\/(.+)\/list/) ||
    [])[1] as keyof typeof CompanyUserType;
};

type useCookiesProps = {
  dependencies?: string[];
  defaultOptions?: CookieSetOptions;
};

type CookieState = {
  agentCompaniesRouteListPageCurrentPageNumber: number;
  agentCompaniesRouteListPageSearchText: string;
  companiesNormalCompaniesRouteListPageCurrentPageNumber: number;
  companiesNormalCompaniesRouteListPageSearchText: string;
  agentCompaniesUsersRouteListPageCurrentPageNumber: number;
  agentCompaniesUsersRouteListPageSearchText: string;
  modulesRouteListPageCurrentPageNumber: number;
  modulesRouteListPageSearchText: string;
  clientRouteSiteManagerUsersRouteListPageCurrentPageNumber: number;
  clientRouteSiteManagerUsersRouteListPageSearchText: string;
  clientRouteModulesRouteListPageCurrentPageNumber: number;
  clientRouteModulesRouteListPageSearchText: string;
  clientRouteSitesRouteListPageCurrentPageNumber: number;
  clientRouteSitesRouteListPageSearchText: string;
  clientRouteSitesModulesRouteListPageCurrentPageNumber: number;
  clientRouteSitesModulesRouteListPageSearchText: string;
  clientRouteSitesSchedulesRouteListPageCurrentPageNumber: number;
  clientRouteSitesSchedulesRouteListPageSearchText: string;
  agentCompaniesNormalCompaniesRouteListPageCurrentPageNumber: number;
};

export const useCookies = ({
  dependencies = [],
  defaultOptions = {},
}: useCookiesProps) => {
  const [cookies, _setCookie] = _useCookies(dependencies);

  useEffect(() => {
    console.log('cookies', cookies);
  }, [cookies]);

  const setCookie = <K extends keyof CookieState>(
    cookieName: K,
    value: CookieState[K],
    options: CookieSetOptions = {}
  ) => {
    return _setCookie(cookieName.toString(), value, {
      ...defaultOptions,
      ...options,
    });
  };

  const getCookie = <K extends keyof CookieState>(
    cookieName: K
  ): CookieState[K] | undefined => {
    return cookies[cookieName.toString()];
  };

  return {
    getCookie,
    setCookie,
  };
};

type FormType = 'show' | 'edit' | 'create';

export type FormTypeManagerCreateProps<T> = {
  currentType: 'create';
  afterCreateSubmit: () => void | Promise<void>;
};

export type FormTypeManagerShowProps<T> = {
  currentType: 'show';
  succesEditMessage: string;
  deleteButton?: React.ReactElement;
};

export type FormTypeManagerProps<T> = {
  backUrl?: string | Function;
  expectResponse: ApiHeader;
  onSubmit: (daat: T) => Promise<void> | void;
} & (FormTypeManagerCreateProps<T> | FormTypeManagerShowProps<T>);

export const useFormTypeManager = <T extends {}>({
  backUrl,
  expectResponse,
  onSubmit: _handleSubmit,
  ...props
}: FormTypeManagerProps<T>) => {
  const [type, setType] = useState<FormType>(props.currentType);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, ...useFormMethods } = useForm<T>();
  const response = useSelector((state: RootState) =>
    getResponseSelector(state)
  );
  const dispatch = useDispatch();

  const inputProps = {
    readOnly: type === 'show',
  };

  useEffect(() => {
    if (!contain(response, expectResponse)) return;

    if (props.currentType === 'show') {
      setType('show');
      dispatch(setFlashSuccess(props.succesEditMessage));
      dispatch(initCommonState());
    } else {
      props.afterCreateSubmit();
    }
    // eslint-disable-next-line
  }, [response]);

  const onSubmit = handleSubmit(async (props) => {
    await setLoading(true);
    await _handleSubmit(props);
    await setLoading(false);
  });

  const handleClickCancelButton = () => {
    useFormMethods.reset();
    setType('show');
  };

  const Footer = () => {
    const OKButton =
      type === 'show' ? (
        <EditButton onClick={() => setType('edit')} />
      ) : (
        <SaveButton loading={loading} onClick={onSubmit} type="submit" />
      );

    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" justifyContent="flex-end">
          {props.currentType === 'show' && props.deleteButton}
        </Box>
        <Box>
          <ButtonList>
            {type === 'edit' ? (
              <MaterialButton
                variant="contained"
                color="default"
                onClick={handleClickCancelButton}
              >
                キャンセル
              </MaterialButton>
            ) : backUrl ? (
              <BackButton backUrl={backUrl} />
            ) : null}
            {OKButton}
          </ButtonList>
        </Box>
      </Box>
    );
  };

  return {
    type,
    inputProps,
    Footer,
    useFormMethods: { ...useFormMethods, handleSubmit: handleSubmit },
  };
};

export const useAgent = () => {
  const [isMobile, setMobileMode] = useState(false);
  useEffect(() => {
    const checkMode = () => {
      setMobileMode(window.innerWidth <= 768);
    };
    checkMode();
    window.addEventListener('resize', checkMode);
    return () => {
      window.removeEventListener('resize', checkMode);
    };
  }, []);

  return isMobile;
};
