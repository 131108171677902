import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/app/store';
import { RootState } from '@/app/rootReducer';
import { PagingOptions } from '@/interfaces';

export type User = {
  id: number;
  name: string;
  email: string;
  type: UserType;
  parent_id: number | null;
  company_id: number;
  site_id: number | null;
  realType?: UserType;
  email_verified_at?: string | null;
};

export type UserType = 100 | 200 | 300 | 400 | 500 | 600 | 700;

export const UserTypeSuperAdmin = 100;
export const UserTypeAdmin = 200;
export const UserTypeAgent = 300;
export const UserTypeGeneralManager = 400;
export const UserTypeManager = 500;
export const UserTypeSiteManager = 600;
export const UserTypeOperator = 700;

export type UsersState = {
  usersById: Record<number, User>;
  pagingOptions: PagingOptions;
};

export const initialState: UsersState = {
  usersById: {},
  pagingOptions: {
    total: 0,
    perPage: 5,
  },
};

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    clearUsers(state) {
      const deleteIds = Object.keys(state.usersById);
      deleteIds.forEach((id) => {
        delete state.usersById[id as any];
      });
      state.pagingOptions.total = 0;
    },
    userSetPagingOptions(state, action: PayloadAction<PagingOptions>) {
      state.pagingOptions.perPage = action.payload.perPage;
      state.pagingOptions.total = action.payload.total;
    },
    userGetsSucces(state, action: PayloadAction<User[]>) {
      action.payload.forEach((user) => {
        state.usersById[user.id] = user;
      });
    },
    userGetSuccess(state, action: PayloadAction<User>) {
      const user = action.payload;
      state.usersById[user.id] = user;
    },
    userDeleteSuccess(state, { payload }: PayloadAction<number>) {
      delete state.usersById[payload];
    },
    usersDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach((userId) => {
        delete state.usersById[userId];
      });
    },
  },
});

export const {
  userDeleteSuccess,
  userGetsSucces,
  userGetSuccess,
  usersDeleteById,
  clearUsers,
  userSetPagingOptions,
} = usersSlice.actions;

export default usersSlice.reducer;

export const usersDelete = (
  dispatch: AppDispatch,
  rootState: RootState,
  userIdList: number[]
) => {
  dispatch(usersDeleteById(userIdList));
};
