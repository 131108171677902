import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';

import { route } from '@/routes/url-generator';
import { useSelector } from 'react-redux';
import { getLoginUserSelector } from '@/selector/users';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';

const SitesModulesBreadCrumb: React.FC = () => {
  const loginUser = useSelector(getLoginUserSelector);

  return (
    loginUser && (
      <BreadCrumbs>
        <BreadCrumbItem
          title="遠隔電源司令盤管理"
          to={route.clientRoute.sites.modules.list(
            loginUser.company_id,
            loginUser.site_id!
          )}
        />
      </BreadCrumbs>
    )
  );
};

export default SitesModulesBreadCrumb;
