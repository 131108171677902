export async function createFileFromBase64(
  base64: string,
  fileName: string
): Promise<File> {
  const arr = base64.split(',');
  const mime = arr[0]!.match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}

// export async function createFileFromUrl(
//   url: string,
//   fileName: string
// ): Promise<File> {
//   const canvas = document.createElement('canvas');
//   const img = document.createElement('img');

//   return new Promise(res => {
//     img.onload = function() {
//       canvas.width = img.width;
//       canvas.height = img.height;

//       img.setAttribute('crossOrigin', 'Anonymous');
//       const ctx = canvas.getContext('2d')!;
//       ctx.drawImage(img, 0, 0);
//       const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
//         .data;

//       const buffer: any = new Uint8Array(imageData.length);
//       for (var index = 0; index < imageData.length; index++)
//         buffer[index] = imageData[index];

//       const imageBlob: any = new Blob(buffer);

//       res(
//         new File([imageBlob], fileName, {
//           type: 'image/jpeg',
//         })
//       );

//       document.body.removeChild(img);
//     };
//     img.setAttribute('src', url);
//     img.style.position = 'fixed';
//     img.style.zIndex = '-1';
//     img.style.opacity = '0';

//     document.body.appendChild(img);
//   });
// }
