import React, { CSSProperties } from 'react';
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
} from '@material-ui/core';

import classes from './styles.module.scss';

type SwitchCheckboxProps = Omit<CheckboxProps, 'color'> & {
  label?: string;
  color?: string;
};

const SwitchCheckbox = ({
  style,
  color = '#fff',
  label,
  ...props
}: SwitchCheckboxProps) => (
  <FormControlLabel
    style={
      {
        '--color': color,
        display: style?.display,
      } as CSSProperties
    }
    className={classes.checkbox}
    control={<Checkbox disableRipple={true} size="medium" {...props} />}
    label={
      <Box color={color} marginTop={-0.2} component="div" fontSize={14}>
        {label}
      </Box>
    }
    labelPlacement={'end'}
  />
);

export default SwitchCheckbox;
