import { combineReducers } from '@reduxjs/toolkit';

import usersState from '@/slicers/users';
import companiesState from '@/slicers/companies';
import modulesState from '@/slicers/modules';
import sitesState from '@/slicers/sites';
import operationPageState from '@/slicers/operation_page';
import flashState from '@/slicers/flash';
import loadingState from '@/slicers/loading';
import loginState from '@/slicers/login';
import responseState from '@/slicers/response';
import sidebarState from '@/slicers/sidebar';
import errorState from '@/slicers/error';
import operationLogState from '@/slicers/operation_log';
import scheduleState from '@/slicers/schedules';
import scheduleItemState from '@/slicers/schedule_items';
import siteUsersState from '@/slicers/siteUsers';
import siteModulesState from '@/slicers/siteModules';
import companyUsersState from '@/slicers/companyUsers';
import cameraState from '@/slicers/cameras';
import agentModulesState from '@/slicers/agentModules';
import siteCameraAlbumsState from '@/slicers/siteCameraAlbums';
import moduleCamerasState from '@/slicers/moduleCameras';
import agentSwitchesState from '@/slicers/switchesModules';
import companySwitchesState from '@/slicers/companySwitches';

const rootReducer = combineReducers({
  moduleCamerasState,
  siteCameraAlbumsState,
  agentModulesState,
  companyUsersState,
  siteModulesState,
  siteUsersState,
  usersState,
  companiesState,
  modulesState,
  sitesState,
  operationPageState,
  flashState,
  loadingState,
  loginState,
  responseState,
  sidebarState,
  errorState,
  operationLogState,
  scheduleState,
  scheduleItemState,
  cameraState,
  agentSwitchesState,
  companySwitchesState,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
