import { createSelector } from 'reselect';
import { RootState } from '@/app/rootReducer';
import memoize from 'lodash.memoize';
import { Camera } from '@/slicers/cameras';

export const selectorFindCamerasByModuleId = createSelector(
  (state: RootState) => state.moduleCamerasState.moduleCamerasById,
  (moduleCamerasById: Record<number, Camera>) =>
    memoize((moduleId: number) => {
      return Object.values(moduleCamerasById).filter(
        camera => camera.module_id === moduleId
      );
    })
);
