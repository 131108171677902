import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Method } from 'axios';

export type ApiHeader = {
  url: string;
  method: HttpMethod;
  status: number;
};

export type HttpMethod = 'get' | 'post' | 'put' | 'delete';

export type ResponseProps = {
  url: string | null;
  method: Method;
  status: number;
  data: any;
};

export type ValidationErrrorResponseProps = {
  message: string;
  errors?: { [key: string]: Array<string> };
};

export enum ErrorPosition {
  MAIN = 'main',
  DRAWR = 'drawer',
}

export type ResponseState = {
  url: string | null;
  method: Method;
  status: number;
  data: any;
  position?: ErrorPosition;
};

export const initialResponseState: ResponseState = {
  method: 'get',
  url: null,
  status: 0,
  data: null,
  position: ErrorPosition.MAIN,
};

type StateKey = keyof Omit<ResponseState, 'position'>;

const responseSlice = createSlice({
  name: 'response',
  initialState: initialResponseState,
  reducers: {
    setResponse(
      state,
      {
        payload: { position = ErrorPosition.MAIN, ...payload },
      }: PayloadAction<ResponseState>
    ) {
      const keys = Object.keys(payload) as StateKey[];
      keys.forEach((key: any) => {
        state[key] = payload[key];
      });
      state.position = position;
    },
    initResponse(state) {
      const keys = Object.keys(initialResponseState) as Array<
        keyof typeof initialResponseState
      >;
      keys.forEach((key: any) => {
        state[key] = initialResponseState[key];
      });
    },
  },
});

export const { setResponse, initResponse } = responseSlice.actions;

export default responseSlice.reducer;
