import { RootState } from '@/app/rootReducer';
import { createSelector } from 'reselect';

export const selectorFindAllAgentModulesByOwnerId = (ownerId: number) =>
  createSelector(
    (state: RootState) => state.agentModulesState.modulesById,
    (modulesById) =>
      Object.values(modulesById).filter(
        (module) => Number(module.owner_id) === ownerId
      )
  );

export const selectorFindAllAgentModulesByCompanyId = (companyId: number) =>
  createSelector(
    (state: RootState) => state.agentModulesState.modulesById,
    (modulesById) =>
      Object.values(modulesById).filter(
        (module) => module.company_id === companyId
      )
  );
