import React, { CSSProperties } from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
} from '@material-ui/core';

import classes from './styles.module.scss';

type TogglerProps = SwitchProps & {
  label?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  labelColor?: string;
};

const Toggler = ({
  label = '表示',
  labelPlacement = 'top',
  labelColor = '#fff',
  ...props
}: TogglerProps) => {
  return (
    <FormControlLabel
      style={
        {
          '--label-color': labelColor,
        } as CSSProperties
      }
      className={classes.toggler}
      control={<Switch {...props} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

export default Toggler;
