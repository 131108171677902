import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = (drawerWidth: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      green: {
        background: '#06B10D',
      },
      blue: {
        background: '#0300AE',
      },
      orange: {
        background: '#F2994A',
      },
      black: {
        background: '#3B3C3D',
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      hide: {
        display: 'none',
      },
      content: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
      item: {},
      flex_align_center: {
        display: 'flex',
        alignItems: 'center',
      },
      userIconMenu: {
        color: 'white',
      },
      loginLink: {
        display: 'flex',
        alignItems: 'center',
      },
      rightAlign: {
        display: 'flex',
        alignItems: 'center',
      },
      switchesLeak: {
        background: '#f08989',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: '2.5px 10px',
        minWidth: '15vw',
        animation: 'blink .5s linear 0s infinite alternate',
      },
      '@global': {
        '@keyframes blink': {
          '0%': {
            background: '#f08989',
          },
          '100%': {
            background: 'red',
          },
        },
      },
    })
  );

export default useStyles;
