import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useExpansionPannelStyles = (expanded: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        boxShadow: 'none',
        flexGrow: 1,
        cursor: 'initial',
        // width: expanded ? '100%' : '9.5rem',
        // backgroundColor: grey[100]
      },
    })
  );

export const useExpansionSummaryStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'inherit !important',
      padding: 0,
    },
    content: {
      margin: '.5rem !important',
      justifyContent: 'flex-end',
      //   flexGrow: 0
    },
    expanded: {
      //   flexGrow: 1
    },
  })
);

export const useExpansionPanelDetailsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0 .5rem',
    },
  })
);

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& > div:nth-child(2)': {
        '& > div': {
          height: 450,
        },
      },
    },
  })
);
