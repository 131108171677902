import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = () =>
  makeStyles(() =>
    createStyles({
      root: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        textAlign: 'center',
        padding: '0 2rem',
        whiteSpace: 'pre-wrap',
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& p': {
          color: '#ff4040',
          '&[aria-label="success"]': {
            color: '#27ae60',
          },
        },
      },
    })
  );

export default useStyles;
