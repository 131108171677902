import React from 'react'
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem'
import { useGetClientRouteParam } from '@/hooks'
import { RootState } from '@/app/rootReducer'
import { useSelector } from 'react-redux'

import { getLoginUserAndCompanySelector } from '@/selector/users'
import { route } from '@/routes/url-generator'
import { UserTypeGeneralManager } from '@/slicers/users'
import BreadCrumbs from '@/components/molecules/BreadCrumbs'

const ClientRouteBreadCrumb: React.FC = () => {
  const { companyId } = useGetClientRouteParam()

  const { loginUserCompany, loginUser } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  )

  const company = useSelector(
    (state: RootState) => state.companiesState.companiesById[Number(companyId)]
  )

  const Render: React.FC = () => {
    if (!(loginUser && loginUserCompany)) return null

    if (loginUser.type >= UserTypeGeneralManager) {
      return null
    }

    return company ? (
      <BreadCrumbs>
        <BreadCrumbItem
          title="企業管理"
          to={route.companies.normalCompanies.list}
        />
        {/* <BreadCrumbItem
          title={company.name}
          to={route.clientRoute.siteManagerUsers.list(
            companyId,
            generalManagerUserId
          )}
        /> */}
      </BreadCrumbs>
    ) : null
  }

  return <Render />
}

export default ClientRouteBreadCrumb
