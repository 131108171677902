import React from 'react';
import { useHistory } from 'react-router-dom';
import { BackButton as BackButtonBase } from '@/components/atoms/Buttons';

type BackButtonProps = {
  backUrl?: string | Function;
};

const BackButton: React.FC<BackButtonProps> = ({
  backUrl,
  children = '戻る',
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (!backUrl) {
      history.goBack();
    } else if (typeof backUrl === 'string') {
      history.push(backUrl);
    } else {
      backUrl();
    }
  };
  return <BackButtonBase onClick={handleClick}>{children}</BackButtonBase>;
};

export default BackButton;
