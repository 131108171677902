import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import {
  clearSiteCameraAlbums,
  siteCameraAlbumDeletes,
  siteCameraAlbumGetsSucces,
  siteCameraAlbumSetPagingOptions,
} from '@/slicers/siteCameraAlbums';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/sites')
class SitesCameraAlbumsRequest {
  caller!: TApiCaller;
  index(siteId: number, cameraId: number) {
    return {
      request:
        (page?: number, keyword?: string, date?: string): AppThunk =>
        async (dispatch) => {
          const query: Record<string, any> = {
            page: page || 1,
            search: keyword,
          };
          if (date) {
            query.date = date;
          }
          if (query.page === 1) {
            dispatch(clearSiteCameraAlbums({ siteId, cameraId }));
          }
          const response = await this.caller.GET(
            `/${siteId}/camera/${cameraId}/album`,
            query
          );

          if (response.status === 200) {
            const { data, total, per_page } = response.data;
            dispatch(clearSiteCameraAlbums({ siteId, cameraId }));
            dispatch(
              siteCameraAlbumSetPagingOptions({
                siteId,
                cameraId,
                paging: {
                  total,
                  perPage: per_page,
                },
              })
            );
            dispatch(
              siteCameraAlbumGetsSucces({ siteId, cameraId, albums: data })
            );
            dispatch(setResponse(parseAxiosResponse(response)));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
          }
        },
    };
  }
  post(siteId: number, cameraId: number) {
    return {
      request:
        (formData: FormData): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(
            `/${siteId}/camera/${cameraId}/upload_camera_image`,
            formData
          );

          if (response.status === 201) {
            dispatch(
              siteCameraAlbumGetsSucces({
                siteId,
                cameraId,
                albums: [response.data],
              })
            );
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }
  delete(siteId: number, cameraId: number, albumId: number) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.DEL(
          `/${siteId}/camera/${cameraId}/delete_image/${albumId}`
        );

        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました'));
          dispatch(
            siteCameraAlbumDeletes({
              siteId,
              cameraId,
              albumIds: [albumId],
            })
          );
        } else {
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }
}

const sitesCameraAlbumsRequest = new SitesCameraAlbumsRequest();
export default sitesCameraAlbumsRequest;
