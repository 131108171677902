import companiesGeneralManagerUsersRequest from '@/api/companies_general_manager_users';
import { RootState } from '@/app/rootReducer';
import Drawer from '@/components/atoms/Drawer';
import List from '@/components/atoms/List';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';
import AgentUserForm from '@/components/organisms/AgentUserForm';
import { UserFormData } from '@/components/organisms/UserForm';
import { findGeneralManagerUsersByCompanyIdSelectorFactory } from '@/selector/users';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@/slicers/loading';
interface ListUserProps {
  companyId: number;
}

export default function ListUser({ companyId }: ListUserProps) {
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const { loading } = useSelector((state: RootState) => state.loadingState);
  const dispatch = useDispatch();

  const [isOpenEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState<null | Record<string, any>>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const users = useSelector((state: RootState) =>
    findGeneralManagerUsersByCompanyIdSelectorFactory(state)(companyId)
  );

  const onSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'password', 'email'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input) {
        if (input.hasAttribute('required')) {
          if (!input.value) {
            errors[field] = '必須入力項目です';
          }
        }
        data[field] = input.value;
      }

      return errors;
    }, {});

    setErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (editData) {
        const api = companiesGeneralManagerUsersRequest.put(
          companyId.toString(),
          editData.id
        );
        await dispatch(api.request(data as UserFormData));
        setOpenEdit(false);
        setEditData(null);
      } else {
        const api = companiesGeneralManagerUsersRequest.post(
          companyId.toString()
        );
        await dispatch(api.request(data as UserFormData));
        closeDrawer();
      }
      hideOverlay();
    }
  };

  const onDelete = async (id: string) => {
    showOverlay();
    const api = companiesGeneralManagerUsersRequest.delete(
      companyId.toString(),
      id
    );
    await dispatch(api.request());
    setOpenEdit(false);
    hideOverlay();
  };

  useEffect(() => {
    const api = companiesGeneralManagerUsersRequest.index(companyId.toString());
    dispatch(api.request());
  }, [dispatch, companyId]);

  useEffect(() => {
    if (!isOpenEdit) {
      setErrors({});
      setEditData(null);
    }
  }, [isOpenEdit]);

  return (
    <>
      {overlayLoader}
      <List
        loading={loading}
        data={users}
        columns={[
          {
            field: 'name',
            title: 'ユーザー名',
            width: '40%',
          },
          {
            field: 'email',
            title: 'メールアドレス',
            width: '60%',
          },
        ]}
        getDrawerContent={(closeDrawer) => {
          const onCloseDrawer = () => {
            closeDrawer();
            setErrors({});
          };

          return (
            <AgentUserForm
              errors={errors}
              onSubmit={onSubmit(onCloseDrawer)}
              onCancel={onCloseDrawer}
            />
          );
        }}
        emptyMessage="データがありません"
        title=""
        tooltip={{
          options: (item) =>
            item.email_verified_at
              ? [
                  {
                    key: 'edit',
                    title: 'ユ-ザ-の編集',
                  },
                ]
              : [
                  {
                    key: 'edit',
                    title: 'ユ-ザ-の編集',
                  },
                  {
                    key: 'sendMail',
                    title: '認証メール送信',
                  },
                ],
          onClick: async (key, record) => {
            if (key === 'delete') {
              onDelete(record.id.toString());
            } else if (key === 'edit') {
              setOpenEdit(true);
              setEditData(record);
            } else if (key === 'sendMail') {
              console.log(record);
              await dispatch(setLoading(true));
              await dispatch(
                companiesGeneralManagerUsersRequest
                  .sendAuthMail(String(record.company_id), record.email)
                  .request()
              );
              await dispatch(setLoading(false));
            }
          },
        }}
        addButtonText="ユーザー作成"
      />
      <Drawer isOpen={isOpenEdit} onChange={setOpenEdit}>
        {editData && (
          <AgentUserForm
            initialData={editData}
            errors={errors}
            onSubmit={onSubmit(() => setOpenEdit(false))}
            onCancel={() => setOpenEdit(false)}
            onConfirmDelete={() => onDelete(editData?.id)}
          />
        )}
      </Drawer>
    </>
  );
}
