import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import authRequest from '@/api/auth';
import { RootState } from '@/app/rootReducer';
import { getLoginUserAndCompanySelector } from '@/selector/users';
import { useRedirectIfExpectResponse } from '@/hooks';
import { route } from '@/routes/url-generator';
import { initCommonState } from '@/slicers/loading';

const Common: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLogin = useSelector(
    (state: RootState) => getLoginUserAndCompanySelector(state).isLogin
  );

  useEffect(() => {
    return () => {
      dispatch(initCommonState());
    };
  }, [dispatch, location.pathname]);

  useEffect(() => {
    const func = async () => {
      await dispatch(authRequest.fetchLoginUserAndCompany().request());
    };
    if (!isLogin && location.pathname !== route.login) {
      func();
    }
  }, [dispatch, isLogin, location]);

  useRedirectIfExpectResponse({
    url: '/api/oauth/token',
    method: 'post',
    status: 'error',
    action: route.login,
  });
  useRedirectIfExpectResponse({
    url: '/api/user',
    status: 'error',
    method: 'get',
    action: route.login,
  });
  useRedirectIfExpectResponse({
    url: '/api/login_info',
    status: 'error',
    method: 'get',
    action: route.login,
  });

  return null;
};

export default Common;
