import React, { useEffect } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { getResponseSelector } from '@/selector/users';
import { setError } from '@/slicers/error';
import {
  ValidationErrrorResponseProps,
  ErrorPosition,
} from '@/slicers/response';

interface ErrorMessageProps {
  position: ErrorPosition;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ position }) => {
  const response = useSelector((state: RootState) =>
    getResponseSelector(state)
  );
  const error = useSelector((state: RootState) => state.errorState.error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (position === response.position) {
      let error: string[] = [];
      switch (response.status) {
        case 403:
          error = ['権限がありません'];
          break;

        case 404:
          error = ['Not Found'];
          break;

        case 422:
          let res = response.data as ValidationErrrorResponseProps;
          if (res.errors) {
            error = Object.values(res.errors).map((item) => item[0]);
          }
          break;

        case 429:
          error = [
            '最大アクセス数の上限に達しました。しばらくしてからもう一度アクセスしてください。',
          ];
          break;

        case 500:
          error = ['システムエラーが発生しました。'];
          break;

        default:
          break;
      }

      dispatch(setError(error));
    }
  }, [response, dispatch, position]);

  return position === response.position && error.length ? (
    <Alert severity="error">
      <AlertTitle>エラー</AlertTitle>
      <ul>
        {error.map((_error) => (
          <li key={_error}>{_error}</li>
        ))}
      </ul>
    </Alert>
  ) : null;
};

export default ErrorMessage;
