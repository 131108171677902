import axios, { AxiosInstance, AxiosResponse } from 'axios';

export const MAIN_API = process.env.REACT_APP_BACKEND_URL
  ? `${window.location.protocol}//${process.env.REACT_APP_BACKEND_URL}`
  : '';

export const NODE_API = process.env.REACT_APP_NODE_API_URL
  ? `${window.location.protocol}//${process.env.REACT_APP_NODE_API_URL}`
  : '';

function getAxios(baseURL: string) {
  const ins = axios.create({
    baseURL: baseURL,
  });

  ins.interceptors.request.use(function (config) {
    if (!config.headers) {
      config.headers = {};
    }
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    // Do something before request is sent
    return config;
  });

  ins.interceptors.response.use(
    (response) => response,
    (error) => error.response
  );

  return ins;
}

const MainAxios = getAxios(`${MAIN_API}/api`);
const NodeAxios = getAxios(`${NODE_API}/node`);

class ApiCaller {
  constructor(private caller: AxiosInstance, private basePath: string) {}

  combineApiPath(path: string) {
    const lastSlashIndex = path.lastIndexOf('/');
    const _path =
      lastSlashIndex === path.length - 1
        ? path.substring(lastSlashIndex, path.length - 1)
        : path;
    return `${this.basePath}${_path}`;
  }

  GET<D = any>(
    path: string,
    query: Record<string, any> = {},
    options: Record<string, any> = {}
  ): Promise<AxiosResponse<D>> {
    return this.caller.get(this.combineApiPath(path), {
      params: query,
      ...options,
    });
  }

  POST<D = any>(
    path: string,
    body: Record<string, any> | FormData = {}
  ): Promise<AxiosResponse<D>> {
    return this.caller.post(this.combineApiPath(path), body);
  }

  PUT<D = any>(
    path: string,
    body: Record<string, any> | FormData = {}
  ): Promise<AxiosResponse<D>> {
    return this.caller.put(this.combineApiPath(path), body);
  }

  DEL<D = any>(
    path: string,
    query: Record<string, any> = {}
  ): Promise<AxiosResponse<D>> {
    return this.caller.delete(this.combineApiPath(path), { params: query });
  }
}

export function getMainApiCaller(basePath: string) {
  return new ApiCaller(MainAxios, basePath);
}

export function getNodeApiCaller(basePath: string) {
  return new ApiCaller(NodeAxios, basePath);
}

export function MainApiCaller(basePath: string): Function {
  const caller = getMainApiCaller(basePath);
  return function (constructor: any): void {
    Object.defineProperty(constructor.prototype, 'caller', {
      enumerable: true,
      get: function () {
        return caller;
      },
      set: function () {},
    });
  };
}

export function NodeApiCaller(basePath: string): Function {
  const caller = getNodeApiCaller(basePath);
  return function (constructor: any): void {
    Object.defineProperty(constructor.prototype, 'caller', {
      enumerable: true,
      get: function () {
        return caller;
      },
      set: function () {},
    });
  };
}

export type TApiCaller = ApiCaller;
