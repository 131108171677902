import React from 'react';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';

import classes from './styles.module.scss';

type CalendarProps = {
  title: string;
  disabled?: boolean;
  value?: Date;
  onChange: (value?: Date) => void;
};

const Calendar = ({title, disabled, value, onChange }: CalendarProps) => {
  return (
    <div className={classes.calendar}>
      <div>{title}：</div>
      <div className={classes.picker}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disabled={disabled}
            allowKeyboardControl={false}
            emptyLabel="日付を選択..."
            value={value || null}
            disableToolbar
            variant="inline"
            format="yyyy/MM/dd"
            margin="normal"
            label=""
            onChange={(date) => onChange(date || undefined)}
          />
        </MuiPickersUtilsProvider>
        {value ? (
          <CloseIcon
            onClick={() => onChange(undefined)}
            className={[classes.pickerIcon, classes.clickable].join(' ')}
          />
        ) : (
          <DateRangeIcon
            className={[classes.pickerIcon, classes.calendarIcon].join(' ')}
          />
        )}
      </div>
    </div>
  );
};

export default Calendar;
