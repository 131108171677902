import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import clsx from 'clsx';
import { CssBaseline, Box, Paper } from '@material-ui/core';
import AppHeader from '@/components/organisms/AppHeader';
import AppSidebar from '@/components/ecosystem/AppSideBar';
import useStyles from './styles';
import UserIcomMenu from '@/components/ecosystem/UserIconMenu';
import FlashMessageSystem from '../FlashMessageSystem';
import ErrorMessage from '../ErrorMessage';
import BreadCrumbs from '../BreadcrumbsSystem';
import Common from '@/components/Common';
import { toggleSideBar } from '@/slicers/sidebar';
import LoadingSystem from '../Loading';
import { ErrorPosition } from '@/slicers/response';

export const sideBarWidth = 240;

export const AdminContainer: React.FC = ({ children }) => {
  const mobileMaxWidth = 768;
  const classes = useStyles({} as any);
  const dispatch = useDispatch();

  const sideBarOpened = useSelector(
    (state: RootState) => state.sidebarState.sideBarOpened
  );
  const handleDrawer = (open: boolean) => () => {
    dispatch(toggleSideBar(open));
  };

  useEffect(() => {
    const isMobile = window.innerWidth <= mobileMaxWidth;
    if (isMobile) {
      dispatch(toggleSideBar(false));
    }

    const onResize = () => {
      dispatch(toggleSideBar(!(window.innerWidth <= mobileMaxWidth)));
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppHeader
        sideBarWidth={sideBarWidth}
        sideBarOpened={sideBarOpened}
        onSidebarOpen={handleDrawer(true)}
      >
        <UserIcomMenu />
      </AppHeader>
      <AppSidebar
        sideBarWidth={sideBarWidth}
        onSideBarClose={handleDrawer(false)}
        sideBarOpened={sideBarOpened}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sideBarOpened,
        })}
      >
        <div className={classes.drawerHeader} />
        <Box m={2}>
          <BreadCrumbs />
        </Box>
        <Paper elevation={3} className={classes.inner}>
          <LoadingSystem />
          <Common />
          <FlashMessageSystem />
          <ErrorMessage position={ErrorPosition.MAIN} />
          {children}
        </Paper>
      </main>
    </div>
  );
};

export default AdminContainer;
