import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { userGetSuccess, userGetsSucces, usersDelete } from '@/slicers/users';
import { ErrorPosition } from '@/slicers/response';
import { setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/agent_companies')
class AgentCompaniesUsersRequest {
  caller!: TApiCaller;
  index(companyId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/${companyId}/users`);
        if (response.status === 200) {
          dispatch(userGetsSucces(response.data));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました'));
        }
      },
    };
  }

  post(companyId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(`/${companyId}/users`, data);

          if (response.status === 201) {
            dispatch(userGetSuccess(response.data));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }

  put(companyId: string, userId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${companyId}/users/${userId}`,
            data
          );
          if (response.status === 200) {
            dispatch(userGetSuccess(response.data));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }

  delete(companyId: string, userId: string) {
    return {
      request: (): AppThunk => async (dispatch, getState) => {
        const response = await this.caller.DEL(`/${companyId}/users/${userId}`);
        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました'));
          usersDelete(dispatch, getState(), [Number(userId)]);
        } else {
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }

  sendAuthMail(companyId: string, email: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(
          `/${companyId}/send_auth_mail/${email}`
        );
        if (response.status === 200) {
          dispatch(setFlashSuccess('認証メール送信が完了しました。'));
        } else {
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }
}

const agentCompaniesUsersRequest = new AgentCompaniesUsersRequest();
export default agentCompaniesUsersRequest;
