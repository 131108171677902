import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';

import { route } from '@/routes/url-generator';
import { useSelector } from 'react-redux';
import { getLoginUserSelector } from '@/selector/users';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';
import { useAgent } from '@/hooks';

const CompaniesSitesBreadCrumb: React.FC = () => {
  const loginUser = useSelector(getLoginUserSelector);

  /// 電力消費量でモバイルの場合パンくず表示しない
  const isMobile = useAgent();
  const isPowerConsumption =
    window.location.pathname.split('/')[5] === 'power_consumption_pannel';

  return isMobile && isPowerConsumption ? (
    <></>
  ) : (
    loginUser && (
      <BreadCrumbs>
        <BreadCrumbItem
          title="現場管理"
          to={route.clientRoute.sites.list(loginUser.company_id)}
        />
      </BreadCrumbs>
    )
  );
};

export default CompaniesSitesBreadCrumb;
