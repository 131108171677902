import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { RootState } from '@/app/rootReducer';
import { route } from '@/routes/url-generator';

import { setLoading } from '@/slicers/loading';
import { UserTypeAdmin } from '@/slicers/users';

import { getNormalCompaniesWithAgent } from '@/selector/companies';
import { getLoginUserSelector } from '@/selector/users';

import { BackButton } from '@/components/atoms/Buttons';
import { CustomButton } from '@/components/atoms/CustomButton';

import companiesNormalCompaniesRequest from '@/api/companies_normal_companies';
import companiesSitesRequest from '@/api/companies_sites';

import useStyles from './styles';
import { selectorFindAllSitesByCompanyId } from '@/selector/site';

const SiteLogManagementList: React.FC = () => {
  const classes = useStyles()();
  const dispatch = useDispatch();

  const normalCompanies = useSelector((state: RootState) =>
    getNormalCompaniesWithAgent(state)
  );

  const loginUser = useSelector(getLoginUserSelector);
  const history = useHistory();
  const [selectedCompany, selectCompany] = useState<string>('');

  const sites = useSelector((state: RootState) =>
    selectorFindAllSitesByCompanyId(Number(selectedCompany))(state)
  );

  const getNormalCompanies = useCallback(async () => {
    dispatch(setLoading(true));
    await dispatch(companiesNormalCompaniesRequest.index('').request(-1));
    dispatch(setLoading(false));
  }, [dispatch]);

  const getSites = useCallback(async () => {
    if (selectedCompany) {
      await dispatch(setLoading(true));
      await dispatch(companiesSitesRequest.index(selectedCompany).request(-1));
      await dispatch(setLoading(false));
    }
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    getNormalCompanies();
  }, [getNormalCompanies]);

  useEffect(() => {
    getSites();
  }, [getSites]);

  return loginUser!.realType! <= UserTypeAdmin ? (
    <div className={classes.root}>
      <div className={classes.rangeDate}>
        <FormControl style={{ minWidth: '120px' }}>
          <InputLabel style={{ fontSize: '1rem' }}>企業検索</InputLabel>
          <Select
            style={{ width: '10vw' }}
            value={selectedCompany}
            onChange={(event: React.ChangeEvent<{ value: any }>) => {
              selectCompany(event.target.value);
            }}
          >
            {normalCompanies.map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div className={classes.fileList}>
        {sites.map((item) => {
          return (
            <div key={item.id} className={classes.siteItem}>
              <span>{item.name}</span>
              <Link
                to={route.clientRoute.sites.operationLog(
                  item.company_id,
                  item.id
                )}
              >
                <CustomButton
                  color="primary"
                  onClick={() => {}}
                  style={{ margin: '5px' }}
                >
                  ログ
                </CustomButton>
              </Link>
            </div>
          );
        })}
      </div>
      <div>
        <BackButton onClick={history.goBack} />
      </div>
    </div>
  ) : (
    <div>権限がありません。</div>
  );
};

export default SiteLogManagementList;
