import React, { useMemo, useEffect, useState, useCallback } from 'react';
import * as ReactDOM from 'react-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import classes from './styles.module.scss';

interface OverlayLoadingProps {
  isOpen?: boolean;
}

export default function OverlayLoading({ isOpen }: OverlayLoadingProps) {
  const container = useMemo(() => {
    const c = document.createElement('div');
    c.style.zIndex = '9999';
    c.style.position = 'fixed';
    c.style.top = '0';
    c.style.left = '0';
    return c;
  }, []);

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(
    isOpen ? (
      <div className={classes.overlay}>
        <CircularProgress color="secondary" />
      </div>
    ) : null,
    container
  );
}

interface UseProps {
  defaultShow?: boolean;
}

export const useOverlayLoading = ({ defaultShow }: UseProps = {}): [
  React.ReactNode,
  {
    handleTrigger: (
      onClick: (e: React.MouseEvent<any, any>) => void
    ) => (e: React.MouseEvent<any, any>) => void;
    showOverlay: () => void;
    hideOverlay: () => void;
    isLoading: boolean;
  }
] => {
  const [isOpen, setOpen] = useState(defaultShow || false);

  const handleTrigger = useCallback(
    (onClick: (e: React.MouseEvent<any, any>) => void) => {
      return async (e: React.MouseEvent<any, any>) => {
        setOpen(true);
        await onClick(e);
        setOpen(false);
      };
    },
    []
  );

  const showOverlay = useCallback(() => {
    setOpen(true);
  }, []);

  const hideOverlay = useCallback(() => {
    setOpen(false);
  }, []);

  return [
    <OverlayLoading isOpen={isOpen} />,
    {
      handleTrigger,
      showOverlay,
      hideOverlay,
      isLoading: isOpen,
    },
  ];
};
