import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import {
  moduleWithGetsSuccess,
  moduleWithUpdateName,
} from '@/slicers/operation_page';
import { setResponse } from '@/slicers/response';
import { setFlashError } from '@/slicers/flash';
import { cameraGetsSucces } from '@/slicers/cameras';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/sites')
class OperationPageRequest {
  caller!: TApiCaller;
  index(siteId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/${siteId}/modules`);
        if (response.status === 200) {
          dispatch(moduleWithGetsSuccess(response.data));
          dispatch(
            cameraGetsSucces(
              response.data.map((data: any) => data.cameras).flat()
            )
          );

          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  updateModuleName(siteId: string, moduleId: string) {
    return {
      request:
        (name: string): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${siteId}/modules/${moduleId}/changeModuleName`,
            { name }
          );

          if (response.status === 200) {
            dispatch(
              moduleWithUpdateName({
                deviceCode: response.data.device_code,
                name,
              })
            );
            dispatch(setResponse(parseAxiosResponse(response)));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
          }
        },
    };
  }
}
const operationPageRequest = new OperationPageRequest();
export default operationPageRequest;
