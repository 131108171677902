import React from 'react';
import { route } from '@/routes/url-generator';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import { useSelector } from 'react-redux';
import { getLoginUserSelector } from '@/selector/users';

const AgentCompaniesModulesBreadCrumbList: React.FC = () => {
  const loginUser = useSelector(getLoginUserSelector);

  return (
    loginUser && (
      <React.Fragment>
        <BreadCrumbItem
          title="代理店管理者管理"
          to={route.agentCompanies.generalManagerUsers.list(
            loginUser.company_id
          )}
        />
      </React.Fragment>
    )
  );
};

export default AgentCompaniesModulesBreadCrumbList;
