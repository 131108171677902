import React, { useState, useCallback } from 'react';
import classes from './styles.module.scss';
import { CustomButton } from '@/components/atoms/CustomButton';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core';
import { getFormElements } from '@/utils/dom';

export type FormData = {
  name: string;
  password: string;
};

export type LoginFormProps = {
  className?: string;
  onSubmit: (formData: FormData) => void;
  loading: boolean;
};

const LoginForm: React.FC<LoginFormProps> = ({
  loading,
  onSubmit: _onSubmit,
}) => {
  const [errors, setErrors] = useState<Record<string, any>>({});

  const handleClickPasswordForgotButton = () => {
    const protocol = window.location.protocol;
    window.location.href = `${protocol}//${
      process.env.REACT_APP_BACKEND_URL || ''
    }/password/reset`;
  };

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const form = e.target as HTMLFormElement;

      const fields = getFormElements(form);

      const data: Record<string, any> = {};
      const errors = fields.reduce((errors: Record<string, string>, input) => {
        const errorMessage =
          input.getAttribute('data-error-message') || '必須入力項目です';

        if (input.hasAttribute('required')) {
          if (input.name.includes('*')) {
            const [name, index] = input.name.split('.*.');
            if (index === '[]') {
              errors[name] = errorMessage;
            }
          } else {
            if (input.value === '' || input.value == null) {
              errors[input.name] = errorMessage;
            }
          }
        }
        if (input.name.includes('*')) {
          const [name, index] = input.name.split('.*.');
          if (index !== '[]') {
            data[name] = data[name] || [];
            data[name][index] = input.value;
          } else {
            data[name] = [];
          }
        } else {
          data[input.name] = input.value;
        }
        return errors;
      }, {});

      setErrors(errors);

      if (!Object.keys(errors).length) {
        _onSubmit(data as FormData);
      }
    },
    [_onSubmit]
  );
  return (
    <form noValidate className={classes.loginForm} onSubmit={onSubmit}>
      <div className={classes.logo}>
        <img alt="" src="logo192.svg" />
      </div>
      <div className={classes.inputs}>
        <div>
          <FormControl className={classes.input} error={!!errors.name}>
            <InputLabel>メールアドレス</InputLabel>
            <Input required name="name" />
            <FormHelperText>{errors.name}</FormHelperText>
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.input} error={!!errors.name}>
            <InputLabel>パスワード</InputLabel>
            <Input required name="password" type="password" />
            <FormHelperText>{errors.password}</FormHelperText>
          </FormControl>
        </div>
      </div>
      <div className={classes.buttons}>
        <CustomButton
          type="submit"
          color="success"
          className={classes.submitButton}
          disabled={loading}
        >
          ログイン
        </CustomButton>
      </div>
      <div className={classes.forgotPassword}>
        <span onClick={handleClickPasswordForgotButton}>
          パスワードを忘れた場合
        </span>
      </div>
    </form>
  );
};

export default LoginForm;
