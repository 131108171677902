import React, { useEffect, useMemo } from 'react';
import useStyles from './styles';

export default function EarthquakeAlert() {
  const classes = useStyles()();

  useMemo(() => {
    (window as any).isShowedEearthquakeAlert = true;
  }, []);

  useEffect(() => {
    return () => {
      (window as any).isShowedEearthquakeAlert = false;
    };
  }, []);
  return (
    <div className={classes.earthquakeAlert}>
      {`緊急地震速報を受信しました。\n今から電源を落とします`}
    </div>
  );
}
