import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '@/app/store'
import { RootState } from '@/app/rootReducer'
import { Module } from '../modules'

export type ModuleWithCameras = Module & { cameras?: Camera[] }

export type CamerasState = {
  camerasById: Record<number, Camera>
}

export type Camera = {
  id: number
  module_id: number
  name: string
  ip: string
  login_id: string
  pass: string
  is_valid: number
}

export const initialCameraState: CamerasState = {
  camerasById: {}
}

const camerasSlice = createSlice({
  name: 'cameras',
  initialState: initialCameraState,
  reducers: {
    cameraGetsSucces(state, action: PayloadAction<Camera[]>) {
      action.payload.forEach(camera => {
        state.camerasById[camera.id] = camera
      })
    },
    cameraGetSuccess(state, action: PayloadAction<Camera>) {
      const camera = action.payload
      state.camerasById[camera.id] = camera
    },
    cameraDeleteSuccess(state, { payload }: PayloadAction<number>) {
      delete state.camerasById[payload]
    },
    camerasDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach(id => {
        delete state.camerasById[id]
      })
    }
  }
})

export const {
  cameraDeleteSuccess,
  cameraGetSuccess,
  cameraGetsSucces,
  camerasDeleteById
} = camerasSlice.actions

export default camerasSlice.reducer

export const camerasDelete = (
  dispatch: AppDispatch,
  rootState: RootState,
  scheduleIdList: number[]
) => {
  dispatch(camerasDeleteById(scheduleIdList))
}
