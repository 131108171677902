import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Module } from '../modules';

export type SiteModulesState = {
  siteModulesById: Record<number, Module>;
  unAttachModules: Record<number, Module[]>;
};

export const initialState: SiteModulesState = {
  siteModulesById: {},
  unAttachModules: {},
};

const siteModulesSlice = createSlice({
  name: 'siteModules',
  initialState,
  reducers: {
    siteModuleAddUnAttachModules(
      state,
      action: PayloadAction<{ siteId: number; modules: Module[] }>
    ) {
      const { siteId, modules } = action.payload;
      state.unAttachModules[siteId] = state.unAttachModules[siteId] || [];
      modules.forEach(mdl => {
        if (!state.unAttachModules[siteId].some(item => item.id === mdl.id)) {
          state.unAttachModules[siteId].push(mdl);
        }
      });
    },
    siteModuleSetUnAttachModules(
      state,
      action: PayloadAction<{ siteId: number; modules: Module[] }>
    ) {
      const { siteId, modules } = action.payload;
      state.unAttachModules[siteId] = [...modules];
    },
    removeSiteModules(state, action: PayloadAction<number[]>) {
      action.payload.forEach(moduleId => {
        delete state.siteModulesById[moduleId];
      });
    },
    siteModuleGetsSucces(state, action: PayloadAction<Module[]>) {
      action.payload.forEach(commsModule => {
        state.siteModulesById[commsModule.id] = commsModule;
      });
    },
  },
});

export const {
  siteModuleGetsSucces,
  siteModuleSetUnAttachModules,
  removeSiteModules,
  siteModuleAddUnAttachModules,
} = siteModulesSlice.actions;

export default siteModulesSlice.reducer;
