import React from 'react'
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem'
import { route } from '@/routes/url-generator'
import BreadCrumbs from '@/components/molecules/BreadCrumbs'

const AgentCompaniesBreadCrumb: React.FC = () => {
  return (
    <BreadCrumbs>
      <BreadCrumbItem title="代理店管理" to={route.agentCompanies.list} />
    </BreadCrumbs>
  )
}

export default AgentCompaniesBreadCrumb
