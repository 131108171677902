import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

type UseDeviceCommunicationProps = {
  raw?: boolean;
  disabled?: boolean;
};

type Unsub = () => void;
export type CommEvent = {
  onOpen?: () => void;
  onClose?: () => void;
  onMessage?: (payload: Record<string, any> | string) => void;
  send: WebSocket['send'];
  sendToEvent: (event: string, data?: any) => void;
  close: () => void;
  messageListeners: NonNullable<CommEvent['onMessage']>[];
  addMessageListener: (listener: CommEvent['messageListeners'][0]) => Unsub;
};

export function useDeviceCommunication({
  raw = false,
  disabled = false,
}: UseDeviceCommunicationProps = {}) {
  const [isAvailable, setAvailable] = useState(false);
  const refInfo = useRef<{ isDestroyed?: boolean }>({});

  useEffect(() => {
    return () => {
      refInfo.current.isDestroyed = true;
    };
  }, []);

  const events = useMemo(() => {
    return {
      messageListeners: [] as CommEvent['onMessage'][],
      addMessageListener: (listener: CommEvent['messageListeners'][0]) => {
        events.messageListeners.push(listener);
        return () => {
          const indexOf = events.messageListeners.indexOf(listener);
          events.messageListeners.splice(indexOf, 1);
        };
      },
    } as CommEvent;
  }, []);

  const reconnectTimer = useRef<NodeJS.Timeout>();

  const connectWS = useCallback(() => {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    return disabled
      ? null
      : new WebSocket(
          `${protocol}://${
            process.env.REACT_APP_WEB_SOCKET_URL || 'localhost:3004'
          }/ws/`
        );
  }, [disabled]);

  const defaultWs = useMemo(() => {
    return connectWS();
  }, [connectWS]);

  const [ws, setWS] = useState(defaultWs);

  const reconnect = useCallback(() => {
    if (reconnectTimer.current) {
      clearTimeout(reconnectTimer.current);
    }
    reconnectTimer.current = setTimeout(() => {
      console.log('Reconnecting...');
      setWS(connectWS());
    }, 2000);
  }, [connectWS]);

  useEffect(() => {
    if (!ws) {
      return;
    }
    ws.onopen = () => {
      console.log('open commucation');
      events.send = ws.send.bind(ws);
      events.sendToEvent = (event, data) => {
        return events.send(JSON.stringify({ event, data }));
      };
      events.close = ws.close.bind(ws);
      setAvailable(true);
      events.onOpen?.();
    };

    ws.onclose = async (e) => {
      console.log('close communication');
      events.send = () => {
        console.warn('Socket is disconnected');
      };

      if (!refInfo.current.isDestroyed) {
        setAvailable(false);
        reconnect();
      }

      events.onClose?.();
    };

    ws.onmessage = (message) => {
      // console.log('onmessage', message);
      const data = raw ? message.data : JSON.parse(message.data);
      events.onMessage?.(data);
      for (const messageListener of events.messageListeners) {
        messageListener(data);
      }
    };
  }, [ws, raw, events, reconnect]);

  return { isAvailable, events };
}
