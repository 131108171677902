import { createSelector } from 'reselect';
import { RootState } from '@/app/rootReducer';
import {
  UserTypeSiteManager,
  UserTypeGeneralManager,
  UserType,
  UserTypeAgent,
  User,
} from '@/slicers/users';
import { ResponseState } from '@/slicers/response';
import memoize from 'lodash.memoize';

export const getResponseSelector = createSelector(
  (state: RootState) => state.responseState.method,
  (state: RootState) => state.responseState.url,
  (state: RootState) => state.responseState.status,
  (state: RootState) => state.responseState.data,
  (state: RootState) => state.responseState.position,
  (method, url, status, data, position) => {
    const res: ResponseState = {
      url,
      method,
      status,
      data,
      position,
    };
    return res;
  }
);

export const getLoginUserSelector = createSelector(
  (state: RootState) => state.loginState.loginUserId,
  (state: RootState) => state.loginState.forwardQueue,
  (state: RootState) => state.usersState.usersById,
  (loginUserId, forwardQueue, users) => {
    if (!loginUserId) return null;
    const loginUser = users[loginUserId];
    const lastLayer = forwardQueue[forwardQueue.length - 1];

    if (!loginUser) {
      return null;
    }

    const realType = loginUser.type;

    if (lastLayer) {
      return {
        ...loginUser,
        realType,
        type: lastLayer.type,
        company_id: lastLayer.companyId,
      } as User;
    }
    return { ...loginUser, realType };
  }
);

export const getLoginUserAndCompanySelector = createSelector(
  (state: RootState) => state.loginState.loginUserId,
  (state: RootState) => state.loginState.forwardQueue,
  (state: RootState) => state.usersState.usersById,
  (state: RootState) => state.companiesState.companiesById,
  (loginUserId, forwardQueue, usersById, companiesById) => {
    const loginUser = loginUserId ? usersById[loginUserId] : null;
    const loginUserCompany = loginUser
      ? companiesById[loginUser.company_id]
      : null;
    const isLogin = !!(loginUser && loginUserCompany);
    const lastLayer = forwardQueue[forwardQueue.length - 1];

    if (!loginUser) {
      return {};
    }

    const realType = loginUser.type;

    if (lastLayer) {
      return {
        loginUser: {
          ...loginUser,
          realType,
          type: lastLayer.type,
          company_id: lastLayer.companyId,
        } as User,
        loginUserCompany,
        isLogin,
      };
    }

    return {
      loginUser: {
        ...loginUser,
        realType,
      },
      loginUserCompany,
      isLogin,
    };
  }
);

export const getSiteManagerUsers = createSelector(
  (state: RootState) => state.usersState.usersById,
  usersById => {
    const siteManagerUsers = Object.values(usersById).filter(
      user => user.type === UserTypeSiteManager
    );
    return siteManagerUsers;
  }
);

export const getGeneralManagerUserSelector = (companyId: number) =>
  createSelector(
    (state: RootState) => Object.values(state.usersState.usersById),
    users => {
      const generalManagerUser = users.filter(
        user =>
          user.company_id === companyId && user.type === UserTypeGeneralManager
      )[0];
      if (!generalManagerUser) return null;
      return generalManagerUser;
    }
  );

export const getAgentCompaniesUsersSelector = (companyId: number) =>
  createSelector(
    (state: RootState) => Object.values(state.usersState.usersById),
    users => {
      const agentCompaniesUsers = users.filter(
        user => user.company_id === companyId
      );
      return agentCompaniesUsers;
    }
  );

export const selectorFindAllUsersByCompanyId = (
  companyId: number,
  type?: UserType
) =>
  createSelector(
    (state: RootState) => state.usersState.usersById,
    usersById =>
      Object.values(usersById).filter(user => {
        let result = user.company_id === companyId;
        if (type) {
          result = result && user.type === type;
        }
        return result;
      })
  );

export const selectorFindUsersBySiteId = (siteId: number, type?: UserType) =>
  createSelector(
    (state: RootState) => state.usersState.usersById,
    usersById =>
      Object.values(usersById).filter(user => {
        let result = user.site_id === siteId;
        if (type) {
          result = result && user.type === type;
        }
        return result;
      })
  );

export const findGeneralManagerUsersByCompanyIdSelectorFactory = createSelector(
  (state: RootState) => state.usersState.usersById,
  usersById =>
    memoize((companyId: number) =>
      Object.values(usersById).filter(
        user =>
          user.company_id === companyId && user.type === UserTypeGeneralManager
      )
    )
);

export const findAgentGeneralManagerUsersByCompanyIdSelectorFactory = createSelector(
  (state: RootState) => state.usersState.usersById,
  usersById =>
    memoize((companyId: number) =>
      Object.values(usersById).filter(
        user => user.company_id === companyId && user.type === UserTypeAgent
      )
    )
);

export const findUserByIdSelectorFactory = createSelector(
  (state: RootState) => state.usersState.usersById,
  usersById =>
    memoize((userId: number) =>
      Object.values(usersById).find(user => user.id === userId)
    )
);
