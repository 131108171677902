import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { getLoginUserAndCompanySelector } from '@/selector/users';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';
import { CompanyUserType } from '@/routes/ClientRoute';
import { UserTypeGeneralManager, UserTypeSiteManager } from '@/slicers/users';
import { useCompanyUserType } from '@/hooks';
import { route } from '@/routes/url-generator';

const CompaniesUsersBreadCrumb: React.FC = () => {
  const type = useCompanyUserType();

  const userType = CompanyUserType[type];

  const { loginUser } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  );

  const Render = () => {
    if (!loginUser) return null;

    return (
      <BreadCrumbs>
        <BreadCrumbItem
          title={`${
            userType === UserTypeGeneralManager
              ? '企業管理者'
              : userType === UserTypeSiteManager
              ? '現場管理者'
              : '使用者'
          }管理`}
          to={route.clientRoute.companyUsers.list(loginUser.company_id, type)}
        />
      </BreadCrumbs>
    );
  };

  return <Render />;
};

export default CompaniesUsersBreadCrumb;
