import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: '.2rem'
    },
    circlureIcon: {
      marginRight: '.5rem',
      marginLeft: '.3rem'
    }
  })
)

export default useStyles

export const useBackButttonStyles = makeStyles((theme: Theme) =>
  createStyles({
    withIconButton: {
      padding: '6px 8px'
    }
  })
)
