import { AppThunk } from '@/app/store';
import { userGetSuccess } from '@/slicers/users';
import { companyGetSuccess } from '@/slicers/companies';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { setResponse } from '@/slicers/response';
import { setFlashError } from '@/slicers/flash';
import { setForwardQueue, setLoginUserId } from '@/slicers/login';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('')
class AuthRequest {
  caller!: TApiCaller;
  login(props: Record<string, any>) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.POST(`/oauth/token`, props);

        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          const { access_token } = response.data;
          await localStorage.setItem('accessToken', access_token);
        } else {
          dispatch(setFlashError('ログインに失敗しました'));
        }
      },
    };
  }

  fetchLoginUserAndCompany() {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/login_info`);
        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          dispatch(userGetSuccess(response.data.user));
          dispatch(setLoginUserId(response.data.user.id));

          const forwardQueue = localStorage.getItem('forwardQueue')
            ? JSON.parse(localStorage.getItem('forwardQueue')!)
            : [];

          dispatch(setForwardQueue(forwardQueue));

          dispatch(companyGetSuccess(response.data.company));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else if (response.status === 403) {
          dispatch(setFlashError('メール認証が完了していません'));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          // dispatch(setFlashError('ログイン情報の取得に失敗しました'))
        }
      },
    };
  }

  logout() {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.DEL(`/logout`);

        dispatch(setResponse(parseAxiosResponse(response)));
        if (response.status !== 200) {
          dispatch(setFlashError('ログアウトに失敗しました'));
        }

        dispatch(setForwardQueue([]));
        localStorage.removeItem('accessToken');
        localStorage.removeItem('forwardQueue');
      },
    };
  }
}

const authRequest = new AuthRequest();
export default authRequest;
