import React, { useCallback, useEffect, useState } from 'react';
import { route } from '@/routes/url-generator';
import { useHistory } from 'react-router-dom';
import { useGetClientRouteParam } from '@/hooks';
import moment from 'moment';
import { Box, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '@/components/atoms/Drawer';
import SiteForm from '@/components/organisms/SiteForm';
import List from '@/components/atoms/List';

import ScheduleForm from '../ScheduleForm';
import ListModule from '../ListModule';

import { setLoading } from '@/slicers/loading';
import sitesSchedulesRequest from '@/api/sites_schedules';
import { selectorSchedulesBySiteIdFactory } from '@/selector/schedule';
import { RootState } from '@/app/rootReducer';
import { getLoginUserSelector } from '@/selector/users';
import { User, UserTypeAgent, UserTypeOperator } from '@/slicers/users';

import classes from './styles.module.scss';
import { ScheduleFormData } from '@/components/organisms/ScheduleForm';
import SitesUsersRequest from '@/api/sites_users';
import { selectorFindUsersBySiteId } from '@/selector/siteUsers';
import AgentUserForm from '@/components/organisms/AgentUserForm';
import ErrorMessage from '../../ErrorMessage';
import { ErrorPosition } from '@/slicers/response';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';
import { getFormElements } from '@/utils/dom';

interface DetailProps {
  onSubmit: (
    closeDrawer: Function
  ) => (e: React.FormEvent<HTMLFormElement>) => void;
  errors: Record<string, string>;
  initialData: Record<string, any>;
  onConfirmDelete: (id: string) => void;
  editType?: string;
  onCancel: () => void;
  drawToAnchor?: () => Promise<void>;
}

export const weeks = {
  monday: '月',
  tuesday: '火',
  wednesday: '水',
  thursday: '木',
  friday: '金',
  saturday: '土',
  sunday: '日',
};

export type WeekDecorationProps = {
  week: keyof typeof weeks;
  valid: boolean;
};

export const WeekDecoration: React.FC<WeekDecorationProps> = ({
  valid,
  week,
}) => {
  return (
    <span
      className={[
        classes.week,
        valid ? classes.weekvalid : classes.weekInvalid,
      ].join(' ')}
    >
      {weeks[week]}
    </span>
  );
};

export default function Detail({
  drawToAnchor,
  errors,
  onSubmit,
  initialData,
  onConfirmDelete,
  editType,
  onCancel,
}: DetailProps) {
  const siteId = initialData.id;

  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();

  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.loadingState);
  const selectorSchedulesBySiteId = useSelector((state: RootState) =>
    selectorSchedulesBySiteIdFactory(state)
  );

  const userPagingOptions = useSelector(
    (state: RootState) => state.siteUsersState.pagingOptions
  );
  const selectorUsersBySiteId = useSelector((state: RootState) =>
    selectorFindUsersBySiteId(state)
  );

  const schedules = selectorSchedulesBySiteId(Number(siteId));
  const users = selectorUsersBySiteId(Number(siteId));

  const loginUser = useSelector((state: RootState) =>
    getLoginUserSelector(state)
  );

  const { companyId } = useGetClientRouteParam();

  const [isOpenEdit, setOpenEdit] = useState(false);

  const [isOpenEditSchedule, setOpenEditSchedule] = useState(false);
  const [scheduleEditData, setScheduleEditData] = useState<null | Record<
    string,
    any
  >>(null);
  const [scheduleErrors, setScheduleErrors] = useState<Record<string, string>>(
    {}
  );

  const [companySiteUserEditData, setCompanySiteUserEditData] = useState<
    undefined | User
  >(undefined);
  const [isOpenEditCompanySiteUser, setOpenEditCompanySiteUser] = useState(false);
  const [userErrors, setUserErrors] = useState<Record<string, string>>({});

  const onUserSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const fields = getFormElements(form);

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, input) => {
      const errorMessage =
        input.getAttribute('data-error-message') || '必須入力項目です';

      if (input.hasAttribute('required')) {
        if (input.name.includes('*')) {
          const [name, index] = input.name.split('.*.');
          if (index === '[]') {
            errors[name] = errorMessage;
          }
        } else {
          if (input.value === '' || input.value == null) {
            errors[input.name] = errorMessage;
          }
        }
      }
      if (input.name.includes('*')) {
        const [name, index] = input.name.split('.*.');
        if (index !== '[]') {
          data[name] = data[name] || [];
          data[name][index] = input.value;
        } else {
          data[name] = [];
        }
      } else {
        if (input.name === 'action_at') {
          data[input.name] = moment(input.value, 'HH:mm').format('HH:mm:ss');
        } else if (
          !!weeks[input.name as keyof typeof weeks] ||
          input.name === 'state'
        ) {
          data[input.name] = +input.value; //parse string to int
        } else {
          data[input.name] = input.value;
        }
      }
      return errors;
    }, {});

    setUserErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (companySiteUserEditData) {
        const api = SitesUsersRequest.put(
          siteId,
          companySiteUserEditData.id.toString()
        );
        await dispatch(api.request(data as User));
        setOpenEditCompanySiteUser(false);
      } else {
        const api = SitesUsersRequest.post(siteId);
        await dispatch(api.request(data as User));
        closeDrawer();
      }
      hideOverlay();
    }
  };

  const onDeleteUser = async (id: string) => {
    showOverlay();
    const api = SitesUsersRequest.delete(siteId, id);
    await dispatch(api.request());
    setOpenEditCompanySiteUser(false);
    hideOverlay();
  };

  const handleClickSwitch = (moduleId: any) => {
    history.push(
      route.clientRoute.sites.operationPanel(
        companyId,
        initialData.id,
        moduleId
      )
    );
  };

  const onScheduleSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;

    const fields = getFormElements(form);

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, input) => {
      const errorMessage =
        input.getAttribute('data-error-message') || '必須入力項目です';

      if (input.hasAttribute('required')) {
        if (input.name.includes('*')) {
          const [name, index] = input.name.split('.*.');
          if (index === '[]') {
            errors[name] = errorMessage;
          }
        } else {
          if (input.value === '' || input.value == null) {
            errors[input.name] = errorMessage;
          }
        }
      }
      if (input.name.includes('*')) {
        const [name, index] = input.name.split('.*.');
        if (index !== '[]') {
          data[name] = data[name] || [];
          data[name][index] = input.value;
        } else {
          data[name] = [];
        }
      } else {
        if (input.name === 'action_at') {
          data[input.name] = moment(input.value, 'HH:mm').format('HH:mm:ss');
        } else if (
          !!weeks[input.name as keyof typeof weeks] ||
          input.name === 'state'
        ) {
          data[input.name] = +input.value; //parse string to int
        } else {
          data[input.name] = input.value;
        }
      }
      return errors;
    }, {});

    setScheduleErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (scheduleEditData) {
        const api = sitesSchedulesRequest.put(siteId, scheduleEditData.id);
        await dispatch(api.request(data as ScheduleFormData));
        setScheduleEditData({ ...data, id: scheduleEditData.id });
      } else {
        const api = sitesSchedulesRequest.post(siteId);
        await dispatch(api.request(data as ScheduleFormData));
      }
      hideOverlay();
      closeDrawer();
      drawToAnchor && drawToAnchor();
    }
  };

  const onDeleteSchedule = async (id: string) => {
    showOverlay();
    const api = sitesSchedulesRequest.delete(siteId, id);
    await dispatch(api.request());
    hideOverlay();
  };

  const getUsers = useCallback(
    async (page?) => {
      await dispatch(setLoading(true));
      await dispatch(SitesUsersRequest.index(siteId).request(page));
      await dispatch(setLoading(false));
    },
    [dispatch, siteId]
  );

  const updateSitesScheduleValidStatus = useCallback(
    async (scheduleId: number, isValid: boolean) => {
      await dispatch(setLoading(true));
      await dispatch(
        sitesSchedulesRequest
          .putIsValid(siteId, scheduleId.toString())
          .request({ is_valid: Number(isValid) })
      );
      await dispatch(setLoading(false));
    },
    [dispatch, siteId]
  );

  useEffect(() => {
    if (!isOpenEditSchedule) {
      setScheduleErrors({});
      setScheduleEditData(null);
    }
  }, [isOpenEditSchedule]);

  const getInitialData = useCallback(async () => {
    await dispatch(setLoading(true));
    await Promise.all([
      dispatch(sitesSchedulesRequest.index(siteId).request()),
      dispatch(SitesUsersRequest.index(siteId).request()),
    ]);
    await dispatch(setLoading(false));
  }, [dispatch, siteId]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);
  useEffect(() => {
    if (!isOpenEditCompanySiteUser) {
      setUserErrors({});
      setCompanySiteUserEditData(undefined);
    }
  }, [isOpenEditCompanySiteUser]);
  return (
    <div className={classes.detail}>
      {overlayLoader}
      <ErrorMessage position={ErrorPosition.DRAWR} />
      {editType === 'company' && (
        <SiteForm
          initialData={initialData}
          errors={errors}
          onSubmit={onSubmit(() => setOpenEdit(false))}
          onCancel={onCancel}
          confirmDelete={() => onConfirmDelete(initialData.id)}
        />
      )}
      {editType === 'user' && (
        <List
          loading={loading}
          showSearch={false}
          total={userPagingOptions.total}
          getDrawerContent={(closeDrawer) => {
            const onCloseDrawer = () => {
              closeDrawer();
              setUserErrors({});
            };
            return (
              <AgentUserForm
                selectRole
                errors={userErrors}
                onCancel={onCloseDrawer}
                onSubmit={onUserSubmit(onCloseDrawer)}
              />
            );
          }}
          onChangePage={getUsers}
          data={users.map((user) => ({
            ...user,
            permission:
              user.type === UserTypeOperator ? '使用者' : '現場管理者',
          }))}
          columns={[
            {
              field: 'name',
              title: 'ユーザー名',
              width: '40%',
            },
            {
              field: 'email',
              title: 'メールアドレス',
              width: '40%',
            },
            {
              field: 'permission',
              title: '權限',
              width: '20%',
            },
          ]}
          title=""
          tooltip={{
            options: (item) =>
              item.email_verified_at
                ? [
                    {
                      key: 'edit',
                      title: 'ユーザーの編集',
                    },
                  ]
                : [
                    {
                      key: 'edit',
                      title: 'ユーザーの編集',
                    },
                    {
                      key: 'sendMail',
                      title: '認証メール送信',
                    },
                  ],
            onClick: async (key, record) => {
              if (key === 'edit') {
                setOpenEditCompanySiteUser(true);
                setCompanySiteUserEditData(record);
              }
              if (key === 'sendMail') {
                await dispatch(setLoading(true));
                await dispatch(
                  SitesUsersRequest.sendAuthMail(
                    String(record.site_id),
                    record.email
                  ).request()
                );
                await dispatch(setLoading(false));
              }
            },
          }}
          addButtonText="ユーザー作成"
        />
      )}
      {editType === 'module' && (
        <ListModule siteId={siteId} handleClickSwitch={handleClickSwitch} />
      )}
      {editType === 'schedule' && (
        <List
          loading={loading}
          getDrawerContent={(closeDrawer) => {
            const onCloseDrawer = () => {
              closeDrawer();
              setScheduleErrors({});
            };
            return (
              <ScheduleForm
                siteId={siteId}
                errors={scheduleErrors}
                onCancel={onCloseDrawer}
                onSubmit={onScheduleSubmit(onCloseDrawer)}
              />
            );
          }}
          data={schedules}
          columns={[
            {
              field: 'name',
              title: 'スケジュール名',
              width: '20%',
            },
            {
              field: 'is_valid',
              title: '有効',
              center: true,
              width: '10%',
              render: (is_valid, record) => (
                <Box>
                  <Switch
                    checked={!!is_valid}
                    color="primary"
                    onClick={() =>
                      updateSitesScheduleValidStatus(record.id, !is_valid)
                    }
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
              ),
            },
            {
              field: 'state',
              title: '実行内容',
              width: '15%',
              render: (state) => (
                <div
                  className={[
                    classes.switchState,
                    state ? classes.off : classes.on,
                  ].join(' ')}
                >
                  {state ? 'OFF' : 'ON'}
                </div>
              ),
            },
            {
              field: 'action_at',
              title: '時刻',
              width: '10%',
              render: (action_at) => (
                <div>{moment(action_at, 'HH:mm:ss').format('HH:mm')}</div>
              ),
            },
            {
              field: 'week',
              title: '曜日',
              render: (_, record) => (
                <Box style={{ minWidth: '15rem' }}>
                  {(Object.keys(weeks) as (keyof typeof weeks)[]).map(
                    (week) => (
                      <span key={week} className={classes.weekContainer}>
                        <WeekDecoration valid={!!record[week]} week={week} />
                      </span>
                    )
                  )}
                </Box>
              ),
            },
          ]}
          title=""
          tooltip={{
            options: [
              {
                key: 'edit',
                title: 'スケジュールの編集',
              },
              {
                key: 'delete',
                title: 'スケジュールの削除',
                isConfirmDelete: true,
              },
            ],
            onClick: (key, record) => {
              if (key === 'edit') {
                const { ...data } = record;
                const week = Object.keys(weeks).reduce((arr: string[], k) => {
                  delete data[k as keyof typeof data];
                  if (record[k as keyof typeof weeks]) {
                    arr.push(k);
                  }
                  return arr;
                }, []);
                setOpenEditSchedule(true);
                setScheduleEditData({
                  ...data,
                  week,
                });
              } else if (key === 'delete') {
                onDeleteSchedule(record.id.toString());
              }
            },
          }}
          addButtonText={
            loginUser?.type === UserTypeAgent ? '' : 'スケジュール作成'
          }
        />
      )}
      <Drawer isOpen={isOpenEdit} onChange={setOpenEdit}>
        {initialData && (
          <SiteForm
            initialData={initialData}
            errors={errors}
            onSubmit={onSubmit(() => setOpenEdit(false))}
            onCancel={() => setOpenEdit(false)}
          />
        )}
      </Drawer>
      <Drawer isOpen={isOpenEditCompanySiteUser} onChange={setOpenEditCompanySiteUser}>
        {companySiteUserEditData && (
          <AgentUserForm
            initialData={companySiteUserEditData}
            errors={userErrors}
            onSubmit={onUserSubmit(() => setOpenEditCompanySiteUser(false))}
            onCancel={() => setOpenEditCompanySiteUser(false)}
            onConfirmDelete={() => {
              onDeleteUser(companySiteUserEditData?.id.toString());
            }}
          />
        )}
      </Drawer>
      <Drawer isOpen={isOpenEditSchedule} onChange={setOpenEditSchedule}>
        {scheduleEditData && (
          <ScheduleForm
            siteId={siteId}
            initialData={scheduleEditData}
            errors={scheduleErrors}
            onSubmit={onScheduleSubmit(() => setOpenEditSchedule(false))}
            onCancel={() => setOpenEditSchedule(false)}
          />
        )}
      </Drawer>
    </div>
  );
}
