import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import { route } from '@/routes/url-generator';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { getLoginUserAndCompanySelector } from '@/selector/users';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';

const ModulesBreadCrumb: React.FC = () => {
  const { loginUserCompany, loginUser } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  );

  const Render = () => {
    if (!(loginUser && loginUserCompany)) return null;

    return (
      <BreadCrumbs>
        <BreadCrumbItem title="遠隔電源司令盤管理" to={route.modules.list} />
      </BreadCrumbs>
    );
  };

  return <Render />;
};

export default ModulesBreadCrumb;
