import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { EnhancedStore } from '@reduxjs/toolkit'

type ProviderWrapperProps = {
  children: React.ReactChild
  store: EnhancedStore
}

const ProviderWrapper: React.FC<ProviderWrapperProps> = ({
  children,
  store
}) => (
  <Provider store={store}>
    <Router>{children}</Router>
  </Provider>
)

export default ProviderWrapper
