import { Visibility, VisibilityOff } from '@material-ui/icons';

import React, { useState, useCallback } from 'react';

import classes from './styles.module.scss';

interface PasswordProps {
  defaultValue?: string;
  prefix?: React.ReactElement | string | null | boolean;
  label: string;
  name?: string;
  required?: boolean;
  error?: string;
}

export default function Password({
  defaultValue,
  prefix,
  label,
  name,
  required,
  error,
}: PasswordProps) {
  const [isVisble, setVisible] = useState(false);

  const toggle = useCallback(() => {
    setVisible(prev => !prev);
  }, []);

  return (
    <div className={[classes.password, error ? classes.error : ''].join(' ')}>
      <label>
        <span className={classes.label}>{label}</span>
        <div className={classes.wrapper}>
          {prefix && <div className={classes.prefix}>{prefix}</div>}
          {isVisble ? (
            <>
              <input
                defaultValue={defaultValue}
                name={name}
                required={required}
                autoComplete="off"
                type="text"
              />
              <Visibility onClick={toggle} />
            </>
          ) : (
            <>
              <input
                defaultValue={defaultValue}
                name={name}
                required={required}
                autoComplete="off"
                type="password"
              />
              <VisibilityOff onClick={toggle} />
            </>
          )}
        </div>
      </label>
      <span className={classes.errorMessage}>{error}</span>
    </div>
  );
}
