import React, { useState } from 'react';

import classes from './styles.module.scss';

import Drawer from '@/components/atoms/Drawer';
import AgentCompanyForm from '@/components/organisms/AgentCompanyForm';
import ListUser from '../ListUser';
import ListModule from '../ListModule';
import ListSwitch from '../ListSwitch';
import ErrorMessage from '../../ErrorMessage';
import { ErrorPosition } from '@/slicers/response';

interface DetailProps {
  onSubmit: (
    closeDrawer: Function
  ) => (e: React.FormEvent<HTMLFormElement>) => void;
  errors: Record<string, string>;
  initialData: Record<string, any>;
  onConfirmDelete: (id: string) => void;
  editType: string;
  onCancel?: () => void;
}

export default function Detail({
  errors,
  onSubmit,
  initialData,
  onConfirmDelete,
  editType,
  onCancel = () => {},
}: DetailProps) {
  const [isOpenEdit, setOpenEdit] = useState(false);

  return (
    <div className={classes.detail}>
      <ErrorMessage position={ErrorPosition.DRAWR} />
      {editType === 'agent' && (
        <AgentCompanyForm
          initialData={initialData}
          errors={errors}
          onSubmit={onSubmit(() => setOpenEdit(false))}
          onCancel={onCancel}
          onConfirmDelete={() => onConfirmDelete(initialData.id)}
          onDeleteMessage="企業を削除すると現場データ、ユーザーすべてが削除されます。本当に削除してもよろしいですか？"
        />
      )}
      {editType === 'agentmanager' && <ListUser companyId={initialData.id} />}
      {editType === 'module' && <ListModule companyId={initialData.id} />}
      {editType === 'switch' && <ListSwitch companyId={initialData.id} />}
      <Drawer isOpen={isOpenEdit} onChange={setOpenEdit}>
        {initialData && (
          <AgentCompanyForm
            initialData={initialData}
            errors={errors}
            onSubmit={onSubmit(() => setOpenEdit(false))}
            onCancel={onCancel}
          />
        )}
      </Drawer>
    </div>
  );
}
