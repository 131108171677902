import React from 'react'
import classes from './styles.module.scss'
import { useForm } from 'react-hook-form'
import { TextField } from '@material-ui/core'

const RemarkTextField: React.FC<{
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    remark: string;
  }> = ({ handleChange, remark }) => {
  const { register } = useForm();

  return (
            <div className={classes.root}>
              <TextField
                onChange={handleChange}
                multiline
                rows={21}
                variant="outlined"
                fullWidth
                label="備考"
                name="remarks"
                id="remarks"
                value={remark}
                inputRef={register}
              />
            </div>
  )
}

export default RemarkTextField;
