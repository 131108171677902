import { RootState } from '@/app/rootReducer'
import { createSelector } from 'reselect'
import memoize from 'lodash.memoize'
import { Schedule } from '@/slicers/schedules'

export const selectorFindScheduleWithItemsById = (scheduleId: number) =>
  createSelector(
    (state: RootState) => state.scheduleState.schedulesById,
    (state: RootState) => state.scheduleItemState.scheduleItemsById,
    (schedulesById, scheduleItemsById) => {
      const schedule = schedulesById[scheduleId]
      if (!schedule) return null
      const scheduleItems =
        Object.values(scheduleItemsById).filter(
          item => item.schedule_id === scheduleId
        ) || []

      return { ...schedule, items: scheduleItems }
    }
  )

export const selectorSchedulesBySiteIdFactory = createSelector(
  (state: RootState) => state.scheduleState.schedulesById,
  (schedulesById: Record<number, Schedule>) =>
    memoize((siteId: number) => {
      const schedules = Object.values(schedulesById).filter(
        schedule => schedule.site_id === siteId
      )
      return schedules
    })
)
