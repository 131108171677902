import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { getLoginUserAndCompanySelector } from '@/selector/users';
import { User } from '@/slicers/users';
import { useHistory } from 'react-router-dom';
import { route } from '@/routes/url-generator';
import UserForm from '@/components/organisms/UserForm';
import usersRequest from '@/api/users';
import { ApiHeader } from '@/slicers/response';

const LoginAccountUserShow: React.FC = () => {
  const { loginUser: user } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  );
  const dispatch = useDispatch();
  const api = usersRequest.put(user?.id.toString() || '');
  const history = useHistory();

  const handleSubmit = async (data: User) => {
    if (!user) return;

    dispatch(api.request(data));
  };

  const handleClickBackButton = () => {
    history.push(route.login);
  };

  return user ? (
    <UserForm
      currentType="show"
      onSubmit={handleSubmit}
      data={{ ...user, password: '' }}
      password={false}
      expectResponse={
        {
          url: `/api/users/${user?.id}`,
          method: 'put',
          status: 200,
        } as ApiHeader
      }
      succesEditMessage="更新しました"
      backUrl={handleClickBackButton}
    />
  ) : null;
};

export default LoginAccountUserShow;
