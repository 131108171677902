import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/app/store';
import { usersDelete } from '../users';
import { sitesDelete } from '../sites';
import { RootState } from '@/app/rootReducer';
import { modulesDelete } from '../modules';
import { PagingOptions } from '@/interfaces';

export type CompanysState = {
  companiesById: Record<number, Company>;
  pagingOptions: PagingOptions;
};

export type Company = {
  name: string;
  post_code: string;
  pref: string;
  address: string;
  tel: string;
  fax: string | null;
  id: number;
  type: CompanyType;
  parent_id: number | null;
  created_at?: string;
  parent_company?: Company;
};

export type CompanyType = 100 | 200 | 300;

export const CompanyTypeAdmin = 100;
export const CompanyTypeAgent = 200;
export const CompanyTypeNormal = 300;

export const initialState: CompanysState = {
  companiesById: {},
  pagingOptions: {
    total: 0,
    perPage: 1,
  },
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    companySetPagingOptions(state, action: PayloadAction<PagingOptions>) {
      state.pagingOptions.perPage = action.payload.perPage;
      state.pagingOptions.total = action.payload.total;
    },
    clearCompanies(state) {
      const deleteIds = Object.keys(state.companiesById);
      deleteIds.forEach(id => {
        if (state.companiesById[id as any].type === CompanyTypeNormal) {
          delete state.companiesById[id as any];
        }
      });
    },
    companyGetsSucces(state, action: PayloadAction<Company[]>) {
      action.payload.forEach(company => {
        if (company) {
          state.companiesById[company.id] = company;
        }
      });
    },
    companyGetSuccess(state, action: PayloadAction<Company>) {
      const company = action.payload;
      state.companiesById[company.id] = company;
    },
    companyDeleteSuccess(state, { payload }: PayloadAction<number>) {
      delete state.companiesById[payload];
    },
    companiesDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach(id => {
        delete state.companiesById[id];
      });
    },
  },
});

export const {
  clearCompanies,
  companyDeleteSuccess,
  companyGetSuccess,
  companyGetsSucces,
  companiesDeleteById,
  companySetPagingOptions
} = companiesSlice.actions;

export default companiesSlice.reducer;

export const companiesDelete = (
  dispatch: AppDispatch,
  rootState: RootState,
  companyIdList: number[]
) => {
  dispatch(companiesDeleteById(companyIdList));

  const userIds = Object.values(rootState.usersState.usersById)
    .filter(user => companyIdList.includes(user.company_id))
    .map(user => user.id);
  usersDelete(dispatch, rootState, userIds);

  const siteIds = Object.values(rootState.sitesState.sitesById)
    .filter(site => companyIdList.includes(site.company_id))
    .map(site => site.id);
  sitesDelete(dispatch, rootState, siteIds);

  const commModuleIds = Object.values(rootState.modulesState.modulesById)
    .filter(commModule => companyIdList.includes(commModule.company_id))
    .map(commModule => commModule.id);
  modulesDelete(dispatch, rootState, commModuleIds);
};
