import { Switch } from '@material-ui/core'
import { withStyles, createStyles, Theme } from '@material-ui/core/styles'

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 48,
      height: 16,
      padding: 0,
      display: 'flex'
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(32px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.error.main,
          borderColor: theme.palette.error.main
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none'
    },
    track: {
      border: `1px solid ${theme.palette.success.main}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.success.main
    },
    checked: {}
  })
)(Switch)

export default AntSwitch
