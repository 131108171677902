import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import {
  moduleGetSuccess,
  moduleGetsSucces,
  moduleDeleteSuccess,
  Module,
  moduleSetUnAttachModules,
  moduleAddUnAttachModules,
} from '@/slicers/modules';
import { ErrorPosition } from '@/slicers/response';
import { setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import { cameraGetsSucces } from '@/slicers/cameras';
import { MainApiCaller, TApiCaller } from '@/api-service';

export const readModuleWithToModuleWithMap = (
  readModuleWith: Record<string, any>
): Module => {
  const { units = [], ...other } = readModuleWith;
  let moduleWith: Module = { ...other, unitsByNumber: {} } as any;

  units.forEach((powerUnit: any) => {
    const { switches, ...other } = powerUnit;
    moduleWith.unitsByNumber[powerUnit.number] = {
      ...other,
      switchesByNumber: {},
    };
    switches.forEach((powerSwitch: any) => {
      moduleWith.unitsByNumber[powerUnit.number].switchesByNumber[
        powerSwitch.number
      ] = powerSwitch;
    });
  });

  return moduleWith;
};

@MainApiCaller('/normal_companies')
class CompaniesModulesRequest {
  caller!: TApiCaller;
  get(companyId: string, moduleId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(
          `/${companyId}/modules/${moduleId}`
        );
        if (response.status === 200) {
          dispatch(
            moduleGetSuccess(readModuleWithToModuleWithMap(response.data))
          );
          dispatch(cameraGetsSucces(response.data.cameras));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました'));
        }
      },
    };
  }

  index(companyId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/${companyId}/modules`);

        if (response.status === 200) {
          dispatch(
            moduleGetsSucces(
              response.data.map((readModuleWith: any) =>
                readModuleWithToModuleWithMap(readModuleWith)
              )
            )
          );
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  unAttach(companyId: number) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(
          `/${companyId}/module_without_company`
        );
        if (response.status === 200) {
          dispatch(
            moduleSetUnAttachModules({
              companyId,
              modules: response.data.map((readModuleWith: any) =>
                readModuleWithToModuleWithMap(readModuleWith)
              ),
            })
          );
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(
            setResponse({
              ...parseAxiosResponse(response),
              position: ErrorPosition.DRAWR,
            })
          );
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  attach(companyId: number, moduleId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.PUT(
          `/${companyId}/modules/${moduleId}/attach`
        );
        if (response.status === 200) {
          dispatch(
            moduleGetSuccess(readModuleWithToModuleWithMap(response.data))
          );
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashSuccess('指令盤をレンタルしました'));
        } else {
          dispatch(
            setResponse({
              ...parseAxiosResponse(response),
              position: ErrorPosition.DRAWR,
            })
          );
          dispatch(setFlashError('指令盤のレンタルに失敗しました。'));
        }
      },
    };
  }

  detach(companyId: number, moduleId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.PUT(
          `/${companyId}/modules/${moduleId}/detach`
        );

        if (response.status === 200) {
          dispatch(
            moduleAddUnAttachModules({
              companyId,
              modules: [readModuleWithToModuleWithMap(response.data)],
            })
          );
          dispatch(moduleDeleteSuccess(response.data.id));
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashSuccess('親機のレンタルの解除をしました'));
        } else {
          dispatch(
            setResponse({
              ...parseAxiosResponse(response),
              position: ErrorPosition.DRAWR,
            })
          );
          dispatch(setFlashError('親機のレンタルの解除に失敗しました。'));
        }
      },
    };
  }
}

const companiesModulesRequest = new CompaniesModulesRequest();

export default companiesModulesRequest;
