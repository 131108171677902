import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';

const AgentCompaniesGeneralManagerUsersBreadCrumbList: React.FC = () => {
  return (
    <React.Fragment>
      <BreadCrumbItem title="代理店管理者一覧" />
    </React.Fragment>
  );
};

export default AgentCompaniesGeneralManagerUsersBreadCrumbList;
