import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import {
  companyGetsSucces,
  clearCompanies,
  companySetPagingOptions,
} from '@/slicers/companies';
import { setResponse } from '@/slicers/response';
import { setFlashError } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/normal_companies')
class CompaniesNormalCompaniesRequest {
  caller!: TApiCaller;

  index(companyId?: string) {
    return {
      request:
        (page?: number, keyword?: string): AppThunk =>
        async (dispatch) => {
          const query = {
            page: page || 1,
            search: keyword,
            paginate: page === -1 ? 0 : 1,
          };
          if (query.page === 1) {
            dispatch(clearCompanies());
          }

          const response = await this.caller.GET(
            companyId ? `/${companyId}` : '/',
            query
          );

          if (response.status === 200) {
            dispatch(clearCompanies());
            if (Array.isArray(response.data)) {
              dispatch(
                companySetPagingOptions({
                  total: response.data.length,
                  perPage: response.data.length,
                })
              );
              dispatch(companyGetsSucces(response.data));
            } else {
              const { data, total, per_page } = response.data;
              dispatch(
                companySetPagingOptions({
                  total,
                  perPage: per_page,
                })
              );
              dispatch(companyGetsSucces(data));
            }

            dispatch(setResponse(parseAxiosResponse(response)));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました'));
          }
        },
    };
  }
}

const companiesNormalCompaniesRequest = new CompaniesNormalCompaniesRequest();
export default companiesNormalCompaniesRequest;
