import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';

const AgentCompaniesModulesBreadCrumbList: React.FC = () => {
  return (
    <React.Fragment>
      <BreadCrumbItem title="指令盤一覧" />
    </React.Fragment>
  );
};

export default AgentCompaniesModulesBreadCrumbList;
