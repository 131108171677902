import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TablePagination } from '@material-ui/core';

import { RootState } from '@/app/rootReducer';

import { getLoginUserAndCompanySelector } from '@/selector/users';
import { selectorFindAllModules } from '@/selector/module';

import { setLoading } from '@/slicers/loading';
import { UserTypeAgent } from '@/slicers/users';

import modulesRequest from '@/api/modules';

import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import { CustomButton } from '@/components/atoms/CustomButton';
import ConfirmDelete from '@/components/atoms/ConfirmDelete';
import ForwardLogin from '@/components/organisms/ForwardLogin';

const ModulesList: React.FC = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentKw, setCurrentKw] = useState('');

  const onDeleteModule = async (id: string) => {
    dispatch(setLoading(true));
    const api = modulesRequest.delete(id);
    await dispatch(api.request());
    dispatch(setLoading(false));
  };

  const pagingOptions = useSelector(
    (state: RootState) => state.modulesState.pagingOptions
  );
  const modules = useSelector((state: RootState) =>
    selectorFindAllModules(state)
  );

  const { loginUser } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  );

  const getModules = useCallback(
    async (page?, keyword?) => {
      dispatch(setLoading(true));
      await dispatch(modulesRequest.index().request(page, keyword));
      dispatch(setLoading(false));
    },
    [dispatch]
  );

  const mdls = useMemo(() => {
    return loginUser ? modules.map((item: any) => ({ ...item })) : [];
  }, [modules, loginUser]);

  return (
    <>
      <CustomMaterialTable
        onSearchChange={(text) => {
          setCurrentKw(text);
          setCurrentPage(1);
          getModules(1, text);
        }}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              rowsPerPageOptions={[]}
              rowsPerPage={5}
              page={currentPage - 1}
              count={loginUser ? pagingOptions.total : 0}
              onChangePage={(e, page) => {
                setCurrentPage(page + 1);
                getModules(page + 1, currentKw);
              }}
            />
          ),
        }}
        title="指令盤一覧"
        options={{
          pageSizeOptions: [5],
        }}
        searchPlaceholder="デバイスコード・指令盤名・管理番号"
        columns={[
          {
            field: 'id',
            title: 'ID',
          },
          {
            field: 'control_no',
            title: '管理番号',
          },
          {
            field: 'device_code',
            title: 'デバイスコード',
          },
          {
            field: 'name',
            title: '指令盤名',
          },
          {
            field: 'company',
            title: 'レンタル企業名',
            render: ({ company }: any) => {
              return company?.name;
            },
          },
          {
            field: 'owner',
            title: '所有代理店名',
            render: ({ owner }: any) => {
              return (
                !!owner && (
                  <ForwardLogin type={UserTypeAgent} companyId={owner.id}>
                    <span
                      style={{
                        cursor: 'pointer',
                        color: '#90caf9',
                      }}
                    >
                      {owner.name}
                    </span>
                  </ForwardLogin>
                )
              );
            },
          },
          {
            field: 'action',
            title: '',
            render: ({ id, owner }: any) => {
              return (
                <ConfirmDelete
                  message="親機を完全に削除します。よろしいですか。"
                  onConfirm={() => {
                    onDeleteModule(id);
                  }}
                >
                  <CustomButton disabled={!!owner} color="error">
                    削除
                  </CustomButton>
                </ConfirmDelete>
              );
            },
          },
        ]}
        data={mdls}
      />
    </>
  );
};

export default ModulesList;
