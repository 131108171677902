import React, { useRef, useMemo } from 'react';
import ConfirmDelete, { ConfirmDeleteOptionalProps } from '../ConfirmDelete';
import { CustomButton } from '../CustomButton';

import classes from './styles.module.scss';

export interface Column<T> {
  center?: boolean;
  field: string;
  key?: string;
  title: string | React.ReactElement;
  width?: number | string;
  render?: (value: any, record: T) => string | React.ReactElement | null;
  onClick?: (value: any, record: T) => void;
}

export type OptionsRow = Record<string, string | React.ReactElement | null>;

type ToolTipOption = {
  key: string;
  title: string;
  isConfirmDelete?: boolean;
  confirmProps?: ConfirmDeleteOptionalProps;
  to?: string;
};
export interface TableProps<T> {
  onSort?: (a: T, b: T) => number;
  className?: string;
  size?: 'medium' | 'small';
  emptyMessage?: string;
  loading?: boolean;
  tooltip?: {
    options: ToolTipOption[] | ((record: T) => ToolTipOption[]);
    onClick: (key: string, record: T) => void;
  };
  columns: Column<T>[];
  data: T[];
  optionsRow?: OptionsRow | OptionsRow[];
}

export default function Table<T extends { [key: string]: any; id: number }>({
  onSort,
  size = 'medium',
  className,
  loading,
  optionsRow = [],
  emptyMessage = 'データがありません',
  tooltip,
  columns: _columns,
  data,
}: TableProps<T>) {
  const refTable = useRef<any>();

  const dataSource = useMemo(() => {
    if (!onSort) {
      return data;
    }

    const dt = [...data];
    dt.sort(onSort);
    return dt;
    // eslint-disable-next-line
  }, [onSort, JSON.stringify(data)]);

  const columns = useMemo(() => {
    const cols = [..._columns];
    if (tooltip?.options.length) {
      cols.push({
        title: 'アクション',
        field: 'tooltip',
        render: (_, item) => {
          const options =
            typeof tooltip?.options === 'function'
              ? tooltip.options(item)
              : tooltip?.options || [];

          return (
            <div className={classes.toolRow}>
              {options.map((opt) =>
                opt.isConfirmDelete ? (
                  <ConfirmDelete
                    key={opt.key}
                    onConfirm={() => {
                      tooltip.onClick(opt.key, item);
                    }}
                    {...(opt.confirmProps || {})}
                  >
                    <CustomButton key={opt.key} color="grey">
                      {opt.title}
                    </CustomButton>
                  </ConfirmDelete>
                ) : (
                  <CustomButton
                    key={opt.key}
                    color="grey"
                    onClick={() => {
                      tooltip.onClick(opt.key, item);
                    }}
                  >
                    {opt.title}
                  </CustomButton>
                )
              )}
            </div>
          );
        },
      });
    }
    return cols;
    // eslint-disable-next-line
  }, [JSON.stringify(tooltip), JSON.stringify(_columns)]);
  //   {tooltip.options.map(opt =>
  //     opt.isConfirmDelete ? (
  //       <ConfirmDelete
  //         key={opt.key}
  //         onCancel={() => {
  //           setCurrentTooltip(null);
  //         }}
  //         onConfirm={() => {
  //           setCurrentTooltip(null);
  //           tooltip.onClick(opt.key, item);
  //         }}
  //         {...(opt.confirmProps || {})}
  //       >
  //         <div>{opt.title}</div>
  //       </ConfirmDelete>
  //     ) : (
  //       <div
  //         key={opt.key}
  //         onClick={() => {
  //           setCurrentTooltip(null);
  //           tooltip.onClick(opt.key, item);
  //         }}
  //       >
  //         {opt.title}
  //       </div>
  //     )
  //   )}

  return (
    <div
      ref={refTable}
      className={[classes.table, className, classes[size]].join(' ')}
    >
      <table>
        <thead>
          <tr>
            {columns.map((col) => {
              const width =
                col.width != null
                  ? typeof col.width === 'number'
                    ? `${col.width}px`
                    : col.width
                  : null;

              const style: any = {};
              if (width) {
                style.width = width;
              } else {
                style.minWidth = 240;
              }

              if (col.center) {
                style.textAlign = 'center';
              }

              return (
                <td style={style} key={col.key || col.field}>
                  {col.title}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(optionsRow)
            ? optionsRow
            : [optionsRow].map((row, index) => (
                <tr key={index}>
                  {columns.map((col) => {
                    const width =
                      col.width != null
                        ? typeof col.width === 'number'
                          ? `${col.width}px`
                          : col.width
                        : null;

                    const style: any = {};
                    if (width) {
                      style.width = width;
                    } else {
                      style.minWidth = 240;
                    }

                    if (col.center) {
                      style.textAlign = 'center';
                    }
                    return (
                      <td style={style} key={col.key || col.field}>
                        {row[col.field]}
                      </td>
                    );
                  })}
                </tr>
              ))}
          {dataSource.length ? (
            dataSource.map((item) => {
              return (
                <tr key={item.id}>
                  {columns.map((col) => {
                    const width =
                      col.width != null
                        ? typeof col.width === 'number'
                          ? `${col.width}px`
                          : col.width
                        : null;

                    const style: any = {};
                    if (width) {
                      style.width = width;
                    } else {
                      style.minWidth = 150;
                    }

                    if (col.center) {
                      style.textAlign = 'center';
                    }

                    if (col.onClick) {
                      style.cursor = 'pointer';
                    }

                    return (
                      <td
                        key={col.key || col.field}
                        style={style}
                        onClick={() =>
                          col.onClick && col.onClick(item[col.field], item)
                        }
                      >
                        {col.render
                          ? col.render(item[col.field], item)
                          : item[col.field]}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <div className={classes.emptyMessage}>
                  {loading ? 'データ取得中' : emptyMessage}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
