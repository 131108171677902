import React, { useState } from 'react';
import styles from './styles.module.scss';
import {
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { User } from '@/slicers/users';
import { FormTypeManagerProps, useFormTypeManager } from '@/hooks';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export type UserFormData = {
  password: string;
} & User;

export type LoginFormProps = {
  data?: UserFormData | User;
  password: boolean;
} & FormTypeManagerProps<UserFormData>;

const UserForm: React.FC<LoginFormProps> = ({ data, password, ...props }) => {
  const {
    Footer,
    inputProps,
    useFormMethods,
  } = useFormTypeManager<UserFormData>(props);
  const { register, errors } = useFormMethods;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((state) => !state);
  };

  return (
    <form>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <TextField
            required
            label="名前"
            name="name"
            defaultValue={data ? data.name : ''}
            inputRef={register({ required: true })}
            InputProps={inputProps}
          />
          {errors.name && 'name is required.'}

          {password && (
            <FormControl>
              <InputLabel htmlFor="standard-adornment-password">
                パスワード
              </InputLabel>
              <Input
                required
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                name="password"
                inputRef={register({ required: true })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.password && 'password is required.'}
            </FormControl>
          )}

          <TextField
            required
            label="メールアドレス"
            name="email"
            defaultValue={data ? data.email : ''}
            type="email"
            inputRef={register({ required: true })}
          />
          {errors.email && 'email is required.'}
        </div>
      </div>
      <div className={styles.button_container}>
        <Footer />
      </div>
    </form>
  );
};

export default UserForm;
