import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { useParams } from 'react-router-dom';
import { setLoading } from '@/slicers/loading';
import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import sitesModulesRequest from '@/api/sites_modules';
import { selectorFindAllModulesBySiteId } from '@/selector/siteModules';

const OperatorModuleList: React.FC = () => {
  const dispatch = useDispatch();
  const { site: siteId } = useParams<{ site: string }>();

  const modules = useSelector(
    (state: RootState) => selectorFindAllModulesBySiteId(Number(siteId))(state),
    shallowEqual
  );

  const getInitialData = useCallback(async () => {
    await dispatch(setLoading(true));
    await dispatch(sitesModulesRequest.index(siteId.toString()).request());
    await dispatch(setLoading(false));
  }, [dispatch, siteId]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const data = useMemo(() => {
    return modules.map((item) => ({ ...item }));
    // eslint-disable-next-line
  }, [JSON.stringify(modules)]);

  return (
    <>
      <CustomMaterialTable
        title="指令盤"
        options={{
          pageSizeOptions: [5],
        }}
        columns={[
          {
            field: 'id',
            title: 'ID',
          },
          {
            field: 'name',
            title: '指令盤名',
          },
          {
            field: 'control_no',
            title: '資産番号',
          },
          {
            field: 'device_code',
            title: 'デバイスコード',
          },
        ]}
        data={data}
      />
    </>
  );
};

export default OperatorModuleList;
