import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import {
  siteUserGetsSucces,
  siteUserGetSuccess,
  clearSiteUsers,
  siteUsersDelete,
  siteUserSetPagingOptions,
  setFirstUser,
} from '@/slicers/siteUsers';
import { ErrorPosition, setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/sites')
class SitesUsersRequest {
  caller!: TApiCaller;
  index(siteId: string) {
    return {
      request:
        (page?: number, keyword?: string): AppThunk =>
        async (dispatch) => {
          const query = {
            page: page || 1,
            search: keyword,
          };
          if (query.page === 1) {
            dispatch(clearSiteUsers());
          }
          const response = await this.caller.GET(`/${siteId}/users`, query);

          if (response.status === 200) {
            const { data, total, per_page } = response.data;
            dispatch(clearSiteUsers());
            dispatch(
              siteUserSetPagingOptions({
                total,
                perPage: per_page,
              })
            );
            dispatch(siteUserGetsSucces(data));
            dispatch(setResponse(parseAxiosResponse(response)));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('取得が失敗しました。'));
          }
        },
    };
  }

  firstUser(siteId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        dispatch(setFirstUser(null));

        const response = await this.caller.GET(`/${siteId}/first_user`);
        if (response.status === 200) {
          if (response.data) {
            dispatch(
              setFirstUser(
                Object.keys(response.data).length ? response.data : null
              )
            );
          } else {
            dispatch(setFirstUser(null));
          }
        } else {
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  post(siteId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(`/${siteId}/users`, data);
          if (response.status === 201) {
            dispatch(siteUserGetSuccess(response.data));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }

  put(siteId: string, userId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${siteId}/users/${userId}`,
            data
          );
          if (response.status === 200) {
            dispatch(siteUserGetSuccess(response.data));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }


  delete(siteId: string, userId: string) {
    return {
      request: (): AppThunk => async (dispatch, getState) => {
        const response = await this.caller.DEL(`/${siteId}/users/${userId}`);

        dispatch(setResponse(parseAxiosResponse(response)));
        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました'));
          siteUsersDelete(dispatch, getState(), [Number(userId)]);
        } else {
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }

  sendAuthMail(siteId: string, email: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(
          `/${siteId}/send_auth_mail/${email}`
        );
        if (response.status === 200) {
          dispatch(setFlashSuccess('認証メール送信が完了しました。'));
        } else {
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }
}

const sitesUsersRequest = new SitesUsersRequest();
export default sitesUsersRequest;
