import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagingOptions } from '@/interfaces';
import { Switch } from '../switches';

export type SwitchesState = {
  switchesById: Record<number, Switch>;
  pagingOptions: PagingOptions;
  unAttachSwitches: Record<number, Switch[]>;
  unAttachSwitchesModule: Record<number, Switch[]>;
};

export const initialState: SwitchesState = {
  switchesById: {},
  pagingOptions: {
    total: 0,
    perPage: 5,
  },
  unAttachSwitches: [],
  unAttachSwitchesModule: [],
};

const companySwitchesSlice = createSlice({
  name: 'companySwitches',
  initialState,
  reducers: {
    companySwitchGetsSucces(state, action: PayloadAction<Switch[]>) {
      action.payload.forEach((companySwitch) => {
        state.switchesById[companySwitch.id] = companySwitch;
      });
    },
    companySwitchGetSuccess(state, action: PayloadAction<Switch>) {
      const switchItem = action.payload;
      state.switchesById[switchItem.id] = switchItem;
    },
    companySwitchUpdateSuccess(state, action: PayloadAction<Switch>) {
      const switchItem = action.payload;
      state.switchesById[switchItem.id] = switchItem;
    },
    companySwitchRentSuccess(state, action: PayloadAction<Switch>) {
      const switchItem = action.payload;
      state.switchesById[switchItem.id] = switchItem;
    },
    companySwitchesDetachById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach((id) => {
        delete state.switchesById[id];
      });
    },
    companyAddUnAttachSwitches(
      state,
      action: PayloadAction<{ companyId: number; switches: Switch[] }>
    ) {
      const { companyId, switches } = action.payload;
      state.unAttachSwitches[companyId] =
        state.unAttachSwitches[companyId] || [];
      switches.forEach((swc) => {
        if (
          !state.unAttachSwitches[companyId].some((item) => item.id === swc.id)
        ) {
          state.unAttachSwitches[companyId].push(swc);
        }
      });
    },
    companySetUnAttachSwitches(
      state,
      action: PayloadAction<{ companyId: number; switches: Switch[] }>
    ) {
      const { companyId, switches } = action.payload;
      state.unAttachSwitches[companyId] = switches;
    },
    companySetUnAttachSwitchesModule(
      state,
      action: PayloadAction<{ companyId: number; switches: Switch[] }>
    ) {
      const { companyId, switches } = action.payload;
      state.unAttachSwitchesModule[companyId] = switches;
    },

    companySetAttachSwitchesModule(
      state,
      action: PayloadAction<{ switchId: number; companyId: number }>
    ) {
      const { switchId, companyId } = action.payload;
      state.unAttachSwitchesModule[companyId] = state.unAttachSwitchesModule[
        companyId
      ].filter((swc) => swc.id !== switchId);
    },
  },
});

export const {
  companySwitchGetsSucces,
  companySwitchGetSuccess,
  companySwitchUpdateSuccess,
  companySwitchRentSuccess,
  companySwitchesDetachById,
  companyAddUnAttachSwitches,
  companySetUnAttachSwitches,
  companySetUnAttachSwitchesModule,
  companySetAttachSwitchesModule,
} = companySwitchesSlice.actions;

export default companySwitchesSlice.reducer;
