import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { Box } from '@material-ui/core';
import agentCompaniesUsersRequest from '@/api/agent_companies_users';
import { findAgentGeneralManagerUsersByCompanyIdSelectorFactory } from '@/selector/users';
import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import { useParams } from 'react-router-dom';
import { AgentCompaniesRouteProps } from '@/routes/AgentRoute';
import Drawer from '@/components/atoms/Drawer';
import AgentUserForm from '@/components/organisms/AgentUserForm';
import { CustomButton } from '@/components/atoms/CustomButton';
import { UserFormData } from '@/components/organisms/UserForm';
import Detail from '../Detail';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

const AgentCompaniesGeneralManagerUsersList: React.FC = () => {
  const {
    agent_company: agentCompanyId,
  } = useParams<AgentCompaniesRouteProps>();
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const dispatch = useDispatch();
  const users = useSelector((state: RootState) =>
    findAgentGeneralManagerUsersByCompanyIdSelectorFactory(state)(
      Number(agentCompanyId)
    )
  );

  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState<null | Record<string, any>>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const onSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'password', 'email'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input) {
        if (input.hasAttribute('required')) {
          if (!input.value) {
            errors[field] = '必須入力項目です';
          }
        }
        data[field] = input.value;
      }

      return errors;
    }, {});

    setErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (editData) {
        const api = agentCompaniesUsersRequest.put(agentCompanyId, editData.id);
        await dispatch(api.request(data as UserFormData));
        setEditData({ ...data, id: editData.id });
      } else {
        const api = agentCompaniesUsersRequest.post(agentCompanyId);
        await dispatch(api.request(data as UserFormData));
      }
      closeDrawer();
      hideOverlay();
    }
  };

  const onDelete = async (id: string) => {
    showOverlay();
    const api = agentCompaniesUsersRequest.delete(agentCompanyId, id);
    await dispatch(api.request());
    setEditData(null);
    setOpenDrawer(false);
    hideOverlay();
  };

  useEffect(() => {
    if (!isOpenDrawer) {
      setErrors({});
      setEditData(null);
    }
  }, [isOpenDrawer]);

  useEffect(() => {
    dispatch(agentCompaniesUsersRequest.index(agentCompanyId).request());
  }, [dispatch, agentCompanyId]);

  return (
    <>
      {overlayLoader}
      <CustomMaterialTable
        title="代理店管理者一覧"
        columns={[
          {
            field: 'name',
            title: '代理店管理者氏名',
          },
          {
            title: '操作',
            render: (record: any) => (
              <Box style={{ minWidth: '240px' }}>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setOpenDrawer(true);
                    setEditData(record);
                  }}
                >
                  編集
                </CustomButton>
              </Box>
            ),
          },
        ]}
        data={(users || []).map((item) => ({ ...item }))}
        addButton={
          <CustomButton
            color="grey"
            style={{ background: '#56ccf2', color: 'white' }}
            onClick={() => setOpenDrawer(true)}
          >
            追加
          </CustomButton>
        }
      />
      <Drawer isOpen={isOpenDrawer} onChange={setOpenDrawer}>
        {editData ? (
          <Detail
            onConfirmDelete={onDelete}
            errors={errors}
            initialData={editData}
            onSubmit={onSubmit}
          />
        ) : (
          <AgentUserForm
            errors={errors}
            onSubmit={onSubmit(() => setOpenDrawer(false))}
            onCancel={() => setOpenDrawer(false)}
          />
        )}
      </Drawer>
    </>
  );
};

export default AgentCompaniesGeneralManagerUsersList;
