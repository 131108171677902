import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import OverlayLoading from '@/components/atoms/OverlayLoading';

const LoadingSystem = () => {
  const loading = useSelector((state: RootState) => state.loadingState.loading);

  return <OverlayLoading isOpen={loading} />;
};

export default LoadingSystem;
