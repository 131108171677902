import ModulesBreadCrumb from '@/components/ecosystem/Modules/BreadCrumb';

import ModulesList from '@/components/ecosystem/Modules/List';
import ModulesListBreadCrumb from '@/components/ecosystem/Modules/List/BreadCrumb';

import PowerChart from '@/components/ecosystem/Modules/PowerChart';
import PowerChartDay from '@/components/ecosystem/Modules/PowerChart/ChartDay';
import PowerChartTime from '@/components/ecosystem/Modules/PowerChart/ChartTime';
import PowerChartBreadCrumb from '@/components/ecosystem/Modules/PowerChart/BreadCrumb';

export type companiesarams = {
  agent_company: string;
};

export type agentCompaniesUsersRouteParams = {
  user: string;
};

export type CompaniesRouteParams = {
  company_id: string;
};

export type CompaniesNormalCompaniesRouteParams = {
  normal_company_id: string;
};

export const modulesRoute = {
  url: '/modules',
  breadCrumbs: [ModulesBreadCrumb],
  childMaps: [
    {
      url: '/list',
      component: ModulesList,
      breadCrumbs: [ModulesListBreadCrumb],
    },
    {
      url: '/:moduleId',
      childMaps: [
        {
          url: '/power-chart',
          component: PowerChart,
          breadCrumbs: [PowerChartBreadCrumb],
          childMaps: [
            {
              url: '/month',
              component: PowerChartDay,
            },
            {
              url: '/day',
              component: PowerChartTime,
            },
          ],
        },
      ],
    },
  ],
};
