import React, { useEffect, useCallback, useMemo } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { findModulesWithOperationPageSelectorFactory } from '@/selector/operation-page';
import { getLoginUserAndCompanySelector } from '@/selector/users';

import { RootState } from '@/app/rootReducer';
import {
  ClientRouteParams,
  SitesModulesRouteParams,
} from '@/routes/ClientRoute';

import { UserTypeSiteManager } from '@/slicers/users';
import { initState } from '@/slicers/operation_page';
import { ModuleChannelType } from '@/slicers/modules';

import companiesSitesRequest from '@/api/companies_sites';
import operationPageRequest from '@/api/operation_page';
import SitesUsersRequest from '@/api/sites_users';

import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

import OperationB16Channel from '../components/OperationB16Channel';
import OperationB1Channel from '../components/OperationB1Channel';
import OperationB64Channel from '../components/OperationB64Channel';

const OperationPannel = () => {
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const { site: siteId, module: moduleId } = useParams<
    ClientRouteParams & SitesModulesRouteParams
  >();
  const dispatch = useDispatch();

  const moduleWithByDeviceCode = useSelector(
    (state: RootState) => findModulesWithOperationPageSelectorFactory(state),
    shallowEqual
  );

  const { loginUser, site } = useSelector(
    (state: RootState) => ({
      loginUser: getLoginUserAndCompanySelector(state).loginUser,
      site: state.sitesState.sitesById[Number(siteId)],
    }),
    shallowEqual
  );

  const firstSiteUser = useSelector(
    (state: RootState) => state.siteUsersState.firstUser
  );

  const companyId = loginUser!.company_id;
  const loginType = loginUser!.type;

  const getFirstUser = useCallback(async () => {
    if (loginType < UserTypeSiteManager) {
      if (siteId) {
        dispatch(SitesUsersRequest.firstUser(siteId).request());
      }
    }
  }, [dispatch, siteId, loginType]);

  useEffect(() => {
    getFirstUser();
  }, [getFirstUser]);

  useEffect(() => {
    if (!site) {
      dispatch(
        companiesSitesRequest.get(companyId!.toString(), siteId).request()
      );
    }
  }, [site, dispatch, companyId, siteId]);

  useEffect(() => {
    dispatch(initState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(operationPageRequest.index(siteId).request());
  }, [dispatch, siteId]);

  const commUserId = useMemo(() => {
    let targetId;
    if (loginUser?.type! < UserTypeSiteManager) {
      targetId = firstSiteUser?.id;
    } else {
      targetId = loginUser?.id;
    }
    return targetId;
  }, [firstSiteUser, loginUser]);

  const modules = useMemo(() => {
    return moduleId
      ? Object.values(moduleWithByDeviceCode).filter(
          (mdl) => mdl.id === +moduleId
        )
      : Object.values(moduleWithByDeviceCode);
  }, [moduleId, moduleWithByDeviceCode]);

  const { b16Modules, b64Modules, b1Modules } = useMemo(() => {
    return modules.reduce<{
      b16Modules: ModuleWithCamera[];
      b1Modules: ModuleWithCamera[];
      b64Modules: ModuleWithCamera[];
    }>(
      (obj, mdl) => {
        if (mdl.type === ModuleChannelType.B1Channel) {
          obj.b1Modules.push(mdl);
        } else if (mdl.type === ModuleChannelType.B64Channel) {
          obj.b64Modules.push(mdl);
        } else {
          obj.b16Modules.push(mdl);
        }
        return obj;
      },
      { b64Modules: [], b16Modules: [], b1Modules: [] }
    );
  }, [modules]);

  return (
    <div style={{ paddingBottom: 10 }}>
      {overlayLoader}
      {site && loginUser && (
        <>
          {!!b16Modules.length && (
            <OperationB16Channel
              showOverlay={showOverlay}
              hideOverlay={hideOverlay}
              site={site}
              firstSiteUser={firstSiteUser}
              moduleId={moduleId}
              loginUser={loginUser}
              moduleWithByDeviceCode={b16Modules.reduce<
                Record<string, ModuleWithCamera>
              >((obj, mdl) => {
                obj[mdl.deviceCode] = mdl;
                return obj;
              }, {})}
            />
          )}

          {!!b64Modules.length && (
            <OperationB64Channel
              showOverlay={showOverlay}
              hideOverlay={hideOverlay}
              site={site}
              firstSiteUser={firstSiteUser}
              moduleId={moduleId}
              loginUser={loginUser}
              moduleWithByDeviceCode={b64Modules.reduce<
                Record<string, ModuleWithCamera>
              >((obj, mdl) => {
                obj[mdl.deviceCode] = mdl;
                return obj;
              }, {})}
            />
          )}

          {!!b1Modules.length && (
            <>
              <div style={{ marginTop: 20 }} />
              <OperationB1Channel
                commUserId={commUserId}
                showOverlay={showOverlay}
                hideOverlay={hideOverlay}
                site={site}
                firstSiteUser={firstSiteUser}
                loginUser={loginUser}
                modules={b1Modules}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OperationPannel;
