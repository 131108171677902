import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';

const AgentCompaniesNormalCompaniesBreadCrumbList: React.FC = () => {
  return (
    <React.Fragment>
      <BreadCrumbItem title="企業一覧" />
    </React.Fragment>
  );
};

export default AgentCompaniesNormalCompaniesBreadCrumbList;
