import React from 'react'
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem'
import BreadCrumbs from '@/components/molecules/BreadCrumbs'

const CompaniesModulesListBreadCrumb: React.FC = () => {
  return (
    <BreadCrumbs>
      <BreadCrumbItem title="一覧" />
    </BreadCrumbs>
  )
}

export default CompaniesModulesListBreadCrumb
