import React from 'react';
import Input from '@/components/atoms/Input';

import { CustomButton } from '@/components/atoms/CustomButton';
import ConfirmDelete from '@/components/atoms/ConfirmDelete';
import classes from './styles.module.scss';

interface AgentCompanyFormProps {
  viewMode?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  readonly?: boolean;
  initialData?: Record<string, any>;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  errors?: Record<string, string>;
  onCancel?: () => void;
  onConfirmDelete?: () => void;
  onDeleteMessage?: string;
}

export default function AgentCompanyForm({
  viewMode,
  children,
  readonly,
  initialData = {},
  errors = {},
  onSubmit = () => {},
  onCancel = () => {},
  onConfirmDelete = () => {},
  onDeleteMessage,
}: AgentCompanyFormProps) {
  return (
    <div className={classes.createForm}>
      <form noValidate onSubmit={onSubmit}>
        {viewMode ? (
          <div className={classes.inputs}>
            <div className={classes.input}>
              <Input
                readonly={readonly}
                required
                label="会社名 ※ "
                name="name"
                defaultValue={initialData.name}
                error={errors.name}
              />
            </div>
            <div className={classes.input}>
              <Input
                readonly={readonly}
                required
                defaultValue={initialData.address}
                label="住所 ※ "
                name="address"
                error={errors.address}
              />
            </div>
          </div>
        ) : (
          <div className={classes.inputs}>
            <div className={classes.input}>
              <Input
                readonly={readonly}
                required
                label="会社名 ※ "
                name="name"
                defaultValue={initialData.name}
                error={errors.name}
              />
            </div>
            <div className={classes.input}>
              <div className={classes.group}>
                <Input
                  readonly={readonly}
                  required
                  label="都道府県 ※ "
                  name="pref"
                  defaultValue={initialData.pref}
                  error={errors.pref}
                />
                <Input
                  readonly={readonly}
                  defaultValue={initialData.post_code}
                  required
                  label="郵便番号 ※ "
                  name="post_code"
                  error={errors.post_code}
                />
              </div>
            </div>
            <div className={classes.input}>
              <Input
                readonly={readonly}
                required
                defaultValue={initialData.address}
                label="住所 ※ "
                name="address"
                error={errors.address}
              />
            </div>
            <div className={classes.input}>
              <div className={classes.group}>
                <Input
                  readonly={readonly}
                  defaultValue={initialData.tel}
                  required
                  label="電話番号 ※ "
                  name="tel"
                  error={errors.tel}
                />
                <Input
                  readonly={readonly}
                  defaultValue={initialData.fax}
                  label="FAX"
                  name="fax"
                />
              </div>
            </div>
          </div>
        )}

        <div className={classes.buttons}>
          {children || (
            <>
              <CustomButton color="grey" onClick={onCancel}>
                戻る
              </CustomButton>
              <ConfirmDelete
                message={onDeleteMessage}
                onConfirm={onConfirmDelete}
              >
                <CustomButton color="error" className={classes.deleteButton}>
                  削除
                </CustomButton>
              </ConfirmDelete>
              <CustomButton
                type="submit"
                color="primary"
                disabled={viewMode || readonly}
                className={classes.submitButton}
              >
                保存
              </CustomButton>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
