import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import { route } from '@/routes/url-generator';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';

const CompaniesNormalCompaniesBreadCrumb: React.FC = () => {
  return (
    <BreadCrumbs>
      <BreadCrumbItem
        title="企業管理"
        to={route.companies.normalCompanies.list}
      />
    </BreadCrumbs>
  );
};

export default CompaniesNormalCompaniesBreadCrumb;
