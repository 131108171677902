import { RootState } from '@/app/rootReducer';
import { createSelector } from 'reselect';
import { Camera } from '@/slicers/cameras';
import { ModuleWith } from '@/slicers/operation_page';

export const findModulesWithOperationPageSelectorFactory = createSelector(
  (state: RootState) => state.operationPageState.moduleWithByDeviceCode,
  (state: RootState) => state.cameraState.camerasById,
  (
    moduleWithByDeviceCode: Record<string, ModuleWith>,
    camerasById
  ): Record<string, ModuleWith & { cameras: Camera[] }> => {
    const modulesWith = Object.values(moduleWithByDeviceCode);

    const moduleWithCameras: Record<
      string,
      ModuleWith & { cameras: Camera[] }
    > = {};

    for (const [, moduleWith] of Object.entries(modulesWith)) {
      moduleWithCameras[moduleWith.deviceCode] = {
        ...moduleWith,
        cameras: Object.values(camerasById).filter(
          (camera) => camera.module_id === moduleWith.id
        ),
      };
    }

    return moduleWithCameras;
  }
);
