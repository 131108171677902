import { CommEvent, useDeviceCommunication } from '@/hooks/socket';
import { User } from '@/slicers/users';
import { ReactElement, useEffect, useState } from 'react';

type CommSocketProviderProps = {
  loginUser: User;
  render: (props: { isOk: boolean; events: CommEvent }) => ReactElement;
};

export const CommSocketProvider = ({
  loginUser,
  render,
}: CommSocketProviderProps) => {
  const [isUserLogged, setUserLogged] = useState(false);
  const { isAvailable, events } = useDeviceCommunication();

  const commUserId = loginUser.id;

  useEffect(() => {
    if (commUserId) {
      events.onOpen = function () {
        events.sendToEvent('client-user-login', {
          token: localStorage.getItem('accessToken'),
          userId: commUserId,
        });
      };
      events.onMessage = function (payload) {
        const { event } = payload as { event: string; data: any };
        if (event === 'ready') {
          console.log(
            '[Module settings] Client user login success with id',
            commUserId
          );
          setUserLogged(true);
        } else if (event === 'clientUserLoginFiled') {
          console.log(
            '[Module settings] Client user login fail with id',
            commUserId
          );
          setUserLogged(false);
        }
      };
      return () => {
        events.close?.();
      };
    }
  }, [events, commUserId]);

  return render({ isOk: isAvailable && isUserLogged, events });
};
