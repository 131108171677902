import React, { useState } from 'react';
import { CustomButton } from '@/components/atoms/CustomButton';

import classes from './styles.module.scss';

type ExportPopupProps = {
  disabled?: boolean;
  onExport: (meterId: number, month: number) => void;
  meterOptions: {
    label: string;
    value: number;
  }[];
};

const ExportPopup = ({
  meterOptions,
  disabled,
  onExport,
}: ExportPopupProps) => {
  const [meterId, setMeterId] = useState<number>();
  const [month, setMonth] = useState<number>();
  return (
    <div className={classes.exportPopup}>
      <div className={classes.input}>
        <select value={meterId} onChange={(e) => setMeterId(+e.target.value)}>
          <option value="">--スイッチのPID--</option>
          {meterOptions.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </div>
      <div className={classes.input}>
        <select value={month} onChange={(e) => setMonth(+e.target.value)}>
          <option value="">--月--</option>
          {Array.from({ length: 12 }).map((_, i) => {
            const month = i + 1;
            return (
              <option key={month} value={month}>
                {month}月
              </option>
            );
          })}
        </select>
      </div>

      <CustomButton
        onClick={() => onExport(meterId!, month!)}
        disabled={disabled || !month || !meterId}
        color="info"
      >
        ダウンロード
      </CustomButton>
    </div>
  );
};

export default ExportPopup;
