import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { PaletteColorProps } from '../CustomButton';

export type CircleProps = {
  type: PaletteColorProps | 'gray';
  width?: number | string;
  className?: string;
};

const Circle: React.FC<CircleProps> = ({
  type,
  width = '1rem',
  className = '',
}) => {
  const theme = useTheme();

  const backgroundColor =
    type !== 'gray' ? theme.palette[type].main : theme.palette.grey[500];

  return (
    <Box
      className={className}
      style={{ backgroundColor, display: 'inline-block' }}
      borderRadius="50%"
      width={width}
      height={width}
    ></Box>
  );
};

export default Circle;
