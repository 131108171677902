import React from 'react';
import Input from '@/components/atoms/Input';
import { CustomButton } from '@/components/atoms/CustomButton';

import classes from './styles.module.scss';

interface SwitchManagementFormProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  errors: Record<string, string>;
  initialData?: Record<string, any>;
  onCancel: () => void;
}

export default function SwitchManagementForm({
  initialData = {},
  errors,
  onSubmit,
  onCancel,
}: SwitchManagementFormProps) {
  return (
    <div className={classes.switchManagementForm}>
      <form noValidate onSubmit={onSubmit}>
        <div className={classes.inputs}>
          <div className={classes.input}>
            <Input
              required
              label="PID(半角英数6文字) "
              name="pid"
              defaultValue={initialData.pid}
              error={errors.pid}
              maxLength={6}
            />
          </div>
          <div className={classes.input}>
            <Input
              label="紐づける電力計 "
              name="power_meter_id"
              defaultValue={initialData.power_meter_id}
              error={errors.power_meter_id}
            />
          </div>
        </div>
        <div className={classes.buttons}>
          <CustomButton color="grey" onClick={onCancel}>
            戻る
          </CustomButton>
          <CustomButton
            type="submit"
            color="primary"
            className={classes.submitButton}
          >
            保存
          </CustomButton>
        </div>
      </form>
    </div>
  );
}
