import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { Business, Menu, Person } from '@material-ui/icons';

import { getLoginUserSelector } from '@/selector/users';
import { RootState } from '@/app/rootReducer';
import {
  UserTypeAdmin,
  UserTypeAgent,
  UserTypeGeneralManager,
  UserTypeManager,
  UserTypeOperator,
  UserTypeSiteManager,
  UserTypeSuperAdmin,
} from '@/slicers/users';
import { useAgent } from '@/hooks';
import companiesNormalCompaniesRequest from '@/api/companies_normal_companies';
import agentCompaniesRequest from '@/api/agent_companies';

import Modal, { ModalFunction } from '@/components/atoms/Modal';
import EarthquakeAlert from '../EarthquakeAlert';

import useStyles from './styles';

export type AdminHeaderProps = {
  sideBarWidth: number;
  children: React.ReactNode;
  sideBarOpened: boolean;
  onSidebarOpen: () => void;
};

const payload = (eventName: string, data: Object = {}) =>
  JSON.stringify({
    event: eventName,
    data,
  });

const parseWsMessage = (message: string) => {
  try {
    const res = JSON.parse(message);
    return {
      event: res?.event,
      data: res?.data,
    };
  } catch (error) {
    return {
      event: message,
      data: '',
    };
  }
};

export default function AdminHeader({
  sideBarWidth,
  children,
  sideBarOpened,
  onSidebarOpen,
}: AdminHeaderProps) {
  const classes = useStyles(sideBarWidth)();
  const location = useLocation();
  const [ws, setWs] = useState<WebSocket>();
  const [leakModule, setLeakModule] = useState<any>();
  const instance = useRef<{
    heatbeatId: NodeJS.Timeout | null;
    leakModule?: any;
  }>({
    heatbeatId: null,
    leakModule: null,
  });
  const refEarthquakeModal = useRef<ModalFunction>();

  const loginUser = useSelector((state: RootState) =>
    getLoginUserSelector(state)
  );

  const isMobile = useAgent();
  const companyId =
    location.pathname.match(/\/agent_companies\/(\d+)/)?.[1] ||
    location.pathname.match(/\/curt_nml_co\/(\d*)/)?.[1];

  const dispatch = useDispatch();
  const company = useSelector(
    (state: RootState) => state.companiesState.companiesById[Number(companyId)]
  );

  const onCloseEvent = async () => {
    setWs(undefined);
    setLeakModule('');
  };

  const createWs = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const ws = new WebSocket(
      `${protocol}://${
        process.env.REACT_APP_WEB_SOCKET_URL || 'localhost:3004'
      }/ws/`
    );
    ws.onopen = () => {
      console.log('ws opened in headder with user ', loginUser!.id);
      ws.send(
        payload('client-user-login', {
          token: localStorage.getItem('accessToken'),
          userId: loginUser!.id,
        })
      );
    };

    ws.onclose = onCloseEvent;

    ws.onmessage = (res) => {
      const { data, event } = parseWsMessage(res.data);
      console.log(`AppHeader receive ${event}`, data);
      switch (event) {
        case 'ready':
          setWs(ws);
          ws.send(payload('request-check-leak'));
          break;
        case 'earthquakeNotify':
          if (!(window as any).isShowedEearthquakeAlert) {
            console.log('earthquakeNotify 2 open');
            refEarthquakeModal.current!.open();
          }
          break;
        case 'leakNotify':
          instance.current.leakModule = data;
          setLeakModule(data);
          break;
        case 'nonLeakNotify':
          if (data.device_code === instance.current.leakModule?.device_code) {
            instance.current.leakModule = undefined;
            setLeakModule(undefined);
          }
          break;
        case 'moduleDisconnected':
          instance.current.leakModule = undefined;
          setLeakModule(undefined);
          break;
        default:
          break;
      }
    };
  };

  useEffect(() => {
    if (!loginUser) return;
    if (instance.current.heatbeatId) {
      clearInterval(instance.current.heatbeatId);
      instance.current.heatbeatId = null;
    }

    function run() {
      if (!ws) {
        createWs();
      } else {
      }
    }

    run();
    const heatbeatId = setInterval(run, 5 * 1000);
    instance.current.heatbeatId = heatbeatId;
    return () => {
      clearInterval(heatbeatId);
      if (ws) {
        ws.removeEventListener('close', onCloseEvent);
        ws.close();
      }
    };
    // eslint-disable-next-line
  }, [JSON.stringify(loginUser), ws]);

  useEffect(() => {
    if (!company && companyId) {
      dispatch(agentCompaniesRequest.get(companyId).request());
      dispatch(companiesNormalCompaniesRequest.index(companyId).request());
    }
    // eslint-disable-next-line
  }, [dispatch, companyId]);

  let auth = '';
  if (loginUser) {
    switch (loginUser.type) {
      case UserTypeSuperAdmin:
        auth = 'クエスタ';
        break;
      case UserTypeAdmin:
        auth = 'アドミン';
        break;
      case UserTypeAgent:
        auth = '代理店';
        break;
      case UserTypeGeneralManager:
        auth = '企業管理者';
        break;
      case UserTypeManager:
        auth = '管理者';
        break;
      case UserTypeSiteManager:
        auth = '現場管理者';
        break;
      case UserTypeOperator:
        auth = '使用者';
        break;
      default:
      // code block
    }
  }

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: sideBarOpened,
        [classes.green]:
          loginUser &&
          [UserTypeSuperAdmin, UserTypeAdmin].includes(loginUser!.type),
        [classes.orange]:
          loginUser && [UserTypeAgent].includes(loginUser!.type),
        // [classes.black]: loginUser && [
        //   UserTypeGeneralManager,
        //   UserTypeManager,
        //   UserTypeSiteManager,
        // ],
        [classes.blue]:
          loginUser &&
          [
            UserTypeGeneralManager,
            UserTypeManager,
            UserTypeSiteManager,
          ].includes(loginUser!.type),
      })}
    >
      <Modal ref={refEarthquakeModal}>
        <EarthquakeAlert />
      </Modal>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onSidebarOpen}
          edge="start"
          className={clsx(classes.menuButton, sideBarOpened && classes.hide)}
        >
          <Menu />
        </IconButton>
        <div className={classes.content}>
          {!isMobile && (
            <Typography
              variant="h6"
              noWrap
              className={classes.flex_align_center}
            >
              {loginUser && (
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Box
                      p={0.5}
                      style={{ backgroundColor: '#fff', color: 'black' }}
                    >
                      <Typography
                        style={{
                          verticalAlign: 'middle',
                          display: 'inline-flex',
                        }}
                      >
                        <Person /> {loginUser.name}としてログイン中
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box
                      p={0.5}
                      style={{ backgroundColor: '#fff', color: 'black' }}
                    >
                      <Typography
                        style={{
                          verticalAlign: 'middle',
                          lineHeight: '2',
                        }}
                      >
                        {auth}権限
                      </Typography>
                    </Box>
                  </Grid>
                  {company && (
                    <Grid item xs>
                      <Box
                        p={0.5}
                        style={{ backgroundColor: '#fff', color: 'black' }}
                      >
                        <Typography
                          style={{
                            verticalAlign: 'middle',
                            display: 'inline-flex',
                          }}
                        >
                          <Business /> {company.name}を操作中
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
            </Typography>
          )}
          <div className={classes.rightAlign}>
            {leakModule && (
              <div className={classes.switchesLeak}>
                {leakModule.name}​で
                {leakModule.connectStatus.leakageType === 1 ? '異常' : '漏電'}
                検出中
              </div>
            )}
            {children}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
