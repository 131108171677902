import React, { useEffect, useState } from 'react'
import {  useLocation } from 'react-router-dom'
import { routeMaps } from '@/routes'
import { RouteProps } from '@/routes/RouteRecursive'
import BreadCrumbs from '@/components/molecules/BreadCrumbs'

const recursive = (pathName: string, routeMaps: RouteProps[]) => {
  let breadClumbComponentList: React.FC[] = []

  routeMaps.forEach((route) => {
    let url = route.url.replace(/:.*?\//, '\\d+/')
    url = url.replace(/:.*(?!\/)/, '\\d+')

    const matchedWord = pathName.match(RegExp(`^${url}`))?.[0]
    if (matchedWord) {
      if (route.breadCrumbs) breadClumbComponentList.push(...route.breadCrumbs)
      if (route.childMaps) {
        breadClumbComponentList.push(
          ...recursive(pathName.slice(matchedWord.length), route.childMaps)
        )
      }
    }
  })
  return breadClumbComponentList
}

const BreadCrumbsSystem = () => {
  const location = useLocation()

  const [breadClumbComponentList, setBreadClumbComponentList] = useState<
    React.FC[]
  >([])

  useEffect(() => {
    setBreadClumbComponentList(recursive(location.pathname, routeMaps))
  }, [location.pathname])

  return (
    <div>
      <BreadCrumbs>
        {breadClumbComponentList.map((Item, index) => (
          <Item key={index} />
        ))}
      </BreadCrumbs>
    </div>
  )
}

export default BreadCrumbsSystem
