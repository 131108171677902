import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import sitesCameraAlbumsRequest from '@/api/sites_cameras_albums';
import { selectCameraAlbums } from '@/selector/siteCameraAlbums';

import { CustomButton } from '@/components/atoms/CustomButton';
import Modal from '@/components/atoms/Modal';
import List from '@/components/atoms/List';

import classes from './styles.module.scss';

type ListAlbumProps = {
  siteId: number;
  cameraId: number;
  onDelete: (id: number) => void;
};

export default function ListAlbum({
  siteId,
  cameraId,
  onDelete,
}: ListAlbumProps) {
  const [isLoaded, setLoaded] = useState(false);

  const dispatch = useDispatch();
  const {
    list,
    pagingOptions,
    filters = {},
  } = useSelector(selectCameraAlbums)(siteId, cameraId);

  const getAlbums = useCallback(
    async (page?: number) => {
      setLoaded(false);
      await dispatch(
        sitesCameraAlbumsRequest
          .index(siteId, cameraId)
          .request(page, undefined, filters.date)
      );
      setLoaded(true);
    },
    [dispatch, siteId, cameraId, filters.date]
  );

  useEffect(() => {
    getAlbums();
  }, [getAlbums]);

  return (
    <List
      size="small"
      className={classes.savedCaptureList}
      showSearch={false}
      loading={!isLoaded}
      data={list}
      total={pagingOptions.total}
      subHeading={<div />}
      onChangePage={getAlbums}
      onSort={(a, b) => {
        const time1 = moment(a.created_at, 'YYYY-MM-DD HH:mm:ss').unix();
        const time2 = moment(b.created_at, 'YYYY-MM-DD HH:mm:ss').unix();

        return time2 - time1;
      }}
      columns={[
        {
          field: 'file_name',
          key: 'name',
          title: 'ファイル名',
          width: 200,
          render: (name) => {
            const paths = name.split('?')[0].split('/');
            return paths[paths.length - 1];
          },
        },
        {
          field: 'created_at',
          title: '撮影日時',
          width: 150,
          render: (time) => {
            return moment(time, 'YYYY-MM-DD HH:mm:ss').format(
              'YYYY年MM月DD日 HH:mm'
            );
          },
        },
        {
          field: 'file_name',
          key: 'thumbnail',
          title: '撮影データ',
          width: 70,
          render: (url, { created_at }) => {
            return (
              <Modal
                trigger={
                  <div className={classes.thumbnail}>
                    <img alt="" src={url} />
                  </div>
                }
                width={500}
                onClose={() => {}}
                footer={({ close }) => {
                  return (
                    <div className={classes.captureFooter}>
                      <CustomButton
                        onClick={close}
                        color="success"
                        className={classes.button}
                      >
                        閉じる
                      </CustomButton>
                    </div>
                  );
                }}
              >
                <div className={classes.capturePopup}>
                  <div className={classes.captureTitle}>
                    {moment(created_at, 'YYYY-MM-DD HH:mm:ss').format(
                      'YYYY年MM月DD日 HH:mm'
                    )}
                  </div>
                  <div className={classes.capture}>
                    <img alt="" src={url} />
                  </div>
                </div>
              </Modal>
            );
          },
        },
      ]}
      title=""
      tooltip={{
        options: [
          {
            key: 'delete',
            title: '削除する',
          },
        ],
        onClick: (_, record) => {
          onDelete(record.id);
        },
      }}
    />
  );
}
