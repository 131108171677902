import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@/components/atoms/List';
import Drawer from '@/components/atoms/Drawer';
import SwitchManagementForm from '@/components/ecosystem/AgentCompanies/SwitchManagementForm';

import { RootState } from '@/app/rootReducer';

import Select from '@/components/atoms/Select';
import { CustomButton } from '@/components/atoms/CustomButton';

import { setLoading } from '@/slicers/loading';
import {
  selectorFindAllSwitchesByCompanyId,
  selectorUnAttachSwitchesByCompanyId,
} from '@/selector/companySwitches';

import companiesSwitchesRequest from '@/api/company_switches';

import { Switch } from '@/slicers/switches';

import classes from './styles.module.scss';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';
import { SwitchStatusType } from '@/slicers/switches';

interface ListSwitchProps {
  companyId: number;
}

export default function ListSwitch({ companyId }: ListSwitchProps) {
  const [overlayLoader, { handleTrigger, showOverlay, hideOverlay }] =
    useOverlayLoading();
  const dispatch = useDispatch();

  const [selectedSwitchForAttach, setSelectedSwitchForAttach] = useState('');
  const [isOpenEdit, setOpenEdit] = useState(false);
  const [switchCompanyEditData, setSwitchCompanyEditData] = useState<
    undefined | Switch
  >(undefined);
  const [switchErrors, setSwitchErrors] = useState<Record<string, string>>({});

  const { loading } = useSelector((state: RootState) => state.loadingState);
  const switches = useSelector((state: RootState) =>
    selectorFindAllSwitchesByCompanyId(companyId)(state)
  )?.map((item) => ({
    ...item,
    status:
      item.status === SwitchStatusType.Renting
        ? 'レンタル中'
        : item.status === SwitchStatusType.InUse
        ? '紐付け中'
        : '利用可能',
  }));

  const unAttachSwitches = useSelector((state: RootState) =>
    selectorUnAttachSwitchesByCompanyId(companyId)(state)
  );

  const getInitialData = useCallback(async () => {
    await dispatch(setLoading(true));
    await dispatch(
      companiesSwitchesRequest.index(companyId.toString()).request()
    );
    await dispatch(companiesSwitchesRequest.unAttach(companyId).request());
    await dispatch(setLoading(false));
  }, [dispatch, companyId]);

  const onSwitchSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'pid', 'max_amps'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input) {
        if (input.hasAttribute('required')) {
          if (!input.value) {
            errors[field] = '必須入力項目です';
          }
        }
        data[field] = input.value;
      }

      return errors;
    }, {});

    setSwitchErrors(errors);
    if (!Object.keys(errors).length) {
      showOverlay();
      if (switchCompanyEditData) {
        const api = companiesSwitchesRequest.putUpdate(
          companyId.toString(),
          switchCompanyEditData.id.toString()
        );
        await dispatch(api.request(data as any));
        setOpenEdit(false);
      }
      closeDrawer();
      hideOverlay();
    }
  };

  const onAttach = useCallback(async () => {
    showOverlay();
    await dispatch(
      companiesSwitchesRequest
        .attach(companyId, selectedSwitchForAttach)
        .request()
    );
    hideOverlay();
  }, [dispatch, companyId, selectedSwitchForAttach, showOverlay, hideOverlay]);

  const onDetach = useCallback(
    async (switchId) => {
      showOverlay();
      await dispatch(
        companiesSwitchesRequest.detach(companyId, switchId).request()
      );
      hideOverlay();
    },
    [dispatch, showOverlay, hideOverlay, companyId]
  );

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const unAttachOptions = useMemo(() => {
    return unAttachSwitches.map((item) => ({
      label: item.pid,
      value: item.id,
    }));
  }, [unAttachSwitches]);

  return (
    <>
      {overlayLoader}
      <List
        loading={loading}
        subHeading={
          <div className={classes.attachSection}>
            <div className={classes.select}>
              <Select
                onChange={setSelectedSwitchForAttach}
                options={unAttachOptions}
                placeholder="PID"
              />
            </div>
            <CustomButton
              disabled={!selectedSwitchForAttach}
              color="primary"
              className={classes.attachButton}
              onClick={handleTrigger(onAttach)}
            >
              レンタル
            </CustomButton>
          </div>
        }
        showSearch={false}
        data={switches}
        columns={[
          {
            field: 'name',
            title: 'スイッチ名',
            width: '50%',
          },
          {
            field: 'pid',
            title: 'PID',
            width: '20%',
          },
          {
            field: 'status',
            title: '状態',
            width: '20%',
          },
        ]}
        title=""
        tooltip={{
          options: [
            {
              key: 'edit',
              title: '編集',
            },
            {
              key: 'delete',
              title: '削除',
              isConfirmDelete: true,
            },
          ],
          onClick: (key, record) => {
            if (key === 'edit') {
              setOpenEdit(true);
              setSwitchCompanyEditData(record);
            } else if (key === 'delete') {
              onDetach(record.id);
            }
          },
        }}
      />
      <Drawer isOpen={isOpenEdit} onChange={setOpenEdit}>
        <SwitchManagementForm
          initialData={switchCompanyEditData}
          errors={switchErrors}
          onSubmit={onSwitchSubmit(() => setOpenEdit(false))}
          onCancel={() => setOpenEdit(false)}
        />
      </Drawer>
    </>
  );
}
