import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { ErrorPosition, setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import {
  clearModuleCameras,
  moduleCameraGetsSucces,
  moduleCamerasDeleteById,
  moduleCameraSetPagingOptions,
} from '@/slicers/moduleCameras';
import { Camera } from '@/slicers/cameras';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/modules')
class ModulesCamerasRequest {
  caller!: TApiCaller;
  index(moduleId: number) {
    return {
      request:
        (page?: number, keyword?: string): AppThunk =>
        async (dispatch) => {
          const query = {
            page: page || 1,
            search: keyword,
          };
          if (query.page === 1) {
            dispatch(clearModuleCameras());
          }
          const response = await this.caller.GET(`/${moduleId}/cameras`, query);

          if (response.status === 200) {
            const { data, total, per_page } = response.data;
            dispatch(clearModuleCameras());
            dispatch(
              moduleCameraSetPagingOptions({
                total,
                perPage: per_page,
              })
            );
            dispatch(moduleCameraGetsSucces(data));
            dispatch(setResponse(parseAxiosResponse(response)));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('取得が失敗しました。'));
          }
        },
    };
  }

  post(moduleId: number) {
    return {
      request:
        (data: Camera): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(`/${moduleId}/cameras`, data);

          if (response.status === 201) {
            dispatch(moduleCameraGetsSucces([response.data]));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }

  put(moduleId: number, cameraId: number) {
    return {
      request:
        (data: Camera): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${moduleId}/cameras/${cameraId}`,
            data
          );
          if (response.status === 200) {
            dispatch(moduleCameraGetsSucces([response.data]));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }

  delete(moduleId: number, cameraId: number) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.DEL(
          `/${moduleId}/cameras/${cameraId}`
        );
        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました'));
          dispatch(moduleCamerasDeleteById([cameraId]));
        } else {
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }
}

const modulesCamerasRequest = new ModulesCamerasRequest();
export default modulesCamerasRequest;
