import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';

const OperatorModuleListBreadCrumb: React.FC = () => {
  return (
    <BreadCrumbs>
      <BreadCrumbItem title="スケジュール管理" />
    </BreadCrumbs>
  );
};

export default OperatorModuleListBreadCrumb;
