import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import LoginForm, { LoginFormProps } from '@/components/organisms/LoginForm';
import { Redirect } from 'react-router-dom';
import { getLoginUserSelector } from '@/selector/users';
import authRequest from '@/api/auth';
import {
  UserTypeSuperAdmin,
  UserTypeAdmin,
  UserTypeAgent,
  UserTypeGeneralManager,
  UserTypeSiteManager,
  UserTypeOperator,
} from '@/slicers/users';
import { route } from '@/routes/url-generator';
import { useRedirectIfExpectResponse } from '@/hooks';

const LoginPage = () => {
  const dispatch = useDispatch();
  const loginUser = useSelector(getLoginUserSelector);
  const [loading, setLogding] = useState(false);
  const handleSubmit: LoginFormProps['onSubmit'] = async (formData) => {
    await setLogding(true);
    await dispatch(
      authRequest
        .login({
          username: formData.name,
          password: formData.password,
          client_id: Number(process.env.REACT_APP_BACKEND_OAUTH_CLIENT_ID),
          client_secret: process.env
            .REACT_APP_BACKEND_OAUTH_CLIENT_SECRET as string,
          grant_type: 'password',
          scope: '*',
        })
        .request()
    );
    await setLogding(false);
  };

  useEffect(() => {
    localStorage.removeItem('forwardQueue');
  }, []);

  useRedirectIfExpectResponse({
    url: '/api/oauth/token',
    method: 'post',
    status: 200,
    action: async () => {
      await setLogding(true);
      await dispatch(authRequest.fetchLoginUserAndCompany().request());
      await setLogding(false);
    },
  });

  const RedirectBranching = () => {
    if (
      loginUser!.type === UserTypeSiteManager &&
      loginUser!.site_id === null
    ) {
      return <Redirect to={{ pathname: route.notFoundSiteManaged }} />;
    }

    let pathname = '';
    switch (loginUser!.type) {
      case UserTypeSuperAdmin:
      case UserTypeAdmin:
        pathname = route.agentCompanies.list;
        break;
      case UserTypeAgent:
        pathname = route.agentCompanies.normalCompanies.list(
          loginUser!.company_id
        );
        break;
      case UserTypeGeneralManager:
        pathname = route.clientRoute.sites.list(loginUser!.company_id);
        break;
      case UserTypeSiteManager:
        pathname = route.clientRoute.sites.list(loginUser!.company_id);
        break;
      case UserTypeOperator:
        pathname = route.operator.operationPanel(loginUser!.site_id!);
        break;
      default:
        return null;
    }
    return <Redirect to={{ pathname }} />;
  };

  return loginUser && !loading ? (
    <RedirectBranching />
  ) : (
    <div className={styles.container}>
      <LoginForm loading={loading} onSubmit={handleSubmit} />
    </div>
  );
};

export default LoginPage;
