import React, { useEffect, forwardRef } from 'react';
import { CloseSharp } from '@material-ui/icons';

import classes from './styles.module.scss';
import { setError } from '@/slicers/error';
import { useDispatch } from 'react-redux';

export interface DrawerProps {
  children: React.ReactElement | null | undefined;
  isOpen: boolean;
  onChange: (isOpen: boolean) => void;
}

const Drawer = forwardRef(
  ({ children, isOpen, onChange }: DrawerProps, ref: any) => {
    const dispatch = useDispatch();

    useEffect(() => {
      if (isOpen) {
        dispatch(setError([]));
      }
    }, [isOpen, dispatch]);

    return (
      <div className={[classes.drawer, isOpen ? classes.isOpen : ''].join(' ')}>
        {isOpen && (
          <div
            className={classes.closeBackground}
            onClick={() => onChange(false)}
          ></div>
        )}

        <div ref={ref} className={classes.wrapper}>
          <div className={classes.heading}>
            <div />
            <div className={classes.headingRight}>
              <CloseSharp
                className={classes.closeButton}
                onClick={() => onChange(false)}
              />
            </div>
          </div>
          <div>{isOpen && children}</div>
        </div>
      </div>
    );
  }
);

export default Drawer;
