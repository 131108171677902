import React, { useState } from 'react';

import onAvatar from './images/group-all-on.svg';
import offAvatar from './images/group-all-off.svg';

import { PowerSwitchState, SwitchingState } from '@/slicers/operation_page';

import PowerToggle from '../PowerToggle';
import Switcher from '../Switcher';

import { useDeviceCommunication } from '@/hooks/socket';

import classes from './styles.module.scss';

type UnitProps = {
  isReady?: boolean;
  states: Record<string, CommunicationState>;
  switches: CommunicationSwitch[];
  onChange: (
    pid: string,
    nextState: PowerSwitchState,
    shouldConfirm: 1 | 0
  ) => void;
  onChangeUnit: (nextState: PowerSwitchState) => void;
};

const Unit = ({
  isReady = false,
  states,
  switches,
  onChange,
  onChangeUnit,
}: UnitProps) => {
  const { isAvailable, events } = useDeviceCommunication({ raw: true });

  const [isShowPower, setShowPower] = useState(false);

  const handleResetSwitch = (
    pid: string | undefined,
    powerMeterId: number | undefined
  ) => {
    if (!pid && !powerMeterId) {
      return;
    }

    events.sendToEvent('reset-switch-power', {
      pid,
      powerMeterId,
    });
  };

  return (
    <div className={classes.unit}>
      <div className={classes.switches}>
        {switches.map((swt, i) => (
          <Switcher
            key={i}
            isReady={isReady && !!swt.pid}
            switching={
              states[swt.pid!]?.switchingState === SwitchingState.SWITCHING
            }
            state={
              states[swt.pid!] ? states[swt.pid!].state : PowerSwitchState.INIT
            }
            onChange={(nextState) => {
              onChange(swt.pid!, nextState, swt.requiredConfirmation);
            }}
            maxAmps={swt.maxAmps}
            amps={states[swt.pid!]?.amps}
            showAmp={isShowPower}
            label={swt.name}
            isLock={!!swt.isLock}
            showResetButton={!!(isAvailable && swt.pid && swt.powerMeterId)}
            reset={() => handleResetSwitch(swt.pid, swt.powerMeterId)}
          />
        ))}
      </div>
      <div className={classes.unitActions}>
        <p>グループ一括操作</p>
        <div className={classes.onoffInfo}>
          <img
            src={onAvatar}
            alt=""
            onClick={() => onChangeUnit(PowerSwitchState.ON)}
          />
          <img
            src={offAvatar}
            alt=""
            onClick={() => onChangeUnit(PowerSwitchState.OFF)}
          />
        </div>
        <p>電力余裕度表示</p>
        <PowerToggle isOn={isShowPower} onChange={setShowPower} />
      </div>
    </div>
  );
};

export default Unit;
