import AgentCompaniesBreadCrumb from '@/components/ecosystem/AgentCompanies/BreadCrumb';
import { companiesRoute } from './CompaniesRoute';
import { clientRoute } from './ClientRoute';
import { agentRoute } from './AgentRoute';
import { operatorRoute } from './OperatorRoute';
import WebSocketTest from '@/components/ecosystem/WebSocketTest';
import NotFoundSiteManaged from '@/components/ecosystem/NotFoundSiteManaged';

import LoggerList from '@/components/ecosystem/Logger/List';
import LoggerListBreadCrumb from '@/components/ecosystem/Logger/List/BreadCrumb';

import SiteLogManagementList from '@/components/ecosystem/SiteLogManagement/List';
import SiteLogManagementListBreadCrumb from '@/components/ecosystem/SiteLogManagement/List/BreadCrumb';

import { loginAccountRoute } from './LoginAccount';
import { modulesRoute } from './ModulesRoute';

export const routeMaps = [
  agentRoute,
  companiesRoute,
  modulesRoute,
  clientRoute,
  operatorRoute,
  loginAccountRoute,
  {
    url: '/logger',
    component: LoggerList,
    breadCrumb: LoggerListBreadCrumb,
  },
  {
    url: '/site-log-management',
    component: SiteLogManagementList,
    breadCrumb: SiteLogManagementListBreadCrumb,
  },
  {
    url: '/current_company/:current_company/get_general_manager',
    breadCrumb: AgentCompaniesBreadCrumb,
  },
  {
    url: '/not_found_site_managed',
    component: NotFoundSiteManaged,
    breadCrumb: AgentCompaniesBreadCrumb,
  },
  {
    url: '/web_socket_test',
    component: WebSocketTest,
  },
];
