import React from 'react';

import onAvatar from './images/switch-on.svg';
import offAvatar from './images/switch-off.svg';

import classes from './styles.module.scss';

export type PowerToggleProps = {
  isOn: boolean;
  onChange: (isOn: boolean) => void;
};

const PowerToggle = ({ isOn, onChange }: PowerToggleProps) => {
  return (
    <div onClick={() => onChange(!isOn)} className={classes.powerToggle}>
      <img src={isOn ? onAvatar : offAvatar} alt="" />
    </div>
  );
};

export default PowerToggle;
