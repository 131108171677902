import { createSelector } from 'reselect';
import { RootState } from '@/app/rootReducer';
import { User } from '@/slicers/users';
import memoize from 'lodash.memoize';

export const selectorFindUsersByCompanyId = createSelector(
  (state: RootState) => state.companyUsersState.companyUsersById,
  (siteUsersById: Record<number, User>) =>
    memoize((companyId: number) => {
      const users = Object.values(siteUsersById).filter(
        user => user.company_id === companyId
      );
      return users;
    })
);
