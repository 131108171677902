import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

import Input from '@/components/atoms/Input';
import { CustomButton } from '@/components/atoms/CustomButton';
import { WithIconButton } from '@/components/atoms/Buttons';
import Datetime from '@/components/atoms/Datetime';
import Checkboxes from '@/components/atoms/Checkboxes';

import classes from './styles.module.scss';
import Select from '@/components/atoms/Select';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { selectorFindAllModulesByIdBySiteId } from '@/selector/siteModules';
import { Module } from '@/slicers/modules';
import sitesModulesRequest from '@/api/sites_modules';
import {
  SwitchButon,
  SwitchPannel as SwitchPannelBase,
  Unit,
  Units,
  SwitchButonDummy,
  SwitchGroup,
} from '@/components/organisms/SwitchPannelPresentar';
import { ButtonType } from '@/components/atoms/3DButton';
import { Clear } from '@material-ui/icons';
import { unitOrigin, switchOrigin } from '@/config';
import { selectorFindScheduleItemsByScheduleId } from '@/selector/scheduleItems';
import sitesSchedulesRequest from '@/api/sites_schedules';
import { setFlashError } from '@/slicers/flash';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

const weeks = {
  monday: '月',
  tuesday: '火',
  wednesday: '水',
  thursday: '木',
  friday: '金',
  saturday: '土',
  sunday: '日',
};

type DayOfWeek = keyof typeof weeks;

interface ScheduleFormProps {
  siteId: number;
  children?: React.ReactElement | React.ReactElement[];
  readonly?: boolean;
  initialData?: Record<string, any>;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  errors?: Record<string, string>;
  onCancel?: () => void;
}

type SwitchPannelProps = {
  mdl: Module;
};

const uniq = <T extends {}>(array: T[]): T[] => {
  return Array.from(new Set(array));
};

export default function ScheduleForm({
  siteId,
  children,
  readonly,
  initialData = {},
  errors = {},
  onSubmit = () => {},
  onCancel = () => {},
}: ScheduleFormProps) {
  const dispatch = useDispatch();
  const [overlayLoader, { showOverlay, hideOverlay, isLoading }] =
    useOverlayLoading({
      defaultShow: true,
    });

  const modulesById = useSelector((state: RootState) =>
    selectorFindAllModulesByIdBySiteId(siteId)(state)
  );

  const switchIds = useSelector((state: RootState) =>
    selectorFindScheduleItemsByScheduleId(Number(initialData.id))(state)
  ).map((item) => item.switch_id);

  const [isShowModuleOptions, setShowModuleOptions] = useState(false);
  const [mdlState, setMdlState] = useState(initialData.state);
  const [selectedModuleIds, setSelectedModuleIds] = useState<number[]>([]);
  const [selectedSwitchIds, setSelectedSwitchIds] =
    useState<number[]>(switchIds);

  const unselectedModules = useMemo(() => {
    return Object.keys(modulesById).reduce<Module[]>((arr, id) => {
      if (!selectedModuleIds.includes(Number(id))) {
        arr.push(modulesById[id]);
      }
      return arr;
    }, []);
  }, [modulesById, selectedModuleIds]);

  const getSiteModules = useCallback(async () => {
    showOverlay();
    await Promise.all([
      dispatch(sitesModulesRequest.index(siteId.toString()).request()),
      initialData.id
        ? dispatch(
            sitesSchedulesRequest
              .get(siteId.toString(), initialData.id)
              .request()
          )
        : null,
    ]);
    hideOverlay();
  }, [showOverlay, hideOverlay, dispatch, siteId, initialData.id]);

  useEffect(() => {
    if (switchIds.length) {
      setSelectedSwitchIds(switchIds);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(switchIds)]);

  useEffect(() => {
    const modules = Object.values(modulesById);
    if (switchIds.length && modules.length) {
      setSelectedModuleIds((state) => {
        let newState = [...state];
        for (const commModule of modules) {
          const powerUnits = Object.values(commModule.unitsByNumber);
          for (const powerUnit of powerUnits) {
            const powerSwitches = Object.values(powerUnit.switchesByNumber);
            for (const powerSwitch of powerSwitches) {
              if (switchIds.includes(powerSwitch.id)) {
                newState.push(commModule.id);
              }
            }
          }
        }
        return uniq(newState);
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(switchIds), JSON.stringify(modulesById)]);

  useEffect(() => {
    getSiteModules();
  }, [getSiteModules]);

  const handleClickModuleDeleteButton = (moduleId: number) => {
    const moduleWith = modulesById[moduleId];
    if (!moduleWith) return;

    let switchIds: number[] = [];
    Object.values(moduleWith.unitsByNumber).forEach((powerUnit) => {
      Object.values(powerUnit.switchesByNumber).forEach((powerSwitch) => {
        switchIds.push(powerSwitch.id);
      });
    });

    setSelectedSwitchIds((state) => {
      const newState = state.filter((id) => !switchIds.includes(id));
      return newState;
    });

    setSelectedModuleIds((state) => {
      const newState = state.filter((id) => id !== moduleId);
      return newState;
    });
  };

  const SwitchPannel: React.FC<SwitchPannelProps> = ({ mdl }) => {
    const switchButton = (unitNumber: number, switchNumber: number) => {
      const powerSwitch =
        mdl.unitsByNumber[unitNumber].switchesByNumber[switchNumber];

      const type: ButtonType = !!+mdlState ? 'lightGreen' : 'red';

      const handleClickSwitch = (switchId: number) => {
        if (powerSwitch.is_lock) {
          dispatch(
            setFlashError(
              'スイッチのロッグではスケジュール機能は使用できません。'
            )
          );
        } else if (powerSwitch.shutter_mode) {
          dispatch(
            setFlashError(
              'ワンショットモードではスケジュール機能は使用できません。'
            )
          );
        } else {
          setSelectedSwitchIds((state) => {
            let newState = [...state];
            if (newState.includes(switchId)) {
              newState = newState.filter((id) => id !== switchId);
            } else {
              newState.push(switchId);
            }
            return newState;
          });
        }
      };

      return (
        <SwitchButon
          name={powerSwitch.name}
          type={selectedSwitchIds.includes(powerSwitch.id) ? type : 'grey'}
          onClick={() => handleClickSwitch(powerSwitch.id)}
          key={powerSwitch.id}
        />
      );
    };

    return (
      <SwitchPannelBase>
        <div className={classes.switch_pannel_header}>
          <div />
          <div className={classes.pid}>
            {mdl.name}{' '}
            {mdl.device_code && `(デバイスコード: ${mdl.device_code})`}
          </div>
          <div
            onClick={() => handleClickModuleDeleteButton(mdl.id)}
            className={classes.switch_pannel_delete_button}
          >
            <Clear>X</Clear>
          </div>
        </div>
        <Units aspectRatio={100 / 30}>
          {Array(4)
            .fill(0)
            .map((_, index) => index + unitOrigin)
            .reverse()
            .map((unitIndex) => (
              <Unit key={unitIndex}>
                {mdl.unitsByNumber[unitIndex] && (
                  <SwitchGroup>
                    {Array(4)
                      .fill(0)
                      .map((_, index) => index + switchOrigin)
                      .map((switchIndex) =>
                        mdl.unitsByNumber[unitIndex].switchesByNumber[
                          switchIndex
                        ] ? (
                          switchButton(unitIndex, switchIndex)
                        ) : (
                          <SwitchButonDummy />
                        )
                      )}
                  </SwitchGroup>
                )}
              </Unit>
            ))}
        </Units>
      </SwitchPannelBase>
    );
  };

  return (
    <div className={classes.scheduleForm}>
      {overlayLoader}
      {!isLoading ? (
        !Object.keys(modulesById).length && (
          <Alert severity="error">
            <div>選択可能な遠隔電源指令盤が存在しません。</div>
          </Alert>
        )
      ) : (
        <div>読み込み中...</div>
      )}
      <form noValidate onSubmit={onSubmit}>
        <div className={classes.inputs}>
          <input
            readOnly
            style={{ display: 'none' }}
            name="is_valid"
            value={initialData.is_valid || 1}
          />
          <div className={classes.input}>
            <Input
              readonly={readonly}
              required
              label="スケジュール名 ※ "
              name="name"
              defaultValue={initialData.name}
              error={errors.name}
            />
          </div>
          <div className={[classes.input, classes.datetimeInput].join(' ')}>
            <Datetime
              dateFormat={false}
              defaultValue={
                initialData.action_at
                  ? moment(initialData.action_at, 'HH:mm:ss').format('HH:mm')
                  : ''
              }
              renderInput={(props, openCalendar) => {
                return (
                  <Input
                    {...(!readonly ? props : {})}
                    readonly
                    required
                    onFocus={!readonly ? openCalendar : undefined}
                    label="設定時刻 ※ "
                    name="action_at"
                    defaultValue={
                      initialData.action_at
                        ? moment(initialData.action_at, 'HH:mm:ss').format(
                            'HH:mm'
                          )
                        : ''
                    }
                    error={errors.action_at}
                  />
                );
              }}
            />
          </div>
          <div className={classes.input}>
            <Checkboxes
              defaultValue={initialData.week}
              readOnly={readonly}
              keepFalse
              flat
              name="week"
              label="設定曜日 ※ "
              options={Object.keys(weeks).map((k) => ({
                label: weeks[k as DayOfWeek],
                value: k,
              }))}
            />
          </div>
          <div className={classes.input}>
            <Select
              required
              name="state"
              label="状態 ※ "
              defaultValue={initialData.state}
              onChange={setMdlState}
              error={errors.state}
              options={[
                {
                  label: 'ON',
                  value: 0,
                },
                {
                  label: 'OFF',
                  value: 1,
                },
              ]}
            />
          </div>
          {!!unselectedModules.length && (
            <div className={classes.input}>
              <CustomButton
                color="grey"
                onClick={() => setShowModuleOptions((prev: boolean) => !prev)}
                className={classes.showOptionsModuleButton}
              >
                指令盤追加
              </CustomButton>
              {isShowModuleOptions && (
                <div className={classes.listModuleOptions}>
                  <div className={classes.options}>
                    {unselectedModules.map((mdl) => (
                      <div
                        key={mdl.id}
                        onClick={() => {
                          setShowModuleOptions(false);
                          setSelectedModuleIds((prev) => [...prev, mdl.id]);
                        }}
                      >
                        {mdl.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {!selectedModuleIds.length ? (
            <>
              <input
                data-error-message="指令盤を追加してください。"
                required
                readOnly
                type="hidden"
                name="modules.*.[]"
              />
              {errors.modules && (
                <div className={classes.errorMessage}>{errors.modules}</div>
              )}
            </>
          ) : (
            !selectedSwitchIds.length &&
            errors.switchIds && (
              <div className={classes.errorMessage}>{errors.switchIds}</div>
            )
          )}
        </div>
        {selectedModuleIds.length > 0 && (
          selectedModuleIds.map((moduleId, index) => (
            <input
              readOnly
              type="hidden"
              key={moduleId}
              name={`selectedModuleIds.*.${index}`}
              value={moduleId}
            />
          ))
        )}
        <div className={[classes.input, classes.panelInput].join(' ')}>
          {selectedSwitchIds.length ? (
            selectedSwitchIds.map((switchId, index) => (
              <input
                readOnly
                type="hidden"
                key={switchId}
                name={`switchIds.*.${index}`}
                value={switchId}
              />
            ))
          ) : (
            <input
              data-error-message="少なくとも1つのスイッチを選択してください"
              required
              readOnly
              type="hidden"
              name="switchIds.*.[]"
            />
          )}
          {selectedModuleIds.map((moduleId) => (
            <div key={moduleId} className={classes.switch_pannel}>
              <SwitchPannel key={moduleId} mdl={modulesById[moduleId]} />
            </div>
          ))}
        </div>

        <div className={classes.buttons}>
          {children || (
            <>
              <CustomButton color="grey" onClick={onCancel}>
                戻る
              </CustomButton>
              <WithIconButton
                type="submit"
                color="primary"
                className={classes.submitButton}
              >
                保存
              </WithIconButton>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
