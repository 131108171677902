import React, { useState, useCallback, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import SearchOutlined from '@material-ui/icons/SearchOutlined';
import CloseOutlined from '@material-ui/icons/CloseOutlined';

import classes from './styles.module.scss';

interface SearchBarProp {
  onSearch?: (keyword: string) => void;
  onChange?: (keyword: string) => void;
  value?: string;
  defaultValue?: string;
  searchPlaceholder?: string;
}

export default function SearchBar({
  defaultValue,
  value = '',
  searchPlaceholder,
  onSearch = () => {},
  onChange = () => {},
}: SearchBarProp) {
  const [isFocus, setFocus] = useState(false);
  const [handleValue, setHandleValue] = useState(defaultValue || '');
  const debounceValue = useDebounce(
    defaultValue !== undefined ? handleValue || '' : value,
    1000
  );

  const isFilled = !!handleValue;

  const onClear = useCallback(() => {
    setHandleValue('');
    onChange('');
  }, [onChange]);

  const _debounceValue = debounceValue[0];
  useEffect(() => {
    onSearch(_debounceValue.toString());
    // eslint-disable-next-line
  }, [_debounceValue]);

  const inputProps: Record<string, any> = {};
  if (defaultValue !== undefined) {
    inputProps.onChange = (e: any) => {
      const vl = e.target.value;
      setHandleValue(vl);
      onChange(vl);
    };
    inputProps.defaultValue = defaultValue;
  } else {
    inputProps.value = value;
    inputProps.onChange = (e: any) => {
      const vl = e.target.value;
      onChange(vl);
    };
  }

  return (
    <div
      className={[
        classes.search,
        isFocus ? 'focused' : '',
        isFilled ? classes.filled : '',
      ].join(' ')}
    >
      <SearchOutlined />
      <input
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        placeholder={searchPlaceholder || 'Search'}
        style={{ width: searchPlaceholder ? '17.5rem' : 'unset' }}
        {...inputProps}
      />
      <CloseOutlined onClick={onClear} />
    </div>
  );
}
