import React from 'react';
import Input from '@/components/atoms/Input';
import Password from '@/components/atoms/Password';
import { CustomButton } from '@/components/atoms/CustomButton';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { FormControlLabel, Switch } from '@material-ui/core';

import classes from './styles.module.scss';

interface CameraFormProps {
  readonly?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  selectRole?: boolean;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  errors?: Record<string, string>;
  initialData?: Record<string, any>;
  onCancel?: () => void;
  autoFill?: boolean;
}

export default function CameraForm({
  autoFill = true,
  readonly,
  children,
  initialData = {},
  errors = {},
  onSubmit,
  onCancel,
}: CameraFormProps) {
  return (
    <div className={classes.createForm}>
      <form noValidate onSubmit={onSubmit}>
        <div className={classes.formWrap}>
          <div className={classes.avatar}>
            <div className={classes.thumbnail}>
              <CameraAltIcon />
            </div>
          </div>
          <div className={classes.inputs}>
            <div className={classes.input}>
              <Input
                autoFill={autoFill}
                required
                label="※カメラ名"
                name="name"
                readonly={readonly}
                defaultValue={initialData.name}
                error={errors.name}
              />
            </div>
            <div className={classes.input}>
              <Input
                autoFill={autoFill}
                required
                label="※カメラIP"
                name="ip"
                readonly={readonly}
                defaultValue={initialData.ip}
                error={errors.ip}
              />
            </div>
            <div className={classes.input}>
              <Input
                autoFill={autoFill}
                label="カメラログインID"
                name="login_id"
                readonly={readonly}
                defaultValue={initialData.login_id}
                error={errors.login_id}
              />
            </div>
            <div className={classes.input}>
              <Password
                defaultValue={initialData.pass}
                label="カメラPASS"
                name="pass"
                error={errors.pass}
              />
            </div>
            <div className={classes.input}>
              <FormControlLabel
                className={classes.switch}
                control={
                  <Switch
                    name="is_valid"
                    defaultChecked={
                      initialData.is_valid == null
                        ? true
                        : !!initialData.is_valid
                    }
                    size="small"
                    color="primary"
                  />
                }
                label="表示する"
                labelPlacement="start"
              />
            </div>
            <div className={classes.input}>
              <div className={classes.note}>※ 必須入力項目です。</div>
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          {children || (
            <>
              <CustomButton color="grey" onClick={onCancel}>
                戻る
              </CustomButton>
              <CustomButton
                type="submit"
                color="primary"
                className={classes.submitButton}
              >
                保存
              </CustomButton>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
