import React from 'react';
import {
  Create,
  Edit,
  Delete,
  Save,
  Details,
  ExitToApp,
  ArrowBack,
  Add,
} from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { CustomButtonProps, CustomButton } from '../CustomButton';
import { useHistory } from 'react-router-dom';
import useStyles, { useBackButttonStyles } from './styles';
import clsx from 'clsx';

type WithIconButtonProps = {
  icon?: (props: any) => JSX.Element | null;
  loading?: boolean;
} & CustomButtonProps;

type ButtonProps = Omit<WithIconButtonProps, 'icon' | 'color'>;

type BackButtonProps = {
  url?: string;
} & ButtonProps;

export const WithIconButton: React.FC<WithIconButtonProps> = ({
  icon: Icon,
  children,
  loading = false,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  return (
    <CustomButton {...props} disabled={disabled || loading}>
      {loading ? (
        <CircularProgress
          className={classes.circlureIcon}
          size="1rem"
          data-testid="loadingIcon"
        />
      ) : (
        Icon && <Icon className={classes.icon} data-testid="icon" />
      )}
      {children}
    </CustomButton>
  );
};

export const CreateButton: React.FC<ButtonProps> = ({
  children = '作成',
  ...props
}) => (
  <WithIconButton color="info" icon={Create} {...props}>
    {children}
  </WithIconButton>
);

export const AddButton: React.FC<ButtonProps> = ({
  children = '追加',
  ...props
}) => (
  <WithIconButton color="info" icon={Add} {...props}>
    {children}
  </WithIconButton>
);

export const EditButton: React.FC<ButtonProps> = ({
  children = '編集',
  ...props
}) => (
  <WithIconButton color="primary" icon={Edit} {...props}>
    {children}
  </WithIconButton>
);

export const DetailButton: React.FC<ButtonProps> = ({
  children = '詳細',
  ...props
}) => (
  <WithIconButton color="primary" icon={Details} {...props}>
    {children}
  </WithIconButton>
);

export interface DeleteButtonProps extends ButtonProps {
  showIcon?: boolean;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  showIcon = true,
  children = '削除',
  ...props
}) => (
  <WithIconButton
    color="secondary"
    icon={showIcon ? Delete : () => null}
    {...props}
  >
    {children}
  </WithIconButton>
);

export const SaveButton: React.FC<ButtonProps> = ({
  children = '保存',
  ...props
}) => (
  <WithIconButton color="success" icon={Save} {...props}>
    {children}
  </WithIconButton>
);

export const LoginButton: React.FC<ButtonProps> = ({
  children = 'ログイン',
  ...props
}) => (
  <WithIconButton color="warning" icon={ExitToApp} {...props}>
    {children}
  </WithIconButton>
);

export const BackButton: React.FC<BackButtonProps> = ({
  children = '戻る',
  url,
  ...props
}) => {
  const history = useHistory();
  const classes = useBackButttonStyles();

  const handleClick = url
    ? (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        history.push(url)
    : props.onClick;

  return (
    <WithIconButton
      color="grey"
      icon={ArrowBack}
      {...{ ...props, onClick: handleClick }}
      className={clsx(classes.withIconButton, props.className)}
    >
      {children}
    </WithIconButton>
  );
};
