import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/app/store';
import { RootState } from '@/app/rootReducer';
import { PagingOptions } from '@/interfaces';
import { User } from '../users';

export type SiteUsersState = {
  siteUsersById: Record<number, User>;
  pagingOptions: PagingOptions;
  firstUser?: User | null;
};

export const initialState: SiteUsersState = {
  siteUsersById: {},
  pagingOptions: {
    total: 0,
    perPage: 5,
  },
};

const siteUsersSlice = createSlice({
  name: 'siteUsersSlice',
  initialState,
  reducers: {
    setFirstUser(state, { payload }: PayloadAction<User | null>) {
      state.firstUser = payload;
    },
    clearSiteUsers(state) {
      const deleteIds = Object.keys(state.siteUsersById);
      deleteIds.forEach(id => {
        delete state.siteUsersById[id as any];
      });
      state.pagingOptions.total = 0;
    },
    siteUserSetPagingOptions(state, action: PayloadAction<PagingOptions>) {
      state.pagingOptions.perPage = action.payload.perPage;
      state.pagingOptions.total = action.payload.total;
    },
    siteUserGetsSucces(state, action: PayloadAction<User[]>) {
      action.payload.forEach(siteUser => {
        state.siteUsersById[siteUser.id] = siteUser;
      });
    },
    siteUserGetSuccess(state, action: PayloadAction<User>) {
      const siteUser = action.payload;
      state.siteUsersById[siteUser.id] = siteUser;
    },
    siteUserDeleteSuccess(state, { payload }: PayloadAction<number>) {
      delete state.siteUsersById[payload];
    },
    siteUsersDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach(siteUserId => {
        delete state.siteUsersById[siteUserId];
      });
    },
  },
});

export const {
  setFirstUser,
  siteUserDeleteSuccess,
  siteUserGetsSucces,
  siteUserGetSuccess,
  siteUsersDeleteById,
  clearSiteUsers,
  siteUserSetPagingOptions,
} = siteUsersSlice.actions;

export default siteUsersSlice.reducer;

export const siteUsersDelete = (
  dispatch: AppDispatch,
  rootState: RootState,
  siteUserIdList: number[]
) => {
  dispatch(siteUsersDeleteById(siteUserIdList));
};
