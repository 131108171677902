import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import EmojiObjectsTwoToneIcon from '@material-ui/icons/EmojiObjectsTwoTone';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { Boolean } from '@/slicers/switches';
import Tooltip from '@/components/atoms/Tooltip';

import Toggler from '../Toggler';
import SwitchCheckbox from '../SwitchCheckbox';

import classes from './styles.module.scss';

type IconAvatar = '' | 'light' | 'up' | 'down' | 'stop';
type SwitchBoxProps = {
  index: number;
  control: Control<any>;
};

const SwitchBox = ({ control, index }: SwitchBoxProps) => {
  return (
    <div className={classes.switchBox}>
      <div className={classes.row}>
        <Controller
          control={control}
          name={`booleanFields[${index}]`}
          as={({ value, onChange }) => {
            return (
              <>
                <Toggler
                  checked={!!value.isDisplay}
                  labelColor="#000"
                  color="primary"
                  onChange={({ target: { checked } }) => {
                    onChange({ ...value, isDisplay: +checked as Boolean });
                  }}
                  name="xxx"
                />
                <div>
                  <SwitchCheckbox
                    checked={!!value.leakageCheck}
                    onChange={({ target: { checked } }) => {
                      onChange({ ...value, leakageCheck: +checked as Boolean });
                    }}
                    color="#000"
                    label="異常メール受信"
                  />
                  <SwitchCheckbox
                    checked={!!value.isLock}
                    onChange={({ target: { checked } }) => {
                      onChange({ ...value, isLock: +checked as Boolean });
                    }}
                    color="#000"
                    label="ロック"
                  />
                  <SwitchCheckbox
                    checked={!!value.requiredConfirmation}
                    onChange={({ target: { checked } }) => {
                      onChange({
                        ...value,
                        requiredConfirmation: +checked as Boolean,
                      });
                    }}
                    color="#000"
                    label="操作確認"
                  />
                </div>
              </>
            );
          }}
        />
      </div>
      <Controller
        control={control}
        name={`textFields[${index}]`}
        as={({ value, onChange }) => (
          <>
            <div className={classes.input}>
              <span>PID</span>
              <input
                placeholder="PIDXXX"
                value={value.pid}
                onChange={(e) => onChange({ ...value, pid: e.target.value })}
              />
            </div>
            <div className={classes.input}>
              <input
                placeholder="スイッチ名"
                value={value.name}
                onChange={(e) => onChange({ ...value, name: e.target.value })}
              />
            </div>
            <div className={classes.input}>
              <span>電力計</span>
              <input
                placeholder="紐づける電力計"
                value={value.powerMeterId}
                type="number"
                onChange={(e) =>
                  onChange({ ...value, powerMeterId: e.target.value })
                }
              />
            </div>
            <div className={classes.input}>
              <span>MAX</span>
              <input
                placeholder="電流の上限値"
                value={value.maxAmps}
                type="number"
                onChange={(e) =>
                  onChange({ ...value, maxAmps: e.target.value })
                }
              />
            </div>
            <Tooltip
              trigger={
                <div className={classes.avatarButton}>
                  {value.icon === 'stop' ? (
                    <div className={classes.stopIcon} />
                  ) : value.icon === 'light' ? (
                    <EmojiObjectsTwoToneIcon />
                  ) : value.icon === 'up' ? (
                    <ArrowDropUpIcon className={classes.small} />
                  ) : value.icon === 'down' ? (
                    <ArrowDropDownIcon className={classes.small} />
                  ) : (
                    'デザイン選択'
                  )}
                </div>
              }
            >
              <div className={classes.avatarOptions}>
                <div className={classes.title}>
                  ボタンのデザインを選択して下さい
                </div>
                <RadioGroup
                  value={value.icon}
                  onChange={(e) => {
                    onChange({ ...value, icon: e.target.value as IconAvatar });
                  }}
                >
                  <div className={classes.iconOptions}>
                    <FormControlLabel
                      data-tooltip-role="close"
                      className={classes.iconOption}
                      control={<Radio value="" size="small" />}
                      label={
                        <div className={classes.optionHolder}>
                          <div />
                        </div>
                      }
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      data-tooltip-role="close"
                      className={classes.iconOption}
                      control={<Radio value="light" size="small" />}
                      label={
                        <div className={classes.optionHolder}>
                          <div>
                            <EmojiObjectsTwoToneIcon />
                          </div>
                        </div>
                      }
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      data-tooltip-role="close"
                      className={classes.iconOption}
                      control={<Radio value="up" size="small" />}
                      label={
                        <div className={classes.optionHolder}>
                          <div>
                            <ArrowDropUpIcon fontSize="large" />
                          </div>
                        </div>
                      }
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      data-tooltip-role="close"
                      className={classes.iconOption}
                      control={<Radio value="stop" size="small" />}
                      label={
                        <div className={classes.optionHolder}>
                          <div>
                            <div className={classes.stopIcon} />
                          </div>
                        </div>
                      }
                      labelPlacement="bottom"
                    />
                    <FormControlLabel
                      data-tooltip-role="close"
                      className={classes.iconOption}
                      control={<Radio value="down" size="small" />}
                      label={
                        <div className={classes.optionHolder}>
                          <div>
                            <ArrowDropDownIcon fontSize="large" />
                          </div>
                        </div>
                      }
                      labelPlacement="bottom"
                    />
                  </div>
                </RadioGroup>
              </div>
            </Tooltip>
          </>
        )}
      />
    </div>
  );
};

export default SwitchBox;
