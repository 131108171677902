import React, { useMemo, useState, useEffect, useCallback } from 'react';
import AddOutlined from '@material-ui/icons/AddOutlined';

import { WithIconButton } from '../Buttons';
import SearchBar from '../SearchBar';
import Pagination from '../Pagination';
import Table, { TableProps } from '../Table';
import Drawer, { DrawerProps } from '../Drawer';

import classes from './styles.module.scss';

interface ListProps<T> extends TableProps<T> {
  drawerProps?: Omit<DrawerProps, 'isOpen' | 'onChange' | 'children'>;
  subHeading?: React.ReactElement | boolean;
  showSearch?: boolean;
  total?: number;
  forcePage?: number;
  forceKeyword?: string;
  title: string;
  addButtonText?: string;
  onSearch?: (keyword: string) => void;
  onChangePage?: (page: number, keyword?: string) => void;
  perPage?: number;
  filterFields?: string[];
  getDrawerContent?: (closeDrawer: () => void) => React.ReactElement | null;
}

export default function List<T extends { [key: string]: any; id: number }>({
  drawerProps = {},
  subHeading,
  showSearch = true,
  total,
  forceKeyword = '',
  forcePage,
  getDrawerContent = () => null,
  onChangePage = () => {},
  perPage = 5,
  title,
  addButtonText,
  onSearch = () => {},
  data,
  filterFields = ['name'],
  columns,
  ...rest
}: ListProps<T>) {
  const [currentPage, setCurrentPage] = useState(forcePage || 1);
  const [currentKw, setCurrentKw] = useState(forceKeyword);
  const [isOpenDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setCurrentPage(forcePage || 1);
  }, [forcePage]);

  useEffect(() => {
    setCurrentKw(forceKeyword);
  }, [forceKeyword]);

  const filteredData = useMemo(() => {
    if (total != null) {
      return data;
    }

    if (currentKw) {
      return data.filter((item) => {
        return filterFields.some((field) => item[field].includes(currentKw));
      });
    }
    return data;
    // eslint-disable-next-line
  }, [JSON.stringify(data), currentKw, total]);

  const pageList = useMemo(() => {
    if (total != null) {
      return filteredData;
    }

    const start = (currentPage - 1) * perPage;
    const end = start + perPage;
    return filteredData.slice(start, end);
    // eslint-disable-next-line
  }, [JSON.stringify(filteredData), total, currentPage, perPage]);

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page);
      onChangePage(page, currentKw);
    },
    [currentKw, onChangePage]
  );

  const onKeywordChange = useCallback(
    (kw) => {
      onSearch(kw);
      handleChangePage(1);
    },
    // eslint-disable-next-line
    [handleChangePage]
  );

  const drawerContent = useMemo(() => {
    return getDrawerContent(() => setOpenDrawer(false));
  }, [getDrawerContent]);

  return (
    <div className={classes.list}>
      <div className={classes.heading}>
        <div className={classes.headingTitle}>{title}</div>
        <div className={classes.headingRight}>
          <div className={classes.search}>
            {showSearch && (
              <SearchBar
                value={currentKw}
                onChange={setCurrentKw}
                onSearch={onKeywordChange}
              />
            )}
          </div>
          {addButtonText && (
            <WithIconButton
              icon={AddOutlined}
              color="info"
              className={classes.addButton}
              onClick={() => setOpenDrawer(true)}
            >
              {addButtonText}
            </WithIconButton>
          )}
        </div>
      </div>
      {subHeading}
      <div
        className={[classes.table, !subHeading ? classes.spacing : ''].join(
          ' '
        )}
      >
        <Table<T> data={pageList} columns={columns} {...rest} />

        <Pagination
          className={classes.paging}
          total={total != null ? total : filteredData.length}
          currentPage={currentPage}
          perPage={perPage}
          onChange={handleChangePage}
        />
      </div>

      <Drawer isOpen={isOpenDrawer} onChange={setOpenDrawer} {...drawerProps}>
        {drawerContent}
      </Drawer>
    </div>
  );
}
