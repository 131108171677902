import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/app/store';
import { RootState } from '@/app/rootReducer';
import { schedulesDelete } from '../schedules';
import { PagingOptions } from '@/interfaces';

export type SitesState = {
  sitesById: Record<number, Site>;
  pagingOptions: PagingOptions;
};

export type Site = {
  name: string;
  post_code: string;
  pref: string;
  address: string;
  tel: string | null;
  fax: string | null;
  id: number;
  company_id: number;
  created_at?: string;
};

export const initialState: SitesState = {
  sitesById: {},
  pagingOptions: {
    total: 0,
    perPage: 5,
  },
};

const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    clearSites(state) {
      const deleteIds = Object.keys(state.sitesById);
      deleteIds.forEach(id => {
        delete state.sitesById[id as any];
      });
      state.pagingOptions.total = 0;
    },
    siteSetPagingOptions(state, action: PayloadAction<PagingOptions>) {
        state.pagingOptions.perPage = action.payload.perPage;
        state.pagingOptions.total = action.payload.total;
      },
    siteGetsSucces(state, action: PayloadAction<Site[]>) {
      action.payload.forEach(site => {
        state.sitesById[site.id] = site;
      });
    },
    siteGetSuccess(state, action: PayloadAction<Site>) {
      const site = action.payload;
      state.sitesById[site.id] = site;
    },
    siteDeleteSuccess(state, { payload }: PayloadAction<number>) {
      delete state.sitesById[payload];
    },
    sitesDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach(siteId => {
        delete state.sitesById[siteId];
      });
    },
  },
});

export const {
  siteDeleteSuccess,
  siteGetSuccess,
  siteGetsSucces,
  sitesDeleteById,
  clearSites,
  siteSetPagingOptions
} = sitesSlice.actions;

export default sitesSlice.reducer;

export const sitesDelete = (
  dispatch: AppDispatch,
  rootState: RootState,
  siteIdList: number[]
) => {
  dispatch(sitesDeleteById(siteIdList));

  const scheduleIds = Object.values(rootState.scheduleState.schedulesById)
    .filter(schedule => siteIdList.includes(schedule.site_id))
    .map(schedule => schedule.id);

  schedulesDelete(dispatch, rootState, scheduleIds);
};
