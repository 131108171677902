import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import { useSelector } from 'react-redux';
import { route } from '@/routes/url-generator';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';
import { getLoginUserSelector } from '@/selector/users';

const AgentCompaniesBreadCrumbResource: React.FC = () => {
  const loginUser = useSelector(getLoginUserSelector);

  return (
    loginUser && (
      <BreadCrumbs>
        <BreadCrumbItem
          title={'企業管理'}
          to={route.agentCompanies.normalCompanies.list(
            loginUser.company_id.toString()
          )}
        />
      </BreadCrumbs>
    )
  );
};

export default AgentCompaniesBreadCrumbResource;
