import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { CustomButton } from '@/components/atoms/CustomButton';

import classes from './styles.module.scss';

type EmissionFactorInputProps = {
  disabled?: boolean;
  onSave: (factorId: number, value: number) => void;
  options: EmissionFactor[];
  defaultValue?: number;
};

const EmissionFactorInput = ({
  defaultValue,
  disabled,
  onSave,
  options,
}: EmissionFactorInputProps) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      emissionFactor: defaultValue,
    },
  });

  const onSubmit = useCallback(
    async ({ emissionFactor }) => {
      emissionFactor = +emissionFactor; //to number
      const value = options.find((opt) => {
        return opt.id === emissionFactor;
      })!.factor;
      onSave(emissionFactor, value);
    },
    [onSave, options]
  );

  return (
    <form
      className={classes.emissionFactorInput}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={classes.input}>
        <select
          name="emissionFactor"
          ref={register({
            required: true,
          })}
        >
          <option value="">--基礎排出係数--</option>
          {options.map((opt) => (
            <option key={opt.id} value={opt.id}>
              {opt.company} ({opt.factor})
            </option>
          ))}
        </select>
        {(errors.emissionFactor as any) && (
          <span className={classes.error}>Please input correct value</span>
        )}
      </div>

      <CustomButton disabled={disabled} type="submit" color="primary">
        登録
      </CustomButton>
    </form>
  );
};

export default EmissionFactorInput;
