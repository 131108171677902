import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';

const LoggerListBreadCrumb: React.FC = () => {
  return (
    <BreadCrumbs>
      <BreadCrumbItem title="Logger" />
    </BreadCrumbs>
  );
};

export default LoggerListBreadCrumb;
