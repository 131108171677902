import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/app/store';
import { RootState } from '@/app/rootReducer';
import { PagingOptions } from '@/interfaces';
import { Site } from '../sites';
import { Switch as CompanySwitch } from '../switches';

export type Boolean = 0 | 1;

export type ModulesState = {
  modulesById: Record<number, Module>;
  switchesById: Record<number, CompanySwitch>;
  unAttachSwitches: Record<number, Switch[]>;
  pagingOptions: PagingOptions;
  unAttachModules: Record<number, Module[]>;
};

export enum ModuleChannelType {
  B16Channel = 1,
  B1Channel = 2,
  B64Channel,
}

export type Module = {
  id: number;
  name: string;
  device_code: string;
  control_no: string | null;
  unitsByNumber: Record<number, Units>;
  site_id: number | null;
  company_id: number;
  owner_id?: number;
  site?: Site;
  is_lock?: Boolean;
  start_date?: string;
  due_date?: string;
  leakage_check?: boolean;
  earthquake_warning_flg?: boolean;
  earthquake_intensity?: number;
  m_emission_factor_id?: number;
  type?: ModuleChannelType;
  schedule_alert?: boolean;
  remark?: string;
};

export type Units = {
  id: number;
  name: string;
  number: number;
  group_on_off_flg?: Boolean;
  switchesByNumber: Record<number, Switch>;
  amps?: number; // 電力消費量
};

export type Switch = {
  id: number;
  name: string;
  number: number;
  shutter_mode: Boolean;
  icon: string;
  state?: number;
  is_lock?: Boolean;
  leakage_check?: boolean;
  required_confirmation?: boolean;
  switch_id?: number;
  is_display?: Boolean;
  communication_switch?: {
    id: number;
    max_amps: number;
    name: string;
    pid: string;
    power_meter_id: number;
  };
} & Record<string, any>;

export const initialState: ModulesState = {
  modulesById: {},
  switchesById: {},
  unAttachSwitches: [],
  pagingOptions: {
    total: 0,
    perPage: 5,
  },
  unAttachModules: {},
};

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    moduleAddUnAttachModules(
      state,
      action: PayloadAction<{ companyId: number; modules: Module[] }>
    ) {
      const { companyId, modules } = action.payload;
      state.unAttachModules[companyId] = state.unAttachModules[companyId] || [];
      modules.forEach((mdl) => {
        if (
          !state.unAttachModules[companyId].some((item) => item.id === mdl.id)
        ) {
          state.unAttachModules[companyId].push(mdl);
        }
      });
    },
    moduleSetUnAttachModules(
      state,
      action: PayloadAction<{ companyId: number; modules: Module[] }>
    ) {
      const { companyId, modules } = action.payload;
      state.unAttachModules[companyId] = [...modules];
    },
    clearModules(state) {
      const deleteIds = Object.keys(state.modulesById);
      deleteIds.forEach((id) => {
        delete state.modulesById[id as any];
      });
      state.pagingOptions.total = 0;
    },
    moduleSetPagingOptions(state, action: PayloadAction<PagingOptions>) {
      state.pagingOptions.perPage = action.payload.perPage;
      state.pagingOptions.total = action.payload.total;
    },
    moduleGetsSucces(state, action: PayloadAction<Module[]>) {
      action.payload.forEach((commsModule) => {
        state.modulesById[commsModule.id] = commsModule;
      });
    },
    moduleOnlyGetsSucces(
      state,
      action: PayloadAction<Omit<Module, 'unitsByNumber'>[]>
    ) {
      action.payload.forEach((commsModule) => {
        state.modulesById[commsModule.id] = {
          ...commsModule,
          unitsByNumber: state.modulesById[commsModule.id]?.unitsByNumber || [],
        };
      });
    },
    moduleGetSuccess(state, action: PayloadAction<Module>) {
      const module = action.payload;
      state.modulesById[module.id] = module;
    },
    moduleDeleteSuccess(state, { payload }: PayloadAction<number>) {
      delete state.modulesById[payload];
    },
    modulesDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach((id) => {
        delete state.modulesById[id];
      });
    },
    switchesByModuleId(state, action: PayloadAction<CompanySwitch[]>) {
      action.payload.forEach((commsSwitch) => {
        state.switchesById[commsSwitch.id] = commsSwitch;
      });
    },
    switchByModuleIdGetSuccess(state, action: PayloadAction<CompanySwitch>) {
      const switchItem = action.payload;
      state.switchesById[switchItem.id] = switchItem;
    },
    moduleDetachSwitchById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach((id) => {
        delete state.switchesById[id];
      });
    },
  },
});

export const {
  moduleAddUnAttachModules,
  moduleSetUnAttachModules,
  moduleDeleteSuccess,
  moduleGetSuccess,
  moduleGetsSucces,
  moduleOnlyGetsSucces,
  modulesDeleteById,
  moduleSetPagingOptions,
  clearModules,
  switchesByModuleId,
  switchByModuleIdGetSuccess,
  moduleDetachSwitchById,
} = modulesSlice.actions;

export default modulesSlice.reducer;

export const modulesDelete = (
  dispatch: AppDispatch,
  rootState: RootState,
  scheduleIdList: number[]
) => {
  dispatch(modulesDeleteById(scheduleIdList));
};
