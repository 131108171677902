import React, { forwardRef } from 'react';

import classes from './styles.module.scss';

interface SelectProps {
  readonly?: boolean;
  label?: string;
  placeholder?: string;
  onChange?: (value: any) => void;
  name?: string;
  defaultValue?: string | number;
  required?: boolean;
  error?: string;
  options: { label: string; value: string | number }[];
}

function Select(
  {
    readonly,
    label,
    options,
    placeholder = '',
    onChange = () => {},
    name,
    defaultValue = '',
    required,
    error,
  }: SelectProps,
  ref: any
) {
  return (
    <div className={[classes.input, error ? classes.error : ''].join(' ')}>
      {label && <span className={classes.label}>{label}</span>}
      <select
        ref={ref}
        disabled={readonly}
        required={required}
        placeholder={placeholder}
        name={name}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="" label={placeholder ? `--${placeholder}--` : ''} />
        {options.map((option, index) => (
          <option key={option.value + '-' + index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <span className={classes.errorMessage}>{error}</span>}
    </div>
  );
}

export default forwardRef(Select);
