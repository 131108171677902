import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = (ratio: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        height: 0,
        paddingBottom: `${(1 / ratio) * 100}%`,
        position: 'relative'
      },
      container: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      inner: {
        width: '100%',
        height: '100%'
      }
    })
  )

export default useStyles
