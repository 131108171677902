import { RootState } from '@/app/rootReducer'
import { createSelector } from 'reselect'

export const selectorFindScheduleItemsByScheduleId = (scheduleId: number) =>
  createSelector(
    (state: RootState) => state.scheduleItemState.scheduleItemsById,
    scheduleItemsById => {
      const scheduleItems =
        Object.values(scheduleItemsById).filter(
          item => item.schedule_id === scheduleId
        ) || []

      return scheduleItems
    }
  )
