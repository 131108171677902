import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { moduleGetSuccess, Module } from '@/slicers/modules';
import { setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import { cameraGetsSucces, camerasDeleteById } from '@/slicers/cameras';
import { MainApiCaller, TApiCaller } from '@/api-service';

export const readModuleWithToModuleWithMap = (
  readModuleWith: Record<string, any>
): Module => {
  const { units = [], ...other } = readModuleWith as any;
  let moduleWith: Module = { ...other, unitsByNumber: {} } as any;

  units.forEach((powerUnit: any) => {
    const { switches, ...other } = powerUnit;
    moduleWith.unitsByNumber[powerUnit.number] = {
      ...other,
      switchesByNumber: {},
    };
    switches.forEach((powerSwitch: any) => {
      moduleWith.unitsByNumber[powerUnit.number].switchesByNumber[
        powerSwitch.number
      ] = powerSwitch;
    });
  });

  return moduleWith;
};

@MainApiCaller('/companies')
class CompaniesRequest {
  caller!: TApiCaller;

  reregister(companyId: string, moduleId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${companyId}/modules/${moduleId}/reregister`,
            data
          );
          if (response.status === 200) {
            dispatch(
              moduleGetSuccess(readModuleWithToModuleWithMap(response.data))
            );
            dispatch(cameraGetsSucces(response.data.cameras));
            data.deleted_camera_ids &&
              dispatch(camerasDeleteById(data.deleted_camera_ids));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
            // dispatch(moduleDeleteSuccess(Number(moduleId)))
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }
}

const companiesRequest = new CompaniesRequest();
export default companiesRequest;
