import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';

export type iconNames = 'inbox';

type SideBarMenuItemProps = {
  icon?: React.FC;
  to?: string;
  title: string;
  anotherTab?: boolean;
};

const SideBarMenuItem: React.FC<SideBarMenuItemProps> = ({
  icon,
  title,
  to,
  anotherTab = false,
}) => {
  const handleClick = () => {
    window.open(to, '_blank');
  };

  return to ? (
    anotherTab ? (
      <div onClick={handleClick}>
        <ListItem button key={to}>
          {icon && <IconWrapper Icon={icon} />}
          <ListItemText primary={title} />
        </ListItem>
      </div>
    ) : (
      <ListItem button key={to} component={Link} to={to}>
        {icon && <IconWrapper Icon={icon} />}
        <ListItemText primary={title} />
      </ListItem>
    )
  ) : (
    <ListItem>
      {icon && <IconWrapper Icon={icon} />}
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default SideBarMenuItem;

type IconWrapperProps = {
  Icon: React.FC;
};

const IconWrapper: React.FC<IconWrapperProps> = ({ Icon }) => (
  <ListItemIcon>
    <Icon />
  </ListItemIcon>
);
