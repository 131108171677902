import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './react-datetime.css'
import * as serviceWorker from './serviceWorker'
import AppWrapper from './AppWrapper'

const render = () => {
  const App = require('./App').default

  ReactDOM.render(
    <AppWrapper>
      <App />
    </AppWrapper>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
