import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import VideocamIcon from '@material-ui/icons/Videocam';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import { FormControlLabel, Switch } from '@material-ui/core';

import { Camera } from '@/slicers/cameras';
import { Site } from '@/slicers/sites';
import {
  ModuleWith,
  PowerSwitchState,
  SwitchingState,
} from '@/slicers/operation_page';
import { User, UserTypeAdmin } from '@/slicers/users';

import Modal from '@/components/atoms/Modal';
import KWResetAlert from '@/components/organisms/KWResetAlert';

import { useDeviceCommunication } from '@/hooks/socket';
import { useAgent } from '@/hooks';

import CameraItem from '../CameraItem';
import Unit from '../Unit';

import classes from './switches.module.scss';

interface SwitchesProps {
  loginUser: User;
  isReady?: boolean;
  events: ReturnType<typeof useDeviceCommunication>['events'];
  site: Site;
  mdl: ModuleWith & {
    cameras: Camera[];
    units?: {
      switches: {
        communication_switch: {
          power_meter_id?: number;
        };
        id: number;
      }[];
    }[];
  };
  handleSaveModuleName: (id: number, name: string) => void;
}

export default function Switches({
  loginUser,
  isReady,
  events,
  site,
  mdl,
  handleSaveModuleName,
}: SwitchesProps) {
  const isMobile = useAgent();
  const [kwResetAlertData, setKWResetAlertData] = useState<{
    pid: string;
    isSuccess: boolean;
  }>();
  const [isEditName, showEditName] = useState(false);
  const [isContinuumTesting, setContinuumTesting] = useState(false);
  const communicationSwitches = useMemo(() => {
    const unit = mdl.unitsByNumber[1];
    const switches = mdl.units?.[0]?.switches;
    if (!unit || !switches) {
      return [];
    }

    return Object.values(unit.switchesByNumber)
      .filter((swt) => swt.switchId != null && !!swt.isDisplay)
      .map(
        (swt) =>
          ({
            id: swt.id,
            isLock: swt.isLock,
            requiredConfirmation: swt.requiredConfirmation,
            name: swt.name,
            icon: swt.icon,
            pid: swt.pid,
            maxAmps: swt.maxAmps,
            powerMeterId: switches.find((sw) => sw.id === swt.id)
              ?.communication_switch?.power_meter_id,
          } as CommunicationSwitch)
      );
  }, [mdl]);

  const communicationSwitchesHavePID = useMemo(() => {
    return communicationSwitches.filter(({ pid }) => !!pid) as (Omit<
      CommunicationSwitch,
      'pid'
    > & { pid: string })[];
  }, [communicationSwitches]);

  const handleSaveNewName = useCallback(
    async (e) => {
      const newName = e.target.value;
      if (newName) {
        await handleSaveModuleName(mdl.id, newName);
      }
      showEditName(false);
    },
    [mdl, handleSaveModuleName]
  );

  const handleChangeSwitchState = useCallback(
    (pid, nextState, shouldConfirm) => {
      let canChange = true;

      if (shouldConfirm) {
        const isConfirm = window.confirm('スイッチ操作をしますか？');
        canChange = isConfirm;
      }
      if (canChange) {
        events.sendToEvent('pid-switch', {
          pids: [pid],
          state: nextState,
        });
      }
    },
    [events]
  );

  const [stateByPID, setStateByPID] = useState(
    useMemo(() => {
      return communicationSwitchesHavePID.reduce(
        (obj: Record<string, CommunicationState>, commSwitch) => {
          obj[commSwitch.pid] = {
            state: PowerSwitchState.INIT,
            switchingState: SwitchingState.STANDBY,
            socketId: undefined,
          };
          return obj;
        },
        {}
      );
    }, [communicationSwitchesHavePID])
  );

  const connectedOKPIDs = useMemo(() => {
    return Object.keys(stateByPID).reduce<
      (CommunicationState & { pid: string })[]
    >((arr, pid) => {
      if (
        stateByPID[pid] &&
        (stateByPID[pid].state === PowerSwitchState.ON ||
          stateByPID[pid].state === PowerSwitchState.OFF)
      ) {
        arr.push({ ...stateByPID[pid], pid });
      }
      return arr;
    }, []);
  }, [stateByPID]);

  const refPidsStack = useRef<Record<string, boolean> | undefined>(undefined);

  const continuumTester = useMemo(() => {
    return {
      offAll: function () {
        events.sendToEvent('pid-switch', {
          pids: connectedOKPIDs.map((comm) => comm.pid),
          state: PowerSwitchState.OFF,
        });
      },
      onAll: function () {
        setContinuumTesting(true);
        refPidsStack.current = {};
        if (connectedOKPIDs.length) {
          for (const { pid } of connectedOKPIDs) {
            refPidsStack.current[pid] = false;
          }
          events.sendToEvent('pid-switch', {
            pids: connectedOKPIDs.map((comm) => comm.pid),
            state: PowerSwitchState.ON,
          });
        } else {
          setTimeout(() => {
            refPidsStack.current = undefined;
            setContinuumTesting(false);
          }, 1000);
        }
      },
      checkStack: function (pid: string, isDone: boolean) {
        if (refPidsStack.current) {
          refPidsStack.current[pid] = isDone;
          for (const pid in refPidsStack.current) {
            if (!refPidsStack.current[pid]) {
              // exist aleast one pid have not done yet
              return false;
            }
          }
          // all OK
          return true;
        }
        return false;
      },
      checkAndRevert: function (pid: string, isDone: boolean) {
        const isAllOk = this.checkStack(pid, isDone);
        if (isAllOk) {
          const offAll = this.offAll;
          setTimeout(() => {
            refPidsStack.current = undefined;
            offAll();
            setTimeout(() => {
              setContinuumTesting(false);
            }, 0);
          }, 1000);
        }
      },
    } as {
      onAll: () => void;
      offAll: () => void;
      checkStack: (pid: string, isDone: boolean) => boolean;
      checkAndRevert: (pid: string, isDone: boolean) => void;
    };
  }, [connectedOKPIDs, events]);

  const switchesByUnits = useMemo(() => {
    const switchPerUnit = 4;
    const amountOfUnits = Math.ceil(communicationSwitches.length / 4);
    return Array.from({ length: amountOfUnits }).map((_, i) => {
      return communicationSwitches.slice(
        i * switchPerUnit,
        i * switchPerUnit + switchPerUnit
      );
    });
  }, [communicationSwitches]);

  useEffect(() => {
    const unsub = events.addMessageListener(function (payload) {
      const { event, data } = payload as { event: string; data: any };
      switch (event) {
        case 'switchResetStatus': {
          const { pid, status } = data;
          setKWResetAlertData({ pid, isSuccess: status === 'reset' });
          break;
        }
        case 'switchResponseState': {
          for (const { deviceCode, switches } of data) {
            if (deviceCode === mdl.deviceCode) {
              console.log('received connected switches', data);
              setStateByPID({});
              for (const { state, switchingState, pid, socketId } of switches) {
                setStateByPID((prev) => ({
                  ...prev,
                  [pid]: { ...prev[pid], socketId, state, switchingState },
                }));
              }
            }
          }
          break;
        }
        case 'switchSwitched': {
          const { deviceCode, switches } = data;
          if (deviceCode === mdl.deviceCode) {
            for (const { state, switchingState, pid, socketId } of switches) {
              continuumTester.checkAndRevert(
                pid,
                switchingState === SwitchingState.STANDBY
              );

              setStateByPID((prev) => ({
                ...prev,
                [pid]: {
                  ...prev[pid],
                  state,
                  switchingState,
                  socketId,
                },
              }));
            }
          }
          break;
        }
        case 'powerConsumption': {
          const { deviceCode, amps, switches } = data;
          if (deviceCode === mdl.deviceCode) {
            for (const { pid } of switches) {
              console.log(`${pid} set amps: ${amps}`);
              setStateByPID((prev) => ({
                ...prev,
                [pid]: { ...prev[pid], amps },
              }));
            }
          }
          break;
        }
        case 'switchDisconnected': {
          const { deviceCode, switches } = data;
          if (deviceCode === mdl.deviceCode) {
            for (const { pid } of switches) {
              console.log(`${pid} disconnected`);
              setStateByPID((prev) => ({
                ...prev,
                [pid]: undefined,
              }));
            }
          }
          break;
        }
        default:
          break;
      }
    });

    return unsub;
  }, [events, mdl, continuumTester]);

  const handleContinuumTest = useCallback(
    (isOn: boolean) => {
      if (isOn) {
        setContinuumTesting(true);
        continuumTester.onAll();
      }
    },
    [continuumTester]
  );

  const switchInformation = useMemo(() => {
    return communicationSwitches.map((commSwitch) => ({
      ...commSwitch,
      socketId: stateByPID[`${commSwitch.pid}`]?.socketId,
    }));
  }, [communicationSwitches, stateByPID]);
  return (
    <>
      <div>
        {!isMobile && (
          <>
            {loginUser.realType === UserTypeAdmin && (
              <div className={classes.topTool}>
                <FormControlLabel
                  className={classes.switch}
                  control={
                    <Switch
                      disabled={
                        !isReady ||
                        isContinuumTesting ||
                        connectedOKPIDs.every(
                          (comm) => comm.state === PowerSwitchState.ON
                        )
                      }
                      checked={isContinuumTesting}
                      onChange={(e) => {
                        handleContinuumTest(e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label="連続運転"
                  labelPlacement="start"
                />
              </div>
            )}
          </>
        )}
        <div className={classes.module}>
          <div className={classes.left}>
            <div className={classes.heading}>
              {isEditName ? (
                <input
                  maxLength={20}
                  defaultValue={mdl.name?.substring(0, 20)}
                  onBlur={handleSaveNewName}
                  placeholder="指令盤の名..."
                />
              ) : (
                <div
                  onClick={() => {
                    showEditName(true);
                  }}
                  className={classes.headingTitle}
                >
                  <span>{mdl.name?.substring(0, 20)}</span>
                  <EditIcon htmlColor="#fff" />
                </div>
              )}
            </div>
            <div className={classes.board}>
              <div className={classes.columns}>
                {switchesByUnits.map((switches, i) => {
                  return (
                    <div key={i} className={classes.column}>
                      <Unit
                        onChangeUnit={(nextState) => {
                          events.sendToEvent('pid-switch', {
                            pids: switches
                              .filter((swt) => !!swt.pid)
                              .map((swt) => swt.pid),
                            state: nextState,
                          });
                        }}
                        onChange={handleChangeSwitchState}
                        isReady={isReady}
                        states={stateByPID}
                        switches={switches}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.header}>
              <div className={classes.headInfo}>
                <div className={classes.siteName}>
                  <span>{site?.name}</span>
                </div>
              </div>
            </div>
            <div className={classes.listHeading}>
              <div>
                <span>
                  <VideocamIcon />
                </span>
              </div>
              <div className={classes.title}>カメラ確認</div>
              <div>
                <CheckCircleIcon htmlColor="#FF6969" />
              </div>
            </div>
            <div className={classes.listCamera}>
              {mdl.cameras
                .filter((camera) => camera.is_valid)
                .map((camera) => (
                  <CameraItem
                    key={camera.id}
                    camera={camera}
                    siteId={site?.id}
                    siteName={site?.name}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className={classes.deviceInformation}>
          {switchInformation.map((switcheInfo) => (
            <div
              className={classes.deviceInformationContainer}
              key={switcheInfo.id}
            >
              <span>SocketID: {switcheInfo.socketId || '_ _'}</span>
              <span>PID: {switcheInfo.pid || ''}</span>
              <span>管理番号: {mdl.control_no || ''}</span>
            </div>
          ))}
        </div>
      </div>
      {kwResetAlertData && (
        <Modal defaultOpen onClose={() => setKWResetAlertData(undefined)}>
          <KWResetAlert isSuccess={kwResetAlertData.isSuccess} />
        </Modal>
      )}
    </>
  );
}
