import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import ForwardLogin from '@/components/organisms/ForwardLogin';
import { useParams } from 'react-router-dom';
import { getNormalCompaniesWithAgent } from '@/selector/companies';
import agentCompaniesNormalCompaniesRequest from '@/api/agent_companies_normal_companies';
import { UserTypeGeneralManager, UserTypeAdmin } from '@/slicers/users';
import { useCookies } from '@/hooks';
import { AgentCompaniesRouteProps } from '@/routes/AgentRoute';
import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import { setLoading } from '@/slicers/loading';
import { Box } from '@material-ui/core';
import agentCompaniesRequest from '@/api/agent_companies';
import { getLoginUserSelector } from '@/selector/users';
import { route } from '@/routes/url-generator';
import Drawer from '@/components/atoms/Drawer';
import AgentCompanyForm from '@/components/organisms/AgentCompanyForm';
import { CustomButton } from '@/components/atoms/CustomButton';
import { Company } from '@/slicers/companies';
import Detail from '../Detail';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

export const ListAgentCompaniesNormalCompanies: React.FC<{}> = () => {
  const {
    agent_company: agentCompanyId,
  } = useParams<AgentCompaniesRouteProps>();
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const dispatch = useDispatch();
  const loginUser = useSelector((state: RootState) =>
    getLoginUserSelector(state)
  );

  const { normalCompanies } = useSelector(
    (state: RootState) => ({
      normalCompanies: getNormalCompaniesWithAgent(state),
    }),
    shallowEqual
  );

  const { getCookie, setCookie } = useCookies({});

  const loginType = loginUser!.type;
  useEffect(() => {
    const func = async () => {
      await dispatch(setLoading(true));
      await dispatch(
        agentCompaniesNormalCompaniesRequest.index(agentCompanyId).request()
      );
      if (loginType <= UserTypeAdmin) {
        await dispatch(agentCompaniesRequest.index().request());
      }
      await dispatch(setLoading(false));
    };
    func();
  }, [dispatch, agentCompanyId, loginType]);

  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState<null | Record<string, any>>(null);
  const [editType, setEditType] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});

  const onSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'post_code', 'pref', 'address', 'tel', 'fax'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input) {
        if (input.hasAttribute('required')) {
          if (!input.value) {
            errors[field] = '必須入力項目です';
          }
        }
        data[field] = input.value;
      }

      return errors;
    }, {});

    setErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (editData) {
        const api = agentCompaniesNormalCompaniesRequest.put(
          agentCompanyId,
          editData.id
        );
        await dispatch(api.request(data as Company));
        setEditData({ ...data, id: editData.id });
      } else {
        const api = agentCompaniesNormalCompaniesRequest.post(agentCompanyId);
        await dispatch(api.request(data as Company));
      }
      hideOverlay();
      closeDrawer();
    }
  };

  const onDelete = async (id: string) => {
    showOverlay();
    const api = agentCompaniesNormalCompaniesRequest.delete(agentCompanyId, id);
    await dispatch(api.request());
    setEditData(null);
    setOpenDrawer(false);
    hideOverlay();
  };

  useEffect(() => {
    if (!isOpenDrawer) {
      setErrors({});
      setEditData(null);
    }
  }, [isOpenDrawer]);

  return !normalCompanies ? (
    <div>取得中です</div>
  ) : (
    <>
      {overlayLoader}
      <CustomMaterialTable
        title="企業管理"
        options={{
          initialPage: getCookie(
            'companiesNormalCompaniesRouteListPageCurrentPageNumber'
          ),
          searchText: getCookie(
            'companiesNormalCompaniesRouteListPageSearchText'
          ),
        }}
        onChangePage={(page: any) => {
          setCookie(
            'companiesNormalCompaniesRouteListPageCurrentPageNumber',
            page
          );
        }}
        onSearchChange={(text: any) => {
          setCookie('companiesNormalCompaniesRouteListPageSearchText', text);
        }}
        columns={[
          {
            field: 'name',
            title: '企業名',
          },
          {
            title: '操作',
            render: (row: any) => (
              <Box style={{ minWidth: '240px' }}>
                <ForwardLogin
                  type={UserTypeGeneralManager}
                  companyId={row.id}
                  to={route.clientRoute.sites.list(row.id)}
                >
                  <CustomButton color="primary">各企業管理画面へ</CustomButton>
                </ForwardLogin>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('agent');
                    setOpenDrawer(true);
                  }}
                  style={{ margin: '5px' }}
                >
                  企業情報編集
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('agentmanager');
                    setOpenDrawer(true);
                  }}
                >
                  ユーザー編集
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('module');
                    setOpenDrawer(true);
                  }}
                  style={{ margin: '5px' }}
                >
                  指令盤編集
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('switch');
                    setOpenDrawer(true);
                  }}
                  style={{ margin: '5px' }}
                >
                  スイッチ管理
                </CustomButton>
              </Box>
            ),
          },
        ]}
        data={normalCompanies.map((item: any) => ({ ...item }))}
        addButton={
          <CustomButton
            color="grey"
            style={{ background: '#56ccf2', color: 'white' }}
            onClick={() => setOpenDrawer(true)}
          >
            追加
          </CustomButton>
        }
      />
      <Drawer isOpen={isOpenDrawer} onChange={setOpenDrawer}>
        {editData ? (
          <Detail
            onConfirmDelete={onDelete}
            errors={errors}
            initialData={editData}
            onSubmit={onSubmit}
            editType={editType}
            onCancel={() => setOpenDrawer(false)}
          />
        ) : (
          <AgentCompanyForm
            errors={errors}
            onSubmit={onSubmit(() => setOpenDrawer(false))}
            onCancel={() => setOpenDrawer(false)}
          />
        )}
      </Drawer>
    </>
  );
};

export default ListAgentCompaniesNormalCompanies;
