import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { setResponse, ErrorPosition } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import {
  scheduleGetsSucces,
  scheduleGetSuccess,
  schedulesDelete,
} from '@/slicers/schedules';
import {
  scheduleItemItemGetsSucces,
  scheduleItemDeletesByScheduleIdSucces,
} from '@/slicers/schedule_items';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/sites')
class SitesSchedulesRequest {
  caller!: TApiCaller;
  index(siteId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/${siteId}/schedules`);
        if (response.status === 200) {
          dispatch(scheduleGetsSucces(response.data));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(
            setResponse({
              ...parseAxiosResponse(response),
              position: ErrorPosition.DRAWR,
            })
          );
          dispatch(setFlashError('スケジュール一覧の取得に失敗しました。'));
        }
      },
    };
  }

  get(siteId: string, scheduleId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(
          `/${siteId}/schedules/${scheduleId}`
        );
        if (response.status === 200) {
          const { items, ...schedule } = response.data;
          dispatch(scheduleGetSuccess(schedule));
          dispatch(scheduleItemItemGetsSucces(items));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('スケジュール一覧の取得に失敗しました。'));
        }
      },
    };
  }

  post(siteId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(`/${siteId}/schedules`, data);

          if (response.status === 201) {
            const { items: scheduleItems, ...schedule } = response.data;
            dispatch(scheduleGetSuccess(schedule));
            dispatch(scheduleItemItemGetsSucces(scheduleItems));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }

  put(siteId: string, scheduleId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${siteId}/schedules/${scheduleId}`,
            data
          );

          if (response.status === 200) {
            const { items: scheduleItems, ...schedule } = response.data;
            dispatch(scheduleGetSuccess(schedule));
            // scheduleItemは一旦全部削除して作り直す(バックエンド側)
            dispatch(scheduleItemDeletesByScheduleIdSucces(schedule.id));
            dispatch(scheduleItemItemGetsSucces(scheduleItems));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }

  putIsValid(siteId: string, scheduleId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${siteId}/schedules/${scheduleId}/update_is_valid`,
            data
          );

          if (response.status === 200) {
            const { items: scheduleItems, ...schedule } = response.data;
            dispatch(scheduleGetSuccess(schedule));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }

  delete(siteId: string, scheduleId: string) {
    return {
      request: (): AppThunk => async (dispatch, getState) => {
        const response = await this.caller.DEL(
          `/${siteId}/schedules/${scheduleId}`
        );
        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました'));
          schedulesDelete(dispatch, getState(), [Number(scheduleId)]);
          // dispatch(scheduleDeleteSuccess(Number(scheduleId)))
        } else {
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }
}

const sitesSchedulesRequest = new SitesSchedulesRequest();
export default sitesSchedulesRequest;
