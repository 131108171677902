import React from 'react';
import Face from '@material-ui/icons/FaceSharp';

import classes from './styles.module.scss';

enum PowerLevel {
  SAFE = 'safe',
  WARN = 'warn',
  DANGER = 'danger',
}

type TargetPowerProps = {
  monthValue: number;
  value: number;
};

const TargetPower = ({ monthValue, value }: TargetPowerProps) => {
  return (
    <div className={classes.targetPower}>
      <div className={classes.target}>
        <div>
          <span>当月電力量目標值</span>
          <input readOnly type="number" value={value} onChange={() => {}} />
        </div>
        <span>kW/h</span>
      </div>
      <div className={classes.powerLevel}>
        <div>
          <span>現在の積算電力利用量</span>
          <div
            data-level={
              monthValue < value
                ? PowerLevel.SAFE
                : monthValue > value
                ? PowerLevel.DANGER
                : PowerLevel.WARN
            }
            className={classes.powerAvatar}
          >
            <Face fontSize="small" />
            <span>{monthValue.toFixed(3)}</span>
          </div>
        </div>
        <span>kW/h</span>
      </div>
    </div>
  );
};

export default TargetPower;
