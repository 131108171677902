import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Module } from '../modules';

export type ModulesState = {
  modulesById: Record<number, Module>;
};

export const initialState: ModulesState = {
  modulesById: {},
};

const agentModulesSlice = createSlice({
  name: 'agentModules',
  initialState,
  reducers: {
    agentModuleGetsSucces(state, action: PayloadAction<Module[]>) {
      action.payload.forEach((commsModule) => {
        state.modulesById[commsModule.id] = commsModule;
      });
    },
    agentModuleGetSuccess(state, action: PayloadAction<Module>) {
      const module = action.payload;
      state.modulesById[module.id] = module;
    },
    agentModulesDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach((id) => {
        delete state.modulesById[id];
      });
    },
  },
});

export const {
  agentModuleGetsSucces,
  agentModuleGetSuccess,
  agentModulesDeleteById,
} = agentModulesSlice.actions;

export default agentModulesSlice.reducer;
