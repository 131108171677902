import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@/components/atoms/List';

import { RootState } from '@/app/rootReducer';

import Select from '@/components/atoms/Select';
import Drawer from '@/components/atoms/Drawer';
import { CustomButton } from '@/components/atoms/CustomButton';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { setLoading } from '@/slicers/loading';
import {
  selectorFindAllModulesByCompanyId,
  selectorUnAttachModulesByCompanyId,
} from '@/selector/module';

import companiesModulesRequest from '@/api/companies_modules';

import classes from './styles.module.scss';
import { route } from '@/routes/url-generator';
import { useHistory } from 'react-router-dom';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

import { ModuleChannelType } from '@/slicers/modules';

import Switch from './Switch';

interface ListModuleProps {
  companyId: number;
}

export default function ListModule({ companyId }: ListModuleProps) {
  const [overlayLoader, { handleTrigger, showOverlay, hideOverlay }] =
    useOverlayLoading();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedModuleForAttach, setSelectedModuleForAttach] = useState('');
  const [isShowSwitch, setShowSwitch] = useState(false);
  const [moduleId, setModuleId] = useState<number>(0);
  const [filter, setFilter] = useState({
    ch1: false,
    ch16: false,
    ch64: false,
  });

  const { loading } = useSelector((state: RootState) => state.loadingState);
  const modules = useSelector((state: RootState) =>
    selectorFindAllModulesByCompanyId(companyId)(state)?.map((item) => ({
      ...item,
      typeLabel:
        item.type === ModuleChannelType.B16Channel
          ? '16ch'
          : item.type === ModuleChannelType.B1Channel
          ? '1ch'
          : '64ch',
    }))
  );

  const unAttachModules = useSelector((state: RootState) =>
    selectorUnAttachModulesByCompanyId(companyId)(state)
  );

  const getInitialData = useCallback(async () => {
    await dispatch(setLoading(true));
    await dispatch(
      companiesModulesRequest.index(companyId.toString()).request()
    );
    await dispatch(companiesModulesRequest.unAttach(companyId).request());
    await dispatch(setLoading(false));
  }, [dispatch, companyId]);

  const onAttach = useCallback(async () => {
    await dispatch(
      companiesModulesRequest
        .attach(companyId, selectedModuleForAttach)
        .request()
    );
  }, [dispatch, companyId, selectedModuleForAttach]);

  const onDetach = useCallback(
    async (moduleId) => {
      showOverlay();
      await dispatch(
        companiesModulesRequest.detach(companyId, moduleId).request()
      );
      hideOverlay();
    },
    [dispatch, showOverlay, hideOverlay, companyId]
  );

  const unAttachOptions = useMemo(() => {
    return unAttachModules.map((item) => ({
      label: item.device_code,
      value: item.id,
    }));
  }, [unAttachModules]);

  const handleFilterModules = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const filteredModules = useMemo(() => {
    const filterTypes: ModuleChannelType[] = [];
    if (filter.ch1) filterTypes.push(ModuleChannelType.B1Channel);
    if (filter.ch16) filterTypes.push(ModuleChannelType.B16Channel);
    if (filter.ch64) filterTypes.push(ModuleChannelType.B64Channel);

    if (filterTypes.length) {
      return modules.filter((mdl) => filterTypes.includes(mdl.type!));
    }
    return modules;
  }, [modules, filter]);

  return (
    <>
      {overlayLoader}
      <List
        loading={loading}
        subHeading={
          <div className={classes.attachSection}>
            <FormGroup
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingRight: 20,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="ch64"
                    onChange={handleFilterModules}
                    checked={filter.ch64}
                  />
                }
                label="64ch"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="ch16"
                    onChange={handleFilterModules}
                    checked={filter.ch16}
                  />
                }
                label="16ch"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="ch1"
                    onChange={handleFilterModules}
                    checked={filter.ch1}
                  />
                }
                label="1ch"
              />
            </FormGroup>
            <div className={classes.select}>
              <Select
                onChange={setSelectedModuleForAttach}
                options={unAttachOptions}
                placeholder="デバイスコード"
              />
            </div>
            <CustomButton
              disabled={!selectedModuleForAttach}
              color="primary"
              className={classes.attachButton}
              onClick={handleTrigger(onAttach)}
            >
              レンタル
            </CustomButton>
          </div>
        }
        showSearch={false}
        data={filteredModules}
        columns={[
          {
            field: 'name',
            title: '指令盤名',
          },
          {
            field: 'typeLabel',
            title: 'タイプ',
            width: 100,
          },
          {
            field: 'control_no',
            title: '資産番号',
            width: 100,
          },
          {
            field: 'device_code',
            title: 'デバイスコード',
            width: 100,
          },
        ]}
        title=""
        tooltip={{
          options: (item) =>
            item.type === ModuleChannelType.B16Channel
              ? item.site
                ? [
                    {
                      key: 'edit',
                      title: '指令盤の編集',
                    },
                  ]
                : [
                    {
                      key: 'edit',
                      title: '指令盤の編集',
                    },
                    {
                      key: 'delete',
                      title: 'レンタル解約',
                      isConfirmDelete: true,
                      confirmProps: {
                        message: '本当に解約しますか',
                        buttonMessage: '解約',
                      },
                    },
                  ]
              : item.site
              ? [
                  {
                    key: 'edit',
                    title: '指令盤の編集',
                  },
                  {
                    key: 'switch',
                    title: 'スイッチ管理',
                  },
                  {
                    key: 'power-chart',
                    title: '使用状況を見る',
                  },
                ]
              : [
                  {
                    key: 'edit',
                    title: '指令盤の編集',
                  },
                  {
                    key: 'delete',
                    title: 'レンタル解約',
                    isConfirmDelete: true,
                    confirmProps: {
                      message: '本当に解約しますか',
                      buttonMessage: '解約',
                    },
                  },
                  {
                    key: 'switch',
                    title: 'スイッチ管理',
                  },
                  {
                    key: 'power-chart',
                    title: '使用状況を見る',
                  },
                ],
          onClick: (key, record) => {
            if (key === 'edit') {
              history.push(
                route.clientRoute.modules.show(companyId, record.id)
              );
            } else if (key === 'delete') {
              onDetach(record.id);
            } else if (key === 'switch') {
              setShowSwitch(true);
              setModuleId(record.id);
            } else if (key === 'power-chart') {
              history.push(route.modules.powerChart(record.id));
            }
          },
        }}
      />
      <Drawer isOpen={isShowSwitch} onChange={setShowSwitch}>
        <Switch companyId={companyId} moduleId={moduleId} />
      </Drawer>
    </>
  );
}
