import React from 'react'
import styles from './styles.module.scss'

import { Breadcrumbs as MaterialBreadcrumbs } from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons'

type BreadCrumbsProps = {
  children: React.ReactNode
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ children }) => (
  <MaterialBreadcrumbs
    aria-label="breadcrumb"
    className={styles.container}
    separator={<NavigateNext fontSize="small" />}
  >
    {children}
  </MaterialBreadcrumbs>
)

export default BreadCrumbs
