import React, { useEffect, useRef, useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import styles from './styles.module.scss';
import { setTimeout } from 'timers';
import {
  ModuleWith,
  PowerSwitchState,
  PowerUnit,
  PowerUnitState,
  SwitchingState,
} from '@/slicers/operation_page';
import SwitchPannelForDeviceSimurator, {
  HandleClickSwitchButtonProps,
} from '@/components/organisms/SwitchPannelForDeviceSimurator';

export const createCommModule = (deviceCode: string): ModuleWith => {
  let commModule: ModuleWith = {
    id: 1,
    deviceCode,
    name: '',
    switchingState: SwitchingState.STANDBY,
    unitsByNumber: (() => {
      let unitsByNumber: Record<number, PowerUnit> = {};
      for (let unitNumber = 1; unitNumber < 5; unitNumber++) {
        if (!unitsByNumber[unitNumber]) {
          unitsByNumber[unitNumber] = {
            number: unitNumber,
            switchesByNumber: {},
            timestamp: 0,
            state: PowerUnitState.READY,
          };
        }
        for (let switchNumber = 0; switchNumber < 4; switchNumber++) {
          unitsByNumber[unitNumber].switchesByNumber[switchNumber] = {
            id: 1,
            number: switchNumber,
            state: PowerSwitchState.OFF,
            name: 'name',
            shutterMode: 0,
            isLock: 0,
            switchingState: SwitchingState.STANDBY,
            icon: '',
          };
        }
      }
      return unitsByNumber;
    })(),
  };
  return commModule;
};

const usePrevious = <T extends {}>(value: T): T | null => {
  const ref = useRef(null) as any;
  useEffect(() => {
    ref.current = value;
  });
  return ref.current as any;
};

export default function WebSocketClientModule() {
  const [ws, setws] = useState<WebSocket>();
  const [wsClosed, setWsClosed] = useState(true);
  const [pid, setPid] = useState('000CB5');
  const [moduleWith, setModuleWith] = useState<ModuleWith>(
    createCommModule(pid)
  );
  const prevModuleWith = usePrevious(moduleWith);

  const handleClickConnectBtn = () => {
    if (!ws) return;
    ws.send(`PID=${pid}`);
    console.log('PID= sended');
    setModuleWith(createCommModule(pid));
  };

  const createREqualRequest = (unitNumber: number) => {
    let rEquestRquest = `R=${unitNumber},`;
    const powerUnit = moduleWith.unitsByNumber[unitNumber];

    let nextState = 0;
    for (let switchNumber = 0; switchNumber < 4; switchNumber++) {
      const state = powerUnit.switchesByNumber[switchNumber].state;
      const bit =
        state === PowerSwitchState.LEAK
          ? 3
          : state === PowerSwitchState.ON
          ? 2
          : state === PowerSwitchState.OFF
          ? 1
          : 0;
      nextState = nextState | (bit << (2 * switchNumber));
    }

    return (rEquestRquest += nextState);
  };

  useEffect(() => {
    if (!wsClosed) return;

    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const ws = new WebSocket(
      `${protocol}://${
        process.env.REACT_APP_WEB_SOCKET_URL || 'localhost:3004'
      }/ws/`
    );

    ws.onopen = () => {
      console.log('onopen');
      setws(ws);
      setWsClosed(false);
    };

    ws.onclose = async () => {
      console.log('onclose');
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setWsClosed(true);
    };

    ws.onmessage = (message) => {
      console.log('onmessage', message);
      if (message.data.substr(0, 5) === 'CADR=') {
      } else if (message.data.substr(0, 2) === 'R?') {
        // 監視対象の子機の通知
        for (let unitNumber = 1; unitNumber < 5; unitNumber++) {
          // const unitNumber = Number(message.data.substr(5 + 2 * i, 2))
          const rEquestRquest = createREqualRequest(unitNumber);
          ws.send(rEquestRquest);
          console.log('send R=');
        }
      } else if (message.data.substr(0, 3) === 'SW=') {
        setModuleWith((moduleWith) => {
          console.log('reseive SW=');
          // スイッチ変更要求
          const payload = message.data.substr(3);
          const p = payload.split(',');
          const unitNumber = Number(p[0]);
          const bits = Number(p[1]);

          const _moduleWith = JSON.parse(
            JSON.stringify(moduleWith)
          ) as ModuleWith;
          const powerUnit = _moduleWith.unitsByNumber[unitNumber];

          // 下位bitから1bitずつスイッチ1,スイッチ2...スイッチ8となっている
          // bitが1のスイッチはONとOFFを反転させる
          for (let switchNumber = 0; switchNumber < 4; switchNumber++) {
            if ((bits >> switchNumber) & 1) {
              powerUnit.switchesByNumber[switchNumber].state =
                powerUnit.switchesByNumber[switchNumber].state ===
                PowerSwitchState.ON
                  ? PowerSwitchState.OFF
                  : PowerSwitchState.ON;
            }
          }

          _moduleWith.unitsByNumber[unitNumber] = powerUnit;

          console.log('_moduleWith', _moduleWith);
          return _moduleWith;
        });
      }
    };
    // eslint-disable-next-line
  }, [wsClosed]);

  useEffect(() => {
    console.log('prevModuleWith', prevModuleWith);
    console.log('moduleWith', moduleWith);
    if (!prevModuleWith) return;
    if (!ws) return;
    for (const powerUnit of Object.values(prevModuleWith.unitsByNumber)) {
      for (const powerSwitch of Object.values(powerUnit.switchesByNumber)) {
        //   スイッチに変更があれば、R=イベントを送信してswitchLoopを抜ける
        if (
          powerSwitch.state !==
          moduleWith.unitsByNumber[powerUnit.number].switchesByNumber[
            powerSwitch.number
          ].state
        ) {
          const rEqualRequest = createREqualRequest(powerUnit.number);
          ws.send(rEqualRequest);
          console.log('send', rEqualRequest);
          break;
        }
      }
    }
    // eslint-disable-next-line
  }, [moduleWith]);

  const handleClickSwitchButton = ({
    state,
    switchNumber,
    unitNumber,
  }: HandleClickSwitchButtonProps) => {
    let _moduleWith = JSON.parse(JSON.stringify(moduleWith)) as ModuleWith;
    _moduleWith.unitsByNumber[unitNumber].switchesByNumber[switchNumber].state =
      state;
    setModuleWith(_moduleWith);
  };

  return (
    //   todo: moduleWithのループでスイッチを制御する
    <FormGroup>
      <div className={styles.container}>
        <div>
          デバイスコード（PID）
          <input
            value={pid}
            onChange={(e) => {
              setPid(e.target.value);
              //   setPid(e.target.value)
            }}
          />
          <button onClick={handleClickConnectBtn}>接続</button>
        </div>
        <div>
          <SwitchPannelForDeviceSimurator
            moduleWith={moduleWith}
            handleClickSwitchButton={handleClickSwitchButton}
          />
        </div>
      </div>
    </FormGroup>
  );
}
