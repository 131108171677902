import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { useGetClientRouteParam } from '@/hooks';
import companiesModulesRequest from '@/api/companies_modules';
import { getLoginUserAndCompanySelector } from '@/selector/users';
import { selectorFindAllModulesByCompanyId } from '@/selector/module';
import { setLoading } from '@/slicers/loading';

import classes from './styles.module.scss';
import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';

type CompaniesModulesListProps = {};

const CompaniesModulesList: React.FC<CompaniesModulesListProps> = () => {
  const { companyId } = useGetClientRouteParam();
  const dispatch = useDispatch();
  const modules = useSelector((state: RootState) =>
    selectorFindAllModulesByCompanyId(Number(companyId))(state)
  );
  const { loginUser } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  );

  useEffect(() => {
    const func = async () => {
      await dispatch(setLoading(true));
      await dispatch(companiesModulesRequest.index(companyId).request());
      await dispatch(setLoading(false));
    };
    func();
  }, [dispatch, companyId]);

  //   const existNoSite = modules.some(mdl => !mdl.site);
  //   未所属の電源指令盤があります。

  return (
    <CustomMaterialTable
      title="電源指令盤一覧"
      fieldSearch={['control_no', 'name', 'device_code', 'site.name']}
      searchPlaceholder="デバイスコード・指令盤名・管理番号"
      options={{
        pageSizeOptions: [5],
      }}
      columns={[
        {
          field: 'id',
          title: 'ID',
          defaultVisibility: false,
        },
        {
          field: 'name',
          title: '指令盤名',
        },
        {
          field: 'control_no',
          title: '管理番号',
        },
        {
          field: 'device_code',
          title: 'デバイスコード',
        },
        {
          field: 'site',
          title: '所属現場名',
          render: ({ site }: any) => {
            return site ? (
              site.name
            ) : (
              <span className={classes.noSite}>未所属</span>
            );
          },
        },
      ]}
      data={loginUser ? modules.map((item) => ({ ...item })) : []}
    />
  );
};

export default CompaniesModulesList;
