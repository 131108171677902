import React from 'react'
import styles from './styles.module.scss'
import AspectRatio from '@/components/atoms/AspectRatio'
import { ButtonType } from '@/components/atoms/3DButton'
import clsx from 'clsx'
import { useCubicButtonStyles } from '@/components/atoms/3DButton/styles'

export const SwitchPannel: React.FC = ({ children }) => {
  return <div className={styles.container}>{children}</div>
}

type PidProps = {
  name: string
  pid?: string
}

export const Pid: React.FC<PidProps> = ({ name, pid }) => {
  return (
    <div className={styles.pid}>
      {name} {pid && `(PID: ${pid})`}
    </div>
  )
}

type UnitsProps = {
  aspectRatio?: number
}

export const Units: React.FC<UnitsProps> = ({
  aspectRatio = 100 / 65,
  children
}) => {
  return (
    <AspectRatio ratio={aspectRatio}>
      <div className={styles.module_container}>{children}</div>
    </AspectRatio>
  )
}

export const Unit: React.FC = ({ children }) => {
  return (
    <div className={styles.unit_wrapper}>
      <div className={styles.unit_container}>{children}</div>
    </div>
  )
}

export const SwitchGroup: React.FC = ({ children }) => {
  return (
    <div
      style={{
        height: (100 * 2) / 3 + '%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      }}
    >
      {children}
    </div>
  )
}

export const SwitchButtonWrapper: React.FC = ({ children }) => {
  return <div className={styles.switch_wrapper}>{children}</div>
}

type SwitchButonProps = {
  name: string
  type: ButtonType
  onClick: Function
}

type CubicButtonProps = {
  className?: string
  text?: string
  type: ButtonType
  onClick?: Function
}

const CubicButton: React.FC<CubicButtonProps> = ({
  text = ' ',
  className,
  onClick,
  type
}) => {
  const classes = useCubicButtonStyles(type)()

  return (
    <div
      className={clsx(classes.root, className)}
      onClick={() => onClick && onClick()}
    >
      <div className={classes.text}>{text}</div>
    </div>
  )
}

export const SwitchButon: React.FC<SwitchButonProps> = ({
  name,
  onClick,
  type
}) => {
  return (
    <div className={styles.switch_wrapper}>
      <CubicButton
        className={styles.full}
        text={name}
        type={type}
        onClick={onClick}
      />
    </div>
  )
}

export const SwitchButonDummy: React.FC = () => {
  return <div className={styles.switch_wrapper}></div>
}

export const AllButtonsInUnitGroup: React.FC = ({ children }) => {
  return (
    <div
      style={{
        height: (100 * 1) / 3 + '%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div className={styles.switch_wrapper_fake}></div>
      {children}
    </div>
  )
}

type AllButtonsInModuleGroupProps = {
  children: React.ReactElement[]
  className?: string
}

export const AllButtonsInModuleGroup: React.FC<AllButtonsInModuleGroupProps> = ({
  children,
  className
}) => {
  return (
    <AspectRatio ratio={100 / 10}>
      <div className={clsx(styles.module_switch_button_wrapper, className)}>
        <div className={styles.module_switch_button_container}>{children}</div>
      </div>
    </AspectRatio>
  )
}

export const AllButtonInModuleGroup: React.FC<SwitchButonProps> = ({
  name,
  onClick,
  type
}) => {
  return (
    <div className={clsx(styles.switch_button, styles.all_switch_button)}>
      <CubicButton
        className={styles.full}
        text={name}
        type={type}
        onClick={() => onClick}
      />
    </div>
  )
}
