import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '@/app/store'
import { RootState } from '@/app/rootReducer'
import { scheduleItemsDelete } from '../schedule_items'

export type SchedulesState = {
  schedulesById: Record<number, Schedule>
}

export type Boolean = 0 | 1

export type Schedule = {
  id: number
  site_id: number
  name: string
  sunday: Boolean
  monday: Boolean
  tuesday: Boolean
  wednesday: Boolean
  thursday: Boolean
  friday: Boolean
  saturday: Boolean
  is_valid: Boolean
  action_at: string
  state: Boolean
}

export const initialSchedulesState: SchedulesState = {
  schedulesById: {}
}

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState: initialSchedulesState,
  reducers: {
    scheduleGetsSucces(state, action: PayloadAction<Schedule[]>) {
      action.payload.forEach(commsSchedule => {
        state.schedulesById[commsSchedule.id] = commsSchedule
      })
    },
    scheduleGetSuccess(state, action: PayloadAction<Schedule>) {
      const schedule = action.payload
      state.schedulesById[schedule.id] = schedule
    },
    scheduleDeleteSuccess(state, { payload }: PayloadAction<number>) {
      delete state.schedulesById[payload]
    },
    schedulesDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach(id => {
        delete state.schedulesById[id]
      })
    }
  }
})

export const {
  scheduleDeleteSuccess,
  scheduleGetSuccess,
  scheduleGetsSucces,
  schedulesDeleteById
} = schedulesSlice.actions

export default schedulesSlice.reducer

export const schedulesDelete = (
  dispatch: AppDispatch,
  rootState: RootState,
  scheduleIdList: number[]
) => {
  dispatch(schedulesDeleteById(scheduleIdList))

  Object.values(rootState.scheduleItemState.scheduleItemsById)
    .filter(scheduleItem => scheduleIdList.includes(scheduleItem.id))
    .map(scheduleItem => scheduleItem.id)

  scheduleItemsDelete(dispatch, rootState, scheduleIdList)
}
