import { AxiosResponse } from 'axios';
import {
  ValidationErrrorResponseProps,
  ResponseState,
} from '@/slicers/response';

export const parseAxiosResponse = (
  response: AxiosResponse | AxiosResponse<ValidationErrrorResponseProps>
) => {
  let data = null;
  if ('errors' in response.data) {
    data = response.data;
  }

  let path = '';
  const url = response.config.baseURL! + response.config.url;
  if (url) {
    // eslint-disable-next-line
    path = /^(https?)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/.test(url)
      ? new URL(url).pathname
      : url;
  }

  const res: ResponseState = {
    url: path,
    method: response.config.method || 'get',
    status: response.status,
    data,
  };

  return res;
};
