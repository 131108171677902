import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { CustomButton } from '@/components/atoms/CustomButton';

import { pad } from '@/utils/number';

import classes from './styles.module.scss';

type PowerInputsProps = {
  onSave: (year: number, monthValues: number[]) => void;
  defaultTargets?: number[];
  year: number;
};

const PowerInputs = ({
  year,
  onSave,
  defaultTargets = [],
}: PowerInputsProps) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      targets: Array.from({ length: 12 }).map((_, i) => defaultTargets[i] || 0),
    },
  });

  const onSubmit = useCallback(
    async ({ targets }) => {
      onSave(
        year,
        targets.map((tg: string) => +tg)
      );
    },
    [year, onSave]
  );

  return (
    <form className={classes.powerInputs} onSubmit={handleSubmit(onSubmit)}>
      <h4>{year}</h4>
      <div className={classes.inputs}>
        {Array.from({ length: 12 }).map((_, i) => {
          return (
            <div key={i}>
              <div className={classes.input}>
                <span>{pad(i + 1, 2)}月</span>
                <input
                  name={`targets[${i}]`}
                  defaultValue={0}
                  type="number"
                  ref={register({
                    required: true,
                  })}
                  // ref={(el) => {
                  //   if (el) {
                  //     el.oninput = function ({ target }) {
                  //       const self = target as any;
                  //       self.value = self.value
                  //         .replace(/[^0-9.]/g, '')
                  //         .replace(/(\..*?)\..*/g, '$1');
                  //     };
                  //   }

                  //   refInput(el);
                  // }}
                />
                <span>kW/h</span>
              </div>
              {(errors.targets as any)?.[i] && (
                <span className={classes.error}>
                  Please input correct value
                </span>
              )}
            </div>
          );
        })}
      </div>
      <CustomButton type="submit" color="primary">
        登録
      </CustomButton>
    </form>
  );
};

export default PowerInputs;
