import React, {
  useMemo,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import * as ReactDOM from 'react-dom';
import CloseIcon from '@material-ui/icons/Close';

import classes from './styles.module.scss';

interface ModalProps {
  defaultOpen?: boolean;
  footer?:
    | (({
        open,
        close,
      }: {
        open: () => void;
        close: () => void;
      }) => React.ReactNode)
    | React.ReactNode;

  children: React.ReactNode;
  trigger?: React.ReactElement;
  width?: number;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
  title?: string;
}

export type ModalFunction = {
  open: () => void;
  close: () => void;
};

function Modal(
  {
    defaultOpen = false,
    title,
    onOpen = () => {},
    onClose = () => {},
    className,
    children,
    trigger,
    footer,
    width = 500,
  }: ModalProps,
  ref: any
) {
  const [isOpen, setOpen] = useState(defaultOpen);

  const container = useMemo(() => {
    const c = document.createElement('div');
    c.style.zIndex = '4000';
    c.style.position = 'fixed';
    c.style.top = '0';
    c.style.left = '0';
    return c;
  }, []);

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  useEffect(() => {
    if (isOpen) {
      onOpen();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    [handleOpen, handleClose]
  );

  return (
    <>
      {trigger &&
        React.cloneElement(
          trigger,
          { onClick: () => setOpen(true) },
          trigger.props.children
        )}
      {ReactDOM.createPortal(
        isOpen ? (
          <div className={classes.modal}>
            <div className={classes.closeBackground} onClick={handleClose} />
            <div
              className={[classes.wraper, className].join(' ')}
              style={{ maxWidth: width }}
            >
              <div className={classes.heading}>
                <div>{title}</div>
                <div>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={handleClose}
                  />
                </div>
              </div>
              <div className={classes.body}>{children}</div>
              {footer
                ? typeof footer === 'function'
                  ? footer({
                      open: handleOpen,
                      close: handleClose,
                    })
                  : footer
                : null}
            </div>
          </div>
        ) : null,
        container
      )}
    </>
  );
}

export default forwardRef(Modal);
