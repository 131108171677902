export function getOffset(el: HTMLElement) {
  let _x = 0;
  let _y = 0;
  let cursor: HTMLElement | null = el;
  while (cursor && !isNaN(cursor.offsetLeft) && !isNaN(cursor.offsetTop)) {
    _x += cursor.offsetLeft - cursor.scrollLeft;
    _y += cursor.offsetTop - cursor.scrollTop;
    cursor = cursor.offsetParent as HTMLElement;
  }
  return { top: _y, left: _x };
}

export async function checkValidImage(url: string): Promise<boolean> {
  let img = document.getElementById('image-validator');

  if (!img) {
    img = document.createElement('img');
    img.style.position = 'fixed';
    img.style.zIndex = '-1';
    img.style.opacity = '0';
    img.id = 'image-validator';
    document.body.appendChild(img);
  }

  return new Promise(res => {
    img!.onload = function() {
      res(true);
    };
    img!.onerror = function() {
      res(false);
    };

    img!.setAttribute('src', url);
  });
}

export function getFormElements(form: HTMLFormElement): HTMLInputElement[] {
  const inputs = form.elements;
  const fields = [];

  for (let i = 0; i < inputs.length; i++) {
    if (['SELECT', 'INPUT', 'TEXTAREA'].includes(inputs[i].nodeName)) {
      fields.push(inputs[i]);
    }
  }

  return fields as HTMLInputElement[];
}
