import React, {
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import EmailSharp from '@material-ui/icons/EmailSharp';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import companiesRequest from '@/api/companies';

import { Boolean } from '@/slicers/switches';
import { ModuleWithCameras } from '@/slicers/cameras';
import { User } from '@/slicers/users';

import { CustomButton } from '@/components/atoms/CustomButton';
import Input from '@/components/atoms/Input';
import { WithIconButton } from '@/components/atoms/Buttons';
import Drawer from '@/components/atoms/Drawer';

import { CommEvent, useDeviceCommunication } from '@/hooks/socket';

import Calendar from '../Calendar';
import Toggler from '../Toggler';
import SwitchCheckbox from '../SwitchCheckbox';
import SwitchBox from '../SwitchBox';
import CameraDrawer from '../CameraDrawer';

import classes from './styles.module.scss';

type B1ChannelSettingsProps = {
  showOverlay: () => void;
  hideOverlay: () => void;
  commModule: ModuleWithCameras;
  loginUser: User;
  events: CommEvent;
  isOk: boolean;
};

const B1ChannelSettings = ({
  loginUser,
  commModule,
  showOverlay,
  hideOverlay,
  events,
  isOk,
}: B1ChannelSettingsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenCameraForm, setOpenCameraForm] = useState(false);

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onSubmit = useCallback(
    async (data) => {
      const meterDTO: { pid: string; meterId?: number }[] = [];
      const unit = commModule.unitsByNumber[1];
      const updateData = {
        id: commModule.id,
        device_code: commModule.device_code,
        name: data.name,
        control_no: data.controlNo,
        due_date: data.dueDate
          ? moment(data.dueDate).format('YYYY-MM-DD')
          : null,
        earthquake_warning_flg: data.earthquakeWarningFlg,
        earthquake_intensity: data.earthquakeIntensity,
        units: unit
          ? [
              {
                id: unit.id,
                number: unit.number,
                switches: data.textFields.map(
                  (swt: SettingsSwitch, index: number) => {
                    const {
                      leakageCheck,
                      requiredConfirmation,
                      isLock,
                      isDisplay,
                    } = data.booleanFields[index];
                    if (swt.pid) {
                      meterDTO.push({
                        meterId: swt.powerMeterId
                          ? +swt.powerMeterId
                          : undefined,
                        pid: swt.pid,
                      });
                    }

                    return {
                      id: swt.id,
                      number: swt.number,
                      icon: swt.icon,
                      leakage_check: leakageCheck,
                      is_display: isDisplay,
                      is_lock: isLock,
                      required_confirmation: requiredConfirmation,
                      communication_switch: {
                        id: swt.commSwitchId,
                        pid: swt.pid,
                        name: swt.name,
                        power_meter_id: swt.powerMeterId,
                        max_amps: swt.maxAmps,
                      },
                      switch_id: swt.switchId,
                    };
                  }
                ),
              },
            ]
          : [],
      };
      if (meterDTO.length) {
        events.sendToEvent('meter-id-change', {
          items: meterDTO,
        });
      }
      showOverlay();
      const api = companiesRequest.reregister(
        loginUser!.company_id.toString(),
        commModule.id.toString()
      );
      await dispatch(api.request(updateData));
      hideOverlay();
    },
    [events, commModule, dispatch, showOverlay, hideOverlay, loginUser]
  );

  const switches = useMemo(() => {
    const unit = commModule.unitsByNumber[1];
    if (!unit) {
      return [];
    }
    return Object.values(unit.switchesByNumber)
      .filter((swt) => !!swt.switch_id && !!swt.communication_switch)
      .map(
        (swt) =>
          ({
            id: swt.id,
            requiredConfirmation: +swt.required_confirmation! as Boolean,
            leakageCheck: +swt.leakage_check! as Boolean,
            isLock: +swt.is_lock! as Boolean,
            isDisplay: +swt.is_display! as Boolean,
            icon: swt.icon,
            name: swt.communication_switch!.name || swt.name,
            pid: swt.communication_switch!.pid,
            commSwitchId: swt.communication_switch!.id,
            powerMeterId: swt.communication_switch!.power_meter_id,
            maxAmps: swt.communication_switch!.max_amps,
            switchId: swt.switch_id,
            number: swt.number,
          } as SettingsSwitch)
      );
  }, [commModule]);

  const switchesByUnits = useMemo(() => {
    const switchPerUnit = 4;
    const amountOfUnits = Math.ceil(switches.length / 4);
    return Array.from({ length: amountOfUnits }).map((_, i) => {
      return switches.slice(
        i * switchPerUnit,
        i * switchPerUnit + switchPerUnit
      );
    });
  }, [switches]);

  const { register, handleSubmit, watch, setValue, control, errors } = useForm({
    defaultValues: {
      ...switches.reduce<{ textFields: any[]; booleanFields: any[] }>(
        (
          obj,
          { leakageCheck, requiredConfirmation, isLock, isDisplay, ...swt }
        ) => {
          obj.textFields.push(swt);
          obj.booleanFields.push({
            leakageCheck,
            requiredConfirmation,
            isLock,
            isDisplay,
          });
          return obj;
        },
        { textFields: [], booleanFields: [] }
      ),
      name: commModule.name?.substring(0, 20),
      controlNo: commModule.control_no || undefined,
      dueDate: commModule.due_date ? new Date(commModule.due_date) : undefined,
      earthquakeWarningFlg: +commModule.earthquake_warning_flg! as Boolean,
      earthquakeIntensity: commModule.earthquake_intensity,
    },
  });

  let switchIndex = 0;
  return (
    <form
      className={classes.b1ChannelSettings}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Drawer isOpen={isOpenCameraForm} onChange={setOpenCameraForm}>
        <CameraDrawer
          moduleId={commModule.id}
          onClose={() => setOpenCameraForm(false)}
        />
      </Drawer>
      <div className={classes.heading}>
        <div className={classes.title}>{commModule.name}​​​​​​​​の基本情報</div>
        <div className={classes.inputs}>
          <div className={classes.input}>
            <Input
              maxLength={20}
              name="name"
              label="指令盤の名"
              labelType="block"
              tooltip={<div>指令盤の名</div>}
              ref={register({
                required: true,
              })}
            />
          </div>
          <div className={classes.input}>
            <Input
              name="controlNo"
              ref={register({})}
              defaultValue={commModule.control_no || ''}
              label="管理番号"
              labelType="block"
              tooltip={<div>管理番号</div>}
            />
          </div>
        </div>
        {errors.name && (
          <div className={classes.errors}>
            <div className={classes.input}>{'name is required.'}</div>
            <div className={classes.input}></div>
          </div>
        )}

        <div className={classes.inputs}>
          <div className={classes.input}>
            <WithIconButton
              icon={CameraAltIcon}
              color="info"
              className={classes.addButton}
              onClick={() => setOpenCameraForm(true)}
            >
              カメラ作成
            </WithIconButton>
          </div>
          <div className={classes.input}>
            <Controller
              control={control}
              name="dueDate"
              as={({ value, onChange }) => {
                return <Calendar title={'終了予定日'} value={value} onChange={onChange} />;
              }}
            />
          </div>
        </div>
      </div>

      <div className={classes.board}>
        <div className={[classes.left, classes.sideBoard].join(' ')}>
          <div className={classes.sideConfig}>
            <div className={classes.heading}>
              <p>ボタンの説明</p>
              <div />
            </div>
            <div className={classes.sideBlock}>
              <Toggler labelPlacement="start" checked={true} color="primary" />
              <p>操作盤への表示/非表示の 設定ができます。</p>
              <div className={classes.divider} />
            </div>
            <div className={classes.sideBlock}>
              <h5>PID</h5>
              <p>1つのボタンに1つ発行さ れるIDです。</p>
              <div className={classes.divider} />
            </div>
            <div className={classes.sideBlock}>
              <h5>スイッチ名</h5>
              <p>各スイッチに名前を指定 することができます。</p>
              <div className={classes.divider} />
            </div>
            <div className={classes.sideBlock}>
              <h5>デザイン選択</h5>
              <p>ボタンのデザインを選択 することができます。</p>
              <div className={classes.divider} />
            </div>
            <div className={classes.sideBlock}>
              <h5>異常メール受信</h5>
              <p>
                子機との通信異常を検出 した場合、メールで受信
                する設定ができます。
              </p>
              <div className={classes.divider} />
            </div>
            <div className={classes.sideBlock}>
              <h5>ロック</h5>
              <p>操作盤での操作をロック することができます。</p>
              <div className={classes.divider} />
            </div>
          </div>
          <div className={classes.sideButtons}>
            <CustomButton onClick={onBack} color="grey">
              戻る
            </CustomButton>
          </div>
        </div>
        <div className={classes.main}>
          <div className={classes.scollContainer}>
            <div className={classes.switchesContainer}>
              {switchesByUnits.map((unit, rowIndex) => (
                <div key={rowIndex} className={classes.unit}>
                  {unit.map((_, colIndex) => {
                    return (
                      <SwitchBox
                        key={colIndex}
                        index={switchIndex++}
                        control={control}
                      />
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={[classes.right, classes.sideBoard].join(' ')}>
          <div className={classes.sideConfig}>
            <div className={classes.heading}>
              <p>この現場で利用できる指令盤</p>
              <div />
            </div>
            <div className={classes.listInPanel}>
              <div className={classes.heading}>
                <div>
                  <span>PID</span>
                </div>
                <div>
                  <span>設定状態</span>
                </div>
              </div>
              <div
                style={
                  {
                    '--max-height': `${6 * 40}px`,
                  } as CSSProperties
                }
                className={classes.table}
              >
                {switches.map((swt) => (
                  <div key={swt.id} className={classes.row}>
                    <div>
                      <span>{swt.pid}</span>
                    </div>
                    <div>
                      <span>設定済み</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.sideBlock}>
              <h5>
                <EmailSharp />
                <span>異常検出機能を使用する</span>
              </h5>
              <div className={classes.cbGroups}>
                <SwitchCheckbox
                  checked={switches.every((swt, index) => {
                    return !!(
                      watch(`booleanFields[${index}]`) as SettingsSwitch
                    ).leakageCheck;
                  })}
                  label="全選択"
                  onChange={({ target: { checked } }) => {
                    if (checked) {
                      for (const index in switches) {
                        const value = watch(
                          `booleanFields[${index}]`
                        ) as SettingsSwitch;
                        value.leakageCheck = 1;
                        setValue(`booleanFields[${index}]`, value);
                      }
                    }
                  }}
                />
                <SwitchCheckbox
                  checked={switches.every((_, index) => {
                    return !(watch(`booleanFields[${index}]`) as SettingsSwitch)
                      .leakageCheck;
                  })}
                  label="全解除"
                  onChange={({ target: { checked } }) => {
                    if (checked) {
                      for (const index in switches) {
                        const value = watch(
                          `booleanFields[${index}]`
                        ) as SettingsSwitch;
                        value.leakageCheck = 0;
                        setValue(`booleanFields[${index}]`, value);
                      }
                    }
                  }}
                />
              </div>
              <p>
                ※チェックを入れると子機との通信異常を
                検出しヘッダでのお知らせとメール通知い たします。
              </p>
              <div className={classes.divider} />
            </div>
            <div className={classes.sideBlock}>
              <Controller
                control={control}
                name="earthquakeWarningFlg"
                as={({ value, onChange }) => (
                  <SwitchCheckbox
                    checked={!!value}
                    onChange={({ target: { checked } }) => {
                      onChange(+checked);
                    }}
                    label="緊急地震速報"
                  />
                )}
              />
              <input
                placeholder="震度1"
                name="earthquakeIntensity"
                ref={register({})}
              />
              <p>震度を選択して下さい</p>
              <p>
                ※選択した震度以上の緊急地震速報があっ
                た場合、全スイッチをシャットダウンしま す。
              </p>
            </div>
          </div>
          <div className={classes.sideButtons}>
            {/* <div>
              <CustomButton color="warning">接続状態テスト</CustomButton>
            </div> */}
            <div>
              <CustomButton disabled={!isOk} type="submit" color="success">
                保存
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default B1ChannelSettings;

type B1CommSocketWrapProps = {
  loginUser: User;
  render: (props: { isOk: boolean; events: CommEvent }) => ReactElement;
};

export const B1CommSocketWrap = ({
  loginUser,
  render,
}: B1CommSocketWrapProps) => {
  const [isUserLogged, setUserLogged] = useState(false);
  const { isAvailable, events } = useDeviceCommunication();

  const commUserId = loginUser.id;

  useEffect(() => {
    if (commUserId) {
      events.onOpen = function () {
        events.sendToEvent('client-user-login', {
          token: localStorage.getItem('accessToken'),
          userId: commUserId,
        });
      };
      events.onMessage = function (payload) {
        const { event } = payload as { event: string; data: any };
        if (event === 'ready') {
          console.log(
            '[Module settings] Client user login success with id',
            commUserId
          );
          setUserLogged(true);
        } else if (event === 'clientUserLoginFiled') {
          console.log(
            '[Module settings] Client user login fail with id',
            commUserId
          );
          setUserLogged(false);
        }
      };
      return () => {
        events.close?.();
      };
    }
  }, [events, commUserId]);

  return render({ isOk: isAvailable && isUserLogged, events });
};
