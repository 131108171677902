import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '@/app/store'
import { setError } from './error'
import { initResponse } from './response'
import { initFlash } from './flash'

export type LoadingState = {
  loading: boolean
}

export const initialLoadingState: LoadingState = {
  loading: false
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialLoadingState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload
    }
  }
})

export const { setLoading } = loadingSlice.actions

export default loadingSlice.reducer

export const initCommonState = (): AppThunk => async dispatch => {
  dispatch(setError([]))
  dispatch(initResponse())
  dispatch(initFlash())
  dispatch(setLoading(false))
}
