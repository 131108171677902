import React, { useState } from 'react';

import Drawer from '@/components/atoms/Drawer';

import AgentUserForm from '@/components/organisms/AgentUserForm';

import classes from './styles.module.scss';
import ErrorMessage from '../../ErrorMessage';
import { ErrorPosition } from '@/slicers/response';

interface DetailProps {
  onSubmit: (
    closeDrawer: Function
  ) => (e: React.FormEvent<HTMLFormElement>) => void;
  errors: Record<string, string>;
  initialData: Record<string, any>;
  onConfirmDelete: (id: string) => void;
}

export default function Detail({
  errors,
  onSubmit,
  initialData,
  onConfirmDelete,
}: DetailProps) {
  const [isOpenEdit, setOpenEdit] = useState(false);

  return (
    <div className={classes.detail}>
      <ErrorMessage position={ErrorPosition.DRAWR} />
      <AgentUserForm
        initialData={initialData}
        errors={errors}
        onSubmit={onSubmit(() => setOpenEdit(false))}
        onCancel={() => setOpenEdit(false)}
        onConfirmDelete={() => onConfirmDelete(initialData.id)}
      />
      <Drawer isOpen={isOpenEdit} onChange={setOpenEdit}>
        {initialData && (
          <AgentUserForm
            initialData={initialData}
            errors={errors}
            onSubmit={onSubmit(() => setOpenEdit(false))}
            onCancel={() => setOpenEdit(false)}
          />
        )}
      </Drawer>
    </div>
  );
}
