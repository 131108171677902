type SomeObject = { [key: string]: any }

export const contain = (hystack: SomeObject, needle: SomeObject) => {
  for (const key in needle) {
    if (hystack.hasOwnProperty(key)) {
      if (hystack[key] !== needle[key]) {
        return false
      }
    } else {
      return false
    }
  }

  return true
}
