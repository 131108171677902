import LoginAccountUserShow from '@/components/ecosystem/LoginAccountUser/Show';
import LoginAccountUserChangePassword from '@/components/ecosystem/LoginAccountUser/ChangePassword';

export type companiesarams = {
  agent_company: string;
};

export type agentCompaniesUsersRouteParams = {
  user: string;
};

export type CompaniesRouteParams = {
  company_id: string;
};

export type CompaniesNormalCompaniesRouteParams = {
  normal_company_id: string;
};

export const loginAccountRoute = {
  url: '/login_account',
  breadCrumbs: [],
  childMaps: [
    {
      url: '/user',
      childMaps: [
        {
          url: '/show',
          component: LoginAccountUserShow,
        },
        {
          url: '/change_password',
          component: LoginAccountUserChangePassword,
        },
      ],
    },
  ],
};
