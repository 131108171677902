import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch as SwitchComp,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';

import EmojiObjectsTwoToneIcon from '@material-ui/icons/EmojiObjectsTwoTone';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

import { CustomButton } from '@/components/atoms/CustomButton';
import Input from '@/components/atoms/Input';
import Tooltip from '@/components/atoms/Tooltip';
import Modal from '@/components/atoms/Modal';
import { WithIconButton } from '@/components/atoms/Buttons';
import Drawer from '@/components/atoms/Drawer';

import { ModuleFormData } from '@/components/organisms/ModuleForm';

import { useAgent } from '@/hooks';

import { ModulesRouteParams } from '@/routes/ClientRoute';

import companiesRequest from '@/api/companies';

import { ModuleWithCameras } from '@/slicers/cameras';
import { switchMaxAmount, switchOrigin, unitMaxAmount } from '@/config';
import { ModuleChannelType, Switch, Units } from '@/slicers/modules';
import { User, UserTypeAgent } from '@/slicers/users';

import Calendar from '../Calendar';
import CameraDrawer from '../CameraDrawer';
import TestConnectPopup from '../TestConnectPopup';
import SwitchCheckbox from '../SwitchCheckbox';
import RemarkTextField from '@/components/atoms/RemarkTextField';

import classes from './styles.module.scss';
import swPnlClasses from './switch-panel.module.scss';
import { CommEvent } from '@/hooks/socket';
import ConfirmDelete from '@/components/atoms/ConfirmDelete';

const createUnit = (id: number, unitNumber: number): Units => ({
  id: id,
  name: unitNumber.toString(),
  number: unitNumber,
  switchesByNumber: {},
});

const createSwitch = (
  id: number,
  switchNumber: number,
  switchName: string
): Switch => ({
  id: id,
  name: switchName,
  number: switchNumber,
  shutter_mode: 0,
  icon: 'light',
});
// 4x4=16スイッチになるように足りなければ足す(id=0の場合はhiddenとする)
const createModuleWith = (_moduleWith?: ModuleWithCameras, groupIndex = 0) => {
  let moduleWith: ModuleFormData = _moduleWith
    ? JSON.parse(JSON.stringify({ ..._moduleWith, deleted_camera_ids: [] }))
    : {
        device_code: '',
        company_id: 0,
        id: 1,
        name: '',
        remark: '',
        site_id: 0,
        unitsByNumber: {},
        cameras: [],
        deleted_camera_ids: [],
      };

  const id = Object.keys(moduleWith.unitsByNumber).length ? 0 : 1;
  const unitOrigin = UnitGroupOffsets[groupIndex];
  for (
    let unitNumber = unitOrigin;
    unitNumber < unitMaxAmount + unitOrigin;
    unitNumber++
  ) {
    if (!moduleWith.unitsByNumber[unitNumber]) {
      // ユニットがなければ作る
      moduleWith.unitsByNumber[unitNumber] = createUnit(id, unitNumber);
    }
    for (
      let switchNumber = switchOrigin;
      switchNumber < switchMaxAmount + switchOrigin;
      switchNumber++
    ) {
      const switchesByNumber =
        moduleWith.unitsByNumber[unitNumber].switchesByNumber;
      if (!switchesByNumber[switchNumber]) {
        //   スイッチがなければ作る
        switchesByNumber[switchNumber] = createSwitch(
          id,
          switchNumber,
          `${unitNumber - 1}${switchNumber + 1}`
        );
      }
    }
  }

  return moduleWith;
};

const UnitGroupOffsets = [
  1, // unit number
  5, // unit number
  9, // unit number
  13, // unit number
];

type B16ChannelSettingsProps = {
  showOverlay: () => void;
  hideOverlay: () => void;
  commModule: ModuleWithCameras;
  loginUser: User;
  events: CommEvent;
  isOk: boolean;
};

const B64ChannelSettings = ({
  loginUser,
  commModule,
  showOverlay,
  hideOverlay,
  events,
  isOk,
}: B16ChannelSettingsProps) => {
  const isMobile = useAgent();
  const history = useHistory();

  const [groupIndex, setGroupIndex] = useState(0);
  const [isOpenCameraForm, setOpenCameraForm] = useState(false);
  const [isOpenRemark, setOpenRemark] = useState(false);
  const { module: moduleId } = useParams<ModulesRouteParams>();

  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm<ModuleFormData>();

  const [moduleWith, setModuleWith] = useState(() =>
    createModuleWith(commModule || undefined)
  );

  // run once
  const [largestGroupIndex] = useState(() => {
    return Math.max(
      0,
      // EX: ceil(4 units / 4) = 1 => index = 1 - 1 = 0
      // EX: ceil(8 units / 4) = 2 => index = 2 - 1 = 1
      Math.ceil(
        Math.max(...Object.keys(moduleWith.unitsByNumber).map(Number)) /
          unitMaxAmount
      ) - 1
    );
  });

  const refLargestGroupIndex = useRef(largestGroupIndex);

  const generateUnits = (groupIdx: number) => {
    setModuleWith((prev) => {
      const moduleWith = { ...prev };
      const id = Object.keys(moduleWith.unitsByNumber).length ? 0 : 1;
      const unitOrigin = UnitGroupOffsets[groupIdx];
      for (
        let unitNumber = unitOrigin;
        unitNumber < unitMaxAmount + unitOrigin;
        unitNumber++
      ) {
        if (!moduleWith.unitsByNumber[unitNumber]) {
          // ユニットがなければ作る
          moduleWith.unitsByNumber[unitNumber] = createUnit(id, unitNumber);
        }
        for (
          let switchNumber = switchOrigin;
          switchNumber < switchMaxAmount + switchOrigin;
          switchNumber++
        ) {
          const switchesByNumber =
            moduleWith.unitsByNumber[unitNumber].switchesByNumber;
          if (!switchesByNumber[switchNumber]) {
            //   スイッチがなければ作る
            switchesByNumber[switchNumber] = createSwitch(
              id,
              switchNumber,
              `${unitNumber - 1}${switchNumber + 1}`
            );
          }
        }
      }

      return moduleWith;
    });
  };

  const handleRemoveLastUnitGroup = () => {
    const lastLargeGroupIndex = refLargestGroupIndex.current;
    const backwardIndex = refLargestGroupIndex.current - 1;
    refLargestGroupIndex.current = backwardIndex;

    generateUnits(backwardIndex);
    setGroupIndex(backwardIndex);

    setTimeout(() => {
      setModuleWith((prev) => {
        const mdl = { ...prev };

        for (
          let unitNum = UnitGroupOffsets[lastLargeGroupIndex];
          unitNum < UnitGroupOffsets[lastLargeGroupIndex] + unitMaxAmount;
          unitNum++
        ) {
          delete mdl.unitsByNumber[unitNum];
        }

        return mdl;
      });
    }, 0);
  };

  const handleAddUnitGroup = () => {
    const nextIndex = refLargestGroupIndex.current + 1;
    refLargestGroupIndex.current = nextIndex;
    generateUnits(nextIndex);

    // switch to new page
    setGroupIndex(nextIndex);
  };

  const handleChangeGroup = (idx: number) => {
    generateUnits(idx);
    setGroupIndex(idx);
  };

  const [remark, setRemark] = useState(moduleWith.remark ?? '');
  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleClickVisibility = (unitNumber: number, switchNumber: number) => {
    setModuleWith((_state) => {
      const state: ModuleFormData = JSON.parse(JSON.stringify(_state));

      const powerSwitch =
        state.unitsByNumber[unitNumber].switchesByNumber[switchNumber];

      if (powerSwitch.id !== 0) {
        powerSwitch.id = 0;
        powerSwitch.icon = '';
        powerSwitch.shutter_mode = 0;
      } else {
        powerSwitch.id = 1;
        powerSwitch.icon = 'light';
      }

      return state;
    });
  };

  const handleChangeShutterMode = (
    unitNumber: number,
    switchNumber: number
  ) => {
    setModuleWith((_state) => {
      const state: ModuleFormData = JSON.parse(JSON.stringify(_state));

      const powerSwitch =
        state.unitsByNumber[unitNumber].switchesByNumber[switchNumber];
      powerSwitch.shutter_mode = +!powerSwitch.shutter_mode as 1 | 0;

      return state;
    });
  };

  const handleLockMode = (unitNumber: number, switchNumber: number) => {
    setModuleWith((_state) => {
      const state: ModuleFormData = JSON.parse(JSON.stringify(_state));

      const powerSwitch =
        state.unitsByNumber[unitNumber].switchesByNumber[switchNumber];
      powerSwitch.is_lock = +!powerSwitch.is_lock as 1 | 0;

      return state;
    });
  };

  const handleLeakageCheck = (unitNumber: number, switchNumber: number) => {
    setModuleWith((_state) => {
      const state: ModuleFormData = JSON.parse(JSON.stringify(_state));

      const powerSwitch =
        state.unitsByNumber[unitNumber].switchesByNumber[switchNumber];
      powerSwitch.leakage_check = !powerSwitch.leakage_check;

      return state;
    });
  };

  const handleRequiredConfirmation = (
    unitNumber: number,
    switchNumber: number
  ) => {
    setModuleWith((_state) => {
      const state: ModuleFormData = JSON.parse(JSON.stringify(_state));

      const powerSwitch =
        state.unitsByNumber[unitNumber].switchesByNumber[switchNumber];
      powerSwitch.required_confirmation = !powerSwitch.required_confirmation;

      return state;
    });
  };

  const handleChangeIconType = (
    unitNumber: number,
    switchNumber: number,
    value: string
  ) => {
    setModuleWith((_state) => {
      const state: ModuleFormData = JSON.parse(JSON.stringify(_state));

      const powerSwitch =
        state.unitsByNumber[unitNumber].switchesByNumber[switchNumber];
      powerSwitch.icon = value;

      return state;
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    const {
      start_date,
      due_date,
      unitsByNumber = [],
      earthquake_intensity,
      ...other
    } = data;
    let payload: any = {
      ...other,
      earthquake_intensity:
        earthquake_intensity != null ? +earthquake_intensity : undefined,
      start_date: start_date ? moment(start_date).format('YYYY-MM-DD') : null,
      due_date: due_date ? moment(due_date).format('YYYY-MM-DD') : null,
      units: [],
      remark: remark,
    };

    Object.values(unitsByNumber).forEach((powerUnit) => {
      const { switchesByNumber, number, ...other } = powerUnit;
      if (number == null) return;
      payload.units.push({
        ...other,
        number,
        switches: Object.values(
          switchesByNumber ??
            moduleWith.unitsByNumber[number]?.switchesByNumber ??
            []
        )
          .filter((sw) => sw.id && sw.id.toString() !== '0')
          .map((sw) => ({
            ...sw,
            is_lock: +!!sw.is_lock as 1 | 0,
          })),
      });
    });

    showOverlay();
    const api = companiesRequest.reregister(
      loginUser!.company_id.toString(),
      moduleId
    );
    await dispatch(api.request(payload));
    generateUnits(groupIndex);
    if (isOk) {
      events.sendToEvent('notify-cadr-64ch', {
        deviceCode: commModule.device_code,
      });
    }
    hideOverlay();
  });

  useEffect(() => {
    if (commModule) {
      const moduleWith = createModuleWith(commModule);
      setModuleWith(moduleWith);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(commModule)]);

  const addCameraButton = (
    <WithIconButton
      icon={CameraAltIcon}
      color="info"
      className={classes.addButton}
      onClick={() => setOpenCameraForm(true)}
    >
      カメラ作成
    </WithIconButton>
  );

  const shouldSummaryChecked = Object.values(moduleWith.unitsByNumber).some(
    (item) => {
      return Object.values(item.switchesByNumber).some((sw) => sw.shutter_mode);
    }
  );

  const shouldSummaryLocked = Object.values(moduleWith.unitsByNumber).some(
    (item) => {
      return Object.values(item.switchesByNumber).some((sw) => sw.is_lock);
    }
  );

  const unitStartNumber = UnitGroupOffsets[groupIndex];
  const unitEndNumber = unitStartNumber + unitMaxAmount - 1;

  const isAgentAbove =
    loginUser.realType && loginUser.realType <= UserTypeAgent;

  return (
    <>
      <form className={classes.b16ChannelSettings} onSubmit={onSubmit}>
        <div className={classes.heading}>
          <div className={classes.title}>
            {commModule.name}​​​​​​​​の基本情報
          </div>
          <div className={classes.commonInfo}>
            <div className={classes.inputs}>
              <div className={classes.input}>
                <Input
                  readonly
                  name="device_code"
                  defaultValue={moduleWith.device_code}
                  label="デバイスコード"
                  labelType="block"
                  ref={register({
                    required: true,
                  })}
                  tooltip={<div>デバイスコード</div>}
                />
              </div>
              <div className={classes.input}>
                <Input
                  maxLength={20}
                  name="name"
                  defaultValue={moduleWith.name?.substr(0, 20)}
                  label="指令盤の名"
                  labelType="block"
                  tooltip={<div>指令盤の名</div>}
                  ref={register({
                    required: true,
                  })}
                />
              </div>
              <div className={classes.input}>
                <Input
                  name="control_no"
                  ref={register({})}
                  defaultValue={moduleWith.control_no || ''}
                  label="管理番号"
                  labelType="block"
                  tooltip={<div>管理番号</div>}
                />
              </div>
            </div>
            <div className={[classes.inputs, classes.checkboxes].join(' ')}>
              <div>{!isMobile && addCameraButton}</div>
              <div>
                <div className={classes.errorMessage}>
                  {errors.name && 'name is required.'}
                </div>
              </div>
              <Controller
                defaultValue={
                  moduleWith.start_date ? new Date(moduleWith.start_date) : null
                }
                control={control}
                name="start_date"
                as={({ value, onChange }) => {
                  return (
                    <Calendar
                      title={'開始予定日'}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
              <Controller
                defaultValue={
                  moduleWith.due_date ? new Date(moduleWith.due_date) : null
                }
                control={control}
                name="due_date"
                as={({ value, onChange }) => {
                  return (
                    <Calendar
                      title={'終了予定日'}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
              {loginUser.realType && loginUser.realType <= 200 && (
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setOpenRemark(true);
                  }}
                >
                  備考
                </CustomButton>
              )}
            </div>
          </div>
          {isMobile && addCameraButton}
        </div>
        <div className={classes.switchDetail}>
          <div className={classes.notePanel}>
            <div>
              <div className={classes.panelTitle}>ボタンの設定編集</div>
              <div className={classes.notes}>
                <div className={classes.note}>
                  <div className={classes.noteHeading}>
                    <FormControlLabel
                      className={classes.switch}
                      control={<SwitchComp checked color="primary" />}
                      label="表示"
                      labelPlacement="start"
                    />
                  </div>
                  <div className={classes.noteDes}>
                    <span>操作盤へ表示されるかを設定できます。</span>
                  </div>
                </div>
                <div className={classes.note}>
                  <div className={classes.noteHeading}>
                    <div className={classes.whiteBox}>スイッチ名</div>
                  </div>
                  <div className={classes.noteDes}>
                    <span>スイッチ名を自由に指定できます。</span>
                  </div>
                </div>
                <div className={classes.note}>
                  <div className={classes.noteHeading}>
                    <div className={classes.shadowBox}>デザイン選択</div>
                  </div>
                  <div className={classes.noteDes}>
                    <span>ボタンのデザインデザインを選択できます。</span>
                  </div>
                </div>
              </div>
            </div>
            {!isMobile && (
              <div className={classes.panelFooter}>
                <CustomButton
                  onClick={onBack}
                  className={classes.backButton}
                  color="grey"
                >
                  戻る
                </CustomButton>
              </div>
            )}
          </div>
          <div className={swPnlClasses.root}>
            <div className={swPnlClasses.tabContainer}>
              <div className={swPnlClasses.units}>
                {Array.from({
                  length:
                    UnitGroupOffsets[refLargestGroupIndex.current] +
                    unitMaxAmount -
                    1,
                }).map((_, i) => {
                  const unitNumber = i + 1;
                  const powerUnit = moduleWith.unitsByNumber[unitNumber];

                  const visible =
                    unitStartNumber <= unitNumber &&
                    unitNumber <= unitEndNumber;
                  return (
                    <div
                      key={unitNumber}
                      aria-hidden={!visible}
                      aria-label={`unit-${unitNumber}`}
                      role={
                        i % unitMaxAmount === 0 ? 'start-of-group' : undefined
                      }
                      className={swPnlClasses.unit}
                    >
                      <input
                        name={`unitsByNumber[${powerUnit.number}].number`}
                        defaultValue={powerUnit.number}
                        ref={register({ required: true })}
                        hidden
                      />
                      <SwitchCheckbox
                        label="グループボタン表示"
                        {...{
                          defaultChecked: !!powerUnit.group_on_off_flg,
                          size: 'small',
                          name: `unitsByNumber[${powerUnit.number}].group_on_off_flg`,
                          inputRef: register({}),
                        }}
                      />
                      {Object.values(powerUnit.switchesByNumber).map(
                        (swt, j) => {
                          const switchName = `unitsByNumber[${powerUnit.number}].switchesByNumber[${swt.number}]`;

                          /// defaultValueで表示するpower_meter_id (nullの時は””を表示したい)
                          // const defaultPwMeterId = powerSwitch.power_meter_id
                          //   ? powerSwitch.power_meter_id
                          //   : '';

                          return (
                            <div key={j} className={swPnlClasses.switchBox}>
                              <div>
                                <input
                                  type="hidden"
                                  name={`${switchName}.id`}
                                  ref={register()}
                                  defaultValue={swt.id}
                                />
                                <input
                                  type="hidden"
                                  name={`${switchName}.state`}
                                  ref={register()}
                                  defaultValue={swt.state}
                                />
                                <div className={swPnlClasses.boxTop}>
                                  <FormControlLabel
                                    className={swPnlClasses.visibleSwitch}
                                    control={
                                      <SwitchComp
                                        size="small"
                                        checked={swt.id !== 0}
                                        color="primary"
                                        onClick={(event) =>
                                          handleClickVisibility(
                                            powerUnit.number,
                                            swt.number
                                          )
                                        }
                                      />
                                    }
                                    label="表示"
                                    labelPlacement="start"
                                  />
                                  <div className={swPnlClasses.boxTopRight}>
                                    <SwitchCheckbox
                                      label="異常メール受信"
                                      color="#000"
                                      disabled={!moduleWith.leakage_check}
                                      {...{
                                        // eslint-disable-next-line
                                        checked:
                                          !!moduleWith.leakage_check &&
                                          !!swt.leakage_check,
                                        name: `${switchName}.leakage_check`,
                                        inputRef: register({}),
                                        onClick: () =>
                                          handleLeakageCheck(
                                            powerUnit.number,
                                            swt.number
                                          ),
                                      }}
                                    />
                                    <SwitchCheckbox
                                      label="ロック"
                                      color="#000"
                                      {...{
                                        // eslint-disable-next-line
                                        checked: swt.is_lock == 1,
                                        name: `${switchName}.is_lock`,
                                        inputRef: register({}),
                                        onClick: () =>
                                          handleLockMode(
                                            powerUnit.number,
                                            swt.number
                                          ),
                                      }}
                                    />
                                    <SwitchCheckbox
                                      label="操作確認"
                                      color="#000"
                                      {...{
                                        checked:
                                          // eslint-disable-next-line
                                          swt.required_confirmation == true,
                                        name: `${switchName}.required_confirmation`,
                                        inputRef: register({}),
                                        onClick: () =>
                                          handleRequiredConfirmation(
                                            powerUnit.number,
                                            swt.number
                                          ),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <input
                                key={swt.name}
                                name={`${switchName}.name`}
                                ref={register()}
                                defaultValue={swt.name}
                                className={swPnlClasses.switchName}
                              />
                              <input
                                hidden
                                name={`${switchName}.number`}
                                ref={register({ required: true })}
                                defaultValue={swt.number}
                              />
                              <Tooltip
                                trigger={
                                  <div className={swPnlClasses.shadowButton}>
                                    <div>
                                      {!swt.icon ? (
                                        'デザイン選択'
                                      ) : swt.icon === 'light' ? (
                                        <EmojiObjectsTwoToneIcon />
                                      ) : swt.icon === 'up' ? (
                                        <ArrowDropUpIcon
                                          className={swPnlClasses.small}
                                        />
                                      ) : swt.icon === 'down' ? (
                                        <ArrowDropDownIcon
                                          className={swPnlClasses.small}
                                        />
                                      ) : (
                                        swt.icon === 'stop' && (
                                          <div
                                            className={swPnlClasses.stopIcon}
                                          />
                                        )
                                      )}
                                    </div>
                                    <input
                                      ref={register({})}
                                      name={`${switchName}.icon`}
                                      type="hidden"
                                      value={swt.icon || ''}
                                    />
                                  </div>
                                }
                              >
                                <div className={swPnlClasses.switchPopup}>
                                  <div className={swPnlClasses.title}>
                                    ボタンのデザインを選択して下さい
                                  </div>
                                  <RadioGroup>
                                    <div className={swPnlClasses.iconOptions}>
                                      <FormControlLabel
                                        data-tooltip-role="close"
                                        className={swPnlClasses.iconOption}
                                        control={
                                          <Radio
                                            checked={!swt.icon}
                                            onChange={() => {
                                              handleChangeIconType(
                                                powerUnit.number,
                                                swt.number,
                                                ''
                                              );
                                            }}
                                            size="small"
                                          />
                                        }
                                        label={
                                          <div
                                            className={
                                              swPnlClasses.optionHolder
                                            }
                                          >
                                            <div />
                                          </div>
                                        }
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        data-tooltip-role="close"
                                        className={swPnlClasses.iconOption}
                                        control={
                                          <Radio
                                            checked={swt.icon === 'light'}
                                            onChange={() => {
                                              handleChangeIconType(
                                                powerUnit.number,
                                                swt.number,
                                                'light'
                                              );
                                            }}
                                            size="small"
                                          />
                                        }
                                        label={
                                          <div
                                            className={
                                              swPnlClasses.optionHolder
                                            }
                                          >
                                            <div>
                                              <EmojiObjectsTwoToneIcon />
                                            </div>
                                          </div>
                                        }
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        data-tooltip-role="close"
                                        className={swPnlClasses.iconOption}
                                        control={
                                          <Radio
                                            checked={swt.icon === 'up'}
                                            onChange={() => {
                                              handleChangeIconType(
                                                powerUnit.number,
                                                swt.number,
                                                'up'
                                              );
                                            }}
                                            size="small"
                                          />
                                        }
                                        label={
                                          <div
                                            className={
                                              swPnlClasses.optionHolder
                                            }
                                          >
                                            <div>
                                              <ArrowDropUpIcon fontSize="large" />
                                            </div>
                                          </div>
                                        }
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        data-tooltip-role="close"
                                        className={swPnlClasses.iconOption}
                                        control={
                                          <Radio
                                            checked={swt.icon === 'stop'}
                                            onChange={() => {
                                              handleChangeIconType(
                                                powerUnit.number,
                                                swt.number,
                                                'stop'
                                              );
                                            }}
                                            size="small"
                                          />
                                        }
                                        label={
                                          <div
                                            className={
                                              swPnlClasses.optionHolder
                                            }
                                          >
                                            <div>
                                              <div
                                                className={
                                                  swPnlClasses.stopIcon
                                                }
                                              />
                                            </div>
                                          </div>
                                        }
                                        labelPlacement="bottom"
                                      />
                                      <FormControlLabel
                                        data-tooltip-role="close"
                                        className={swPnlClasses.iconOption}
                                        control={
                                          <Radio
                                            checked={swt.icon === 'down'}
                                            onChange={() => {
                                              handleChangeIconType(
                                                powerUnit.number,
                                                swt.number,
                                                'down'
                                              );
                                            }}
                                            size="small"
                                          />
                                        }
                                        label={
                                          <div
                                            className={
                                              swPnlClasses.optionHolder
                                            }
                                          >
                                            <div>
                                              <ArrowDropDownIcon fontSize="large" />
                                            </div>
                                          </div>
                                        }
                                        labelPlacement="bottom"
                                      />
                                    </div>
                                  </RadioGroup>
                                </div>
                              </Tooltip>

                              {swt.shutter_mode !== 1 &&
                                !shouldSummaryChecked && (
                                  <div className={swPnlClasses.shutterMode} />
                                )}
                              <SwitchCheckbox
                                style={{
                                  display:
                                    // eslint-disable-next-line
                                    swt.shutter_mode == 1 ||
                                    shouldSummaryChecked
                                      ? 'inherit'
                                      : 'none',
                                }}
                                color="#000"
                                label="ワンショットモード"
                                {...{
                                  // eslint-disable-next-line
                                  checked: swt.shutter_mode == 1,
                                  name: `${switchName}.shutter_mode`,
                                  inputRef: register({}),
                                  onClick: () =>
                                    handleChangeShutterMode(
                                      powerUnit.number,
                                      swt.number
                                    ),
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={swPnlClasses.tabSelectors}>
              {Array.from({ length: refLargestGroupIndex.current + 1 }).map(
                (_, i) => (
                  <div
                    key={i}
                    className={swPnlClasses.tabSelector}
                    aria-selected={groupIndex === i}
                    onClick={() => handleChangeGroup(i)}
                  >
                    {i > 0 && i === refLargestGroupIndex.current && (
                      <ConfirmDelete
                        wrapperProps={{
                          // Prevent conflict event from 'handleChangeGroup'
                          onClick: (e) => e.stopPropagation(),
                        }}
                        onConfirm={handleRemoveLastUnitGroup}
                      >
                        <DeleteSweepIcon role="del-btn" />
                      </ConfirmDelete>
                    )}

                    <span role="label">#{i + 1}</span>
                  </div>
                )
              )}
              {isAgentAbove &&
                refLargestGroupIndex.current + 1 < UnitGroupOffsets.length && (
                  <div
                    className={swPnlClasses.tabSelector}
                    onClick={handleAddUnitGroup}
                  >
                    <span aria-label="add-btn">+</span>
                  </div>
                )}
            </div>
          </div>
          <div className={classes.blankPanel}>
            <div>
              <SwitchCheckbox
                label="異常検出機能を使用する"
                {...{
                  checked: !!moduleWith.leakage_check,
                  name: 'leakage_check',
                  inputRef: register({}),
                  onClick: () => {
                    setModuleWith((prev) => {
                      const nextStatus = !moduleWith.leakage_check;
                      const nextState = {
                        ...prev,
                        leakage_check: nextStatus,
                      };

                      if (!nextStatus) {
                        nextState.unitsByNumber = Object.values(
                          moduleWith.unitsByNumber
                        ).reduce((units: any, unit) => {
                          units[unit.number] =
                            moduleWith.unitsByNumber[unit.number];

                          units[unit.number].switchesByNumber = Object.values(
                            unit.switchesByNumber
                          ).reduce((sws: any, sw) => {
                            sws[sw.number] = unit.switchesByNumber[sw.number];
                            sws[sw.number].leakage_check = false;
                            return sws;
                          }, {});

                          return units;
                        }, {});
                      }
                      return nextState;
                    });
                  },
                }}
              />
              {!!moduleWith.leakage_check && (
                <div className={classes.leakageCheckOptions}>
                  <CustomButton
                    onClick={() => {
                      setModuleWith((prev) => {
                        return {
                          ...prev,
                          unitsByNumber: Object.values(
                            moduleWith.unitsByNumber
                          ).reduce((units: any, unit) => {
                            units[unit.number] =
                              moduleWith.unitsByNumber[unit.number];

                            units[unit.number].switchesByNumber = Object.values(
                              unit.switchesByNumber
                            ).reduce((sws: any, sw) => {
                              sws[sw.number] = unit.switchesByNumber[sw.number];
                              sws[sw.number].leakage_check = true;
                              return sws;
                            }, {});

                            return units;
                          }, {}),
                        };
                      });
                    }}
                    color="grey"
                  >
                    全選択
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setModuleWith((prev) => {
                        return {
                          ...prev,
                          unitsByNumber: Object.values(
                            moduleWith.unitsByNumber
                          ).reduce((units: any, unit) => {
                            units[unit.number] =
                              moduleWith.unitsByNumber[unit.number];

                            units[unit.number].switchesByNumber = Object.values(
                              unit.switchesByNumber
                            ).reduce((sws: any, sw) => {
                              sws[sw.number] = unit.switchesByNumber[sw.number];
                              sws[sw.number].leakage_check = false;
                              return sws;
                            }, {});

                            return units;
                          }, {}),
                        };
                      });
                    }}
                    color="grey"
                  >
                    全解除
                  </CustomButton>
                </div>
              )}
              <div className={classes.description}>
                ※チェックを入れると子機との通信異常を検出しヘッダでのお知らせとメール通知いたします
              </div>
              <SwitchCheckbox
                label="スケジュールアラート"
                {...{
                  checked: !!moduleWith.schedule_alert,
                  name: 'schedule_alert',
                  inputRef: register({}),
                  onClick: () => {
                    setModuleWith((prevState) => ({
                      ...prevState,
                      schedule_alert: !prevState.schedule_alert,
                    }));
                  },
                }}
              />
              <div className={classes.description}>
                ※スケジュールの実行が失敗した時のアラートメール機能を使用するかをオン/オフで切り替えができる
              </div>
              <div className={classes.noteHeading}>
                <SwitchCheckbox
                  label="ワンショットモード"
                  {...{
                    checked: shouldSummaryChecked,
                    onClick: () => {
                      const nextStatus = shouldSummaryChecked ? 0 : 1;

                      setModuleWith((prev) => {
                        return {
                          ...prev,
                          unitsByNumber: Object.values(
                            moduleWith.unitsByNumber
                          ).reduce((units: any, unit) => {
                            units[unit.number] =
                              moduleWith.unitsByNumber[unit.number];

                            units[unit.number].switchesByNumber = Object.values(
                              unit.switchesByNumber
                            ).reduce((sws: any, sw) => {
                              sws[sw.number] = unit.switchesByNumber[sw.number];
                              sws[sw.number].shutter_mode = sws[sw.number].id
                                ? nextStatus
                                : 0;
                              return sws;
                            }, {});

                            return units;
                          }, {}),
                        };
                      });
                    },
                  }}
                />
              </div>
              <div className={classes.description}>
                ワンショットモードにチェックしたスイッチはONにした後すぐにOFFになります。
              </div>
              <SwitchCheckbox
                {...{
                  checked: shouldSummaryLocked,
                  onClick: () => {
                    const nextStatus = shouldSummaryLocked ? 0 : 1;

                    setModuleWith((prev) => {
                      return {
                        ...prev,
                        unitsByNumber: Object.values(
                          moduleWith.unitsByNumber
                        ).reduce((units: any, unit) => {
                          units[unit.number] =
                            moduleWith.unitsByNumber[unit.number];

                          units[unit.number].switchesByNumber = Object.values(
                            unit.switchesByNumber
                          ).reduce((sws: any, sw) => {
                            sws[sw.number] = unit.switchesByNumber[sw.number];
                            sws[sw.number].is_lock = nextStatus;
                            return sws;
                          }, {});

                          return units;
                        }, {}),
                      };
                    });
                  },
                }}
                label="指令盤をロックする"
              />
              <div className={classes.description}>
                スイッチをロックします。 ロックされたスイッチはボタンを押しても
                オン/オフの切り替えがされません。
              </div>
            </div>

            <div
              className={[
                classes.footer,
                isMobile ? classes.isMobile : '',
              ].join(' ')}
            >
              {!isOk && (
                <div data-state="warn" className={classes.description}>
                  ※警告、ソケットが正常に接続されていません、保存操作はデバイス側に通知されません。
                </div>
              )}

              <Modal
                title="接続テスト結果"
                trigger={
                  <CustomButton
                    className={classes.testConnectButton}
                    color="warning"
                  >
                    接続状態テスト
                  </CustomButton>
                }
              >
                <TestConnectPopup
                  mdlType={ModuleChannelType.B64Channel}
                  units={Object.values(commModule.unitsByNumber) as Units[]}
                  siteId={commModule.site_id!}
                  deviceCode={commModule.device_code}
                />
              </Modal>

              {isMobile && (
                <CustomButton
                  onClick={onBack}
                  className={classes.backButton}
                  color="grey"
                >
                  戻る
                </CustomButton>
              )}
              <CustomButton
                type="submit"
                className={classes.backButton}
                color="success"
              >
                保存
              </CustomButton>
            </div>
          </div>
        </div>
      </form>
      <Drawer isOpen={isOpenCameraForm} onChange={setOpenCameraForm}>
        <CameraDrawer
          moduleId={+moduleId}
          onClose={() => setOpenCameraForm(false)}
        />
      </Drawer>
      <Drawer isOpen={isOpenRemark} onChange={setOpenRemark}>
        <>
          <span>＊記入後は備考欄を閉じて保存ボタンを押してください。</span>
          <RemarkTextField
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setRemark(e.target.value)
            }
            remark={remark}
          />
          <CustomButton
            className={classes.remarkDrawCloseButton}
            color="primary"
            onClick={() => setOpenRemark(false)}
          >
            備考欄を閉じる
          </CustomButton>
        </>
      </Drawer>
    </>
  );
};

export default B64ChannelSettings;
