import React from 'react'
import { Route } from 'react-router-dom'

export type RouteProps = {
  url: string
  component?: React.FC
  breadCrumbs?: React.FC[]
  sideBarMenu?: React.FC
  childMaps?: any
}

export type RouteRecursiveProps = {
  routeMaps: RouteProps[]
  parentUrl?: string
}

// noMatchRoute作るなら まずフラットにしてから処理したほうがいいかも

const RouteRecursive: React.FC<RouteRecursiveProps> = ({
  parentUrl,
  routeMaps
}) => {
  return (
    <React.Fragment>
      {routeMaps.map((route, index) => (
        <React.Fragment key={concatUrl([parentUrl, route.url])}>
          {route.component && (
            <Route
              exact
              path={concatUrl([parentUrl, route.url])}
              component={route.component}
            />
          )}
          {route.childMaps && (
            <RouteRecursive
              key={`${concatUrl([parentUrl, route.url])}_recursive`}
              parentUrl={concatUrl([parentUrl, route.url])}
              routeMaps={route.childMaps || []}
            />
          )}
          {/* {index + 1 === routeMaps.length && (
            <Route
              exact
              path={`${concatUrl([parentUrl, route.url])}/*`}
              component={NoMatch}
            />
          )} */}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

export default RouteRecursive

const concatUrl = (urlList: (string | undefined)[]) => {
  urlList = urlList.filter(url => url !== undefined)
  return urlList.join('')
}

export function RouteTest({ path }: { path: string }) {
  console.log('routeTeset', path)
  console.log('routeTeset', path)

  return (
    <div>
      <h3>
        path: <code>{path}</code>
      </h3>
    </div>
  )
}
