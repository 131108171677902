import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { sleep } from '@/utils/sleep';
import { ErrorPosition } from '@/slicers/response';
import { setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import {
  agentSwitchGetsSucces,
  agentSwitchGetSuccess,
  agentSwitchesDeleteById,
} from '@/slicers/switchesModules';
import { Switch } from '@/slicers/switches';
import { MainApiCaller, TApiCaller } from '@/api-service';

export const readSwitchWithToSwitchWithMap = (
  readSwitchWith: Record<string, any>
): Switch => {
  const { units, ...other } = readSwitchWith;
  let switchWith: Switch = { ...other, unitsByNumber: {} } as any;
  return switchWith;
};

@MainApiCaller('/agent_companies')
class AgentCompaniesSwitchesRequest {
  caller!: TApiCaller;

  index(agentId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/${agentId}/switches`);
        if (response.status === 200) {
          dispatch(
            agentSwitchGetsSucces(
              response.data.map((readSwitchWith: any) =>
                readSwitchWithToSwitchWithMap(readSwitchWith)
              )
            )
          );
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  post(agentId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(`/${agentId}/switches`, data);
          if (response.status === 201) {
            dispatch(
              agentSwitchGetSuccess(
                readSwitchWithToSwitchWithMap(response.data)
              )
            );
            dispatch(setResponse(parseAxiosResponse(response)));
            await sleep(200);
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }

  put(agentId: string, switchId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${agentId}/switches/${switchId}`,
            data
          );

          if (response.status === 200) {
            dispatch(
              agentSwitchGetSuccess(
                readSwitchWithToSwitchWithMap(response.data)
              )
            );
            dispatch(setResponse(parseAxiosResponse(response)));
            await sleep(200);
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }

  delete(agentId: string, switchId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.DEL(
          `/${agentId}/switches/${switchId}`
        );
        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました'));
          dispatch(agentSwitchesDeleteById([Number(switchId)]));
        } else {
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }
}

const agentCompaniesSwitchesRequest = new AgentCompaniesSwitchesRequest();
export default agentCompaniesSwitchesRequest;
