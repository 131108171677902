import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';

import { useGetClientRouteParam } from '@/hooks';
import { route } from '@/routes/url-generator';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';
import { useLocation } from 'react-router';

const CompaniesModulesBreadCrumbDetail: React.FC = () => {
  const { companyId } = useGetClientRouteParam();
  const location = useLocation();
  const moduleId = location.pathname.match(/\/modules\/(\d+)/)?.[1] as string;

  const commModule = useSelector(
    (state: RootState) => state.modulesState.modulesById[Number(moduleId)]
  );

  return commModule ? (
    <BreadCrumbs>
      <BreadCrumbItem
        title={commModule?.name}
        to={route.clientRoute.modules.show(companyId, commModule.id)}
      />
    </BreadCrumbs>
  ) : null;
};

export default CompaniesModulesBreadCrumbDetail;
