import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { Divider, Drawer, IconButton, List } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  ChevronLeft,
  ChevronRight,
  Business,
  DeveloperBoard,
  AccessTime,
  Flag,
  ExitToApp,
  Person,
  Inbox,
  Assignment,
} from '@material-ui/icons';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';
import { getLoginUserAndCompanySelector } from '@/selector/users';
import {
  UserTypeAdmin,
  UserTypeAgent,
  UserTypeOperator,
  UserTypeGeneralManager,
  UserTypeSiteManager,
} from '@/slicers/users';
import SideBarMenuItem from '@/components/organisms/SideBarMenuItem';
import { routeMaps } from '@/routes';
import { RouteProps } from '@/routes/RouteRecursive';
import { route } from '@/routes/url-generator';
import BackwardLogin from '@/components/organisms/BackwardLogin';

export type SideBarProps = {
  sideBarWidth: number;
  sideBarOpened: boolean;
  onSideBarClose: () => void;
};

const recursive = (pathName: string, routeMaps: RouteProps[]) => {
  let sideBarMenuComponentList: React.FC[] = [];

  routeMaps.forEach((route, index) => {
    let url = route.url.replace(/:.*?\//, '\\d+/');
    url = url.replace(/:.*(?!\/)/, '\\d+');

    const matched = pathName.match(RegExp(`^${url}`))?.[0];
    if (matched) {
      if (route.sideBarMenu) sideBarMenuComponentList.push(route.sideBarMenu);
      if (route.childMaps) {
        sideBarMenuComponentList.push(
          ...recursive(pathName.slice(matched.length), route.childMaps)
        );
      }
    }
  });
  return sideBarMenuComponentList;
};

export default function SideBar({
  sideBarWidth,
  sideBarOpened,
  onSideBarClose,
}: SideBarProps) {
  const classes = useStyles(sideBarWidth)({} as any);
  const theme = useTheme();
  const location = useLocation();

  const isAbstractLogin = !!useSelector(
    (state: RootState) => state.loginState.forwardQueue
  ).length;

  const { loginUserCompany, loginUser } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  );
  const [sideBarMenuComponentList, setSideBarMenuComponentList] = useState<
    React.FC[]
  >([]);

  useEffect(() => {
    setSideBarMenuComponentList(recursive(location.pathname, routeMaps));
  }, [location.pathname]);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={sideBarOpened}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div
        className={[
          classes.drawerHeader,
          isAbstractLogin ? classes.multi : '',
        ].join(' ')}
      >
        {isAbstractLogin && (
          <BackwardLogin>
            <div className={classes.logout}>
              <ExitToApp />
              <span>戻る</span>
            </div>
          </BackwardLogin>
        )}

        <IconButton onClick={onSideBarClose}>
          {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
      <Divider />

      <List>
        {loginUser &&
          (loginUser?.type <= UserTypeAgent ? (
            <>
              {loginUser.type <= UserTypeAdmin && (
                <React.Fragment>
                  <SideBarMenuItem
                    icon={Business}
                    title="代理店管理"
                    to={route.agentCompanies.list}
                  />
                  <SideBarMenuItem
                    icon={DeveloperBoard}
                    title="遠隔電源指令盤管理"
                    to={route.modules.list}
                  />
                </React.Fragment>
              )}
              {loginUserCompany &&
                (loginUser.type === UserTypeAgent ? (
                  <React.Fragment>
                    <SideBarMenuItem
                      icon={Business}
                      title="企業管理"
                      to={route.agentCompanies.normalCompanies.list(
                        loginUser.company_id
                      )}
                    />
                    <SideBarMenuItem
                      icon={DeveloperBoard}
                      title="遠隔電源指令盤管理"
                      to={route.agentCompanies.modules.list(
                        loginUser.company_id
                      )}
                    />
                    <SideBarMenuItem
                      icon={Inbox}
                      title="代理店管理者管理"
                      to={route.agentCompanies.generalManagerUsers.list(
                        loginUser.company_id
                      )}
                    />
                  </React.Fragment>
                ) : (
                  loginUser?.type <= UserTypeAdmin && (
                    <SideBarMenuItem
                      icon={Business}
                      title="企業管理"
                      to={route.companies.normalCompanies.list}
                    />
                  )
                ))}
              <Divider />
            </>
          ) : loginUser.type === UserTypeGeneralManager ? (
            <>
              <SideBarMenuItem
                title="現場管理"
                to={route.clientRoute.sites.list(loginUser.company_id)}
                icon={Flag}
              />
              <SideBarMenuItem
                title="遠隔電源指令盤管理"
                to={route.clientRoute.modules.list(loginUser.company_id)}
                icon={DeveloperBoard}
              />
              <SideBarMenuItem
                title="企業管理者管理"
                to={route.clientRoute.companyUsers.list(
                  loginUser.company_id,
                  'general_manager'
                )}
                icon={Person}
              />
              <SideBarMenuItem
                title="現場管理者管理"
                to={route.clientRoute.companyUsers.list(
                  loginUser.company_id,
                  'site'
                )}
                icon={Person}
              />
              <SideBarMenuItem
                title="使用者管理"
                to={route.clientRoute.companyUsers.list(
                  loginUser.company_id,
                  'operator'
                )}
                icon={Person}
              />
              <Divider />
            </>
          ) : (
            loginUser.site_id &&
            (loginUser.type === UserTypeSiteManager ? (
              <>
                <SideBarMenuItem
                  title={'現場管理'}
                  to={route.clientRoute.sites.list(loginUser.company_id)}
                  icon={Flag}
                />
                <SideBarMenuItem
                  title={'遠隔電源指令盤管理'}
                  to={route.clientRoute.sites.modules.list(
                    loginUser.company_id,
                    loginUser.site_id
                  )}
                  icon={DeveloperBoard}
                />
                <Divider />
              </>
            ) : (
              loginUser.type === UserTypeOperator && (
                <>
                  <SideBarMenuItem
                    title="スイッチ操作"
                    to={route.operator.operationPanel(loginUser.site_id)}
                    icon={ExitToApp}
                  />
                  <SideBarMenuItem
                    title="指令盤管理"
                    to={route.operator.modules(loginUser.site_id)}
                    icon={DeveloperBoard}
                  />
                  <SideBarMenuItem
                    title="スケジュール管理"
                    to={route.operator.schedules(loginUser.site_id)}
                    icon={AccessTime}
                  />
                  <SideBarMenuItem
                    title="ログ"
                    to={route.operator.operationLog(loginUser.site_id)}
                    icon={Assignment}
                  />
                  <Divider />
                </>
              )
            ))
          ))}

        {sideBarMenuComponentList.map((Item, index) => (
          <Item key={index} />
        ))}
      </List>

      {loginUser?.realType! <= UserTypeAdmin && (
        <>
          <SideBarMenuItem
            icon={Assignment}
            title="通信ログ"
            to={route.logger}
          />
          <SideBarMenuItem
            icon={Assignment}
            title="操作ログ"
            to={route.siteLogManagement}
          />
        </>
      )}
      {loginUser && process.env.NODE_ENV === 'development' && (
        <>
          <SideBarMenuItem
            icon={DeveloperBoard}
            title="[64ch] 遠隔電源司令盤シミュレーター"
            to={`/web-socket-client-module-64ch`}
            anotherTab={true}
          />
          <SideBarMenuItem
            icon={DeveloperBoard}
            title="遠隔電源司令盤シミュレーター"
            to={`/web-socket-client-module`}
            anotherTab={true}
          />
          <SideBarMenuItem
            icon={DeveloperBoard}
            title="遠隔電源スイッチシミュレーター"
            to={`/web-socket-client-switch`}
            anotherTab={true}
          />
        </>
      )}
    </Drawer>
  );
}
