import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import {
  companyGetSuccess,
  companyGetsSucces,
  companiesDelete,
} from '@/slicers/companies';
import { ErrorPosition, setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/agent_companies')
class AgentCompaniesRequest {
  caller!: TApiCaller;

  get(companyId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/${companyId}`);
        if (response.status === 200) {
          dispatch(companyGetSuccess(response.data));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました'));
        }
      },
    };
  }

  index() {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET('/');
        if (response.status === 200) {
          dispatch(companyGetsSucces(response.data));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました'));
        }
      },
    };
  }

  post() {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST('/', data);
          if (response.status === 201) {
            dispatch(companyGetSuccess(response.data));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }

  put(companyId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(`/${companyId}`, data);
          if (response.status === 200) {
            dispatch(companyGetSuccess(response.data));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }

  delete(companyId: string) {
    return {
      request: (): AppThunk => async (dispatch, getState) => {
        const response = await this.caller.DEL(`/${companyId}`);
        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました'));
          companiesDelete(dispatch, getState(), [Number(companyId)]);
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }
}

const agentCompaniesRequest = new AgentCompaniesRequest();
export default agentCompaniesRequest;
