import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModuleChannelType } from '../modules';

export type ModuleWith = {
  id: number;
  name: string;
  deviceCode: string; //communication_modulesテーブルのdeviceCode
  control_no?: string;
  unitsByNumber: Record<number, PowerUnit>; //接続してきた親機が「R=」で情報を渡してくる
  switchingState: SwitchingState;
  amps?: number;
  type?: ModuleChannelType;
  socketId?: number;
};

export enum SwitchingState {
  STANDBY, // スイッチ変更可能
  SWITCHING, // スイッチ変更中
}

export type PowerUnit = {
  number: number; //子機番号
  /**
   * SW=がトグル命令なので、連続switchingの際に親機からのレスポンスがあるまでロックする
   * 但し一定時間経っても解除されなかったら無視する。
   */
  state: PowerUnitState;
  group_on_off_flg?: 1 | 0;
  switchesByNumber: Record<number, PowerSwitch>;
  timestamp: number; //timestamp //子機や子機が所持しているスイッチに何か変更があれば更新される
};

export type PowerSwitch = {
  id: number;
  number: number; //スイッチ番号
  state: PowerSwitchState;
  name: string;
  shutterMode: number;
  isLock: number;
  isDisplay?: number;
  icon: string;
  powerMeterId?: number;
  amps?: number; // 電力消費量
  requiredConfirmation?: number;
  pid?: string;
  switchId?: number;
  maxAmps?: number;
  switchingState: SwitchingState;
};

export enum PowerUnitState {
  LOADING,
  READY,
}

export enum PowerSwitchState {
  INIT = -1, //init
  ON, //on
  OFF, //off
  UNKNOWN, //接続不良
  LEAK, // 漏電
}

export type SwitchPannelState = {
  moduleWithByDeviceCode: Record<string, ModuleWith>;
};

export const initialState: SwitchPannelState = {
  moduleWithByDeviceCode: {},
};

const updateModulesWith = (
  state: Record<string, ModuleWith>,
  data: ModuleWith[]
) => {
  data.forEach((commModule) => {
    let moduleWithState = state[commModule.deviceCode];
    if (!moduleWithState) return;
    moduleWithState.switchingState = commModule.switchingState;
    moduleWithState.socketId = commModule.socketId;
    Object.values(commModule.unitsByNumber).forEach((powerUnit) => {
      let powerUnitState = moduleWithState.unitsByNumber[powerUnit.number];

      if (!powerUnitState) return;

      powerUnitState.state = PowerUnitState.READY;
      powerUnitState.timestamp = 0;

      Object.values(powerUnit.switchesByNumber).forEach((powerSwitch) => {
        let powerSwitchState =
          powerUnitState.switchesByNumber[powerSwitch.number];

        if (!powerSwitchState) return;

        powerSwitchState.state = powerSwitch.state;
        powerSwitchState.switchingState = powerSwitch.switchingState;
      });
    });
  });
};

const switchPannel = createSlice({
  name: 'switchPannel',
  initialState,
  reducers: {
    moduleWithUpdateName(
      state,
      { payload }: PayloadAction<{ deviceCode: string; name: string }>
    ) {
      const { deviceCode, name } = payload;
      state.moduleWithByDeviceCode[deviceCode].name = name;
    },
    moduleWithGetsSuccess(state, { payload }: PayloadAction<any[]>) {
      let newDeviceCodes: string[] = [];

      payload.forEach((commModule) => {
        newDeviceCodes.push(commModule.device_code);

        if (!!state.moduleWithByDeviceCode?.[commModule.device_code]) {
          // すでにあれば何もしない
          return;
        }

        state.moduleWithByDeviceCode[commModule.device_code] = {
          ...commModule,
          deviceCode: commModule.device_code,
          unitsByNumber: {},
          switchingState: SwitchingState.STANDBY,
        };

        commModule.units.forEach((powerUnit: any) => {
          state.moduleWithByDeviceCode[commModule.device_code].unitsByNumber[
            powerUnit.number
          ] = {
            group_on_off_flg: powerUnit.group_on_off_flg,
            number: powerUnit.number,
            state: PowerUnitState.LOADING,
            switchesByNumber: {},
            timestamp: 0,
          };

          powerUnit.switches.forEach((powerSwitch: any) => {
            const commSwitch = powerSwitch.communication_switch;

            state.moduleWithByDeviceCode[commModule.device_code].unitsByNumber[
              powerUnit.number
            ].switchesByNumber[powerSwitch.number] = {
              switchingState: SwitchingState.STANDBY,
              id: powerSwitch.id,
              number: powerSwitch.number,
              state: PowerSwitchState.INIT,
              name: commSwitch?.name || powerSwitch.name,
              shutterMode: powerSwitch.shutter_mode,
              isLock: powerSwitch.is_lock,
              isDisplay: powerSwitch.is_display,
              icon: powerSwitch.icon,
              requiredConfirmation: powerSwitch.required_confirmation,
              pid: commSwitch?.pid,
              maxAmps: commSwitch?.max_amps,
              switchId: powerSwitch.switch_id,
            };
          });
        });
      });

      //   紐付け解除されたデバイスを削除する
      for (const oldDeviceCode of Object.keys(state.moduleWithByDeviceCode)) {
        if (!newDeviceCodes.includes(oldDeviceCode)) {
          delete state.moduleWithByDeviceCode[oldDeviceCode];
        }
      }
    },
    responseStateSuccess(state, { payload }: PayloadAction<ModuleWith[]>) {
      let moduleWithByDeviceCode = JSON.parse(
        JSON.stringify(state.moduleWithByDeviceCode)
      ) as Record<string, ModuleWith>;

      state.moduleWithByDeviceCode = {};

      const moduleWithListExistsOnlyState = Object.values(
        moduleWithByDeviceCode
      ).filter(
        (moduleWithState) =>
          !payload.some(
            (moduleWith) => moduleWith.deviceCode === moduleWithState.deviceCode
          )
      );

      for (const moduleWithState of moduleWithListExistsOnlyState) {
        const unitsByNumber =
          moduleWithByDeviceCode[moduleWithState.deviceCode].unitsByNumber;
        for (const unitNumber in unitsByNumber) {
          const powerUnitState = unitsByNumber[unitNumber];
          powerUnitState.state = PowerUnitState.LOADING;
        }
      }
      updateModulesWith(moduleWithByDeviceCode, payload);

      state.moduleWithByDeviceCode = moduleWithByDeviceCode;
    },
    switchSuccess(state, { payload }: PayloadAction<ModuleWith>) {
      updateModulesWith(state.moduleWithByDeviceCode, [payload]);
    },
    deviceDisconnected(
      state,
      { payload: deviceCode }: PayloadAction<string | null>
    ) {
      const initModule = (deviceCode: string) => {
        const unitsByNumber =
          state.moduleWithByDeviceCode[deviceCode].unitsByNumber;

        for (const unitNumber in unitsByNumber) {
          const powerUnit = unitsByNumber[unitNumber];
          powerUnit.state = PowerUnitState.LOADING;
          const switchesByNumber = powerUnit.switchesByNumber;

          for (const switchNumber in switchesByNumber) {
            const powerSwitch = switchesByNumber[switchNumber];
            powerSwitch.state = PowerSwitchState.UNKNOWN;
          }
        }
      };

      if (deviceCode === null) {
        for (const deviceCode in state.moduleWithByDeviceCode) {
          initModule(deviceCode);
        }
      } else {
        initModule(deviceCode);
      }
    },
    webSocketDisconnected(state) {},
    initState(state) {
      state.moduleWithByDeviceCode = {};
    },
  },
});

export const {
  moduleWithUpdateName,
  moduleWithGetsSuccess,
  responseStateSuccess,
  deviceDisconnected,
  initState,
  switchSuccess,
} = switchPannel.actions;

export default switchPannel.reducer;
