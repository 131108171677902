import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { getNormalCompaniesWithAgent } from '@/selector/companies';
import companiesNormalCompaniesRequest from '@/api/companies_normal_companies';
import { setLoading } from '@/slicers/loading';
import { getLoginUserSelector } from '@/selector/users';
import { UserTypeAdmin } from '@/slicers/users';
import { Company } from '@/slicers/companies';
import Drawer from '@/components/atoms/Drawer';
import Detail from '../Detail';
import { Box, TablePagination } from '@material-ui/core';
import { DetailButton } from '@/components/atoms/Buttons';
import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import agentCompaniesNormalCompaniesRequest from '@/api/agent_companies_normal_companies';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

export const ListNormalCompanies: React.FC<{}> = () => {
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const dispatch = useDispatch();
  const loginUser = useSelector((state: RootState) =>
    getLoginUserSelector(state)
  );

  const companyId = loginUser?.company_id;

  const normalCompaniesPagingOptions = useSelector(
    (state: RootState) => state.companiesState.pagingOptions
  );
  const normalCompanies = useSelector((state: RootState) =>
    getNormalCompaniesWithAgent(state)
  );

  const [isOpenDetail, setOpenDetail] = useState(false);
  const [editData, setEditData] = useState<null | Record<string, any>>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [currentPage, setCurrentPage] = useState(1);
  const [currentKeyword, setCurrentKeyword] = useState('');

  const getNormalCompanies = async (page?: number, keyword?: string) => {
    await dispatch(setLoading(true));
    await dispatch(
      companiesNormalCompaniesRequest.index('').request(page, keyword)
    );
    await dispatch(setLoading(false));
  };

  const onSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'post_code', 'pref', 'address', 'tel', 'fax'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input.hasAttribute('required')) {
        if (!input.value) {
          errors[field] = '必須入力項目です';
        }
      }
      data[field] = input.value;
      return errors;
    }, {});

    setErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (editData) {
        const api = agentCompaniesNormalCompaniesRequest.put(
          companyId!.toString(),
          editData.id
        );
        await dispatch(api.request(data as Company));
        setEditData({ ...data, id: editData.id });
      }
      closeDrawer();
      hideOverlay();
    }
  };

  const onDelete = async (id: string) => {
    showOverlay();
    const api = agentCompaniesNormalCompaniesRequest.delete(
      companyId!.toString(),
      id
    );
    await dispatch(api.request());
    setEditData(null);
    setOpenDetail(false);
    hideOverlay();
  };

  useEffect(() => {
    if (!isOpenDetail) {
      setErrors({});
      setEditData(null);
    }
  }, [isOpenDetail]);

  const columns: any[] = [
    {
      field: 'id',
      title: 'ID',
      width: '5%',
    },
    {
      field: 'name',
      title: '名前',
      width: '20%',
    },
    {
      field: 'pref',
      title: '都道府県',
      width: '15%',
    },
    {
      field: 'address',
      title: '住所',
      width: '35%',
    },
  ];

  if (loginUser && loginUser?.type <= UserTypeAdmin) {
    columns.push({
      field: 'agent',
      title: '代理店名',
      width: '25%',
      render: ({ agent }: any) => agent?.name,
    });
  }

  columns.push({
    title: 'アクション',
    render: (row: any) => (
      <Box style={{ minWidth: '240px' }}>
        <DetailButton
          onClick={() => {
            setEditData(row);
            setOpenDetail(true);
          }}
        />
      </Box>
    ),
  });

  return (
    <>
      {overlayLoader}
      <CustomMaterialTable
        title="企業一覧"
        options={{
          pageSizeOptions: [5],
        }}
        onSearchChange={text => {
          setCurrentKeyword(text);
          setCurrentPage(1);
          getNormalCompanies(1, text);
        }}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              rowsPerPageOptions={[]}
              rowsPerPage={5}
              page={currentPage - 1}
              count={normalCompaniesPagingOptions.total}
              onChangePage={(e, page) => {
                setCurrentPage(page + 1);
                getNormalCompanies(page + 1, currentKeyword);
              }}
            />
          ),
        }}
        columns={columns}
        data={normalCompanies.map((item: any) => ({ ...item }))}
      />
      <Drawer isOpen={isOpenDetail} onChange={setOpenDetail}>
        {editData && (
          <Detail
            onConfirmDelete={onDelete}
            errors={errors}
            initialData={editData}
            onSubmit={onSubmit}
          />
        )}
      </Drawer>
    </>
  );
};

export default ListNormalCompanies;
