import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { useParams, useHistory } from 'react-router-dom';
import { ClientRouteParams, SitesRouteParams } from '@/routes/ClientRoute';
import { initOperationLogState } from '@/slicers/operation_log';
import operationLogsRequest from '@/api/operation_logs';
import moment from 'moment';
import { setLoading } from '@/slicers/loading';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { DatetimeTextField } from '@/components/atoms/Datetime';
import { getLoginUserSelector } from '@/selector/users';
import { BackButton } from '@/components/atoms/Buttons';
import { UserTypeOperator } from '@/slicers/users';
import operationPageRequest from '@/api/operation_page';
import { findModulesWithOperationPageSelectorFactory } from '@/selector/operation-page';
import { PowerSwitchState, ReadOperationLog } from '@/types';

const SiteOperationLogsList: React.FC = () => {
  const { site: siteId } = useParams<ClientRouteParams & SitesRouteParams>();

  const dispatch = useDispatch();
  const logs = useSelector((state: RootState) =>
    Object.values(state.operationLogState.operationLogsById)
  );
  const history = useHistory();

  const loginUser = useSelector(getLoginUserSelector);
  const moduleWithByDeviceCode = useSelector((state: RootState) =>
    findModulesWithOperationPageSelectorFactory(state)
  );

  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  type EventFilter = 'connection' | 'switch' | 'all';
  const [eventFilter, setEventFilter] = useState<EventFilter>('all');

  type ClientFilter = 'user' | 'device' | 'system' | 'all';
  const [clientFilter, setClientFilter] = useState<ClientFilter>('all');

  const [filterStartAt, setFilterStartAt] = useState('00:00');
  const [filterEndAt, setFilterEndAt] = useState('23:59');
  const [selectedModule, setSelectedModule] = useState<string>();

  const selectFilteredLogs = () => {
    return logs.filter((log) => {
      //   if (!log.action_at.includes(date)) {
      //     return false
      //   }

      if (eventFilter !== 'all') {
        let leave = true;
        switch (eventFilter) {
          case 'switch':
            leave = log.event === 'switch';
            break;
          case 'connection':
            const whiteList: ReadOperationLog['event'][] = [
              'connect',
              'disconnect',
            ];
            leave = whiteList.includes(log.event);
            break;
        }
        if (!leave) {
          return false;
        }
      }

      if (clientFilter !== 'all') {
        switch (clientFilter) {
          case 'user':
            if (!log.user_id) return false;
            break;
          case 'device':
            if (!log.device_code) return false;
            break;
          case 'system':
            if (log.user_id || log.device_code) return false;
            break;
        }
      }

      if (clientFilter === 'all' || clientFilter === 'device')
        if (selectedModule) {
          if (log.device_code !== selectedModule) {
            return false;
          }
        }

      const actionAtMoment = moment(log.action_at);
      const dateMoment = moment(date);
      const startAtMoment = dateMoment
        .clone()
        .set('hour', Number(filterStartAt.slice(0, 2)))
        .set('minute', Number(filterStartAt.slice(3, 5)));
      const endAtMoment = dateMoment
        .clone()
        .set('hour', Number(filterEndAt.slice(0, 2)))
        .set('minute', Number(filterEndAt.slice(3, 5)));

      if (
        !(
          actionAtMoment.isSameOrAfter(startAtMoment) &&
          actionAtMoment.isSameOrBefore(endAtMoment)
        )
      ) {
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    dispatch(operationPageRequest.index(siteId).request());
  }, [dispatch, siteId]);

  useEffect(() => {
    const func = async () => {
      await dispatch(setLoading(true));
      await dispatch(
        operationLogsRequest.index(Number(siteId), date).request()
      );
      await dispatch(setLoading(false));
    };
    func();
    return () => {
      dispatch(initOperationLogState);
    };
  }, [dispatch, date, siteId]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <FormControl style={{ minWidth: '120px' }}>
          <InputLabel id="demo-simple-select-label">クライアント</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={clientFilter}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setClientFilter(event.target.value as any);
            }}
          >
            <MenuItem value={'all'}>すべて</MenuItem>
            <MenuItem value={'user'}>ユーザー</MenuItem>
            <MenuItem value={'device'}>親機</MenuItem>
            <MenuItem value={'system'}>システム</MenuItem>
          </Select>
        </FormControl>
        {(clientFilter === 'all' || clientFilter === 'device') && (
          <FormControl style={{ minWidth: '120px' }}>
            <InputLabel id="device-code">指令盤</InputLabel>
            <Select
              labelId="device-code"
              value={selectedModule}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectedModule(event.target.value as any);
              }}
            >
              {Object.values(moduleWithByDeviceCode).map((mdl) => (
                <MenuItem key={mdl.deviceCode} value={mdl.deviceCode}>
                  {mdl.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl style={{ minWidth: '120px' }}>
          <InputLabel id="demo-simple-select-label">イベント</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={eventFilter}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setEventFilter(event.target.value as any);
            }}
          >
            <MenuItem value={'all'}>すべて</MenuItem>
            <MenuItem value={'connection'}>接続・切断</MenuItem>
            <MenuItem value={'switch'}>スイッチ</MenuItem>
          </Select>
        </FormControl>
        <div style={{ display: 'inline-block' }}>
          <InputLabel
            id="demo-simple-select-label"
            style={{ fontSize: '12px' }}
          >
            日付
          </InputLabel>
          <div style={{ display: 'flex' }}>
            <DatetimeTextField
              timeFormat={false}
              value={date}
              dateFormat={'YYYY-MM-DD'}
              onChange={(props: any) => setDate(props.format('YYYY-MM-DD'))}
            />
          </div>
        </div>
        <div style={{ display: 'inline-block' }}>
          <InputLabel
            id="demo-simple-select-label"
            style={{ fontSize: '12px' }}
          >
            時間帯
          </InputLabel>
          <div style={{ display: 'flex' }}>
            <DatetimeTextField
              dateFormat={false}
              value={filterStartAt}
              timeFormat={'HH:mm'}
              onChange={(event: any) => setFilterStartAt(event.format('HH:mm'))}
            />
            ~
            <DatetimeTextField
              dateFormat={false}
              value={filterEndAt}
              timeFormat={'HH:mm'}
              onChange={(event: any) => setFilterEndAt(event.format('HH:mm'))}
            />
          </div>
        </div>
      </div>
      <div>
        {!selectFilteredLogs().length ? (
          <div>データがありません</div>
        ) : (
          selectFilteredLogs().map((log) => displayLog(log))
        )}
      </div>
      <div>
        {loginUser?.type! < UserTypeOperator && (
          <BackButton onClick={history.goBack} />
        )}
      </div>
    </div>
  );
};

export default SiteOperationLogsList;

const displayActionAt = (actionAt: string) => {
  const actionAtMoment = moment(actionAt);
  return (
    <span>
      [<span>{actionAtMoment.format('YYYY-MM-DD')}</span>,<span> </span>
      <span>{actionAtMoment.format('HH:mm:ss')}</span>]
    </span>
  );
};

const displayLog = (log: ReadOperationLog) => {
  let text = '';
  if (log.event === 'connect' || log.event === 'disconnect') {
    text =
      (log.user_id
        ? `ユーザーId: ${log.user_id}, ユーザー名: ${log.name}`
        : `デバイスコード: ${log.device_code}, 親機名: ${log.name}`) + 'が';

    text += (log.event === 'connect' ? '接続' : '切断') + 'しました';
  }

  if (log.event === 'switch') {
    text = log.user_id
      ? `ユーザーId: ${log.user_id}, ユーザー名: ${log.name}がスイッチ操作しました`
      : log.device_code
      ? `デバイスコード: ${log.device_code}, 親機名: ${log.name}のスイッチが変更されました`
      : 'スケジュールが実行されました';
  }

  const powerSwitchStateToText = (state: PowerSwitchState) => {
    switch (state) {
      case PowerSwitchState.ON:
        return 'ON';
      case PowerSwitchState.OFF:
        return 'OFF';
      case PowerSwitchState.UNKNOWN:
        return '不明';
    }
  };

  const displayData = (data?: ReadOperationLog) => {
    return (
      data?.data &&
      Object.values(data.data)?.map((data) => (
        <div>
          <div>
            {Object.values(data).map((moduleWith) => (
              <div style={{ marginLeft: `1rem` }}>
                {true && (
                  // {data.deviceCode !== undefined && (
                  <div>
                    <div>デバイスコード:{moduleWith.deviceCode}</div>
                    <div>親機名:{moduleWith.name}</div>
                  </div>
                )}
                {Object.values(moduleWith.unitsByNumber).map((unit) => (
                  <div style={{ marginLeft: `1rem` }}>
                    <div>子機番号: {unit.number}</div>
                    <div style={{ marginLeft: `1rem` }}>
                      {Object.values(unit.switchesByNumber).map(
                        (powerSwitch) => (
                          <div key={powerSwitch.number}>
                            <div>スイッチ番号: {powerSwitch.number}</div>
                            <div>スイッチ名: {powerSwitch.name}</div>
                            <div style={{ marginLeft: `1rem` }}>
                              状態:
                              {powerSwitchStateToText(powerSwitch.state)}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))
    );
  };
  return (
    <div>
      <div>
        {displayActionAt(log.action_at)} {text}
      </div>
      {displayData(log)}
    </div>
  );
};
