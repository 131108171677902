import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagingOptions } from '@/interfaces';

export interface CameraAlbum {
  id: number;
  camera_id: number;
  site_id: number;
  created_at: string;
  file_name: string;
}

export interface CameraAlbums {
  siteCameraAlbumsById: Record<number, CameraAlbum>;
  pagingOptions: PagingOptions;
  filters?: Record<string, any>;
}

interface CameraAlbumKeys {
  siteId: number;
  cameraId: number;
}

export const getDefaultCameraAlbums = () =>
  ({
    siteCameraAlbumsById: {},
    pagingOptions: {
      total: 0,
      perPage: 5,
    },
    filters: {},
  } as CameraAlbums);

export type SiteCameraAlbumsState = Record<string, CameraAlbums>;

export const initialState: SiteCameraAlbumsState = {};

const siteCameraAlbumsSlice = createSlice({
  name: 'siteCameraAlbumsSlice',
  initialState,
  reducers: {
    clearSiteCameraAlbums(
      state,
      { payload: { siteId, cameraId } }: PayloadAction<CameraAlbumKeys>
    ) {
      const albumsState =
        state[`${siteId}-${cameraId}`] || getDefaultCameraAlbums();
      const deleteIds = Object.keys(albumsState.siteCameraAlbumsById);
      deleteIds.forEach(id => {
        delete albumsState.siteCameraAlbumsById[id as any];
      });
      albumsState.pagingOptions.total = 0;
      state[`${siteId}-${cameraId}`] = albumsState;
    },

    siteCameraAlbumSetPagingOptions(
      state,
      {
        payload: { siteId, cameraId, paging },
      }: PayloadAction<{ paging: PagingOptions } & CameraAlbumKeys>
    ) {
      const albumsState =
        state[`${siteId}-${cameraId}`] || getDefaultCameraAlbums();
      albumsState.pagingOptions.perPage = paging.perPage;
      albumsState.pagingOptions.total = paging.total;
      state[`${siteId}-${cameraId}`] = albumsState;
    },

    siteCameraAlbumGetsSucces(
      state,
      {
        payload: { siteId, cameraId, albums },
      }: PayloadAction<{ albums: CameraAlbum[] } & CameraAlbumKeys>
    ) {
      const albumsState =
        state[`${siteId}-${cameraId}`] || getDefaultCameraAlbums();
      albums.forEach(album => {
        albumsState.siteCameraAlbumsById[album.id] = album;
      });
      state[`${siteId}-${cameraId}`] = albumsState;
    },

    siteCameraAlbumSetFilters(
      state,
      {
        payload: { siteId, cameraId, filters },
      }: PayloadAction<{ filters: Record<string, any> } & CameraAlbumKeys>
    ) {
      const albumsState =
        state[`${siteId}-${cameraId}`] || getDefaultCameraAlbums();
      albumsState.filters = {
        ...albumsState.filters,
        ...filters,
      };
      state[`${siteId}-${cameraId}`] = albumsState;
    },

    siteCameraAlbumDeletes(
      state,
      {
        payload: { siteId, cameraId, albumIds },
      }: PayloadAction<{ albumIds: number[] } & CameraAlbumKeys>
    ) {
      const albumsState =
        state[`${siteId}-${cameraId}`] || getDefaultCameraAlbums();

      albumIds.forEach(id => {
        delete albumsState.siteCameraAlbumsById[id];
      });

      state[`${siteId}-${cameraId}`] = albumsState;
    },
  },
});

export const {
  siteCameraAlbumGetsSucces,
  clearSiteCameraAlbums,
  siteCameraAlbumSetPagingOptions,
  siteCameraAlbumSetFilters,
  siteCameraAlbumDeletes
} = siteCameraAlbumsSlice.actions;

export default siteCameraAlbumsSlice.reducer;
