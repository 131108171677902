import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { route } from '@/routes/url-generator';
import { RootState } from '@/app/rootReducer';
import { useGetClientRouteParam } from '@/hooks';
import companiesSitesRequest from '@/api/companies_sites';
import { selectorFindAllSitesByCompanyId } from '@/selector/site';
import { setLoading } from '@/slicers/loading';
import SiteForm from '@/components/organisms/SiteForm';
import Drawer from '@/components/atoms/Drawer';

import Detail from '../Detail';
import { Company } from '@/slicers/companies';
import { Box, TablePagination } from '@material-ui/core';
import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import { CustomButton } from '@/components/atoms/CustomButton';
import { getLoginUserSelector } from '@/selector/users';
import { UserTypeSiteManager } from '@/slicers/users';

import { useOverlayLoading } from '@/components/atoms/OverlayLoading';
import { useAnchor } from '@/utils/hook';

type CompaniesSitesListProps = {};

const CompaniesSitesList: React.FC<CompaniesSitesListProps> = () => {
  const { ownRegister, drawToAnchor } = useAnchor({ targetPosition: 0 });
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const { companyId } = useGetClientRouteParam();
  const dispatch = useDispatch();
  const pagingOptions = useSelector(
    (state: RootState) => state.sitesState.pagingOptions
  );
  const loginUser = useSelector(getLoginUserSelector);

  const sites = useSelector((state: RootState) =>
    selectorFindAllSitesByCompanyId(Number(companyId))(state)
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [currentKw, setCurrentKw] = useState('');
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState<null | Record<string, any>>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [editType, setEditType] = useState('');

  const onSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'post_code', 'pref', 'address', 'tel', 'fax'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input.hasAttribute('required')) {
        if (!input.value) {
          errors[field] = '必須入力項目です';
        }
      }
      data[field] = input.value;
      return errors;
    }, {});

    setErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (editData) {
        const api = companiesSitesRequest.put(companyId, editData.id);
        await dispatch(api.request(data as Company));
        setEditData({
          id: editData.id,
          ...data,
        });
      } else {
        const api = companiesSitesRequest.post(companyId || '0');
        await dispatch(api.request(data as Company));
      }
      hideOverlay();
      closeDrawer();
    }
  };

  const onDelete = async (id: string) => {
    showOverlay();
    const api = companiesSitesRequest.delete(companyId, id);
    await dispatch(api.request());
    setEditData(null);
    setOpenDrawer(false);
    hideOverlay();
  };

  const getSites = useCallback(
    async (page?, keyword?) => {
      await dispatch(setLoading(true));
      await dispatch(
        companiesSitesRequest.index(companyId).request(page, keyword)
      );
      await dispatch(setLoading(false));
    },
    [dispatch, companyId]
  );

  useEffect(() => {
    if (!isOpenDrawer) {
      setErrors({});
      setEditData(null);
    }
  }, [isOpenDrawer]);

  useEffect(() => {
    getSites();
  }, [getSites]);

  return (
    <>
      {overlayLoader}
      <CustomMaterialTable
        onSearchChange={(text) => {
          setCurrentKw(text);
          setCurrentPage(1);
          getSites(1, text);
        }}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              rowsPerPageOptions={[]}
              rowsPerPage={5}
              page={currentPage - 1}
              count={pagingOptions.total}
              onChangePage={(e, page) => {
                setCurrentPage(page + 1);
                getSites(page + 1, currentKw);
              }}
            />
          ),
        }}
        title="現場管理"
        options={{
          pageSizeOptions: [5],
        }}
        columns={[
          {
            field: 'name',
            title: '現場名',
          },
          {
            title: '操作',
            render: (row: any) => (
              <Box style={{ minWidth: '240px' }}>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('module');
                    setOpenDrawer(true);
                  }}
                  style={{ margin: '5px' }}
                >
                  指令盤操作
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('schedule');
                    setOpenDrawer(true);
                  }}
                >
                  スケジュール
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('company');
                    setOpenDrawer(true);
                  }}
                  style={{ margin: '5px' }}
                >
                  現場情報編集
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('user');
                    setOpenDrawer(true);
                  }}
                >
                  ユーザー編集
                </CustomButton>
                <Link
                  to={route.clientRoute.sites.operationLog(companyId, row.id)}
                >
                  <CustomButton color="primary" style={{ margin: '5px' }}>
                    ログ
                  </CustomButton>
                </Link>
              </Box>
            ),
          },
        ]}
        data={(sites || []).map((item) => ({ ...item }))}
        addButton={
          loginUser?.type && loginUser?.type < UserTypeSiteManager ? (
            <CustomButton
              color="grey"
              style={{ background: '#56ccf2', color: 'white' }}
              onClick={() => setOpenDrawer(true)}
            >
              現場作成
            </CustomButton>
          ) : undefined
        }
      />
      <Drawer ref={ownRegister} isOpen={isOpenDrawer} onChange={setOpenDrawer}>
        {editData ? (
          <Detail
            drawToAnchor={drawToAnchor}
            onConfirmDelete={onDelete}
            errors={errors}
            initialData={editData}
            onSubmit={onSubmit}
            editType={editType}
            onCancel={() => setOpenDrawer(false)}
          />
        ) : (
          <SiteForm
            errors={errors}
            onCancel={() => setOpenDrawer(false)}
            onSubmit={onSubmit(() => setOpenDrawer(false))}
          />
        )}
      </Drawer>
    </>
  );
};

export default CompaniesSitesList;

// <Box className={styles.action_button}>
// <LoginButton
//   component={Link}
//   to={route.clientRoute.sites.schedules.list(companyId, row.id)}
// >
//   スケジュール管理
// </LoginButton>
// </Box>
