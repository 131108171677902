import React from 'react';
import ReactPaginate from 'react-paginate';

import classes from './styles.module.scss';

interface PaginationProps {
  total: number;
  currentPage: number;
  perPage: number;
  className?: string;
  onChange?: (page: number) => void;
}

export default function Pagination({
  className = '',
  total,
  currentPage,
  onChange = () => {},
  perPage,
}: PaginationProps) {
  const pageCount = Math.ceil(total / perPage);

  return pageCount ? (
    <ReactPaginate
      pageCount={pageCount}
      initialPage={currentPage - 1}
      disableInitialCallback={true}
      forcePage={currentPage - 1}
      onPageChange={({ selected }) => onChange(selected + 1)}
      containerClassName={[classes.paging, className].join(' ')}
      pageClassName={classes.item}
      activeClassName={classes.activeItem}
      previousLabel={'<'}
      nextLabel={'>'}
      marginPagesDisplayed={1}
      pageRangeDisplayed={4}
    />
  ) : null;
}
