import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '@/app/store'
import { RootState } from '@/app/rootReducer'

export type ScheduleItemsState = {
  scheduleItemsById: Record<number, ScheduleItem>
}

export type ScheduleItem = {
  id: number
  schedule_id: number
  module_id: number
  unit_id: number
  switch_id: number
}

export const initialScheduleItemsState: ScheduleItemsState = {
  scheduleItemsById: {}
}

const scheduleItemItemsSlice = createSlice({
  name: 'scheduleItemItems',
  initialState: initialScheduleItemsState,
  reducers: {
    scheduleItemItemGetsSucces(state, action: PayloadAction<ScheduleItem[]>) {
      action.payload.forEach(commsScheduleItem => {
        state.scheduleItemsById[commsScheduleItem.id] = commsScheduleItem
      })
    },
    scheduleItemDeletesByScheduleIdSucces(
      state,
      { payload: scheduleId }: PayloadAction<number>
    ) {
      Object.values(state.scheduleItemsById).forEach(item => {
        if (item.schedule_id === scheduleId) {
          delete state.scheduleItemsById[item.id]
        }
      })
    },
    scheduleItemsDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach(id => {
        delete state.scheduleItemsById[id]
      })
    }
  }
})

export const {
  scheduleItemItemGetsSucces,
  scheduleItemDeletesByScheduleIdSucces,
  scheduleItemsDeleteById
} = scheduleItemItemsSlice.actions

export default scheduleItemItemsSlice.reducer

export const scheduleItemsDelete = (
  dispatch: AppDispatch,
  rootState: RootState,
  scheduleIdList: number[]
) => {
  dispatch(scheduleItemsDeleteById(scheduleIdList))
}
