import { useCallback, useMemo, useRef } from 'react';

enum DrawType {
  UP,
  DOWN,
}

interface UseAnchor {
  duration?: number;
  finishedSleep?: number;
  targetPosition?: number;
}

export function useAnchor({
  targetPosition,
  duration = 200,
  finishedSleep = 300,
}: UseAnchor = {}) {
  const ref = useRef<any>();
  const refOwn = useRef<any>();

  const draw = useCallback(async (): Promise<void> => {
    const own = refOwn.current! as any;
    const element = ref.current! as HTMLElement;
    const defaultPos = (own as any).scrollY || own.scrollTop;
    const target = targetPosition == null ? element.offsetTop : targetPosition;
    const step =
      defaultPos !== target ? Math.abs(defaultPos - target) / duration : 0;
    const deltaTime = 10;

    const drawType = defaultPos > target ? DrawType.UP : DrawType.DOWN;

    return new Promise((res) => {
      const preventEndlessScroll = setTimeout(res, duration + 200);

      function handleFinished() {
        clearTimeout(preventEndlessScroll);
        setTimeout(res, finishedSleep); // SLEEP .3 second before trigger finished
      }

      function run(y: number) {
        if (drawType === DrawType.DOWN) {
          if (y > target) {
            y = target;
          }
          setTimeout(() => {
            own.scrollTo(0, y);
            if (y === target) {
              handleFinished();
              return;
            }
            if (y < target) {
              run(y + step * deltaTime);
            }
          }, deltaTime);
        } else {
          if (y < target) {
            y = target;
          }
          setTimeout(() => {
            own.scrollTo(0, y);
            if (y === target) {
              handleFinished();
              return;
            }
            if (y > target) {
              run(y - step * deltaTime);
            }
          }, deltaTime);
        }
      }

      run(defaultPos);
    });
  }, [duration, finishedSleep, targetPosition]);

  return {
    ownRegister: refOwn,
    anchorRegister: ref,
    drawToAnchor: draw,
  };
}

export type AnchorConfigs = ReturnType<typeof useAnchor>;

export function useDropdownBackList() {
  return useMemo(() => {
    const emails = (process.env.REACT_APP_DROPDOWN_BLACKLIST || '')
      .split(',')
      .map((email) => email.trim())
      .filter(Boolean);

    return emails.reduce((hs: Record<string, boolean>, email) => {
      hs[email] = true;
      return hs;
    }, {});
  }, []);
}
