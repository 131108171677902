import { AppThunk } from '@/app/store';
import {
  moduleOnlyGetsSucces,
  moduleSetPagingOptions,
  clearModules,
  moduleDeleteSuccess,
  switchesByModuleId,
  moduleDetachSwitchById,
} from '@/slicers/modules';
import { companySetAttachSwitchesModule } from '@/slicers/companySwitches';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';
import { sleep } from '@/utils/sleep';
import { Switch } from '@/slicers/switches';

export const readSwitchWithToSwitchWithMap = (
  readSwitchWith: Record<string, any>
): Switch => {
  const { ...other } = readSwitchWith;
  let switchWith: Switch = { ...other } as any;
  return switchWith;
};

@MainApiCaller('/modules')
class ModulesRequest {
  caller!: TApiCaller;
  index() {
    return {
      request:
        (page?: number, keyword?: string): AppThunk =>
        async (dispatch) => {
          const query = {
            page: page || 1,
            search: keyword,
          };
          if (query.page === 1) {
            dispatch(clearModules());
          }

          const response = await this.caller.GET('/', query);

          if (response.status === 200) {
            const { data, total, per_page } = response.data;
            dispatch(clearModules());
            dispatch(
              moduleSetPagingOptions({
                total,
                perPage: per_page,
              })
            );
            dispatch(moduleOnlyGetsSucces(data));
            dispatch(setResponse(parseAxiosResponse(response)));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
          }
        },
    };
  }

  delete(moduleId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.DEL(`/${moduleId}`);

        if (response.status === 200) {
          dispatch(moduleDeleteSuccess(+moduleId));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  downloadKwhsCSV(moduleId: number) {
    return {
      request: async (meterId: number, year: number, month: number) => {
        const {
          data,
          headers: { 'content-disposition': fileInfo },
        } = await this.caller.GET(
          `/${moduleId}/download_power_meter_data?meter_id=${meterId}&year=${year}&month=${month}`,
          undefined,
          {
            responseType: 'blob',
          }
        );
        return {
          data,
          filename: fileInfo.split('=')[1],
        } as {
          data: Blob;
          filename: string;
        };
      },
    };
  }

  downloadCarbonsCSV(moduleId: number) {
    return {
      request: async (meterId: number, year: number, month: number) => {
        const {
          data,
          headers: { 'content-disposition': fileInfo },
        } = await this.caller.GET(
          `/${moduleId}/download_carbon_reduction_data?meter_id=${meterId}&year=${year}&month=${month}`,
          undefined,
          {
            responseType: 'blob',
          }
        );
        return {
          data,
          filename: fileInfo.split('=')[1],
        } as {
          data: Blob;
          filename: string;
        };
      },
    };
  }

  getCarbons(moduleId: number) {
    return {
      request:
        (year: number): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.GET(
            `/${moduleId}/carbon_reduction_data?year=${year}`
          );
          if (response.status === 200) {
            dispatch(setResponse(parseAxiosResponse(response)));
            return response.data;
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
            return [];
          }
        },
    };
  }

  getKwhs(moduleId: number) {
    return {
      request:
        (year: number): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.GET(
            `/${moduleId}/power_meter_data?year=${year}`
          );
          if (response.status === 200) {
            dispatch(setResponse(parseAxiosResponse(response)));
            return response.data;
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
            return { requestYear: [], prevYear: [] };
          }
        },
    };
  }

  getKwhsByDay(moduleId: number) {
    return {
      request:
        (year: number, month: number): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.GET(
            `/${moduleId}/power_meter_data/day?year=${year}&month=${month}`
          );
          
          if (response.status === 200) {
            dispatch(setResponse(parseAxiosResponse(response)));
            return response.data;
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
            return { requestYear: [], prevYear: [] };
          }
        },
    };
  }

  getKwhsByTime(moduleId: number) {
    return {
      request:
        (year: number, month: number, day: number): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.GET(
            `/${moduleId}/power_meter_data/time?year=${year}&month=${month}&day=${day}`
          );
          
          if (response.status === 200) {
            dispatch(setResponse(parseAxiosResponse(response)));
            return response.data;
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
            return { requestYear: [], prevYear: [] };
          }
        },
    };
  }

  getPowerMeterTargets(moduleId: number) {
    return {
      request:
        (year: number): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.GET(
            `/${moduleId}/power_meter_targets?year=${year}`
          );

          if (response.status === 200) {
            dispatch(setResponse(parseAxiosResponse(response)));
            return response.data;
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
            return [];
          }
        },
    };
  }

  savePowerMeterTargets(moduleId: number) {
    return {
      request:
        (year: number, monthValues: number[]): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(
            `/${moduleId}/power_meter_targets`,
            {
              year,
              month_values: monthValues,
            }
          );
          if (response.status === 200) {
            dispatch(setResponse(parseAxiosResponse(response)));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
          }
        },
    };
  }

  getSwitchesByModuleId(moduleId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/${moduleId}/switches`);

        if (response.status === 200) {
          dispatch(
            switchesByModuleId(
              response.data.map((readSwitchWith: any) =>
                readSwitchWithToSwitchWithMap(readSwitchWith)
              )
            )
          );
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  attachSwitchesToModule(moduleId: string, company_id: number) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(
            `/${moduleId}/switches`,
            data
          );

          if (response.status === 200) {
            dispatch(
              companySetAttachSwitchesModule({
                switchId: data.switch_id,
                companyId: company_id,
              })
            );
            dispatch(
              switchesByModuleId(
                response.data.map((readSwitchWith: any) =>
                  readSwitchWithToSwitchWithMap(readSwitchWith)
                )
              )
            );
            await sleep(200);
            dispatch(setFlashSuccess('追加しました'));
            dispatch(setResponse(parseAxiosResponse(response)));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('取得が失敗しました。'));
          }
        },
    };
  }

  detachSwitchesModule(moduleId: number, switch_id: number) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.DEL(
          `/${moduleId}/switches/${switch_id}`
        );
        dispatch(setResponse(parseAxiosResponse(response)));
        if (response.status === 200) {
          await sleep(200);
          dispatch(setFlashSuccess('削除しました'));
          dispatch(moduleDetachSwitchById([Number(switch_id)]));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }
}

const modulesRequest = new ModulesRequest();
export default modulesRequest;
