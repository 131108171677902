import { Tooltip } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';

import classes from './styles.module.scss';

interface InputProps {
  tooltip?: React.ReactNode;
  prefix?: React.ReactElement | string | null | boolean;
  readonly?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  autoFill?: boolean;
  label?: string;
  name?: string;
  defaultValue?: string;
  value?: string;
  required?: boolean;
  error?: string;
  isNumber?: boolean;
  onFocus?: () => void;
  labelType?: 'float' | 'block';
  maxLength?: number;
}

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const Input = React.forwardRef<HTMLButtonElement, InputProps>(
  (
    {
      maxLength,
      autoFill = true,
      tooltip,
      labelType = 'float',
      prefix,
      onFocus,
      readonly,
      placeholder = '',
      onChange = () => {},
      isNumber,
      label,
      name,
      value = '',
      defaultValue = '',
      required,
      error,
    },
    ref
  ) => {
    const domProps: Record<string, any> = isNumber
      ? {
          type: 'number',
          pattern: 'd*',
        }
      : {};

    if (!autoFill) {
      domProps.autoComplete = 'off';
    }

    if (readonly) {
      domProps.value = value || defaultValue;
    } else {
      domProps.defaultValue = defaultValue;
    }

    return (
      <div
        className={[
          classes.input,
          error ? classes.error : '',
          classes[`label-${labelType}`],
          readonly ? classes.readOnly : '',
        ].join(' ')}
      >
        <label>
          {label && <span className={classes.label}>{label}</span>}
          {tooltip && (
            <HtmlTooltip
              placement="top"
              className={classes.tooltip}
              title={tooltip}
            >
              <HelpIcon />
            </HtmlTooltip>
          )}

          <div className={classes.prefixWrap}>
            {prefix && <div className={classes.prefix}>{prefix}</div>}
            <input
              ref={ref as any}
              onFocus={onFocus}
              readOnly={readonly}
              name={name}
              placeholder={placeholder}
              required={required}
              onChange={e => onChange(e.target.value)}
              maxLength={maxLength}
              {...domProps}
            />
          </div>
        </label>
        <span className={classes.errorMessage}>{error}</span>
      </div>
    );
  }
);

export default Input;
