import React from 'react'
import { CircularProgress, Typography, Box } from '@material-ui/core'

type LoadingProps = {
  visible: boolean
  text?: string
}

const style = (visible: boolean) => ({
  root: {
    opacity: visible ? 1 : 0
  }
})

const Loading: React.FC<LoadingProps> = ({ visible, text = 'Loading...' }) => (
  <Box display="flex" alignItems="center" style={style(visible).root}>
    <Box mr={'.5rem'}>
      <CircularProgress size="1rem" color="primary" />
    </Box>
    <Typography>{text}</Typography>
  </Box>
)

export default Loading
