import { RootState } from '@/app/rootReducer';
import { createSelector } from 'reselect';

export const selectorFindAllSwitchesByCompanyId = (companyId: number) =>
  createSelector(
    (state: RootState) => state.companySwitchesState.switchesById,
    (switchesById) =>
      Object.values(switchesById).filter(
        (item) => item.company_id === companyId
      )
  );

export const selectorUnAttachSwitchesByCompanyId = (companyId: number) =>
  createSelector(
    (state: RootState) => state.companySwitchesState.unAttachSwitches,
    (state: RootState) => state.companySwitchesState.switchesById,
    (unAttachSwitches, switchesById) =>
      (unAttachSwitches[companyId] || []).filter((swc) => !switchesById[swc.id])
  );

export const selectorUnAttachSwitchesByModuleId = (companyId: number) =>
  createSelector(
    (state: RootState) => state.companySwitchesState.unAttachSwitchesModule,
    (state: RootState) => state.modulesState.switchesById,
    (unAttachSwitchesModule, switchesById) =>
      (unAttachSwitchesModule[companyId] || []).filter(
        (swc) => !switchesById[swc.id]
      )
  );
