import React from 'react'
import MaterialButton, {
  ButtonProps as MaterialButtonProps
} from '@material-ui/core/Button'
import {
  Theme,
  makeStyles,
  createStyles
} from '@material-ui/core/styles'
import styles from './styles.module.scss'
import clsx from 'clsx'

export type PaletteColorProps =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success'

export type ColorProps = 'grey'

export type CustomButtonProps = {
  color: PaletteColorProps | ColorProps
  component?: React.ReactNode
  to?: string
} & Omit<MaterialButtonProps, 'color'>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white'
    }
  })
)

export const CustomButton: React.FC<CustomButtonProps> = ({
  color,
  variant = 'contained',
  ...props
}) => {
  const classes = useStyles()

  let className =
    color === 'grey'
      ? ''
      : clsx(
          classes.root,
          styles[color],
          props.disabled && [styles.disabled, styles[color + '_disabled']]
        )
  const { className: defaultClassName, ...otherProps } = props
  className = clsx(className, defaultClassName)

  return color === 'grey' ? (
    <MaterialButton color="default" variant={variant} {...props} />
  ) : (
    <MaterialButton
      className={className}
      variant="contained"
      color="primary"
      {...otherProps}
    />
  )
}
