import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { findModuleWithCamerasSelectorFactory } from '@/selector/module';
import { getLoginUserAndCompanySelector } from '@/selector/users';

import { RootState } from '@/app/rootReducer';
import { ModulesRouteParams } from '@/routes/ClientRoute';
import companiesModulesRequest from '@/api/companies_modules';
import { ModuleChannelType } from '@/slicers/modules';

import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

import B16ChannelSettings from '../components/B16ChannelSettings';
import B1ChannelSettings, {
  B1CommSocketWrap,
} from '../components/B1ChannelSettings';
import B64ChannelSettings from '../components/B64ChannelSettings';
import { CommSocketProvider } from '../components/CommSocketProvider';

const CompaniesModulesShow: React.FC = () => {
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();

  const { module: moduleId } = useParams<ModulesRouteParams>();

  const dispatch = useDispatch();
  const { commModule, loginUser } = useSelector(
    (state: RootState) => ({
      commModule: findModuleWithCamerasSelectorFactory(state)(Number(moduleId)),
      loginUser: getLoginUserAndCompanySelector(state).loginUser,
    }),
    shallowEqual
  );

  const loginCompanyId = loginUser!.company_id;

  const getModule = useCallback(async () => {
    dispatch(
      companiesModulesRequest.get(loginCompanyId.toString(), moduleId).request()
    );
  }, [dispatch, moduleId, loginCompanyId]);

  useEffect(() => {
    getModule();
  }, [getModule]);

  const settingsKey = useMemo(() => {
    return `${new Date().getTime()}-${Math.random()}`;
    // need 'commModule' to re-generate key
    // eslint-disable-next-line
  }, [commModule]);

  if (!commModule || !loginUser) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {overlayLoader}
      {commModule.type === ModuleChannelType.B1Channel ? (
        <B1CommSocketWrap
          loginUser={loginUser}
          render={({ events, isOk }) => (
            <B1ChannelSettings
              key={settingsKey}
              events={events}
              isOk={isOk}
              commModule={commModule}
              loginUser={loginUser}
              showOverlay={showOverlay}
              hideOverlay={hideOverlay}
            />
          )}
        />
      ) : commModule.type === ModuleChannelType.B64Channel ? (
        <CommSocketProvider
          loginUser={loginUser}
          render={({ events, isOk }) => (
            <B64ChannelSettings
              events={events}
              isOk={isOk}
              commModule={commModule}
              loginUser={loginUser}
              showOverlay={showOverlay}
              hideOverlay={hideOverlay}
            />
          )}
        />
      ) : (
        <B16ChannelSettings
          commModule={commModule}
          loginUser={loginUser}
          showOverlay={showOverlay}
          hideOverlay={hideOverlay}
        />
      )}
    </>
  );
};

export default CompaniesModulesShow;
