import AgentCompaniesModulesList from '@/components/ecosystem/AgentCompaniesModules/List2';
import AgentCompaniesModulesBreadCrumb from '@/components/ecosystem/AgentCompaniesModules/BreadCrumb';
import AgentCompaniesModulesBreadCrumbList from '@/components/ecosystem/AgentCompaniesModules/List2/BreadCrumb';
import AgentCompaniesBreadCrumbResource from '@/components/ecosystem/AgentCompanies/BreadCrumbResource';
import AgentCompaniesNormalCompaniesList from '@/components/ecosystem/AgentCompaniesNormalCompanies/List';
import AgentCompaniesNormalCompaniesBreadCrumbList from '@/components/ecosystem/AgentCompaniesNormalCompanies/List/BreadCrumb';
import AgentCompaniesGeneralManagersList from '@/components/ecosystem/AgentCompaniesGeneralManagers/List';
import AgentCompaniesGeneralManagerUsersBreadCrumb from '@/components/ecosystem/AgentCompaniesGeneralManagers/BreadCrumb';
import AgentCompaniesGeneralManagerUsersBreadCrumbList from '@/components/ecosystem/AgentCompaniesGeneralManagers/List/BreadCrumb';
import AgentCompaniesBreadCrumb from '@/components/ecosystem/AgentCompanies/BreadCrumb';
import AgentCompaniesList from '@/components/ecosystem/AgentCompanies/List';
import AgentCompaniesBreadCrumbList from '@/components/ecosystem/AgentCompanies/List/BreadCrumb';

export type AgentCompaniesRouteProps = {
  agent_company: string;
};

export type AgentCompaniesNormalCompaniesRouteProps = {
  agent_company: string;
  company: string;
};

export type AgentCompaniesNormalCompaniesModulesRouteProps = {
  agent_company: string;
  company: string;
  module: string;
};

export type AgentCompaniesNormalCompaniesGeneralManagersRouteProps = {
  agent_company: string;
  company: string;
  general_manager_user: string;
};

export type AgentCompaniesGeneralManagersRouteProps = {
  agent_company: string;
  general_manager_user: string;
};

export type AgentCompaniesUsersRouteProps = {
  user: string;
};

export const agentRoute = {
  url: '/agent_companies',
  childMaps: [
    {
      url: '/list',
      component: AgentCompaniesList,
      breadCrumbs: [AgentCompaniesBreadCrumb, AgentCompaniesBreadCrumbList],
    },
    {
      url: '/:agent_company',
      childMaps: [
        {
          url: '/normal_companies',
          breadCrumbs: [AgentCompaniesBreadCrumbResource],
          childMaps: [
            {
              url: '/list',
              component: AgentCompaniesNormalCompaniesList,
              breadCrumbs: [AgentCompaniesNormalCompaniesBreadCrumbList],
            },
          ],
        },
        {
          url: '/modules',
          breadCrumbs: [AgentCompaniesModulesBreadCrumb],
          childMaps: [
            {
              url: '/list',
              component: AgentCompaniesModulesList,
              breadCrumbs: [AgentCompaniesModulesBreadCrumbList],
            },
          ],
        },
        {
          url: '/general_managers',
          breadCrumbs: [AgentCompaniesGeneralManagerUsersBreadCrumb],
          childMaps: [
            {
              url: '/list',
              component: AgentCompaniesGeneralManagersList,
              breadCrumbs: [AgentCompaniesGeneralManagerUsersBreadCrumbList],
            },
          ],
        },
      ],
    },
  ],
};
