import React, { useState } from 'react';
import { route } from '@/routes/url-generator';

import classes from './styles.module.scss';
import { CustomButton } from '@/components/atoms/CustomButton';

import Drawer from '@/components/atoms/Drawer';
import ConfirmDelete from '@/components/atoms/ConfirmDelete';
import AgentCompanyForm from '@/components/organisms/AgentCompanyForm';
import ForwardLogin from '@/components/organisms/ForwardLogin';
import { UserTypeGeneralManager } from '@/slicers/users';
import ErrorMessage from '../../ErrorMessage';
import { ErrorPosition } from '@/slicers/response';

interface DetailProps {
  onSubmit: (
    closeDrawer: Function
  ) => (e: React.FormEvent<HTMLFormElement>) => void;
  errors: Record<string, string>;
  initialData: Record<string, any>;
  onConfirmDelete: (id: string) => void;
}

export default function Detail({
  errors,
  onSubmit,
  initialData,
  onConfirmDelete,
}: DetailProps) {
  const [isOpenEdit, setOpenEdit] = useState(false);

  return (
    <div className={classes.detail}>
      <ErrorMessage position={ErrorPosition.DRAWR} />
      <AgentCompanyForm viewMode initialData={initialData} readonly>
        <div />
        <div className={classes.buttons}>
          <ConfirmDelete
            message="企業を削除すると現場データ、ユーザーすべてが削除されます。本当に削除してもよろしいですか？"
            onConfirm={() => onConfirmDelete(initialData.id)}
          >
            <CustomButton color="error" className={classes.deleteButton}>
              削除
            </CustomButton>
          </ConfirmDelete>

          <CustomButton
            onClick={() => setOpenEdit(true)}
            color="primary"
            className={classes.submitButton}
          >
            編集
          </CustomButton>
          <ForwardLogin
            type={UserTypeGeneralManager}
            companyId={initialData.id}
            to={route.clientRoute.sites.list(initialData.id)}
          >
            <CustomButton color="primary" className={classes.loginButton}>
              ログイン
            </CustomButton>
          </ForwardLogin>
        </div>
      </AgentCompanyForm>
      <Drawer isOpen={isOpenEdit} onChange={setOpenEdit}>
        {initialData && (
          <AgentCompanyForm
            initialData={initialData}
            errors={errors}
            onSubmit={onSubmit(() => setOpenEdit(false))}
            onCancel={() => setOpenEdit(false)}
          />
        )}
      </Drawer>
    </div>
  );
}
