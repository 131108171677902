import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Switch } from '../switches';

export type SwitchesState = {
  switchesById: Record<number, Switch>;
};

export const initialState: SwitchesState = {
  switchesById: {},
};

const agentSwitchesSlice = createSlice({
  name: 'agentSwitches',
  initialState,
  reducers: {
    agentSwitchGetsSucces(state, action: PayloadAction<Switch[]>) {
      action.payload.forEach((commsModule) => {
        state.switchesById[commsModule.id] = commsModule;
      });
    },
    agentSwitchGetSuccess(state, action: PayloadAction<Switch>) {
      const switchItem = action.payload;
      state.switchesById[switchItem.id] = switchItem;
    },
    agentSwitchesDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach((id) => {
        delete state.switchesById[id];
      });
    },
  },
});

export const {
  agentSwitchGetsSucces,
  agentSwitchGetSuccess,
  agentSwitchesDeleteById,
} = agentSwitchesSlice.actions;

export default agentSwitchesSlice.reducer;
