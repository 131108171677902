import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { setResponse } from '@/slicers/response';
import { setFlashError } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/emission_factors')
class EmissionFactorsRequest {
  caller!: TApiCaller;
  index() {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET('/');

        if (response.status === 200) {
          dispatch(setResponse(parseAxiosResponse(response)));
          return response.data;
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました。'));
          return [];
        }
      },
    };
  }
}

const emissionFactorsRequest = new EmissionFactorsRequest();
export default emissionFactorsRequest;
