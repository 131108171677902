import React from 'react'
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem'
 

const CompaniesSitesListBreadCrumb: React.FC = () => {
  return (
    <React.Fragment>
       
      <BreadCrumbItem title="一覧" />
    </React.Fragment>
  )
}

export default CompaniesSitesListBreadCrumb
