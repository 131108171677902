import { createSelector } from 'reselect';
import { RootState } from '@/app/rootReducer';
import { User } from '@/slicers/users';
import memoize from 'lodash.memoize';

export const selectorFindUsersBySiteId = createSelector(
  (state: RootState) => state.siteUsersState.siteUsersById,
  (siteUsersById: Record<number, User>) =>
    memoize((siteId: number, type?: number) => {
      const users = type
        ? Object.values(siteUsersById).filter(
            user => user.site_id === siteId && user.type === type
          )
        : Object.values(siteUsersById).filter(user => user.site_id === siteId);
      return users;
    })
);
