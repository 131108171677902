import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { getLoginUserAndCompanySelector } from '@/selector/users';
import usersRequest from '@/api/users';
import UserChangePasswordForm, {
  FormData,
} from '@/components/organisms/UserChangePasswordForm';
import { useRedirectIfExpectResponse } from '@/hooks';
import { ApiHeader, initResponse } from '@/slicers/response';

const LoginAccountUserChangePassword: React.FC = () => {
  const { loginUser: user } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  );
  const dispatch = useDispatch();
  const [increment, setIncrement] = useState(1);

  const api = usersRequest.changePassword(user?.id.toString() || '');

  const handleSubmit = async (data: FormData) => {
    if (!user) return;
    dispatch(api.request(data));
  };

  useRedirectIfExpectResponse({
    ...({
      url: `/api/users/${user?.id}/change_password`,
      method: 'put',
      status: 200,
    } as ApiHeader),
    action: () => {
      setIncrement(increment + 1);
      dispatch(initResponse());
    },
  });

  return user ? (
    <UserChangePasswordForm onSubmit={handleSubmit} increment={increment} />
  ) : null;
};

export default LoginAccountUserChangePassword;
