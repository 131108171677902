import { createSelector } from 'reselect';
import { RootState } from '@/app/rootReducer';
import { CompanyTypeNormal, CompanyTypeAgent } from '@/slicers/companies';
import moment from 'moment';

export const getNormalCompaniesWithAgent = createSelector(
  (state: RootState) => state.companiesState.companiesById,
  companiesById => {
    const normalCompanies = Object.values(companiesById)
      .filter(company => company.type === CompanyTypeNormal)
      .map(company => ({
        ...company,
        agent: company.parent_company,
      }));
    return normalCompanies;
  }
);

export const getAgentCompaniesSelector = createSelector(
  (state: RootState) => state.companiesState.companiesById,
  companiesById => {
    const agentCompanies = Object.values(companiesById).filter(
      company => company.type === CompanyTypeAgent
    );
    agentCompanies.sort((a, b) => {
      return (
        moment(b.created_at, 'YYYY-MM-DD HH:mm:ss').unix() -
        moment(a.created_at, 'YYYY-MM-DD HH:mm:ss').unix()
      );
    });
    return agentCompanies;
  }
);
