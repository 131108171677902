import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';

const SitesModulesBreadCrumbList: React.FC = () => {
  return (
    <BreadCrumbs>
      <BreadCrumbItem title="指令盤一覧" />
    </BreadCrumbs>
  );
};

export default SitesModulesBreadCrumbList;
