import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FlashState } from '@/slicers/flash'

const FlashMessage: React.FC<FlashState> = ({ type, message }) => {
  const [open, setOpen] = React.useState(true)

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
    >
      <Alert onClose={() => setOpen(false)} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default FlashMessage
