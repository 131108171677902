import React from 'react';
import { Button, Menu, MenuItem, Avatar, Box } from '@material-ui/core';
import styles from './styles.module.scss';
import { RootState } from '@/app/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { Person } from '@material-ui/icons';
import { getLoginUserAndCompanySelector } from '@/selector/users';
import authRequest from '@/api/auth';
import { useHistory } from 'react-router-dom';
import { route } from '@/routes/url-generator';
import { useDropdownBackList } from '@/utils/hook';

export type UserIconMenuProps = {
  overrideIconNameClass?: string;
};

export default function UserIconMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { loginUser, loginUserCompany } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  );

  const dropdownBackList = useDropdownBackList();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCompanyProfile = () => {
    handleClose();
    if (!loginUserCompany) return;

    history.push(route.loginAccount.company.show);
  };

  const handleClickProfile = () => {
    handleClose();
    if (!loginUser) return;

    history.push(route.loginAccount.user.show);
  };

  const handleClickChangePassword = () => {
    handleClose();
    if (!loginUser) return;

    history.push(route.loginAccount.user.changePassword);
  };

  const handleClickLogout = async () => {
    await dispatch(authRequest.logout().request());
    window.location.href = route.login;
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Box display="flex" alignItems="center">
          <Avatar className={styles.avatar}>
            <Person />
          </Avatar>
        </Box>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={styles.menu_container}
        // style={{ margin: '2rem' }}
      >
        {!dropdownBackList[loginUser?.email!] && [
          <MenuItem key={1} onClick={handleClickCompanyProfile}>
            会社プロフィール
          </MenuItem>,
          <MenuItem key={2} onClick={handleClickProfile}>
            プロフィール
          </MenuItem>,
          <MenuItem key={3} onClick={handleClickChangePassword}>
            パスワード変更
          </MenuItem>,
        ]}
        <MenuItem onClick={handleClickLogout}>ログアウト</MenuItem>
      </Menu>
    </div>
  );
}
