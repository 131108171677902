import React, { useMemo, useCallback } from 'react';
import { loginDequeue } from '@/slicers/login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '@/app/rootReducer';

interface BackwardLoginProps {
  children: React.ReactElement;
}

export default function BackwardLogin({ children }: BackwardLoginProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const forwardQueue = useSelector(
    (state: RootState) => state.loginState.forwardQueue
  );

  const onBackward = useCallback(() => {
    const lastLayer = forwardQueue[forwardQueue.length - 1];
    dispatch(loginDequeue());
    history.push(lastLayer.prevPath);
  }, [dispatch, history, forwardQueue]);

  const trigger = useMemo(() => {
    return React.cloneElement(
      children,
      { onClick: onBackward },
      children.props.children
    );
  }, [children, onBackward]);

  return trigger;
}
