import React, { useState, useEffect, useMemo } from 'react';
import MaterialTable, {
  MaterialTableProps,
  Column,
  MTableToolbar,
} from 'material-table';
import {
  Checkbox,
  FormControl,
  FormGroup,
  Box,
  FormControlLabel,
  Accordion,
  AccordionDetails,
  Paper,
} from '@material-ui/core';

import { createTheme, ThemeProvider, Theme } from '@material-ui/core/styles';
import styles, {
  useExpansionPanelDetailsStyles,
  useExpansionPannelStyles,
} from './styles';
import { tableIcons } from './icons';
import SearchBar from '@/components/atoms/SearchBar';

type Visibility = {
  title: string;
  visibility: boolean;
  field: string;
};

type VisibilityByFieldName = Record<string, Visibility>;

export const defaultTheme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        background: '#01579b !important',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: 'white !important',
        '&:hover': {
          color: 'white',
        },
      },
      icon: {
        color: 'white !important',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiTextField: {
      root: {
        marginRight: '3rem',
      },
    },
  },
});

const createVisibilityByFilterName = <RowData extends {}>(
  columns: CustomColumn<RowData>[]
): VisibilityByFieldName => {
  let visibilityByFieldName: VisibilityByFieldName = {};
  columns.forEach((column) => {
    if (
      column.hidden !== true &&
      typeof column.field === 'string' &&
      typeof column.title === 'string'
    ) {
      visibilityByFieldName[column.field] = {
        visibility: column.defaultVisibility ?? true,
        title: column.title,
        field: column.field,
      };
    }
  });
  return visibilityByFieldName;
};

type CustomColumn<RowData extends {}> = Column<RowData> & {
  defaultVisibility?: boolean;
};

type CustomMaterialTableProps<RowData extends {}> = {
  onSearchChange?: (kw: string) => void;
  fieldSearch?: string[];
  searchPlaceholder?: string;
  columns: CustomColumn<RowData>[];
  visibilitySetting?: boolean;
  addButton?: React.ReactElement;
  theme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
} & Omit<MaterialTableProps<RowData>, 'columns' | 'onSearchChange'>;

/**
 * MaterialTableに以下の機能を追加
 * ・カラムの表示非表示機能
 */
const CustomMaterialTable = <RowData extends {}>({
  onSearchChange,
  fieldSearch = ['name'],
  searchPlaceholder,
  columns: _columns,
  addButton,
  visibilitySetting = false,
  theme: customTheme = (theme: Theme) => theme,
  data,
  ...props
}: CustomMaterialTableProps<RowData>) => {
  const [visibilityByFieldName, setVisibilityByFilterName] =
    useState<VisibilityByFieldName>(createVisibilityByFilterName(_columns));

  const [expanded] = useState(false);
  const [searchKw, setSearchKw] = useState('');

  const classes = styles();
  const expansionPannelClasses = useExpansionPannelStyles(expanded)();
  const expansionPanelDetailsClasses = useExpansionPanelDetailsStyles();

  const [columns, setColumns] = useState<Column<RowData>[]>([]);

  const changeVisibility = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let state: Record<string, Visibility> = JSON.parse(
      JSON.stringify(visibilityByFieldName)
    );
    state[field].visibility = event.currentTarget.checked;
    setVisibilityByFilterName(state);
  };

  useEffect(() => {
    onSearchChange && onSearchChange(searchKw);
    // eslint-disable-next-line
  }, [searchKw]);

  useEffect(() => {
    setColumns(() => {
      const columns = _columns.map((column) => {
        if (typeof column.field === 'string') {
          const visibilityObject = visibilityByFieldName[column.field];
          if (visibilityObject) {
            column.hidden = !visibilityObject.visibility;
          }
        }
        return column;
      });
      return columns;
    });
    // eslint-disable-next-line
  }, [visibilityByFieldName]);

  const dataSource: any = useMemo(() => {
    if (onSearchChange) {
      return data;
    }
    if (!data.length) {
      return [];
    }

    const kw = (searchKw || '').toLowerCase().trim();

    let filterData: any[] = [];
    fieldSearch.forEach((field) => {
      const split = field.split('.');
      const filter = (data as any[]).filter((item) => {
        if (split.length === 2) {
          return item[split[0]]?.[split[1]]?.toLowerCase().includes(kw);
        } else {
          return item[field]?.toLowerCase().includes(kw);
        }
      });
      filterData = [...filterData, ...filter];
    });

    return Array.from(new Set(filterData.map((item) => item.id))).map((id) =>
      filterData.find((data) => data.id === id)
    );
    // eslint-disable-next-line
  }, [JSON.stringify(data), searchKw]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <ThemeProvider theme={customTheme}>
        <MaterialTable
          {...props}
          data={dataSource}
          icons={tableIcons}
          columns={columns}
          localization={{
            body: {
              emptyDataSourceMessage: 'データがありません',
            },
          }}
          options={{
            search: false,
            paginationType: 'stepped',
            ...props.options,
          }}
          components={{
            ...props.components,
            Container:
              props.components?.Container ||
              ((props) => <div {...props} className={classes.container}></div>),
            Toolbar: (props) => (
              <div>
                <Box
                  display="flex"
                  // justifyContent="center"
                  alignItems="center"
                >
                  <Box flexGrow="1">
                    <MTableToolbar {...props} />
                  </Box>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar
                      searchPlaceholder={searchPlaceholder}
                      defaultValue={searchKw}
                      onSearch={setSearchKw}
                    />
                  </div>

                  {addButton && (
                    <Box marginLeft={2.5} minWidth="6rem">
                      {addButton}
                    </Box>
                  )}
                </Box>
                {visibilitySetting && (
                  <Box
                    mb={1}
                    boxShadow={0}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Accordion
                      expanded={expanded}
                      classes={expansionPannelClasses}
                    >
                      <div></div>
                      <AccordionDetails classes={expansionPanelDetailsClasses}>
                        <FormControl
                          component="fieldset"
                          style={{ width: '100%' }}
                        >
                          <FormGroup>
                            <Paper>
                              <Box
                                display="flex"
                                flexDirection="row"
                                px={1}
                                flexGrow={1}
                              >
                                {Object.values(visibilityByFieldName).map(
                                  (visibility) => (
                                    <div key={visibility.field}>
                                      <label style={{ marginRight: '1rem' }}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={visibility.visibility}
                                              onChange={(event) =>
                                                changeVisibility(
                                                  event,
                                                  visibility.field
                                                )
                                              }
                                            />
                                          }
                                          label={visibility.title}
                                        />
                                      </label>
                                    </div>
                                  )
                                )}
                              </Box>
                            </Paper>
                          </FormGroup>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )}
              </div>
            ),
          }}
        />
      </ThemeProvider>
    </ThemeProvider>
  );
};

export default CustomMaterialTable;
