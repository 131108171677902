import React, { useEffect, useState } from 'react';
import Axios from 'axios';

const WebSocketTest = () => {
  const [ws, setws] = useState<WebSocket>();
  const [connected, setConnected] = useState(false);
  const [receiveTestEvent, setReceiveTestEvent] = useState(false);
  const [receiveLoggingEvent, setReceiveLoggingEvent] = useState(false);

  const [, setNodeResponse] = useState(false);
  const [proxyNodeResponse, setProxyNodeResponse] = useState(false);

  useEffect(() => {
    const ws = new WebSocket(
      `ws://${process.env.REACT_APP_WEB_SOCKET_URL || 'localhost:3004'}/ws/`
    );

    ws.onopen = function () {
      console.log('connected');
      setConnected(true);
      setws(ws);

      ws.send(
        JSON.stringify({
          event: 'test',
        })
      );
    };

    ws.onmessage = function (data: any) {
      const _data = JSON.parse(data.data);
      if (_data.event === 'test') {
        console.log('receive test event');
        setReceiveTestEvent(true);
      } else if (_data.event === 'logging') {
        console.log('receive logging event');
        setReceiveLoggingEvent(true);
      }
    };

    const func = async () => {
      try {
        await Axios.get('http://node:3003/node/test');
        setNodeResponse(true);
      } catch (error) {}

      try {
        await Axios.get('/node/test');
        setProxyNodeResponse(true);
      } catch (error) {}
    };

    func();
  }, []);

  const handleLogButon = () => {
    if (!ws) return;
    ws.send(
      JSON.stringify({
        event: 'logging',
      })
    );
  };

  return (
    <div>
      <h3>nodeServerの疎通確認</h3>
      <p>node response: {proxyNodeResponse ? 'true' : 'false'}</p>
      <h3>socket接続の疎通確認</h3>
      <p>connected: {connected ? 'true' : 'false'}</p>
      <p>
        receive test event:
        {receiveTestEvent ? 'true' : 'false'}
      </p>
      <button onClick={handleLogButon}>send logging event</button>
      <p>
        receive logging event:
        {receiveLoggingEvent ? 'true' : 'false'}
      </p>
    </div>
  );
};

export default WebSocketTest;
