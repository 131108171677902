import React, { useCallback, useState } from 'react';
import VideocamIcon from '@material-ui/icons/Videocam';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import EmojiObjectsTwoToneIcon from '@material-ui/icons/EmojiObjectsTwoTone';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { CustomButton } from '@/components/atoms/CustomButton';
import Loading from '@/components/molecules/Loading';

import { Camera } from '@/slicers/cameras';
import { Site } from '@/slicers/sites';
import {
  ModuleWith,
  PowerSwitch,
  PowerSwitchState,
  PowerUnitState,
  SwitchingState,
} from '@/slicers/operation_page';

import CameraItem from '../CameraItem';

import initState from './images/init-state.svg';
import unknowState from './images/unknow-state.svg';
import lock_svg from './images/lock-solid.svg';

import classes from './switches.module.scss';

interface SwitchesProps {
  disabled?: boolean;
  isConnected: boolean;
  site: Site;
  onChangeSwitchesState: (
    deviceCode: string,
    unitNumber: number,
    state: PowerSwitchState.ON | PowerSwitchState.OFF,
    switchNumbers: number[]
  ) => void;
  mdl: ModuleWith & {
    cameras: Camera[];
  };
  handleSaveModuleName: (id: number, name: string) => void;
  connectStatusInMobile: React.ReactElement | null;
  isHeader: boolean;
  connectStatus: React.ReactElement;
  isDeviceConnected?: boolean;
}

const lockTime = 500; //連続スイッチングを防止する
const ValidState = [PowerSwitchState.ON, PowerSwitchState.OFF];

export default function Switches({
  connectStatusInMobile,
  disabled,
  isConnected,
  site,
  mdl,
  onChangeSwitchesState,
  handleSaveModuleName,
  connectStatus,
}: SwitchesProps) {
  const [isShowEditName, showEditName] = useState(false);
  const [editName, setEditName] = useState('');

  const [changedAt, setChangedAt] = useState(new Date().getTime());

  const handleClickGroupSwitch = async (
    unitNumber: number,
    state: PowerSwitchState.ON | PowerSwitchState.OFF
  ) => {
    if (disabled) return;

    const unit = mdl.unitsByNumber[unitNumber];

    const okSwitches: number[] = [];
    for (const swt of Object.values(unit.switchesByNumber)) {
      // Skip change state
      if (
        swt.isLock ||
        swt.switchingState === SwitchingState.SWITCHING ||
        !ValidState.includes(swt.state)
      )
        continue;

      const isOk = swt.requiredConfirmation
        ? window.confirm(`スイッチ番号${swt.name}を操作しますか?`)
        : true; // If there is no require confirm then it always ok to change state

      if (isOk) {
        okSwitches.push(swt.number);
      }

      // sleep tick before confirm next
      await new Promise((res) => setTimeout(res, 100));
    }

    const timestamp = new Date().getTime();
    if (timestamp - changedAt <= lockTime) return;

    setChangedAt(() => timestamp);

    if (okSwitches.length) {
      onChangeSwitchesState(mdl.deviceCode, unitNumber, state, okSwitches);
    }
  };

  const handleClickSwitchButton = (
    unitNumber: number,
    swt: PowerSwitch,
    nextState: PowerSwitchState.ON | PowerSwitchState.OFF
  ) => {
    if (
      disabled ||
      swt.isLock ||
      swt.switchingState === SwitchingState.SWITCHING ||
      !ValidState.includes(swt.state)
    )
      return;

    const isOk = swt.requiredConfirmation
      ? window.confirm('スイッチ操作をしますか？')
      : true; // If there is no require confirm then it always ok to change state

    if (!isOk) return;

    const timestamp = new Date().getTime();
    if (timestamp - changedAt <= lockTime) return;

    setChangedAt(() => timestamp);

    onChangeSwitchesState(mdl.deviceCode, unitNumber, nextState, [swt.number]);
  };

  const handleSaveNewName = useCallback(
    async (e) => {
      const newName = e.target.value;
      if (newName) {
        await handleSaveModuleName(mdl.id, newName);
      }
      showEditName(false);
      setEditName('');
    },
    [handleSaveModuleName, mdl.id]
  );

  const selectorGetPowerUnit = (unitNumber: number) =>
    mdl.unitsByNumber?.[unitNumber];

  return (
    <div>
      <div className={classes.module}>
        <div className={classes.left}>
          <div
            className={[
              classes.heading,
              !connectStatusInMobile ? classes.full : '',
            ].join(' ')}
          >
            <div className={classes.headingName}>
              {isShowEditName ? (
                <input
                  maxLength={20}
                  className={classes.blurInput}
                  defaultValue={editName?.substr(0, 20)}
                  onBlur={handleSaveNewName}
                  placeholder="指令盤の名..."
                />
              ) : (
                <>
                  <span>{mdl.name?.substr(0, 20)}</span>
                  <EditIcon
                    onClick={() => {
                      showEditName(true);
                      setEditName(mdl!.name);
                    }}
                    htmlColor="#fff"
                  />
                </>
              )}
            </div>
            {!!connectStatusInMobile && (
              <div className={classes.headingStatus}>
                {connectStatusInMobile}
              </div>
            )}
          </div>
          <div className={classes.label}>
            <div className={classes.labelA}>Aグループ</div>
            <div className={classes.labelB}>Bグループ</div>
            <div className={classes.labelC}>Cグループ</div>
            <div className={classes.labelD}>Dグループ</div>
          </div>
          <div className={classes.switches}>
            <div className={classes.switchesWrap}>
              {[4, 3, 2, 1].map((unitNumber, i) => {
                const powerUnit = selectorGetPowerUnit(unitNumber);

                if (!powerUnit) {
                  return (
                    <div key={i} className={classes.unit}>
                      {[0, 1, 2, 3].map((_, j) => {
                        return (
                          <div key={j} className={classes.oneSwitch}>
                            <div className={classes.switch}>
                              <div
                                className={[classes.name, classes.empty].join(
                                  ' '
                                )}
                              >
                                <span>Empty</span>
                              </div>
                              <div className={classes.box}>
                                <div />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }

                return (
                  <div key={i} className={classes.unit}>
                    {[0, 1, 2, 3].map((switchNumber, j) => {
                      const powerSwitch: PowerSwitch =
                        powerUnit.switchesByNumber[switchNumber]!;

                      if (!powerSwitch) {
                        return (
                          <div key={j} className={classes.oneSwitch}>
                            <div className={classes.switch}>
                              <div
                                className={[classes.name, classes.empty].join(
                                  ' '
                                )}
                              >
                                <span>Empty</span>
                              </div>
                              <div className={classes.box}>
                                <div />
                              </div>
                            </div>
                          </div>
                        );
                      }

                      const nextState =
                        powerSwitch.state === PowerSwitchState.ON
                          ? PowerSwitchState.OFF
                          : PowerSwitchState.ON;
                      return (
                        <div key={j} className={classes.oneSwitch}>
                          <div
                            onClick={() =>
                              handleClickSwitchButton(
                                powerUnit.number,
                                powerSwitch,
                                nextState
                              )
                            }
                            className={[
                              classes.switch,
                              isConnected ? classes.clickable : '',
                              powerSwitch.state === PowerSwitchState.ON
                                ? classes.on
                                : '',
                            ].join(' ')}
                          >
                            <div className={classes.name}>
                              <span>{powerSwitch.name}</span>
                            </div>
                            <div
                              className={[
                                classes.box,
                                classes.shape,
                                powerSwitch.switchingState ===
                                  SwitchingState.SWITCHING &&
                                powerSwitch.state !== PowerSwitchState.UNKNOWN
                                  ? classes.switching
                                  : '',
                              ].join(' ')}
                            >
                              {powerSwitch.isLock ? (
                                <img
                                  alt=""
                                  src={lock_svg}
                                  style={{ width: '50%' }}
                                />
                              ) : powerSwitch.state ===
                                PowerSwitchState.INIT ? (
                                <img alt="" src={initState} />
                              ) : powerSwitch.state ===
                                PowerSwitchState.UNKNOWN ? (
                                <img alt="" src={unknowState} />
                              ) : powerSwitch.icon === 'light' ? (
                                <EmojiObjectsTwoToneIcon
                                  fontSize="large"
                                  style={{ fontSize: '3rem' }}
                                />
                              ) : powerSwitch.icon === 'up' ? (
                                <ArrowDropUpIcon fontSize="large" />
                              ) : powerSwitch.icon === 'down' ? (
                                <ArrowDropDownIcon fontSize="large" />
                              ) : (
                                powerSwitch.icon === 'stop' && (
                                  <div className={classes.stopIcon} />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.footer}>グループー括操作</div>
          <div className={classes.staticSwitches}>
            <div className={classes.staticSwitchesWrap}>
              {[4, 3, 2, 1].map((unitNumber) => {
                const powerUnit = selectorGetPowerUnit(unitNumber);

                if (
                  !powerUnit ||
                  !Object.keys(powerUnit.switchesByNumber).length ||
                  !powerUnit.group_on_off_flg
                ) {
                  return (
                    <div key={unitNumber}>
                      <div className={classes.oneSwitch}>
                        <div className={classes.staticSwitch} />
                      </div>
                      <div className={classes.oneSwitch}>
                        <div className={classes.staticSwitch} />
                      </div>
                    </div>
                  );
                }

                const index = 4 - unitNumber + 1;
                const isReady =
                  powerUnit && powerUnit.state === PowerUnitState.READY;

                const switchClass = isReady
                  ? classes[`color${unitNumber}`]
                  : '';

                return (
                  <div key={unitNumber}>
                    <div className={classes.oneSwitch}>
                      <CustomButton
                        onClick={(event) =>
                          handleClickGroupSwitch(
                            powerUnit.number,
                            PowerSwitchState.ON
                          )
                        }
                        color="grey"
                        className={[
                          classes.staticSwitch,
                          classes.shadow,
                          switchClass,
                        ].join(' ')}
                      >
                        G{index}全ON
                      </CustomButton>
                    </div>
                    <div className={classes.oneSwitch}>
                      <CustomButton
                        onClick={() =>
                          handleClickGroupSwitch(
                            powerUnit.number,
                            PowerSwitchState.OFF
                          )
                        }
                        color="grey"
                        className={[
                          classes.staticSwitch,
                          classes.shadow,
                          switchClass,
                        ].join(' ')}
                      >
                        G{index}全OFF
                      </CustomButton>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.header}>
            <div className={classes.headInfo}>
              <div className={classes.siteName}>
                <span>{site?.name}</span>
              </div>
              {!connectStatusInMobile && connectStatus}
            </div>
          </div>
          <div className={classes.listHeading}>
            <div>
              <span>
                <VideocamIcon />
              </span>
            </div>
            <div className={classes.title}>カメラ確認</div>
            <div>
              <CheckCircleIcon htmlColor="#FF6969" />
            </div>
          </div>
          <div className={classes.listCamera}>
            {mdl.cameras
              .filter((camera) => camera.is_valid)
              .map((camera) => (
                <CameraItem
                  key={camera.id}
                  camera={camera}
                  siteId={site?.id}
                  siteName={site?.name}
                />
              ))}
          </div>
        </div>
      </div>
      {mdl.switchingState === SwitchingState.SWITCHING ? (
        <Loading visible text="同期中..." />
      ) : null}
    </div>
  );
}
