import {
  makeStyles,
  Theme,
  createStyles,
  fade,
} from '@material-ui/core/styles';
import { red, lightGreen, amber, grey, green } from '@material-ui/core/colors';

type Type = 'red' | 'lightGreen' | 'amber' | 'grey';

export const useCubicButtonStyles = (type: Type) =>
  makeStyles((theme: Theme) => {
    let color: any;

    switch (type) {
      case 'red':
        color = red;
        break;
      case 'lightGreen':
        color = lightGreen;
        break;
      case 'amber':
        color = amber;
        break;
      case 'grey':
      default:
        color = grey;
        break;
    }

    return createStyles({
      root: {
        /* display: inline-block; */
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        color: '#000',
        // fontWeight: 'bold',
        backgroundColor: color[600], //4
        // padding: 20px 70px,
        padding: '0 .1rem',
        // fontSize: '24px',
        border: `1px solid ${color[800]}`, //2
        backgroundImage: `linear-gradient(bottom, ${color[700]} 0%, ${color[700]} 100%)`, //3    5
        borderRadius: '5px',
        textShadow: '0px -1px 0px rgba(0, 0, 0, .5)',
        // 1
        boxShadow: `0px 6px 0px ${color[900]}, 0px 3px 15px rgba(0, 0, 0, .4), inset 0px 1px 0px rgba(255, 255, 255, .3), inset 0px 0px 3px rgba(255, 255, 255, .5)`,
        transition: 'all .1s ease-in-out',

        '&:hover': {
          // 3
          backgroundImage: `linear-gradient(bottom, ${color[700]} 0%, ${color[700]} 100%)`,
        },
        '&:active': {
          // 1
          boxShadow: `0px 2px 0px ${color[900]}, 0px 1px 6px rgba(0, 0, 0, .4), inset 0px 1px 0px rgba(255, 255, 255, .3), inset 0px 0px 3px rgba(255, 255, 255, .5)`,
          // 6
          backgroundImage: `linear-gradient(bottom, ${color[600]} 0%, ${color[600]})`,
          transform: 'translate(0, 4px)',
        },
      },
      text: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: '1rem',
        minHeight: '1rem',
      },
      icon: {},
    });
  });

export const useCustomSwitchStyles = (type: Type) =>
  makeStyles((theme: Theme) => {
    let color: any;

    switch (type) {
      case 'red':
        color = red;
        break;
      case 'lightGreen':
        color = lightGreen;
        break;
      case 'amber':
        color = amber;
        break;
      case 'grey':
      default:
        color = grey;
        break;
    }

    return createStyles({
      root: {
        width: '100%',
        display: 'inline-block',
        // padding: '0.5em 1em',
        height: '100%',
        textDecoration: 'none',
        borderRadius: '4px',
        color: 'black',
        backgroundImage: `linear-gradient(${color[300]} 0%, ${color[700]} 100%)`,
        boxShadow: `0px 5px 5px ${fade('#000', 0.29)}`,
        cursor: 'pointer',
        '&:active': {
          backgroundImage: `linear-gradient(${color[500]} 0%, ${color[900]} 100%)`,
          boxShadow:
            '0 2px 2px 0 rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2)',
          [theme.breakpoints.down('md')]: {
            backgroundColor: green[500],
          },
        },
      },
    });
  });
