import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import FolderIcon from '@material-ui/icons/Folder';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';

import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import { getMainApiCaller } from '@/api-service';
import sitesCameraAlbumsRequest from '@/api/sites_cameras_albums';

import { Camera } from '@/slicers/cameras';
import { siteCameraAlbumSetFilters } from '@/slicers/siteCameraAlbums';

import { checkValidImage } from '@/utils/dom';
import { createFileFromBase64 } from '@/utils/file';
import { makeUid } from '@/utils/random';

import { WithIconButton } from '@/components/atoms/Buttons';
import { CustomButton } from '@/components/atoms/CustomButton';
import Modal from '@/components/atoms/Modal';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';
import Tab, { TabItem } from '@/components/atoms/Tab';

import ListAlbum from '../ListAlbum';

import classes from './styles.module.scss';

const duration = 1000 * 4;

interface CameraViewProps extends CameraItemProps {
  onSaveCapture: (file: File) => void;
}

const CameraView: React.FC<CameraViewProps> = ({
  siteId,
  camera,
  siteName,
  onSaveCapture,
}) => {
  const [imagePath, setImagePath] = useState('');
  const [toggle, setToggle] = useState(false);
  const [timerId, setTimerId] = useState(0);
  const [captureData, setCapture] = useState('');
  const [loadedCapture, setLoadedCapture] = useState(false);
  const [captureError, setCaptureError] = useState('');

  useEffect(() => {
    setTimerId(
      window.setInterval(() => setToggle((toggle) => !toggle), duration)
    );
    return clearTimeout(timerId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getImagePath = async () => {
      const response = await fetch(
        'https://camera.cloud-sign.com/jitansw/index.php',
        {
          method: 'POST',
          mode: 'cors',
          body: `ip=${camera.ip}&user=${camera.login_id}&pass=${camera.pass}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        }
      );
      const imagePath = `${await response.text()}?random=${Math.random()}`; //url img

      //   service-workerでキャッシュされるのでURLを変える

      const isValidImage = await checkValidImage(imagePath);
      if (isValidImage) {
        setImagePath(imagePath);
      }
    };
    getImagePath();
  }, [camera.ip, camera.login_id, camera.pass, toggle]);

  const onGetCapture = useCallback(async () => {
    try {
      setLoadedCapture(false);
      const { data } = await getMainApiCaller('/sites').POST(
        `/${siteId}/camera/${camera.id}/capture_image`,
        {
          url: imagePath,
        }
      );

      setLoadedCapture(true);
      if (typeof data === 'string') {
        setCapture(data);
        setCaptureError('');
      } else {
        setCapture('');
        setCaptureError((data as any).error);
      }
    } catch (error) {
      setCapture('');
      setLoadedCapture(true);
      setCaptureError((error as any).toString());
    }
  }, [siteId, camera.id, imagePath]);

  const handleSaveCapure = useCallback(async () => {
    const img = await createFileFromBase64(
      captureData,
      `capture-${makeUid(12)}.jpg`
    );
    await onSaveCapture(img);
  }, [onSaveCapture, captureData]);

  return !imagePath ? (
    <div>loading...</div>
  ) : (
    <div>
      <img alt="" src={imagePath} style={{ width: '100%', display: 'block' }} />
      <div className={classes.thumbnailBottom}>
        <Modal
          width={500}
          trigger={
            <WithIconButton
              className={classes.captureButton}
              color="grey"
              icon={() => <CameraAltIcon />}
            >
              撮影する
            </WithIconButton>
          }
          onOpen={onGetCapture}
          onClose={() => {
            setCapture('');
            setLoadedCapture(true);
            setCaptureError('');
          }}
          footer={({ close }) => {
            return (
              <div className={classes.captureFooter}>
                <CustomButton
                  color="success"
                  className={classes.button}
                  onClick={async () => {
                    await handleSaveCapure();
                    close();
                  }}
                  disabled={!loadedCapture || !captureData}
                >
                  保存
                </CustomButton>
                <CustomButton
                  onClick={close}
                  color="grey"
                  className={classes.button}
                >
                  保存しない
                </CustomButton>
              </div>
            );
          }}
        >
          {loadedCapture ? (
            <div className={classes.capturePopup}>
              <div className={classes.captureTitle}>
                {siteName}の{camera.name}
              </div>
              <div className={classes.capture}>
                <img src={captureData} alt={captureError} />
              </div>
            </div>
          ) : (
            <div>撮影中...</div>
          )}
        </Modal>
      </div>
    </div>
  );
};

type CameraItemProps = {
  camera: Camera;
  siteName: string;
  siteId: number;
};

const CameraItem: React.FC<CameraItemProps> = ({
  camera,
  siteId,
  siteName,
}) => {
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();

  const [showView, setView] = useState(false);
  const [tab, setTab] = useState<TabItem | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const dispatch = useDispatch();

  const handleSaveAlbum = async (file: File) => {
    const formData = new FormData();
    formData.append('camera_image', file);

    showOverlay();
    await dispatch(
      sitesCameraAlbumsRequest.post(siteId, camera.id).request(formData)
    );
    hideOverlay();
  };

  const handleDeleteAlbum = async (albumId: number) => {
    showOverlay();
    await dispatch(
      sitesCameraAlbumsRequest.delete(siteId, camera.id, albumId).request()
    );
    hideOverlay();
  };

  useEffect(() => {
    dispatch(
      siteCameraAlbumSetFilters({
        siteId,
        cameraId: camera.id,
        filters: {
          date: selectedDate
            ? moment(selectedDate).format('YYYY-MM-DD')
            : undefined,
        },
      })
    );
  }, [dispatch, siteId, camera.id, selectedDate]);

  return (
    <div className={classes.camera}>
      {overlayLoader}
      <div className={classes.title}>
        <div />
        <div className={classes.name}>{camera.name}</div>
        <div>
          <CustomButton
            color="grey"
            className={[
              classes.cameraToggle,
              !showView ? classes.cameraToggleBackground : '',
            ].join(' ')}
            onClick={() => setView((prev) => !prev)}
          >
            {showView ? '閉じる' : '表示'}
          </CustomButton>
        </div>
      </div>

      {showView && (
        <div className={classes.cameraExpand}>
          <div className={classes.heading}>
            <div className={classes.tabs}>
              <Tab
                onChange={(tab) => setTab(tab)}
                raw={false}
                tabs={[
                  {
                    key: 'view',
                    className: classes.tabItem,
                    title: (
                      <>
                        <CameraAltIcon />
                        <span>カメラ</span>
                      </>
                    ),
                    content: (
                      <CameraView
                        siteId={siteId}
                        onSaveCapture={handleSaveAlbum}
                        camera={camera}
                        siteName={siteName}
                      />
                    ),
                  },
                  {
                    key: 'list',
                    className: classes.tabItem,
                    title: (
                      <>
                        <FolderIcon />
                        <span>アルバム</span>
                      </>
                    ),
                    content: (
                      <ListAlbum
                        siteId={siteId}
                        cameraId={camera.id}
                        onDelete={handleDeleteAlbum}
                      />
                    ),
                  },
                ]}
              />
              <a
                className={classes.camLink}
                target="_blank"
                rel="noopener noreferrer"
                href={`http://${camera.login_id}:${camera.pass}@${camera.ip}/web/mobile.html`}
              >
                カメラの操作ページ
              </a>
            </div>
            {tab?.key === 'list' && (
              <div className={classes.picker}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    allowKeyboardControl={false}
                    emptyLabel="日付を選択..."
                    value={selectedDate}
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    label=""
                    onChange={setSelectedDate}
                  />
                </MuiPickersUtilsProvider>
                {selectedDate ? (
                  <CloseIcon
                    onClick={() => setSelectedDate(null)}
                    className={[classes.pickerIcon, classes.clickable].join(
                      ' '
                    )}
                  />
                ) : (
                  <DateRangeIcon className={classes.pickerIcon} />
                )}
              </div>
            )}
          </div>
          <div className={classes.expandContent}>{tab?.content}</div>
        </div>
      )}
    </div>
  );
};

export default CameraItem;
