import { createSelector } from 'reselect';
import { RootState } from '@/app/rootReducer';
import { Module } from '@/slicers/modules';
import { User, UserTypeSiteManager } from '@/slicers/users';
import { Site } from '@/slicers/sites';
import { selectorFindAllModulesByCompanyId } from './module';
import { selectorFindAllUsersByCompanyId } from './users';
import moment from 'moment';

export type SiteWithModulesAndUsers = {
  modules: Module[];
  users: User[];
} & Site;

export type SitesWithModulesAndUsers = Record<number, SiteWithModulesAndUsers>;

export const selectorFindAllSitesByCompanyId = (companyId: number) =>
  createSelector(
    (state: RootState) => state.sitesState.sitesById,
    sitesById => {
      const list = Object.values(sitesById).filter(
        site => site.company_id === companyId
      );

      list.sort((a, b) => {
        return (
          moment(b.created_at, 'YYYY-MM-DD HH:mm:ss').unix() -
          moment(a.created_at, 'YYYY-MM-DD HH:mm:ss').unix()
        );
      });

      return list;
    }
  );

/**
 * index=0には紐ついていないデータを入れる
 * @param companyId
 */
export const getSitesWithModulesAndUsersByCompanyId = (companyId: number) =>
  createSelector(
    (state: RootState) => selectorFindAllSitesByCompanyId(companyId)(state),
    (state: RootState) => selectorFindAllModulesByCompanyId(companyId)(state),
    (state: RootState) =>
      selectorFindAllUsersByCompanyId(companyId, UserTypeSiteManager)(state),
    (sites, modules, siteManagerUser) => {
      let siteRelations: SitesWithModulesAndUsers = {
        0: {
          id: 0,
          name: '未所属のデータ',
          users: [],
          modules: [],
          address: '',
          company_id: companyId,
          fax: '',
          post_code: '',
          pref: '',
          tel: '',
        },
      };

      console.log('sites', sites);

      sites.forEach(site => {
        siteRelations[site.id] = { ...site, users: [], modules: [] };
      });

      modules.forEach((commsModule, index) => {
        if (commsModule.site_id === null) {
          siteRelations[0].modules.push(commsModule);
        } else {
          siteRelations[commsModule.site_id]?.modules.push(commsModule);
        }
      });

      siteManagerUser.forEach((user, index) => {
        if (user.site_id === null) {
          siteRelations[0].users.push(user);
        } else {
          siteRelations[user.site_id]?.users.push(user);
        }
      });

      return siteRelations;
    }
  );
