import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { useParams } from 'react-router-dom';
import { useCookies } from '@/hooks';

import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import { setLoading } from '@/slicers/loading';
import { ClientRouteParams, SitesRouteParams } from '@/routes/ClientRoute';
import sitesModulesRequest from '@/api/sites_modules';
import { selectorFindAllModulesBySiteId } from '@/selector/siteModules';

const SitesModulesList: React.FC = () => {
  const { site: siteId } = useParams<ClientRouteParams & SitesRouteParams>();

  const dispatch = useDispatch();
  const modules = useSelector((state: RootState) =>
    selectorFindAllModulesBySiteId(Number(siteId))(state)
  );

  const { getCookie, setCookie } = useCookies({});

  useEffect(() => {
    const func = async () => {
      await dispatch(setLoading(true));
      await dispatch(sitesModulesRequest.index(siteId).request());
      await dispatch(setLoading(false));
    };
    func();
  }, [dispatch, siteId]);

  return !modules ? (
    <div>データ取得中</div>
  ) : (
    <CustomMaterialTable
      title="遠隔電源指令盤管理"
      options={{
        initialPage: getCookie(
          'clientRouteSitesModulesRouteListPageCurrentPageNumber'
        ),
        searchText: getCookie('clientRouteSitesModulesRouteListPageSearchText'),
      }}
      onChangePage={(page) =>
        setCookie('clientRouteSitesModulesRouteListPageCurrentPageNumber', page)
      }
      onSearchChange={(text) =>
        setCookie('clientRouteSitesModulesRouteListPageSearchText', text)
      }
      columns={[
        {
          field: 'id',
          title: 'ID',
        },
        {
          field: 'name',
          title: '名前',
        },
        {
          field: 'device_code',
          title: 'デバイスコード',
        },
        {
          field: 'control_no',
          title: '管理番号',
        },
      ]}
      data={modules.map((item) => ({ ...item }))}
    />
  );
};

export default SitesModulesList;
