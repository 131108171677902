import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import FlashMessage from '@/components/organisms/FlashMessage';
import { getFlashSelector } from '@/selector/common';

const FlashMessageSystem = () => {
  const flash = useSelector((state: RootState) => getFlashSelector(state));

  return flash.message ? <FlashMessage key={Math.random()} {...flash} /> : null;
};

export default FlashMessageSystem;
