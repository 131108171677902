import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { getAgentCompaniesSelector } from '@/selector/companies';
import agentCompaniesRequest from '@/api/agent_companies';
import { setLoading } from '@/slicers/loading';
import AgentCompanyForm from '@/components/organisms/AgentCompanyForm';
import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import { UserTypeAgent } from '@/slicers/users';
import { Company } from '@/slicers/companies';
import ForwardLogin from '@/components/organisms/ForwardLogin';
import Drawer from '@/components/atoms/Drawer';
import Detail from '../Detail';
import { Box } from '@material-ui/core';
import { CustomButton } from '@/components/atoms/CustomButton';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

export type AgentCompaniesListHistoryState = {
  id?: number;
};

const AgentCompaniesList: React.FC = () => {
  const dispatch = useDispatch();
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState<null | Record<string, any>>(null);
  const [editType, setEditType] = useState<string>('');
  const agentCompanies = useSelector((state: RootState) =>
    getAgentCompaniesSelector(state)
  );
  const [addCompanyErrors, setCompanyErrors] = useState<Record<string, string>>(
    {}
  );

  useEffect(() => {
    const func = async () => {
      await dispatch(setLoading(true));
      await dispatch(agentCompaniesRequest.index().request());
      await dispatch(setLoading(false));
    };
    func();
  }, [dispatch]);

  const onSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'post_code', 'pref', 'address', 'tel', 'fax'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input.hasAttribute('required')) {
        if (!input.value) {
          errors[field] = '必須入力項目です';
        }
      }
      data[field] = input.value;
      return errors;
    }, {});

    setCompanyErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (editData) {
        const api = agentCompaniesRequest.put(editData.id.toString());
        await dispatch(api.request(data as Company));
        setEditData({ ...data, id: editData.id });
      } else {
        const api = agentCompaniesRequest.post();
        await dispatch(api.request(data as Company));
      }
      closeDrawer();
      hideOverlay();
    }
  };

  const onDelete = async (id: string) => {
    showOverlay();
    const api = agentCompaniesRequest.delete(id);
    await dispatch(api.request());
    setEditData(null);
    setOpenDrawer(false);
    hideOverlay();
  };

  useEffect(() => {
    if (!isOpenDrawer) {
      setCompanyErrors({});
      setEditData(null);
    }
  }, [isOpenDrawer]);

  return (
    <>
      {overlayLoader}
      <CustomMaterialTable
        title="代理店一覧"
        options={{
          pageSizeOptions: [5],
        }}
        columns={[
          {
            field: 'name',
            title: '代理店名',
          },
          {
            title: '操作',
            render: (row: any) => (
              <Box style={{ minWidth: '240px' }}>
                <ForwardLogin type={UserTypeAgent} companyId={row.id}>
                  <CustomButton color="primary">
                    各代理店管理画面へ
                  </CustomButton>
                </ForwardLogin>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('agent');
                    setOpenDrawer(true);
                  }}
                  style={{ margin: '5px' }}
                >
                  代理店情報編集
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('agentmanager');
                    setOpenDrawer(true);
                  }}
                >
                  代理店管理者編集
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('module');
                    setOpenDrawer(true);
                  }}
                  style={{ margin: '5px' }}
                >
                  指令盤編集
                </CustomButton>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setEditType('switches');
                    setOpenDrawer(true);
                  }}
                  style={{ margin: '5px' }}
                >
                  スイッチ管理
                </CustomButton>
              </Box>
            ),
          },
        ]}
        data={agentCompanies.map((item: any) => ({ ...item }))}
        addButton={
          <CustomButton
            color="grey"
            style={{ background: '#56ccf2', color: 'white' }}
            onClick={() => setOpenDrawer(true)}
          >
            代理店作成
          </CustomButton>
        }
      />
      <Drawer isOpen={isOpenDrawer} onChange={setOpenDrawer}>
        {editData ? (
          <Detail
            onConfirmDelete={onDelete}
            errors={addCompanyErrors}
            initialData={editData}
            onSubmit={onSubmit}
            editType={editType}
            onCancel={() => setOpenDrawer(false)}
          />
        ) : (
          <AgentCompanyForm
            errors={addCompanyErrors}
            onCancel={() => setOpenDrawer(false)}
            onSubmit={onSubmit(() => setOpenDrawer(false))}
          />
        )}
      </Drawer>
    </>
  );
};

export default AgentCompaniesList;
