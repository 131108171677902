import React from 'react'
import './index.css'
import store from './app/store'
import ProviderWrapper from '@/provider'
import {
  ThemeProvider,
  createTheme,
  StylesProvider
} from '@material-ui/core/styles'

const AppWrapper: React.FC = ({ children }) => {
  return (
    <ProviderWrapper store={store()}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={createTheme({})}>{children}</ThemeProvider>
      </StylesProvider>
    </ProviderWrapper>
  )
}

export default AppWrapper
