import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from './users';

export interface ForwardLayer {
  companyId: number;
  type: UserType;
  prevPath: string;
}

export type LoginState = {
  loginUserId: number | null;
  forwardQueue: ForwardLayer[];
};

export const initialLoginState: LoginState = {
  loginUserId: null,
  forwardQueue: [],
};

const loginSlice = createSlice({
  name: 'login',
  initialState: initialLoginState,
  reducers: {
    setLoginUserId(state, action: PayloadAction<number>) {
      state.loginUserId = action.payload;
    },
    setForwardQueue(state, action: PayloadAction<ForwardLayer[]>) {
      state.forwardQueue = action.payload;
    },
    loginEnqueue(state, action: PayloadAction<ForwardLayer>) {
      if (
        !state.forwardQueue.some(({ type }) => type === action.payload.type)
      ) {
        state.forwardQueue.push(action.payload);
        localStorage.setItem(
          'forwardQueue',
          JSON.stringify(state.forwardQueue)
        );
      }
    },
    loginDequeue(state) {
      state.forwardQueue.pop();
      localStorage.setItem('forwardQueue', JSON.stringify(state.forwardQueue));
    },
  },
});

export const {
  setLoginUserId,
  loginEnqueue,
  loginDequeue,
  setForwardQueue,
} = loginSlice.actions;

export default loginSlice.reducer;
