import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import agentCompaniesModulesRequest from '@/api/agent_companies_modules';
import { getLoginUserAndCompanySelector } from '@/selector/users';

import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import { setLoading } from '@/slicers/loading';
import { useParams } from 'react-router-dom';
import { AgentCompaniesRouteProps } from '@/routes/AgentRoute';
import { selectorFindAllAgentModulesByOwnerId } from '@/selector/agentModule';

const AgentCompaniesModulesList: React.FC = () => {
  const { agent_company: agentCompanyId } =
    useParams<AgentCompaniesRouteProps>();
  const dispatch = useDispatch();
  const modules = useSelector((state: RootState) =>
    selectorFindAllAgentModulesByOwnerId(Number(agentCompanyId))(state)
  );

  const { loginUser } = useSelector((state: RootState) =>
    getLoginUserAndCompanySelector(state)
  );

  useEffect(() => {
    const func = async () => {
      await dispatch(setLoading(true));
      await dispatch(
        agentCompaniesModulesRequest.index(agentCompanyId).request()
      );
      await dispatch(setLoading(false));
    };
    func();
  }, [dispatch, agentCompanyId]);

  return !(modules && loginUser) ? (
    <div>データ取得中</div>
  ) : (
    <>
      <CustomMaterialTable
        title="遠隔電源指令盤管理"
        fieldSearch={['control_no', 'name', 'device_code', 'company.name']}
        searchPlaceholder="デバイスコード・指令盤名・管理番号"
        columns={[
          {
            field: 'id',
            title: 'ID',
          },
          {
            field: 'control_no',
            title: '管理番号',
          },
          {
            title: 'レンタル先企業名',
            render: (row: any) =>
              row.company ? (
                <span>{row.company.name}</span>
              ) : (
                <span style={{ color: 'red' }}>未所属</span>
              ),
          },
          {
            field: 'device_code',
            title: 'デバイスコード',
          },
          {
            field: 'name',
            title: '指令盤名',
          },
        ]}
        data={modules.map((item) => ({ ...item }))}
      />
    </>
  );
};

export default AgentCompaniesModulesList;
