import React from 'react';
import useStyles from './styles';

type KWResetAlertProps = {
  isSuccess: boolean;
};
export default function KWResetAlert({ isSuccess }: KWResetAlertProps) {
  const classes = useStyles()();

  return (
    <div className={classes.root}>
      {isSuccess ? (
        <p aria-label="success">積算電力量をリセットしました。</p>
      ) : (
        <p>リセットに失敗しました。</p>
      )}
    </div>
  );
}
