import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Chart, Chart as ChartJS, ChartEvent, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { RootState } from '@/app/rootReducer';

import modulesRequest from '@/api/modules';
import companiesModulesRequest from '@/api/companies_modules';

import { useDeviceCommunication } from '@/hooks/socket';

import { getLoginUserAndCompanySelector } from '@/selector/users';
import { findModuleWithCamerasSelectorFactory } from '@/selector/module';

import Select from '@/components/atoms/Select';
import Tooltip from '@/components/atoms/Tooltip';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';
import { CustomButton } from '@/components/atoms/CustomButton';

import PowerInputs from '../components/PowerInputs';
import TargetPower from '../components/TargetPower';
import ExportPopup from '../components/ExportPopup';

import classes from './styles.module.scss';

ChartJS.register(...registerables);

enum ChartType {
  DEFAULT,
  MONTHLY,
  MONTHLY_VS_TARGET,
  CARBON_VALUE,
}

const BAR_CONFIG = {
  barThickness: 30,
  type: 'bar',
  label: '日ごとの積算電力量',
  backgroundColor: '#dec500',
};

type ChartEventProps = ChartEvent &
  Event & {
    chart: Chart;
  };

const PowerChartDay: React.FC = () => {
  const [spinner, { showOverlay, hideOverlay }] = useOverlayLoading();
  const { moduleId } = useParams<Record<string, string>>();
  const [targets, setTargets] = useState<number[]>([]);
  const [stats, setStats] = useState<DayStats[]>([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [dataLoading, setDataLoading] = useState(true);
  const { search } = useLocation();
  const history = useHistory();
  const [chartEvent, setChartEvent] = useState<ChartEventProps>();
  const { events } = useDeviceCommunication();

  const { year, month } = useMemo(() => {
    const query = new URLSearchParams(search);
    const month: any = query.get('month');
    const year: any = query.get('year');

    setSelectedYear(year);

    return {
      month,
      year,
    };
  }, [search]);

  const dispatch = useDispatch();
  const { mdl, loginUser } = useSelector(
    (state: RootState) => ({
      mdl: findModuleWithCamerasSelectorFactory(state)(Number(moduleId)),
      loginUser: getLoginUserAndCompanySelector(state).loginUser,
    }),
    shallowEqual
  );

  const loginCompanyId = loginUser!.company_id;
  const commUserId = loginUser!.id;

  useEffect(() => {
    if (commUserId) {
      events.onOpen = function () {
        events.sendToEvent('client-user-login', {
          token: localStorage.getItem('accessToken'),
          userId: commUserId,
        });
      };
      events.onMessage = function (payload) {
        const { event } = payload as { event: string; data: any };
        if (event === 'ready') {
          console.log(
            '[Module Chart] Client user login success with id',
            commUserId
          );
        } else if (event === 'clientUserLoginFiled') {
          console.log(
            '[Module Chart] Client user login fail with id',
            commUserId
          );
        }
      };
      return () => {
        events.close?.();
      };
    }
  }, [events, commUserId]);

  const getModule = useCallback(async () => {
    dispatch(
      companiesModulesRequest.get(loginCompanyId.toString(), moduleId).request()
    );
  }, [dispatch, moduleId, loginCompanyId]);

  const getData = useCallback(async () => {
    setDataLoading(true);

    const { requestMonth }: any = await dispatch(
      modulesRequest.getKwhsByDay(+moduleId).request(selectedYear, month)
    );

    const requestMonthData = requestMonth.reduce(
      (obj: Record<string, any>, dayData: any) => {
        obj[dayData.day] = {
          value: dayData.total_kwh,
        };
        return obj;
      },
      {}
    );

    setDataLoading(false);
    const daysInMonth = new Date(selectedYear, month, 0).getDate();
    setStats(
      Array.from({ length: daysInMonth }).map((_, i) => {
        const day: number = i + 1;
        return {
          day,
          value: requestMonthData[day]?.value || 0,
        };
      })
    );
  }, [selectedYear, month, moduleId, dispatch]);

  const getTargets = useCallback(async () => {
    const res: any[] = [
      ...((await dispatch(
        modulesRequest.getPowerMeterTargets(+moduleId).request(selectedYear)
      )) as any),
    ];
    res.sort((a, b) => {
      return a.day - b.day;
    });
    setTargets(res.map((item) => item.value));
  }, [selectedYear, moduleId, dispatch]);

  useEffect(() => {
    getModule();
  }, [getModule]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getTargets();
  }, [getTargets]);

  useEffect(() => {
    if (chartEvent) {
      const chart = chartEvent.chart;
      const points = chart.getElementsAtEventForMode(
        chartEvent,
        'nearest',
        { intersect: true },
        true
      );

      if (points.length) {
        const firstPoint = points[0];
        const label: any = chart.data.labels?.[firstPoint.index];

        const month = Number(label.split('月')[0]);
        const day = Number(label.split('月')[1].replace('日', ''));

        history.push(`day?year=${year}&month=${month}&day=${day}`);
      }
    }
  }, [chartEvent, history, year, month]);

  const maxYAxis = useMemo(() => {
    let max;
    max = stats.reduce((max, item, index) => {
      const compareValue = item.value;
      return compareValue > max ? compareValue : max;
    }, 100);

    return max + max / 10;
  }, [stats]);

  const data: any = useMemo(() => {
    const daysInMonth = new Date(selectedYear, month, 0).getDate();
    const labels = Array.from({ length: daysInMonth }).map((_, i) => {
      return `${month}月${i + 1}日`;
    });
    const datasets = [
      {
        ...BAR_CONFIG,
        data: stats.map((item) => item.value),
      },
    ];

    return {
      labels,
      datasets,
    };
  }, [stats, month, selectedYear]);

  const currentDayStats = useMemo(() => {
    const currentDay = new Date().getDate();
    return stats.find((item) => item.day === currentDay);
  }, [stats]);

  const onExport = useCallback(
    async (meterId, month) => {
      showOverlay();
      const res = await modulesRequest
        .downloadKwhsCSV(mdl!.id)
        .request(meterId, selectedYear, month);

      const { data, filename } = res;
      const a = document.createElement('a');
      a.href = URL.createObjectURL(data);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      hideOverlay();
    },
    [selectedYear, mdl, showOverlay, hideOverlay]
  );

  const onSubmit = useCallback(
    async (year, targets) => {
      showOverlay();
      await dispatch(
        modulesRequest.savePowerMeterTargets(+moduleId).request(year, targets)
      );
      setTargets(targets);
      hideOverlay();
    },
    [moduleId, showOverlay, hideOverlay, dispatch]
  );

  const meterOptions = useMemo(() => {
    const unit = mdl?.unitsByNumber[1];
    if (!unit) {
      return [];
    }
    return Object.values(unit.switchesByNumber).reduce((arr: any[], swt) => {
      if (swt.communication_switch?.power_meter_id) {
        arr.push({
          label: swt.communication_switch.pid,
          value: swt.communication_switch.power_meter_id,
        });
      }
      return arr;
    }, []);
  }, [mdl]);

  const targetsKey = useMemo(() => {
    return Math.random().toString();
    // need 'targets' to re-generate key
    //eslint-disable-next-line
  }, [targets]);

  return (
    <div className={classes.powerChart}>
      {spinner}
      <div className={classes.heading}>
        <div className={classes.left}>
          <div className={classes.select}>
            <span>表示内容</span>
            <Select
              defaultValue={ChartType.DEFAULT}
              options={[
                {
                  value: ChartType.DEFAULT,
                  label: '積算電力量',
                },
                {
                  value: ChartType.MONTHLY,
                  label: '月別積算電力量',
                },
                {
                  value: ChartType.MONTHLY_VS_TARGET,
                  label: '月別積算電力量 vs 月間目標値',
                },
                {
                  value: ChartType.CARBON_VALUE,
                  label: '積算カーボン削減見込量',
                },
              ]}
              onChange={(type) => {
                history.push(`/modules/${moduleId}/power-chart?type=${type}`);
              }}
            />
          </div>
          <div className={classes.rangeDate}>
            <span>期間</span>
            <div className={classes.dates}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={['year']}
                  autoOk={true}
                  allowKeyboardControl={false}
                  emptyLabel="年を選択"
                  value={new Date(selectedYear, 1, 1)}
                  disableToolbar
                  variant="inline"
                  margin="normal"
                  onChange={(date) => {
                    setSelectedYear(
                      date ? date.getFullYear() : new Date().getFullYear()
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <Link
            className={classes.currentTime}
            to={`/modules/${moduleId}/power-chart?year=${selectedYear}`}
          >
            &#5171;&nbsp;&nbsp; {selectedYear}の月ごと
          </Link>
          <div className={classes.currentTime}>
            &#5171;&nbsp;&nbsp; {month}月の日ごとの電力使用量の推移
          </div>
        </div>
        <div className={classes.right}>
          <Tooltip
            holder={false}
            trigger={
              <div className={classes.optionsButton}>
                この指令盤の目標値を設定する
              </div>
            }
          >
            <PowerInputs
              key={targetsKey}
              year={selectedYear}
              defaultTargets={targets}
              onSave={onSubmit}
            />
          </Tooltip>
        </div>
      </div>
      <div className={classes.chartContainer}>
        <div className={classes.chartTop}>
          <div className={classes.left}>
            <TargetPower
              monthValue={currentDayStats?.value || 0}
              value={targets[new Date().getMonth()] || 0}
            />
          </div>
          <div className={classes.right}>
            <Tooltip
              holder={false}
              trigger={
                <div>
                  <CustomButton size="small" color="info">
                    CSVダウンロード
                  </CustomButton>
                </div>
              }
            >
              <ExportPopup
                meterOptions={meterOptions}
                disabled={!mdl}
                onExport={onExport}
              />
            </Tooltip>
          </div>
        </div>
        <div className={classes.chart}>
          <div>
            <Bar
              plugins={[ChartDataLabels]}
              data={data}
              options={{
                plugins: {
                  datalabels: {
                    color: 'black',
                    font: {
                      size: 8,
                    },
                    formatter: (value, context) => {
                      const unit = 'kW/h';
                      if (context.dataset.type === 'bar' && value) {
                        return `${value ? value.toFixed(3) : 0}${unit}`;
                      }
                      return '';
                    },
                    anchor: 'end',
                    offset: -20,
                    align: 'start',
                  },
                },
                responsive: true,
                scales: {
                  y: {
                    title: {
                      display: false,
                      text: 'kW/h',
                      align: 'end',
                    },
                    suggestedMax: maxYAxis,
                    beginAtZero: true,
                  },
                },
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                  },
                },
                onClick: (evt: ChartEventProps) => setChartEvent(evt),
              }}
            />

            {dataLoading && (
              <div
                className={classes.loader}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerChartDay;
