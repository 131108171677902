import React, { useState, useEffect } from 'react';

import classes from './styles.module.scss';

type TabTitle = React.ReactElement | string;

export interface TabItem {
  key: string;
  title: TabTitle | TabTitle[];
  content: React.ReactNode;
  className?: string;
}

interface TabProps {
  raw?: boolean;
  onChange?: (tab: TabItem) => void;
  tabs: TabItem[];
}

export default function Tab({
  raw = true,
  onChange = () => {},
  tabs,
}: TabProps) {
  const [tabIndex, setTabIndex] = useState(0);

  const currentTab = tabs[tabIndex];

  useEffect(() => {
    onChange(tabs[tabIndex]);
    // eslint-disable-next-line
  }, [tabIndex]);

  return (
    <div className={classes.tabs}>
      <div className={classes.titles}>
        {tabs.map((tab, index) => (
          <div
            key={tab.key}
            className={[
              index === tabIndex ? classes.active : '',
              tab.className,
            ].join(' ')}
            onClick={() => setTabIndex(index)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {raw && (
        <div key={tabIndex} className={classes.content}>
          {currentTab.content}
        </div>
      )}
    </div>
  );
}
