import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';

import BreadCrumbs from '@/components/molecules/BreadCrumbs';

const CompaniesModulesShowBreadCrumb: React.FC = () => {
  return (
    <BreadCrumbs>
      <BreadCrumbItem title="現場テストの指令盤編集" />
    </BreadCrumbs>
  );
};

export default CompaniesModulesShowBreadCrumb;
