import React, { useMemo, useEffect, useState, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { getOffset } from '@/utils/dom';
import debounce from 'lodash/debounce';

import classes from './styles.module.scss';

interface TooltipProps {
  holder?: boolean;
  children: React.ReactNode;
  trigger: React.ReactElement;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

export default function Tooltip({
  holder = true,
  onOpen = () => {},
  onClose = () => {},
  className,
  children,
  trigger,
}: TooltipProps) {
  const [isOpen, setOpen] = useState(false);
  const [offset, setOffset] = useState<{ left: number; top: number } | null>(
    null
  );

  const refTrigger = useRef<HTMLElement>();
  const refWrapper = useRef<HTMLDivElement>();

  const container = useMemo(() => {
    const c = document.createElement('div');
    c.style.zIndex = '4000';
    c.style.position = 'absolute';
    c.style.top = '0';
    c.style.left = '0';
    return c;
  }, []);

  useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  useEffect(() => {
    if (isOpen) {
      const closers =
        refWrapper.current?.querySelectorAll('[data-tooltip-role="close"]') ||
        [];

      const onCloserClick = function (e: any) {
        setTimeout(handleClose, 150); //wait for outside action done
      };

      for (const closer of closers as HTMLElement[]) {
        closer.onclick = debounce(onCloserClick, 100);
      }

      onOpen();
      const targetOffset = getOffset(refTrigger.current as any);

      const { clientWidth, clientHeight } = refTrigger.current!;

      setOffset({
        top: targetOffset.top + clientHeight,
        left:
          targetOffset.left +
          clientWidth / 2 -
          refWrapper.current!.clientWidth / 2,
      });

      return () => {
        for (const closer of closers as HTMLElement[]) {
          closer.removeEventListener('click', onCloserClick);
        }
      };
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOffset(null);
    onClose();
  };

  return (
    <>
      {React.cloneElement(
        trigger,
        { onClick: handleOpen, ref: refTrigger },
        trigger.props.children
      )}
      {ReactDOM.createPortal(
        isOpen ? (
          <div className={classes.modal}>
            <div className={classes.closeBackground} onClick={handleClose} />
            <div
              ref={refWrapper as any}
              style={
                offset
                  ? {
                      ...offset,
                    }
                  : {
                      visibility: 'hidden',
                    }
              }
              className={[
                classes.wraper,
                className,
                holder ? 'holder' : '',
              ].join(' ')}
            >
              {children}
            </div>
          </div>
        ) : null,
        container
      )}
    </>
  );
}
