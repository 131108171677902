import React, { useState, useCallback, useEffect } from 'react';
import { Checkbox } from '@material-ui/core';

import classes from './styles.module.scss';

interface CheckboxesProps {
  defaultValue?: string[];
  readOnly?: boolean;
  keepFalse?: boolean;
  flat?: boolean;
  onChange?: (selecteds: string[]) => void;
  label: string;
  name: string;
  options: { label: string; value: string | number }[];
}

export default function Checkboxes({
  defaultValue = [],
  readOnly,
  keepFalse,
  flat,
  options,
  onChange = () => {},
  label,
  name,
}: CheckboxesProps) {
  const [selecteds, setSelecteds] = useState<Record<string, boolean>>(
    defaultValue.reduce((hs: Record<string, boolean>, k) => {
      hs[k] = true;
      return hs;
    }, {})
  );

  useEffect(() => {
    if (readOnly) {
      setSelecteds(
        defaultValue.reduce((hs: Record<string, boolean>, k) => {
          hs[k] = true;
          return hs;
        }, {})
      );
    }
  }, [defaultValue, readOnly]);

  const handleSelect = useCallback(
    (e) => {
      e.preventDefault();
      const name = (e.currentTarget as HTMLDivElement).children[1].getAttribute(
        'data-name'
      )!;

      let newSelecteds: Record<string, boolean>;
      setSelecteds((prev) => {
        newSelecteds = {
          ...prev,
          [name]: !prev[name],
        };

        return newSelecteds;
      });

      onChange(
        Object.keys(newSelecteds! || {}).filter((k) => !!newSelecteds[k])
      );
    },
    [onChange]
  );

  const sels = keepFalse
    ? options.map((opt) => opt.value)
    : Object.keys(selecteds!).filter((k) => !!selecteds[k]);

  return (
    <div className={classes.checkboxes}>
      {flat
        ? sels.map((k) => (
            <input
              key={k}
              type="hidden"
              name={k as string}
              value={selecteds[k as any] ? 1 : 0}
            />
          ))
        : sels.map((k, index) => (
            <input
              key={k}
              type="hidden"
              name={`${name}.*.${index}`}
              value={k}
            />
          ))}
      <div className={classes.label}>{label}</div>
      <div className={classes.checkes}>
        {options.map((opt) => (
          <div key={opt.value} onClick={!readOnly ? handleSelect : undefined}>
            <Checkbox
              readOnly={readOnly}
              checked={!!selecteds[opt.value]}
              className={[
                classes.check,
                selecteds[opt.value] ? classes.checked : '',
              ].join(' ')}
            />
            <span data-name={opt.value.toString()}>{opt.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
