import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = () =>
  makeStyles(() =>
    createStyles({
      earthquakeAlert: {
        color: '#ff4040',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        textAlign: 'center',
        padding: '0 2rem',
        whiteSpace: 'pre-wrap',
        marginTop: 10,
      },
    })
  );

export default useStyles;
