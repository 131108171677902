import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';

import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';
import { useAgent, useGetClientRouteParam } from '@/hooks';
import { getLoginUserSelector } from '@/selector/users';

const OperationPannelBreadCrumb: React.FC = () => {
  const isMobile = useAgent();

  const loggedUser = useSelector(getLoginUserSelector);

  const { siteId } = useGetClientRouteParam();

  const site = useSelector(
    (state: RootState) =>
      state.sitesState.sitesById[loggedUser?.site_id || Number(siteId)],
    shallowEqual
  );

  return (
    <BreadCrumbs>
      <BreadCrumbItem title="電源操作画面" />
      {isMobile && site && <BreadCrumbItem title={site.name} />}
    </BreadCrumbs>
  );
};

export default OperationPannelBreadCrumb;
