import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Chart, Chart as ChartJS, ChartEvent, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { RootState } from '@/app/rootReducer';

import modulesRequest from '@/api/modules';
import emissionFactorsRequest from '@/api/emission_factors';
import companiesModulesRequest from '@/api/companies_modules';
import sitesModulesRequest from '@/api/sites_modules';

import { useDeviceCommunication } from '@/hooks/socket';

import { getLoginUserAndCompanySelector } from '@/selector/users';
import { findModuleWithCamerasSelectorFactory } from '@/selector/module';

import Select from '@/components/atoms/Select';
import Tooltip from '@/components/atoms/Tooltip';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';
import { CustomButton } from '@/components/atoms/CustomButton';

import PowerInputs from '../components/PowerInputs';
import TargetPower from '../components/TargetPower';
import EmissionFactorInput from '../components/EmissionFactorInput';
import ExportPopup from '../components/ExportPopup';

import classes from './styles.module.scss';

ChartJS.register(...registerables);

enum ChartType {
  DEFAULT,
  MONTHLY,
  MONTHLY_VS_TARGET,
  CARBON_VALUE,
}

const LABELS = Array.from({ length: 12 }).map((_, i) => {
  return `${i + 1}月`;
});

const BAR_CONFIG = {
  barThickness: 40,
  type: 'bar',
  label: '積算電力量',
  backgroundColor: '#ebe54e',
  pointStyle: 'rect',
};

const LINE_CONFIG = {
  type: 'line',
  label: '最大需要電力量',
  backgroundColor: '#0000',
  borderColor: '#000',
  borderDash: [6, 6],
  pointStyle: 'dash',
};

type ChartEventProps = ChartEvent &
  Event & {
    chart: Chart;
  };

const PowerChart: React.FC = () => {
  const [spinner, { showOverlay, hideOverlay }] = useOverlayLoading();
  const { moduleId } = useParams<Record<string, string>>();
  const [targets, setTargets] = useState<number[]>([]);
  const [stats, setStats] = useState<Stats[]>([]);
  const [emissionFactors, setEmissionFactors] = useState<EmissionFactor[]>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const { search } = useLocation();
  const history = useHistory();
  const [isUserLogged, setUserLogged] = useState(false);
  const [chartEvent, setChartEvent] = useState<ChartEventProps>();
  const { isAvailable, events } = useDeviceCommunication();
  const [selectedYear, setSelectedYear] = useState(() => {
    const query = new URLSearchParams(search);
    const year: any = query.get('year');
    if (year) {
      return +year;
    }
    return new Date().getFullYear();
  });

  const { chartType } = useMemo(() => {
    const query = new URLSearchParams(search);
    let type: any = query.get('type');
    type = isNaN(type as any) ? null : +type;

    return {
      chartType: type,
    };
  }, [search]);

  const dispatch = useDispatch();
  const { mdl, loginUser } = useSelector(
    (state: RootState) => ({
      mdl: findModuleWithCamerasSelectorFactory(state)(Number(moduleId)),
      loginUser: getLoginUserAndCompanySelector(state).loginUser,
    }),
    shallowEqual
  );

  const loginCompanyId = loginUser!.company_id;
  const commUserId = loginUser!.id;

  useEffect(() => {
    if (commUserId) {
      events.onOpen = function () {
        events.sendToEvent('client-user-login', {
          token: localStorage.getItem('accessToken'),
          userId: commUserId,
        });
      };
      events.onMessage = function (payload) {
        const { event } = payload as { event: string; data: any };
        if (event === 'ready') {
          console.log(
            '[Module Chart] Client user login success with id',
            commUserId
          );
          setUserLogged(true);
        } else if (event === 'clientUserLoginFiled') {
          console.log(
            '[Module Chart] Client user login fail with id',
            commUserId
          );
          setUserLogged(false);
        }
      };
      return () => {
        events.close?.();
      };
    }
  }, [events, commUserId]);

  const getModule = useCallback(async () => {
    dispatch(
      companiesModulesRequest.get(loginCompanyId.toString(), moduleId).request()
    );
  }, [dispatch, moduleId, loginCompanyId]);

  const getData = useCallback(async () => {
    setDataLoading(true);
    const [{ requestYear, prevYear, maxInMonth }, carbons]: any =
      await Promise.all([
        dispatch(modulesRequest.getKwhs(+moduleId).request(selectedYear)),
        dispatch(modulesRequest.getCarbons(+moduleId).request(selectedYear)),
      ]);
    const carbonsData = carbons.reduce(
      (obj: Record<string, any>, monthData: any) => {
        obj[monthData.month] = {
          value: monthData.total_carbon_reduction * 1000,
        };
        return obj;
      },
      {}
    );
    const requestYearData = requestYear.reduce(
      (obj: Record<string, any>, monthData: any) => {
        obj[monthData.month] = {
          value: monthData.total_kwh,
        };
        return obj;
      },
      {}
    );
    const prevYearData = prevYear.reduce(
      (obj: Record<string, any>, monthData: any) => {
        obj[monthData.month] = {
          value: monthData.total_kwh,
        };
        return obj;
      },
      {}
    );
    const maxInMonthData = maxInMonth.reduce(
      (obj: Record<string, any>, monthData: any) => {
        obj[monthData.month] = {
          value: monthData.total_kwh,
        };
        return obj;
      },
      {}
    );

    setDataLoading(false);
    setStats(
      Array.from({ length: 12 }).map((_, i) => {
        const month = i + 1;
        return {
          month,
          value: requestYearData[month]?.value || 0,
          prevMonthValue: prevYearData[month]?.value || 0,
          carbon: carbonsData[month]?.value || 0,
          max: maxInMonthData[month]?.value
            ? maxInMonthData[month]?.value * 2
            : 0,
        };
      })
    );
  }, [selectedYear, moduleId, dispatch]);

  const getTargets = useCallback(async () => {
    const res: any[] = [
      ...((await dispatch(
        modulesRequest.getPowerMeterTargets(+moduleId).request(selectedYear)
      )) as any),
    ];
    res.sort((a, b) => {
      return a.month - b.month;
    });
    setTargets(res.map((item) => item.value));
  }, [selectedYear, moduleId, dispatch]);

  const getEmissionFactors = useCallback(async () => {
    const res: any[] = [
      ...((await dispatch(emissionFactorsRequest.index().request())) as any),
    ];
    setEmissionFactors(res);
  }, [dispatch]);

  useEffect(() => {
    getModule();
  }, [getModule]);

  useEffect(() => {
    getEmissionFactors();
  }, [getEmissionFactors]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getTargets();
  }, [getTargets]);

  useEffect(() => {
    if (chartEvent && chartType === ChartType.DEFAULT) {
      const chart = chartEvent.chart;
      const points = chart.getElementsAtEventForMode(
        chartEvent,
        'nearest',
        { intersect: true },
        true
      );

      if (points.length) {
        const firstPoint = points[0];
        const label: any = chart.data.labels?.[firstPoint.index];

        const month = Number(label.slice(0, -1));
        history.push(`power-chart/month?year=${selectedYear}&month=${month}`);
      }
    }
  }, [chartEvent, chartType, history, selectedYear]);

  const maxYAxis = useMemo(() => {
    let max;
    if (chartType === ChartType.CARBON_VALUE) {
      max = stats.reduce((max, item) => {
        const compareValue = item.carbon;
        return compareValue > max ? compareValue : max;
      }, 1);
    } else if (chartType === ChartType.MONTHLY_VS_TARGET) {
      max = stats.reduce((max, item, index) => {
        const compareValue =
          item.value > targets[index] ? item.value : targets[index];
        return compareValue > max ? compareValue : max;
      }, 100);
    } else {
      max = stats.reduce((max, item, index) => {
        const compareValue =
          item.value > item.prevMonthValue ? item.value : item.prevMonthValue;
        return compareValue > max ? compareValue : max;
      }, 100);
    }
    return max + max / 10;
  }, [stats, targets, chartType]);

  const data: any = useMemo(() => {
    let datasets;

    switch (chartType) {
      case ChartType.DEFAULT:
        datasets = [
          {
            ...LINE_CONFIG,
            data: stats.map((item) => item.max),
          },
          {
            ...BAR_CONFIG,
            data: stats.map((item) => item.value),
          },
        ];
        break;
      case ChartType.MONTHLY:
        datasets = [
          {
            ...BAR_CONFIG,
            data: stats.map((item) => item.value),
          },
          {
            type: 'line',
            label: '昨年同月積算電力量',
            data: stats.map((item) => item.prevMonthValue),
            fill: true,
            backgroundColor: '#ceeeff',
            borderColor: '#00000000',
          },
        ];
        break;
      case ChartType.MONTHLY_VS_TARGET:
        datasets = [
          {
            ...BAR_CONFIG,
            data: stats.map((item) => item.value),
          },
          {
            type: 'line',
            label: '目標值',
            data: targets,
            fill: true,
            backgroundColor: '#ffe8ce',
            borderColor: '#00000000',
          },
        ];
        break;
      case ChartType.CARBON_VALUE:
        datasets = [
          {
            ...BAR_CONFIG,
            label: '積算カーボン削減見込量',
            backgroundColor: '#3f51b5',
            data: stats.map((item) => item.carbon),
          },
        ];
        break;
      default:
        break;
    }

    return {
      labels: LABELS,
      datasets,
    };
  }, [chartType, targets, stats]);

  const currentMonthStats = useMemo(() => {
    const currentMonth = new Date().getMonth() + 1;
    return stats.find((item) => item.month === currentMonth);
  }, [stats]);

  const handleSaveFactor = useCallback(
    async (factorId, value) => {
      events.sendToEvent('emission-factor-change', {
        deviceCode: mdl!.device_code,
        factor: value,
      });

      showOverlay();
      await dispatch(
        sitesModulesRequest
          .updateEmissionFactor(mdl!.site_id!)
          .request(+factorId, mdl!.id)
      );
      hideOverlay();
    },
    [mdl, events, showOverlay, hideOverlay, dispatch]
  );

  const onExport = useCallback(
    async (meterId, month) => {
      showOverlay();
      let res;
      if (chartType === ChartType.CARBON_VALUE) {
        res = await modulesRequest
          .downloadCarbonsCSV(mdl!.id)
          .request(meterId, selectedYear, month);
      } else {
        res = await modulesRequest
          .downloadKwhsCSV(mdl!.id)
          .request(meterId, selectedYear, month);
      }
      const { data, filename } = res;
      const a = document.createElement('a');
      a.href = URL.createObjectURL(data);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      hideOverlay();
    },
    [selectedYear, chartType, mdl, showOverlay, hideOverlay]
  );

  const onSubmit = useCallback(
    async (year, targets) => {
      showOverlay();
      await dispatch(
        modulesRequest.savePowerMeterTargets(+moduleId).request(year, targets)
      );
      setTargets(targets);
      hideOverlay();
    },
    [moduleId, showOverlay, hideOverlay, dispatch]
  );

  const meterOptions = useMemo(() => {
    const unit = mdl?.unitsByNumber[1];
    if (!unit) {
      return [];
    }
    return Object.values(unit.switchesByNumber).reduce((arr: any[], swt) => {
      if (swt.communication_switch?.power_meter_id) {
        arr.push({
          label: swt.communication_switch.pid,
          value: swt.communication_switch.power_meter_id,
        });
      }
      return arr;
    }, []);
  }, [mdl]);

  const targetsKey = useMemo(() => {
    return Math.random().toString();
    // need 'targets' to re-generate key
    //eslint-disable-next-line
  }, [targets]);

  return (
    <div className={classes.powerChart}>
      {spinner}
      <div className={classes.heading}>
        <div className={classes.left}>
          <div className={classes.select}>
            <span>表示内容</span>
            <Select
              defaultValue={chartType}
              options={[
                {
                  value: ChartType.DEFAULT,
                  label: '積算電力量',
                },
                {
                  value: ChartType.MONTHLY,
                  label: '月別積算電力量',
                },
                {
                  value: ChartType.MONTHLY_VS_TARGET,
                  label: '月別積算電力量 vs 月間目標値',
                },
                {
                  value: ChartType.CARBON_VALUE,
                  label: '積算カーボン削減見込量',
                },
              ]}
              onChange={(type) => {
                history.push(`?type=${type}`);
              }}
            />
          </div>
          <div className={classes.rangeDate}>
            <span>期間</span>
            <div className={classes.dates}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  views={['year']}
                  autoOk={true}
                  allowKeyboardControl={false}
                  emptyLabel="年を選択"
                  value={new Date(selectedYear, 1, 1)}
                  disableToolbar
                  variant="inline"
                  margin="normal"
                  onChange={(date) => {
                    setSelectedYear(
                      date ? date.getFullYear() : new Date().getFullYear()
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          {chartType === ChartType.CARBON_VALUE && (
            <span className={classes.note}>
              ※積算カーボン削減見込量は、現場単位の数値です。
            </span>
          )}
        </div>
        <div className={classes.right}>
          {chartType !== ChartType.CARBON_VALUE ? (
            <Tooltip
              holder={false}
              trigger={
                <div className={classes.optionsButton}>
                  この指令盤の目標値を設定する
                </div>
              }
            >
              <PowerInputs
                key={targetsKey}
                year={selectedYear}
                defaultTargets={targets}
                onSave={onSubmit}
              />
            </Tooltip>
          ) : (
            <Tooltip
              holder={false}
              trigger={
                <div className={classes.optionsButton}>
                  積算カーボン削減見込量
                </div>
              }
            >
              {mdl && (
                <EmissionFactorInput
                  disabled={!mdl || !isAvailable || !isUserLogged}
                  defaultValue={mdl.m_emission_factor_id}
                  options={emissionFactors}
                  onSave={handleSaveFactor}
                />
              )}
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classes.chartContainer}>
        <div className={classes.chartTop}>
          <div className={classes.left}>
            {chartType !== ChartType.CARBON_VALUE && (
              <TargetPower
                monthValue={currentMonthStats?.value || 0}
                value={targets[new Date().getMonth()] || 0}
              />
            )}
          </div>
          <div className={classes.right}>
            <div className={classes.label}>
              <div>
                <span />
                最大需要電力量
              </div>
              <div>
                <span />
                積算電力量
              </div>
            </div>
            <Tooltip
              holder={false}
              trigger={
                <div>
                  <CustomButton size="small" color="info">
                    CSVダウンロード
                  </CustomButton>
                </div>
              }
            >
              <ExportPopup
                meterOptions={meterOptions}
                disabled={!mdl}
                onExport={onExport}
              />
            </Tooltip>
          </div>
        </div>
        <div className={classes.chart}>
          <div>
            <Bar
              plugins={[ChartDataLabels]}
              data={data}
              options={{
                plugins: {
                  datalabels: {
                    color: 'black',
                    formatter: (value, context) => {
                      const unit = 'kW/h';
                      if (context.dataset.type === 'bar' && value) {
                        return `${value ? value.toFixed(3) : 0}${unit}`;
                      }
                      return '';
                    },
                    anchor: 'end',
                    offset: -20,
                    align: 'start',
                  },
                  tooltip: {
                    intersect: false,
                  },
                  legend: {
                    display: false,
                  },
                },
                responsive: true,
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: 'kW/h',
                      align: 'end',
                    },
                    suggestedMax: maxYAxis,
                  },
                },
                elements: {
                  line: {
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                  },
                },
                onClick: (evt: ChartEventProps) => setChartEvent(evt),
              }}
            />
            {dataLoading && (
              <div
                className={classes.loader}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerChart;
