import React, { useEffect, useState, useCallback } from 'react';
import styles from './styles.module.scss';
import { PowerSwitchState } from '@/slicers/operation_page';
import AntSwitch from '@/components/atoms/AntSwitch';
import { CustomButton } from '@/components/atoms/CustomButton';
import { useDeviceCommunication } from '@/hooks/socket';

type SwitchState = {
  state: PowerSwitchState;
  pid: string;
};

export default function WebSocketClientSwitch() {
  const { isAvailable, events } = useDeviceCommunication({ raw: true });
  const [LPID, setLPID] = useState('81RGY2');
  const [WPID, setWPID] = useState('81RGY2');
  const [amps, setAmps] = useState<string>(
    '1,207.5,208.8,206.9,34.567,35.980,36.030,0.68,60.0,8614.9,9603.4,1011576,0,7,1100793,0,100,0,0,0,0'
  );

  const [switchState, setSwitchState] = useState<SwitchState>();

  useEffect(() => {
    if (!isAvailable) {
      setSwitchState(undefined);
    }
  }, [isAvailable]);

  const handleClickConnectBtn = (type: string) => {
    setSwitchState({
      state: PowerSwitchState.OFF,
      pid: type === 'LPID' ? LPID : WPID,
    });
    const signal = `${type}=${type === 'LPID' ? LPID : WPID}`;
    events.send(signal);
    console.log(`${signal} sended`);
  };

  const handleClickAmpsBtn = () => {
    const signal = `KM-N1=${amps}`;
    events.send(signal);
    console.log(`${signal} sended`);
  };

  const createStateEventToClientUser = useCallback(() => {
    let rEquestRquest = `R=${switchState!.pid},`;
    const switchNumber = 0;
    const bit =
      switchState!.state === PowerSwitchState.UNKNOWN
        ? 3
        : switchState!.state === PowerSwitchState.ON
        ? 2
        : 1;
    rEquestRquest += bit << (2 * switchNumber);
    return rEquestRquest;
  }, [switchState]);

  events.onClose = function () {
    setSwitchState(undefined);
  };
  events.onMessage = function (payload) {
    if (payload === 'unAuthrized') {
      console.log('unAuthrized switch');
      setSwitchState(undefined);
    } else if (payload.substring(0, 2) === 'R?') {
      //  send state event to client user
      const rEquestRquest = createStateEventToClientUser();
      events.send(rEquestRquest);
    } else if (payload.substring(0, 3) === 'SW=') {
      //  toggle state event
      let [, bits] = payload.substring(3).split(','); // SW=xx,xxx => xx,xxx => xxx
      bits = +bits; //convert to number
      const switchNumber = 0;
      if ((bits >> switchNumber) & 1) {
        const state =
          switchState!.state === PowerSwitchState.ON
            ? PowerSwitchState.OFF
            : PowerSwitchState.ON;
        setSwitchState((prev) => ({ ...prev!, state }));
      }
    }
  };

  useEffect(() => {
    if (switchState) {
      console.log('switchState change by UI', switchState);
      const rEqualRequest = createStateEventToClientUser();
      events.send(rEqualRequest);
      console.log('send R=');
    }
  }, [switchState, events, createStateEventToClientUser]);

  const isSwitchReady = isAvailable && !!switchState;
  return (
    <div className={styles.socketClientSwitch}>
      <div className={styles.buttons}>
        <div>
          <span>LPID</span>
          <input
            value={LPID}
            onChange={(e) => {
              setLPID(e.target.value);
            }}
          />
          <button
            disabled={!isAvailable}
            onClick={() => handleClickConnectBtn('LPID')}
          >
            接続
          </button>
        </div>
        <div>
          <span>WPID</span>
          <input
            value={WPID}
            onChange={(e) => {
              setWPID(e.target.value);
            }}
          />
          <button
            disabled={!isAvailable}
            onClick={() => handleClickConnectBtn('WPID')}
          >
            接続
          </button>
        </div>
        <div>
          <span>Amps</span>
          <textarea
            value={amps}
            onChange={(e) => {
              setAmps(e.target.value);
            }}
          />
          <button disabled={!isSwitchReady} onClick={handleClickAmpsBtn}>
            Amps
          </button>
        </div>
      </div>
      <div className={styles.switchDevice}>
        <AntSwitch
          disabled={!isSwitchReady}
          checked={switchState?.state === PowerSwitchState.ON}
          onClick={() => {
            setSwitchState((prev) => ({
              ...prev!,
              state:
                prev!.state === PowerSwitchState.ON
                  ? PowerSwitchState.OFF
                  : PowerSwitchState.ON,
            }));
          }}
        />
        <div className={styles.onoff}>
          <CustomButton
            disabled={!isSwitchReady}
            color="error"
            onClick={() => {
              setSwitchState((prev) => ({
                ...prev!,
                state: PowerSwitchState.ON,
              }));
            }}
          >
            ON
          </CustomButton>
          <CustomButton
            disabled={!isSwitchReady}
            color="success"
            onClick={() => {
              setSwitchState((prev) => ({
                ...prev!,
                state: PowerSwitchState.OFF,
              }));
            }}
          >
            OFF
          </CustomButton>
        </div>
        <CustomButton
          disabled={!isSwitchReady}
          color={
            switchState?.state === PowerSwitchState.UNKNOWN ? 'warning' : 'grey'
          }
          onClick={() => {
            setSwitchState((prev) => ({
              ...prev!,
              state: PowerSwitchState.UNKNOWN,
            }));
          }}
        >
          不明
        </CustomButton>
      </div>
    </div>
  );
}
