import React from 'react';
import Input from '@/components/atoms/Input';
import Password from '@/components/atoms/Password';
import { CustomButton } from '@/components/atoms/CustomButton';
import ConfirmDelete from '@/components/atoms/ConfirmDelete';
import classes from './styles.module.scss';
import Select from '@/components/atoms/Select';
import { UserTypeOperator, UserTypeSiteManager } from '@/slicers/users';

interface AgentUserFormProps {
  readonly?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  selectRole?: boolean;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  errors?: Record<string, string>;
  initialData?: Record<string, any>;
  onCancel?: () => void;
  onConfirmDelete?: () => void;
}

export default function AgentUserForm({
  readonly,
  children,
  selectRole,
  initialData = {},
  errors = {},
  onSubmit,
  onCancel,
  onConfirmDelete = () => {},
}: AgentUserFormProps) {
  return (
    <div className={classes.userForm}>
      <form noValidate onSubmit={onSubmit}>
        <div className={classes.inputs}>
          <div className={classes.input}>
            <Input
              required
              label="名前 ※ "
              name="name"
              readonly={readonly}
              defaultValue={initialData.name}
              error={errors.name}
            />
          </div>
          {!initialData.id && (
            <div className={classes.input}>
              <Password
                required
                label="パスワード ※ "
                name="password"
                error={errors.password}
              />
            </div>
          )}

          <div className={classes.input}>
            <Input
              readonly={readonly}
              defaultValue={initialData.email}
              required
              label="メールアドレス ※ "
              name="email"
              error={errors.email}
            />
          </div>
          {selectRole && (
            <div className={classes.input}>
              <Select
                readonly={readonly}
                options={[
                  {
                    label: '現場管理者',
                    value: UserTypeSiteManager,
                  },
                  {
                    label: '使用者',
                    value: UserTypeOperator,
                  },
                ]}
                defaultValue={initialData.type || UserTypeOperator}
                required
                label="権限 ※ "
                name="type"
                error={errors.type}
              />
            </div>
          )}
        </div>
        <div className={classes.buttons}>
          {children || (
            <>
              <CustomButton color="grey" onClick={onCancel}>
                戻る
              </CustomButton>
              {initialData.id && (
                <ConfirmDelete onConfirm={onConfirmDelete}>
                  <CustomButton color="error" className={classes.deleteButton}>
                    削除
                  </CustomButton>
                </ConfirmDelete>
              )}
              <CustomButton
                type="submit"
                color="primary"
                className={classes.submitButton}
              >
                保存
              </CustomButton>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
