import React, { useEffect, useState, useCallback } from 'react';

import classes from './styles.module.scss';
import List from '@/components/atoms/List';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@/components/atoms/Drawer';
import { ErrorPosition } from '@/slicers/response';
import CameraForm from '@/components/organisms/CameraForm';
import ErrorMessage from '@/components/ecosystem/ErrorMessage';
import modulesCamerasRequest from '@/api/modules_cameras';
import { Camera } from '@/slicers/cameras';
import { selectorFindCamerasByModuleId } from '@/selector/moduleCameras';
import { RootState } from '@/app/rootReducer';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

interface CameraDrawerProps {
  moduleId: number;
  onClose: () => void;
}

export default function CameraDrawer({ moduleId, onClose }: CameraDrawerProps) {
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const dispatch = useDispatch();

  const [isLoaded, setLoaded] = useState(false);

  const [isOpenEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState<null | Record<string, any>>();
  const [errors, setErrors] = useState({});

  const cameras = useSelector(selectorFindCamerasByModuleId)(moduleId);
  const pagingOptions = useSelector(
    (state: RootState) => state.moduleCamerasState.pagingOptions
  );

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'ip', 'login_id', 'pass', 'is_valid'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input.hasAttribute('required')) {
        if (!input.value) {
          errors[field] = '必須入力項目です';
        }
      }
      if (input.type === 'checkbox') {
        data[field] = +input.checked;
      } else {
        data[field] = input.value;
      }
      return errors;
    }, {});

    setErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (editData) {
        const api = modulesCamerasRequest.put(moduleId, editData.id);
        await dispatch(api.request(data as Camera));
      } else {
        const api = modulesCamerasRequest.post(moduleId);
        await dispatch(api.request(data as Camera));
        form.reset();
      }
      hideOverlay();
    }
  };

  const onDelete = async (id: number) => {
    showOverlay();
    const api = modulesCamerasRequest.delete(moduleId, id);
    await dispatch(api.request());
    hideOverlay();
  };

  const getCameras = useCallback(
    async (page?: number, keyword?: string) => {
      setLoaded(false);
      await dispatch(
        modulesCamerasRequest.index(moduleId).request(page, keyword)
      );
      setLoaded(true);
    },
    [dispatch, moduleId]
  );

  useEffect(() => {
    getCameras();
  }, [getCameras]);

  useEffect(() => {
    if (!isOpenEdit) {
      setEditData(null);
      setErrors({});
    }
  }, [isOpenEdit]);

  return (
    <div className={classes.detail}>
      {overlayLoader}
      <ErrorMessage position={ErrorPosition.DRAWR} />
      <CameraForm autoFill={false} onSubmit={onSubmit} onCancel={onClose} />
      <div className={classes.list}>
        <List
          showSearch={false}
          loading={!isLoaded}
          onChangePage={getCameras}
          total={pagingOptions.total}
          data={cameras}
          subHeading={<div style={{ marginTop: 10 }} />}
          columns={[
            {
              field: 'name',
              title: 'カメラ名',
            },
            {
              field: 'ip',
              title: 'IP',
            },
            {
              field: 'login_id',
              title: 'カメラログインID ',
            },
          ]}
          title=""
          tooltip={{
            options: [
              {
                key: 'edit',
                title: 'カメラの編集',
              },
              {
                key: 'delete',
                title: 'カメラの削除',
                isConfirmDelete: true,
              },
            ],
            onClick: (key, record) => {
              if (key === 'edit') {
                setOpenEdit(true);
                setEditData(record);
              } else if (key === 'delete') {
                onDelete(record.id);
              }
            },
          }}
        />
      </div>
      <Drawer isOpen={isOpenEdit} onChange={setOpenEdit}>
        {editData && (
          <CameraForm
            initialData={editData}
            errors={errors}
            onSubmit={async e => {
              await onSubmit(e);
              setOpenEdit(false);
            }}
            onCancel={() => setOpenEdit(false)}
          />
        )}
      </Drawer>
    </div>
  );
}
