import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/app/store';
import { PagingOptions } from '@/interfaces';

export type Boolean = 0 | 1;

export type SwitchesState = {
  switchesById: Record<number, Switch>;
  pagingOptions: PagingOptions;
  unAttachSwitches: Record<number, Switch[]>;
};

export enum SwitchStatusType {
  None = 0,
  Renting = 1,
  InUse = 2,
}

export type Switch = {
  id: number;
  agent_id: number;
  company_id: number;
  module_id: number;
  name: string;
  pid: string;
  power_meter_id: number;
  max_amps: number;
  status: number | string;
  end_date?: string;
};

export const initialState: SwitchesState = {
  switchesById: {},
  pagingOptions: {
    total: 0,
    perPage: 5,
  },
  unAttachSwitches: {},
};

const switchesSlice = createSlice({
  name: 'switches',
  initialState,
  reducers: {
    switchAddUnAttachSwitches(
      state,
      action: PayloadAction<{ companyId: number; switches: Switch[] }>
    ) {
      const { companyId, switches } = action.payload;
      state.unAttachSwitches[companyId] =
        state.unAttachSwitches[companyId] || [];
      switches.forEach((swc) => {
        if (
          !state.unAttachSwitches[companyId].some((item) => item.id === swc.id)
        ) {
          state.unAttachSwitches[companyId].push(swc);
        }
      });
    },
    switchSetUnAttachSwitches(
      state,
      action: PayloadAction<{ companyId: number; switches: Switch[] }>
    ) {
      const { companyId, switches } = action.payload;
      state.unAttachSwitches[companyId] = switches;
    },
    clearSwitches(state) {
      const deleteIds = Object.keys(state.switchesById);
      deleteIds.forEach((id) => {
        delete state.switchesById[id as any];
      });
      state.pagingOptions.total = 0;
    },
    switchSetPagingOptions(state, action: PayloadAction<PagingOptions>) {
      state.pagingOptions.perPage = action.payload.perPage;
      state.pagingOptions.total = action.payload.total;
    },
    switchGetsSucces(state, action: PayloadAction<Switch[]>) {
      action.payload.forEach((commsModule) => {
        state.switchesById[commsModule.id] = commsModule;
      });
    },
    switchGetSuccess(state, action: PayloadAction<Switch>) {
      const switchItem = action.payload;
      state.switchesById[switchItem.id] = switchItem;
    },
    switchDeleteSuccess(state, { payload }: PayloadAction<number>) {
      delete state.switchesById[payload];
    },
    switchesDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach((id) => {
        delete state.switchesById[id];
      });
    },
  },
});

export const {
  switchAddUnAttachSwitches,
  switchSetUnAttachSwitches,
  switchDeleteSuccess,
  switchGetSuccess,
  switchGetsSucces,
  switchesDeleteById,
  switchSetPagingOptions,
  clearSwitches,
} = switchesSlice.actions;

export default switchesSlice.reducer;

export const switchesDelete = (
  dispatch: AppDispatch,
  scheduleIdList: number[]
) => {
  dispatch(switchesDeleteById(scheduleIdList));
};
