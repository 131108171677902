import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { InputLabel } from '@material-ui/core';

import moment from 'moment';

import loggerRequest from '@/api/logger';

import { setLoading } from '@/slicers/loading';
import { UserTypeAdmin } from '@/slicers/users';

import { getLoginUserSelector } from '@/selector/users';

import { BackButton } from '@/components/atoms/Buttons';
import { DatetimeTextField } from '@/components/atoms/Datetime';

import useStyles from './styles';

interface LogFile {
  id: number;
  filename: string;
  downloadPath: string;
}

const LoggerList: React.FC = () => {
  const classes = useStyles()();
  const dispatch = useDispatch();

  const loginUser = useSelector(getLoginUserSelector);
  const history = useHistory();

  const [startDate, setStartDate] = useState<string | undefined>(
    moment().format('YYYY-MM-01')
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    moment().format('YYYY-MM-DD')
  );
  const [list, setList] = useState<LogFile[]>([]);

  const getLogs = useCallback(async () => {
    if (!startDate || !endDate) {
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data: logger } = await loggerRequest
        .get(`${startDate},${endDate}`)
        .request();
      if (!logger['statusCode']) {
        setList(logger);
      }
    } catch (error) {
      console.log('error', error);
      setList([]);
    }
    dispatch(setLoading(false));
  }, [dispatch, startDate, endDate]);

  const download = useCallback(
    async (downloadPath: string) => {
      dispatch(setLoading(true));
      try {
        const {
          data: { filename, content },
        } = await loggerRequest.getContent(downloadPath).request();

        var a = document.createElement('a');
        var file = new Blob([content], { type: 'text/plan' });
        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();
      } catch (error) {
        console.log('error', error);
      }
      dispatch(setLoading(false));
    },
    [dispatch]
  );

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  return loginUser!.realType! <= UserTypeAdmin ? (
    <div className={classes.root}>
      <div className={classes.rangeDate}>
        <InputLabel style={{ fontSize: '1rem' }}>日付検索</InputLabel>
        <div className={classes.fields}>
          <DatetimeTextField
            value={startDate}
            timeFormat={false}
            dateFormat={'YYYY-MM-DD'}
            onChange={(props: any) =>
              props && typeof props.format === 'function'
                ? setStartDate(props.format('YYYY-MM-DD'))
                : setStartDate(undefined)
            }
          />
          ~
          <DatetimeTextField
            value={endDate}
            timeFormat={false}
            dateFormat={'YYYY-MM-DD'}
            onChange={(props: any) =>
              props && typeof props.format === 'function'
                ? setEndDate(props.format('YYYY-MM-DD'))
                : setEndDate(undefined)
            }
          />
        </div>
      </div>
      <div className={classes.fileList}>
        {list.map((item) => {
          return (
            <div key={item.id} className={classes.logfile}>
              <span onClick={() => download(item.downloadPath)}>
                {item.filename}
              </span>
            </div>
          );
        })}
      </div>
      <div>
        <BackButton onClick={history.goBack} />
      </div>
    </div>
  ) : (
    <div>権限がありません。</div>
  );
};

export default LoggerList;
