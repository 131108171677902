import React, { useMemo, useCallback } from 'react';
import { ForwardLayer, loginEnqueue } from '@/slicers/login';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { route } from '@/routes/url-generator';
import { UserTypeAgent, UserTypeGeneralManager } from '@/slicers/users';

interface ForwardLoginProps extends Omit<ForwardLayer, 'prevPath'> {
  children: React.ReactElement;
  to?: string;
}

export default function ForwardLogin({
  children,
  type,
  companyId,
  to,
}: ForwardLoginProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const onForward = useCallback(() => {
    dispatch(
      loginEnqueue({
        type,
        companyId,
        prevPath: location.pathname,
      })
    );
    if (type === UserTypeAgent) {
      history.push(to || route.agentCompanies.normalCompanies.list(companyId));
    } else if (type === UserTypeGeneralManager) {
      history.push(to || route.companies.normalCompanies.list);
    }
  }, [dispatch, history, to, type, location.pathname, companyId]);

  const trigger = useMemo(() => {
    return React.cloneElement(
      children,
      { onClick: onForward },
      children.props.children
    );
  }, [children, onForward]);

  return trigger;
}
