import { configureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import rootReducer, { RootState } from './rootReducer'
// const withReduxEnhancer = require('addon-redux/enhancer')

const store = (preloadedState: {} = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
    //   serializableCheck: false
    }),
    // enhancers: [withReduxEnhancer],
    preloadedState
  })

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
      const newRootReducer = require('./rootReducer').default
      store.replaceReducer(newRootReducer)
    })
  }
  return store
}

export type AppDispatch = ReturnType<typeof store>['dispatch']

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store
