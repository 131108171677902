import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import operationPageRequest from '@/api/operation_page';

import { useDeviceCommunication } from '@/hooks/socket';

import { ModuleWith } from '@/slicers/operation_page';
import { User, UserTypeSiteManager } from '@/slicers/users';
import { Site } from '@/slicers/sites';
import { Camera } from '@/slicers/cameras';

import Switches from './Switches';

import classes from './styles.module.scss';

type OperationB1ChannelProps = {
  commUserId?: number;
  site: Site;
  loginUser: User;
  firstSiteUser?: User | null;
  modules: (ModuleWith & {
    cameras: Camera[];
  })[];
  showOverlay: () => void;
  hideOverlay: () => void;
};

const OperationB1Channel = ({
  commUserId,
  site,
  loginUser,
  firstSiteUser,
  modules,
  showOverlay,
  hideOverlay,
}: OperationB1ChannelProps) => {
  const dispatch = useDispatch();
  const [isUserLogged, setUserLogged] = useState(false);
  const { isAvailable, events } = useDeviceCommunication();
  const siteId = site.id.toString();

  const handleSaveModuleName = useCallback(
    async (moduleId, newName) => {
      showOverlay();
      const api = operationPageRequest.updateModuleName(siteId, moduleId);
      await dispatch(api.request(newName));
      hideOverlay();
    },
    [showOverlay, hideOverlay, dispatch, siteId]
  );

  useEffect(() => {
    if (commUserId) {
      events.onOpen = function () {
        events.sendToEvent('client-user-login', {
          token: localStorage.getItem('accessToken'),
          userId: commUserId,
        });
      };
      events.onMessage = function (payload) {
        const { event } = payload as { event: string; data: any };
        if (event === 'ready') {
          console.log('Client user login success with id', commUserId);
          setUserLogged(true);
          events.sendToEvent('pid-request-state');
        } else if (event === 'clientUserLoginFiled') {
          setUserLogged(false);
        }
      };
      return () => {
        events.close?.();
      };
    }
  }, [events, commUserId]);

  const isReady = isAvailable && isUserLogged;

  return (
    <div>
      {loginUser!.type! >= UserTypeSiteManager || !!firstSiteUser ? (
        modules.map((commModule, i) => {
          return (
            <Switches
              key={commModule.deviceCode}
              loginUser={loginUser}
              isReady={isReady}
              events={events}
              handleSaveModuleName={handleSaveModuleName}
              site={site}
              mdl={commModule}
            />
          );
        })
      ) : (
        <div className={classes.header}>
          <div>
            <div>現場管理者または使用者を1名は作成してください。</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OperationB1Channel;
