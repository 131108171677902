import React, {
  useState,
  useCallback,
  useMemo,
  HtmlHTMLAttributes,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DeleteButton } from '../../atoms/Buttons';
import { CustomButton } from '../CustomButton';

import classes from './styles.module.scss';
import Input from '../Input';

const CONFIRM_KEY = 'completely delete';

export enum ConfirmMode {
  NORMAL = 'normal',
  TYPING = 'typing',
}

export interface ConfirmDeleteOptionalProps {
  message?: string;
  buttonMessage?: string;
  type?: ConfirmMode;
  onCancel?: () => void;
  wrapperProps?: HtmlHTMLAttributes<HTMLDivElement>;
}

export interface ConfirmDeleteProps extends ConfirmDeleteOptionalProps {
  onConfirm: () => void | Promise<void>;
  children: React.ReactElement;
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({
  type = ConfirmMode.NORMAL,
  message = '本当に削除しますか',
  buttonMessage = '削除',
  onCancel = () => {},
  onConfirm,
  children,
  wrapperProps = {},
}) => {
  const [isVisble, setVisible] = useState(false);
  const [confirmValue, setConfirmValue] = useState('');

  const closeConfirm = useCallback(() => setVisible(false), []);

  const onClose = useCallback(() => {
    closeConfirm();
    onCancel();
  }, [closeConfirm, onCancel]);

  const onSubmit = useCallback(() => {
    closeConfirm();
    onConfirm();
  }, [closeConfirm, onConfirm]);

  const trigger = useMemo(() => {
    return React.cloneElement(
      children,
      { onClick: () => setVisible(true) },
      children.props.children
    );
  }, [children]);

  return (
    <div {...wrapperProps}>
      <Dialog
        open={isVisble}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <div className={classes.dialogContent}>
          <DialogContent>
            <div className={classes.message}>{message}</div>
            {type === ConfirmMode.TYPING && (
              <div className={classes.confirmText}>
                <Input placeholder={CONFIRM_KEY} onChange={setConfirmValue} />
              </div>
            )}
          </DialogContent>
          <div className={classes.buttons}>
            <CustomButton onClick={onClose} color="grey">
              戻る
            </CustomButton>
            <DeleteButton
              disabled={
                type === ConfirmMode.TYPING && confirmValue !== CONFIRM_KEY
              }
              onClick={onSubmit}
              className={classes.submitButton}
              showIcon={false}
            >
              {buttonMessage}
            </DeleteButton>
          </div>
        </div>
      </Dialog>
      {trigger}
    </div>
  );
};

export default ConfirmDelete;
