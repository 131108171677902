import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { sleep } from '@/utils/sleep';
import { ErrorPosition } from '@/slicers/response';
import { setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import {
  agentModuleGetsSucces,
  agentModuleGetSuccess,
  agentModulesDeleteById,
} from '@/slicers/agentModules';
import { Module } from '@/slicers/modules';
import { MainApiCaller, TApiCaller } from '@/api-service';

export const readModuleWithToModuleWithMap = (
  readModuleWith: Record<string, any>
): Module => {
  const { units, ...other } = readModuleWith;
  let moduleWith: Module = { ...other, unitsByNumber: {} } as any;
  return moduleWith;
};

@MainApiCaller('/agent_companies')
class AgentCompaniesModulesRequest {
  caller!: TApiCaller;

  index(companyId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/${companyId}/modules`);
        if (response.status === 200) {
          dispatch(
            agentModuleGetsSucces(
              response.data.map((readModuleWith: any) =>
                readModuleWithToModuleWithMap(readModuleWith)
              )
            )
          );
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  post(companyId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(
            `/${companyId}/modules`,
            data
          );
          if (response.status === 201) {
            dispatch(
              agentModuleGetSuccess(
                readModuleWithToModuleWithMap(response.data)
              )
            );
            dispatch(setResponse(parseAxiosResponse(response)));
            await sleep(200);
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }

  delete(companyId: string, moduleId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.DEL(
          `/${companyId}/modules/${moduleId}`
        );
        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました'));
          dispatch(agentModulesDeleteById([Number(moduleId)]));
        } else {
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }
}

const agentCompaniesModulesRequest = new AgentCompaniesModulesRequest();
export default agentCompaniesModulesRequest;
