import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagingOptions } from '@/interfaces';
import { User } from '../users';

export type CompanyUsersState = {
  companyUsersById: Record<number, User>;
  pagingOptions: PagingOptions;
};

export const initialState: CompanyUsersState = {
  companyUsersById: {},
  pagingOptions: {
    total: 0,
    perPage: 5,
  },
};

const companyUsersSlice = createSlice({
  name: 'companyUsersSlice',
  initialState,
  reducers: {
    clearCompanyUsers(state) {
      const deleteIds = Object.keys(state.companyUsersById);
      deleteIds.forEach(id => {
        delete state.companyUsersById[id as any];
      });
      state.pagingOptions.total = 0;
    },
    companyUserSetPagingOptions(state, action: PayloadAction<PagingOptions>) {
      state.pagingOptions.perPage = action.payload.perPage;
      state.pagingOptions.total = action.payload.total;
    },
    companyUserGetsSucces(state, action: PayloadAction<User[]>) {
      action.payload.forEach(companyUser => {
        state.companyUsersById[companyUser.id] = companyUser;
      });
    },
    companyUserDeletes(state, action: PayloadAction<number[]>) {
      action.payload.forEach(id => {
        delete state.companyUsersById[id];
      });
    },
  },
});

export const {
  companyUserDeletes,
  companyUserGetsSucces,
  clearCompanyUsers,
  companyUserSetPagingOptions,
} = companyUsersSlice.actions;

export default companyUsersSlice.reducer;
