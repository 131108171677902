import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import {
  companyGetSuccess,
  companyGetsSucces,
  companiesDelete,
  clearCompanies,
} from '@/slicers/companies';
import { ErrorPosition } from '@/slicers/response';
import { setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/agent_companies')
class AgentCompaniesNormalCompaniesRequest {
  caller!: TApiCaller;
  index(companyId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        dispatch(clearCompanies());
        const response = await this.caller.GET(
          `/${companyId}/normal_companies`
        );
        if (response.status === 200) {
          dispatch(companyGetsSucces(response.data));
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(setResponse(parseAxiosResponse(response)));
          dispatch(setFlashError('取得が失敗しました'));
        }
      },
    };
  }

  post(companyId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(
            `/${companyId}/normal_companies`,
            data
          );
          if (response.status === 201) {
            const company = response.data.company;
            dispatch(companyGetSuccess(company));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('追加しました'));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('追加が失敗しました。'));
          }
        },
    };
  }

  put(companyId: string, normalCompanyId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${companyId}/normal_companies/${normalCompanyId}`,
            data
          );

          if (response.status === 200) {
            dispatch(companyGetSuccess(response.data));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }

  delete(companyId: string, normalCompanyId: string) {
    return {
      request: (): AppThunk => async (dispatch, getState) => {
        const response = await this.caller.DEL(
          `/${companyId}/normal_companies/${normalCompanyId}`
        );
        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました'));
          companiesDelete(dispatch, getState(), [Number(companyId)]);
        } else {
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }
}

const agentCompaniesNormalCompaniesRequest =
  new AgentCompaniesNormalCompaniesRequest();
export default agentCompaniesNormalCompaniesRequest;
