import React from 'react'
import { Link as MaterialLink, Typography, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { IconProps } from '@/slicers/sidebar'
import styles from './styles.module.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex'
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20
    }
  })
)

export type iconNames = 'inbox'

export type BreadCrumbItemProps = {
  icon?: IconProps
  to?: string
  title: string
}

const BreadCrumbItem: React.FC<BreadCrumbItemProps> = ({ title, to, icon }) => {
  const classes = useStyles()

  return (
    <div className={styles.hide}>
      {to ? (
        <MaterialLink
          color="inherit"
          component={Link}
          to={to}
          key={to}
          className={classes.link}
        >
          {icon && <IconWrapper Icon={icon} className={classes.icon} />}
          {title}
        </MaterialLink>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography color="textPrimary" className={styles.text}>
            {icon && <IconWrapper Icon={icon} className={classes.icon} />}
            {title}
          </Typography>
        </Box>
      )}
    </div>
  )
}

export default BreadCrumbItem

type IconWrapperProps = {
  Icon: IconProps
  className: string
}

const IconWrapper: React.FC<IconWrapperProps> = ({ Icon, className }) => (
  <Icon className={className} />
)
