import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import {
  siteModuleGetsSucces,
  siteModuleSetUnAttachModules,
  removeSiteModules,
  siteModuleAddUnAttachModules,
} from '@/slicers/siteModules';
import { readModuleWithToModuleWithMap } from '../companies_modules';
import { ErrorPosition, setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/sites')
class SitesModulesRequest {
  caller!: TApiCaller;
  index(siteId: string) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(`/${siteId}/modules`);
        if (response.status === 200) {
          dispatch(
            siteModuleGetsSucces(
              response.data.map((moduleWith: any) =>
                readModuleWithToModuleWithMap(moduleWith)
              )
            )
          );
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(
            setResponse({
              ...parseAxiosResponse(response),
              position: ErrorPosition.DRAWR,
            })
          );
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  unAttach(siteId: number) {
    return {
      request: (): AppThunk => async (dispatch) => {
        const response = await this.caller.GET(
          `/${siteId}/module_without_site`
        );
        if (response.status === 200) {
          dispatch(
            siteModuleSetUnAttachModules({
              siteId,
              modules: response.data.map((moduleWith: any) =>
                readModuleWithToModuleWithMap(moduleWith)
              ),
            })
          );
          dispatch(setResponse(parseAxiosResponse(response)));
        } else {
          dispatch(
            setResponse({
              ...parseAxiosResponse(response),
              position: ErrorPosition.DRAWR,
            })
          );
          dispatch(setFlashError('取得が失敗しました。'));
        }
      },
    };
  }

  put(siteId: string) {
    return {
      request:
        (isAttach: boolean, moduleId: string): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            isAttach
              ? `/${siteId}/modules/${moduleId}/attach`
              : `/${siteId}/modules/${moduleId}/detach`
          );
          if (response.status === 200) {
            if (isAttach) {
              dispatch(siteModuleGetsSucces([response.data as any]));
            } else {
              dispatch(
                siteModuleAddUnAttachModules({
                  siteId: Number(siteId),
                  modules: [readModuleWithToModuleWithMap(response.data)],
                })
              );
              dispatch(removeSiteModules([Number(moduleId)]));
            }
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }

  updateEmissionFactor(siteId: number) {
    return {
      request:
        (factorId: number, moduleId: number): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${siteId}/modules/${moduleId}/update_emission_factor_id`,
            {
              m_emission_factor_id: factorId,
            }
          );
          if (response.status === 200) {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }
}

const sitesModulesRequest = new SitesModulesRequest();

export default sitesModulesRequest;
