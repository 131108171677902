import { RootState } from '@/app/rootReducer';
import { createSelector } from 'reselect';

export const selectorFindAllModulesByIdBySiteId = (siteId: number) =>
  createSelector(
    (state: RootState) => state.siteModulesState.siteModulesById,
    siteModulesById =>
      Object.fromEntries(
        Object.values(siteModulesById)
          .filter(commModule => commModule.site_id === siteId)
          .map(commModule => [commModule.id, commModule])
      )
  );

export const selectorFindAllModulesBySiteId = (siteId: number) =>
  createSelector(
    (state: RootState) => state.siteModulesState.siteModulesById,
    siteModulesById =>
      Object.values(siteModulesById).filter(mdl => mdl.site_id === siteId)
  );

export const selectorUnAttachModulesBySiteId = (siteId: number) =>
  createSelector(
    (state: RootState) => state.siteModulesState.unAttachModules,
    (state: RootState) => state.siteModulesState.siteModulesById,
    (unAttachModules, siteModulesById) =>
      (unAttachModules[siteId] || []).filter(mdl => !siteModulesById[mdl.id])
  );
