import CompaniesModulesList from '@/components/ecosystem/CompaniesModules/List';
import CompaniesModulesShow from '@/components/ecosystem/CompaniesModules/Show';
import CompaniesSitesList from '@/components/ecosystem/CompaniesSites/List';
import CompaniesSiteManagerUsersOperationPannel from '@/components/ecosystem/CompaniesSiteManagers/OperationPannel';
import CompaniesModulesBreadCrumb from '@/components/ecosystem/CompaniesModules/BreadCrumb';
import CompaniesModulesListBreadCrumb from '@/components/ecosystem/CompaniesModules/List/BreadCrumb';
import CompaniesModulesShowBreadCrumb from '@/components/ecosystem/CompaniesModules/Show/BreadCrumb';
import CompaniesModulesBreadCrumbDetail from '@/components/ecosystem/CompaniesModules/BreadCrumbDetail';
import CompaniesSitesBreadCrumb from '@/components/ecosystem/CompaniesSites/BreadCrumb';
import CompaniesSitesListBreadCrumb from '@/components/ecosystem/CompaniesSites/List/BreadCrumb';
import OperationPannelBreadCrumb from '@/components/ecosystem/CompaniesSiteManagers/OperationPannel/BreadCrumb';
import ClientRouteBreadCrumb from '@/components/ecosystem/ClientRoute/BreadCrumb';
import SitesModulesList from '@/components/ecosystem/SitesModules/List';
import SitesModulesBreadCrumb from '@/components/ecosystem/SitesModules/BreadCrumb';
import SitesModulesBreadCrumbList from '@/components/ecosystem/SitesModules/List/BreadCrumb';
import SiteOperationLogsList from '@/components/ecosystem/SiteOperationLogs/List';
import CompaniesUsersList from '@/components/ecosystem/CompaniesUsers/List';
import CompaniesUsersBreadCrumb from '@/components/ecosystem/CompaniesUsers/BreadCrumb';
import CompaniesUsersListBreadCrumb from '@/components/ecosystem/CompaniesUsers/List/BreadCrumb';

export type ClientRouteParams = {
  curt_nml_co: string;
  site_mng_usr?: string;
};

export type GeneralManagersRouteParams = {
  general_manager_user: string;
};

export type SiteManagersRouteParams = {
  site_manager_user: string;
};

export type ModulesRouteParams = {
  module: string;
};

export type SitesRouteParams = {
  site: string;
};

export type SitesModulesRouteParams = {
  site: string;
  module: string;
};

export type SitesSchedulesRouteParams = {
  site: string;
  schedule: string;
};

export const CompanyUserType = {
  general_manager: 400,
  site: 600,
  operator: 700,
};

export const clientRoute = {
  url: '/curt_nml_co/:curt_nml_co',
  breadCrumbs: [ClientRouteBreadCrumb],
  childMaps: [
    {
      url: '/users',
      breadCrumbs: [CompaniesUsersBreadCrumb],
      childMaps: [
        {
          url: '/general_manager',
          breadCrumbs: [],
          childMaps: [
            {
              url: '/list',
              component: CompaniesUsersList,
              breadCrumbs: [CompaniesUsersListBreadCrumb],
            },
          ],
        },
        {
          url: '/site',
          breadCrumbs: [],
          childMaps: [
            {
              url: '/list',
              component: CompaniesUsersList,
              breadCrumbs: [CompaniesUsersListBreadCrumb],
            },
          ],
        },
        {
          url: '/operator',
          breadCrumbs: [],
          childMaps: [
            {
              url: '/list',
              component: CompaniesUsersList,
              breadCrumbs: [CompaniesUsersListBreadCrumb],
            },
          ],
        },
      ],
    },
    {
      url: '/modules',
      breadCrumbs: [CompaniesModulesBreadCrumb],
      childMaps: [
        {
          url: '/list',
          component: CompaniesModulesList,
          breadCrumbs: [CompaniesModulesListBreadCrumb],
        },
        {
          url: '/:module',
          breadCrumbs: [CompaniesModulesBreadCrumbDetail],

          childMaps: [
            {
              url: '/show',
              component: CompaniesModulesShow,
              breadCrumbs: [CompaniesModulesShowBreadCrumb],
            },
          ],
        },
      ],
    },
    {
      url: '/sites',
      breadCrumbs: [CompaniesSitesBreadCrumb],
      childMaps: [
        {
          url: '/list',
          component: CompaniesSitesList,
          breadCrumbs: [CompaniesSitesListBreadCrumb],
        },

        {
          url: '/:site',
          breadCrumbs: [],
          childMaps: [
            {
              url: '/operation_pannel/:module?',
              component: CompaniesSiteManagerUsersOperationPannel,
              breadCrumbs: [OperationPannelBreadCrumb],
            },
            {
              url: '/operation_log',
              childMaps: [
                {
                  url: '/list',
                  component: SiteOperationLogsList,
                  //   breadCrumbs: [CompaniesModulesListBreadCrumb]
                },
              ],
            },
            {
              url: '/modules',
              breadCrumbs: [SitesModulesBreadCrumb],
              childMaps: [
                {
                  url: '/list',
                  component: SitesModulesList,
                  breadCrumbs: [SitesModulesBreadCrumbList],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
