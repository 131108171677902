import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { userGetSuccess } from '@/slicers/users';
import { setResponse } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/users')
class UsersRequest {
  caller!: TApiCaller;
  put(userId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(`/${userId}`, data);

          if (response.status === 200) {
            dispatch(userGetSuccess(response.data));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('更新が失敗しました。'));
          }
        },
    };
  }

  changePassword(userId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${userId}/change_password`,
            data
          );

          if (response.status === 200) {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('パスワードを変更しました。'));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('パスワードの変更に失敗しました。'));
          }
        },
    };
  }
}

const usersRequest = new UsersRequest();

export default usersRequest;
