import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/app/rootReducer';
import { getAgentCompaniesUsersSelector } from '@/selector/users';

import agentCompaniesUsersRequest from '@/api/agent_companies_users';
import agentCompaniesModulesRequest from '@/api/agent_companies_modules';
import agentCompaniesSwitchesRequest from '@/api/agent_companies_switches';

import { route } from '@/routes/url-generator';

import { Switch } from '@/slicers/switches';
import { ModuleChannelType } from '@/slicers/modules';
import { SwitchStatusType } from '@/slicers/switches';
import { setLoading } from '@/slicers/loading';
import { setFlashSuccess } from '@/slicers/flash';
import { User } from '@/slicers/users';
import { ErrorPosition } from '@/slicers/response';

import { selectorFindAllAgentModulesByOwnerId } from '@/selector/agentModule';
import { getSwitchesByAgentId } from '@/selector/switches';

import { UserFormData } from '@/components/organisms/UserForm';
import Drawer from '@/components/atoms/Drawer';
import { ConfirmMode } from '@/components/atoms/ConfirmDelete';
import AgentCompanyForm from '@/components/organisms/AgentCompanyForm';
import AgentUserForm from '@/components/organisms/AgentUserForm';
import List from '@/components/atoms/List';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

import ModuleCreateForm from '../ModuleCreateForm';
import ErrorMessage from '../../ErrorMessage';
import SwitchManagementForm from '../SwitchManagementForm';

import classes from './styles.module.scss';

interface DetailProps {
  onSubmit: (
    closeDrawer: Function
  ) => (e: React.FormEvent<HTMLFormElement>) => void;
  errors: Record<string, string>;
  initialData: Record<string, any>;
  onConfirmDelete: (id: string) => void;
  editType: string;
  onCancel?: () => void;
}

export default function Detail({
  errors,
  onSubmit,
  initialData,
  onConfirmDelete,
  editType,
  onCancel = () => {},
}: DetailProps) {
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading } = useSelector((state: RootState) => state.loadingState);

  const users = useSelector((state: RootState) =>
    getAgentCompaniesUsersSelector(Number(initialData.id))(state)
  );

  const modules = useSelector((state: RootState) =>
    selectorFindAllAgentModulesByOwnerId(Number(initialData.id))(state)?.map(
      (item) => ({
        ...item,
        typeLabel:
          item.type === ModuleChannelType.B16Channel
            ? '16ch'
            : item.type === ModuleChannelType.B1Channel
            ? '1ch'
            : '64ch',
      })
    )
  );

  const switches = useSelector((state: RootState) =>
    getSwitchesByAgentId(Number(initialData.id))(state)
  )?.map((item) => ({
    ...item,
    status:
      item.status === SwitchStatusType.Renting
        ? 'レンタル中'
        : item.status === SwitchStatusType.InUse
        ? '紐付け中'
        : '利用可能',
  }));

  const [isOpenEdit, setOpenEdit] = useState(false);

  const [userAgencyErrors, setUserAgencyErrors] = useState<
    Record<string, string>
  >({});
  const [userAgencyEditData, setUserAgencyEditData] = useState<
    undefined | User
  >(undefined);
  const [switchAgencyEditData, setSwitchAgencyEditData] = useState<
    undefined | Switch
  >(undefined);
  const [isOpenEditUserAgency, setOpenEditUserAgency] = useState(false);
  const [isOpenEditSwitchAgency, setOpenEditSwitchAgency] = useState(false);
  const [filter, setFilter] = useState({
    ch1: false,
    ch16: false,
    ch64: false,
  });

  const onUserAgencySubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'password', 'email'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input) {
        if (input.hasAttribute('required')) {
          if (!input.value) {
            errors[field] = '必須入力項目です';
          }
        }
        data[field] = input.value;
      }

      return errors;
    }, {});

    setUserAgencyErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (userAgencyEditData) {
        const api = agentCompaniesUsersRequest.put(
          initialData.id,
          userAgencyEditData.id.toString()
        );
        await dispatch(api.request(data as UserFormData));
        setOpenEditUserAgency(false);
      } else {
        const api = agentCompaniesUsersRequest.post(initialData.id);
        await dispatch(api.request(data as UserFormData));
        closeDrawer();
      }
      hideOverlay();
    }
  };

  const onDeleteUserAgency = useCallback(
    async (userAgencyId) => {
      showOverlay();
      const api = agentCompaniesUsersRequest.delete(
        initialData.id,
        userAgencyId
      );
      await dispatch(api.request());
      await dispatch(setFlashSuccess('削除しました'));
      hideOverlay();
    },
    [dispatch, showOverlay, hideOverlay, initialData.id]
  );

  const [moduleErrors, setModuleErrors] = useState<Record<string, string>>({});
  const [switchErrors, setSwitchErrors] = useState<Record<string, string>>({});

  const onModuleSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['device_code', 'type'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];

      if (input) {
        if (input.length) {
          input.forEach((item: any) => {
            if (item.hasAttribute('required')) {
              if (!item.value) {
                errors[field] = '必須入力項目です';
              }
            }
          });
        } else {
          if (input.hasAttribute('required')) {
            if (!input.value) {
              errors[field] = '必須入力項目です';
            }
          }
        }
        data[field] = input.value;
      }

      return errors;
    }, {});

    setModuleErrors(errors);
    if (!Object.keys(errors).length) {
      showOverlay();
      const api = agentCompaniesModulesRequest.post(initialData.id);
      data.type = Number(data.type);
      await dispatch(api.request(data as any));
      closeDrawer();
      hideOverlay();
    }
  };

  const onDeleteModule = useCallback(
    async (moduleId) => {
      const api = agentCompaniesModulesRequest.delete(initialData.id, moduleId);
      showOverlay();
      await dispatch(api.request());
      hideOverlay();
    },
    [dispatch, showOverlay, hideOverlay, initialData.id]
  );

  const handleFilterModules = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    });
  };

  const onSwitchSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['power_meter_id', 'pid', 'max_amps'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input) {
        if (input.hasAttribute('required')) {
          if (!input.value) {
            errors[field] = '必須入力項目です';
          }
        }
        data[field] = input.value;
      }

      return errors;
    }, {});

    setSwitchErrors(errors);
    if (!Object.keys(errors).length) {
      showOverlay();
      data.max_amps = Number(data.max_amps);
      data.power_meter_id = Number(data.power_meter_id);
      if (switchAgencyEditData) {
        const api = agentCompaniesSwitchesRequest.put(
          initialData.id,
          switchAgencyEditData.id.toString()
        );
        await dispatch(api.request(data as any));
        setSwitchAgencyEditData(undefined);
        setOpenEditSwitchAgency(false);
      } else {
        const api = agentCompaniesSwitchesRequest.post(initialData.id);
        await dispatch(api.request(data as any));
        closeDrawer();
      }

      hideOverlay();
    }
  };

  const onDeleteSwitch = useCallback(
    async (switchId) => {
      const api = agentCompaniesSwitchesRequest.delete(
        initialData.id,
        switchId
      );
      showOverlay();
      await dispatch(api.request());
      hideOverlay();
    },
    [dispatch, showOverlay, hideOverlay, initialData.id]
  );

  useEffect(() => {
    const func = async () => {
      await dispatch(setLoading(true));
      await dispatch(
        agentCompaniesModulesRequest.index(initialData.id).request()
      );
      await dispatch(
        agentCompaniesUsersRequest.index(initialData.id).request()
      );
      await dispatch(
        agentCompaniesSwitchesRequest.index(initialData.id).request()
      );
      await dispatch(setLoading(false));
    };
    func();
  }, [dispatch, initialData.id]);

  useEffect(() => {
    if (!isOpenEditUserAgency) {
      setUserAgencyErrors({});
      setUserAgencyEditData(undefined);
    }
  }, [isOpenEditUserAgency]);

  const filteredModules = useMemo(() => {
    const filterTypes: ModuleChannelType[] = [];
    if (filter.ch1) filterTypes.push(ModuleChannelType.B1Channel);
    if (filter.ch16) filterTypes.push(ModuleChannelType.B16Channel);
    if (filter.ch64) filterTypes.push(ModuleChannelType.B64Channel);

    if (filterTypes.length) {
      return modules.filter((mdl) => filterTypes.includes(mdl.type!));
    }
    return modules;
  }, [modules, filter]);

  return (
    <div className={classes.detail}>
      {overlayLoader}
      <ErrorMessage position={ErrorPosition.DRAWR} />
      {editType === 'agent' && (
        <AgentCompanyForm
          initialData={initialData}
          errors={errors}
          onSubmit={onSubmit(() => setOpenEdit(false))}
          onCancel={onCancel}
          onConfirmDelete={() => onConfirmDelete(initialData.id)}
          onDeleteMessage="代理店を削除すると企業データ、現場データ、ユーザーすべてが削除されます。本当に削除してもよろしいですか？"
        />
      )}
      {editType === 'agentmanager' && (
        <List
          loading={loading}
          data={users}
          columns={[
            {
              field: 'name',
              title: '管理者',
              width: '40%',
            },
            {
              field: 'email',
              title: 'メールアドレス',
              width: '60%',
            },
          ]}
          getDrawerContent={(closeDrawer) => {
            const onCloseDrawer = () => {
              closeDrawer();
              setUserAgencyErrors({});
            };

            return (
              <AgentUserForm
                errors={userAgencyErrors}
                onSubmit={onUserAgencySubmit(onCloseDrawer)}
                onCancel={onCloseDrawer}
              />
            );
          }}
          emptyMessage="データがありません"
          title=""
          tooltip={{
            options: (item) =>
              item.email_verified_at
                ? [
                    {
                      key: 'edit',
                      title: 'ユーザーの編集',
                    },
                    {
                      key: 'delete',
                      title: 'ユーザーの削除',
                      isConfirmDelete: true,
                    },
                  ]
                : [
                    {
                      key: 'edit',
                      title: 'ユーザーの編集',
                    },
                    {
                      key: 'delete',
                      title: 'ユーザーの削除',
                      isConfirmDelete: true,
                    },
                    {
                      key: 'sendMail',
                      title: '認証メール送信',
                    },
                  ],
            onClick: async (key, record) => {
              if (key === 'edit') {
                setOpenEditUserAgency(true);
                setUserAgencyEditData(record);
              } else if (key === 'delete') {
                onDeleteUserAgency(record.id);
              } else if (key === 'sendMail') {
                console.log(record);
                await dispatch(setLoading(true));
                await dispatch(
                  agentCompaniesUsersRequest
                    .sendAuthMail(String(record.company_id), record.email)
                    .request()
                );
                await dispatch(setLoading(false));
              }
            },
          }}
          addButtonText="代理店管理者作成"
        />
      )}
      {editType === 'module' && (
        <List
          filterFields={['device_code']}
          loading={loading}
          subHeading={
            <div className={classes.modulesFilter}>
              <FormGroup
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  top: 18,
                  right: 450,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ch64"
                      onChange={handleFilterModules}
                      checked={filter.ch64}
                    />
                  }
                  label="64ch"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ch16"
                      onChange={handleFilterModules}
                      checked={filter.ch16}
                    />
                  }
                  label="16ch"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ch1"
                      onChange={handleFilterModules}
                      checked={filter.ch1}
                    />
                  }
                  label="1ch"
                />
              </FormGroup>
            </div>
          }
          data={filteredModules}
          columns={[
            {
              field: 'name',
              title: '指令盤名',
              width: '40%',
            },
            {
              field: 'typeLabel',
              title: 'タイプ',
              width: '15%',
            },
            {
              field: 'control_no',
              title: '資産番号',
              width: '20%',
            },
            {
              field: 'device_code',
              title: 'デバイスコード',
              width: '20%',
            },
          ]}
          getDrawerContent={(closeDrawer) => {
            const onCloseDrawer = () => {
              closeDrawer();
              setModuleErrors({});
            };

            return (
              <ModuleCreateForm
                errors={moduleErrors}
                onSubmit={onModuleSubmit(onCloseDrawer)}
                onCancel={onCloseDrawer}
              />
            );
          }}
          emptyMessage="データがありません"
          title=""
          tooltip={{
            options: (item) =>
              !item.site_id && !item.company_id
                ? [
                    {
                      key: 'delete',
                      title: '完全削除',
                      isConfirmDelete: true,
                      confirmProps: {
                        type: ConfirmMode.TYPING,
                        message: '親機を完全に削除します。よろしいですか。',
                      },
                    },
                  ]
                : [
                    ...((item.type === ModuleChannelType.B1Channel
                      ? [
                          {
                            key: 'power-chart',
                            title: '使用状況を見る',
                          },
                        ]
                      : []) as any[]),
                  ],
            onClick: (key, record) => {
              if (key === 'delete') {
                onDeleteModule(record.id);
              } else {
                history.push(route.modules.powerChart(record.id));
              }
            },
          }}
          addButtonText="指令盤作成"
        />
      )}
      {editType === 'switches' && (
        <List
          filterFields={['pid']}
          loading={loading}
          data={switches}
          columns={[
            {
              field: 'name',
              title: 'スイッチ名',
              width: '50%',
            },
            {
              field: 'pid',
              title: 'PID',
              width: '20%',
            },
            {
              field: 'status',
              title: '状態',
              width: '20%',
            },
          ]}
          getDrawerContent={(closeDrawer) => {
            const onCloseDrawer = () => {
              closeDrawer();
              setSwitchErrors({});
            };

            return (
              <SwitchManagementForm
                errors={switchErrors}
                onSubmit={onSwitchSubmit(onCloseDrawer)}
                onCancel={onCloseDrawer}
              />
            );
          }}
          emptyMessage="データがありません"
          title=""
          tooltip={{
            options: [
              {
                key: 'edit',
                title: '編集',
              },
              {
                key: 'delete',
                title: '削除',
                isConfirmDelete: true,
                confirmProps: {
                  type: ConfirmMode.TYPING,
                  message: '親機を完全に削除します。よろしいですか。',
                },
              },
            ],
            onClick: (key, record) => {
              if (key === 'edit') {
                setOpenEditSwitchAgency(true);
                setSwitchAgencyEditData(record);
              } else if (key === 'delete') {
                onDeleteSwitch(record.id);
              }
            },
          }}
          addButtonText="スイッチの作成"
        />
      )}
      <Drawer isOpen={isOpenEditUserAgency} onChange={setOpenEditUserAgency}>
        {userAgencyEditData && (
          <AgentUserForm
            initialData={userAgencyEditData}
            errors={userAgencyErrors}
            onSubmit={onUserAgencySubmit(() => setOpenEditUserAgency(false))}
            onCancel={() => setOpenEditUserAgency(false)}
          />
        )}
      </Drawer>
      <Drawer isOpen={isOpenEdit} onChange={setOpenEdit}>
        {initialData && (
          <AgentCompanyForm
            initialData={initialData}
            errors={errors}
            onSubmit={onSubmit(() => setOpenEdit(false))}
            onCancel={() => setOpenEdit(false)}
          />
        )}
      </Drawer>
      <Drawer
        isOpen={isOpenEditSwitchAgency}
        onChange={setOpenEditSwitchAgency}
      >
        {initialData && (
          <SwitchManagementForm
            initialData={switchAgencyEditData}
            errors={switchErrors}
            onSubmit={onSwitchSubmit(() => setOpenEditSwitchAgency(false))}
            onCancel={() => setOpenEditSwitchAgency(false)}
          />
        )}
      </Drawer>
    </div>
  );
}
