import React, { useMemo } from 'react';
import { PowerSwitchState } from '@/slicers/operation_page';

import nonReadyAvatar from './images/non-ready.svg';
import initStateAvatar from './images/init-state.svg';
import lockAvatar from './images/lock-solid.svg';
import leakAvatar from './images/leak.svg';
import unknowAvatar from './images/unknow-state.svg';
import onAvatar from './images/btn-light-on.svg';
import offAvatar from './images/btn-light-off.svg';
import { getLoginUserSelector } from '@/selector/users';

import classes from './styles.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { UserTypeAdmin } from '@/slicers/users';
import ConfirmDelete from '@/components/atoms/ConfirmDelete';

type SwitcherProps = {
  isReady: boolean;
  isLock?: boolean;
  state: PowerSwitchState;
  label: string;
  showAmp?: boolean;
  amps?: number;
  maxAmps?: number;
  switching?: boolean;
  showResetButton?: boolean;
  onChange: (nextState: PowerSwitchState) => void;
  reset: () => void;
};

const Switcher = ({
  onChange,
  switching = false,
  isReady,
  showAmp,
  amps,
  maxAmps,
  isLock,
  state,
  label,
  showResetButton = false,
  reset,
}: SwitcherProps) => {
  const ampsPercent = useMemo(() => {
    if (amps && maxAmps) {
      return +((amps / maxAmps) * 100).toFixed(3);
    }
    return 0;
  }, [amps, maxAmps]);

  const loginUser = useSelector((state: RootState) =>
    getLoginUserSelector(state)
  );
  return (
    <div className={classes.switcher}>
      <div
        data-switching={switching}
        className={classes.avatar}
        onClick={() => {
          if (
            !switching &&
            !isLock &&
            isReady &&
            (state === PowerSwitchState.ON || state === PowerSwitchState.OFF)
          ) {
            onChange(
              state === PowerSwitchState.ON
                ? PowerSwitchState.OFF
                : PowerSwitchState.ON
            );
          } else {
            console.log("can't click ");
          }
        }}
      >
        {isReady ? (
          <>
            <div
              data-type={
                state === PowerSwitchState.ON ||
                state === PowerSwitchState.OFF ||
                state === PowerSwitchState.LEAK
                  ? 'blue'
                  : 'red'
              }
              className={classes.lamp}
            />
            {isLock ? (
              <img src={lockAvatar} alt="" style={{ width: '60%' }} />
            ) : state === PowerSwitchState.LEAK ? (
              <img
                src={leakAvatar}
                alt=""
                style={{ transform: 'scale(1.2) translate(3px, 3px)' }}
              />
            ) : state === PowerSwitchState.UNKNOWN ? (
              <img
                src={unknowAvatar}
                alt=""
                style={{ transform: 'scale(1.2) translate(3px, 3px)' }}
              />
            ) : state === PowerSwitchState.ON ? (
              <img
                src={onAvatar}
                alt=""
                style={{ transform: 'scale(1.2) translate(3px, 3px)' }}
              />
            ) : state === PowerSwitchState.OFF ? (
              <img
                src={offAvatar}
                alt=""
                style={{ transform: 'scale(1.2) translate(3px, 3px)' }}
              />
            ) : (
              <img src={initStateAvatar} alt="" />
            )}
          </>
        ) : (
          <img src={nonReadyAvatar} alt="" />
        )}

        {state === PowerSwitchState.ON && showAmp && (
          <span className={classes.amp}>
            <span>{amps?.toFixed(3) || '----'}</span>
            <span>/{maxAmps?.toFixed(3) || '---'}A</span>
          </span>
        )}
      </div>
      <div data-percent={ampsPercent} className={classes.bottomBar}>
        {Array.from({ length: 10 }).map((_, i) => {
          const unitPosition = i + 1;
          const [start, end] = [(unitPosition - 1) * 10 + 1, unitPosition * 10];

          return (
            <div
              data-percent={`[${start},${end}]`}
              data-light={
                state === PowerSwitchState.ON &&
                (end <= ampsPercent || start <= ampsPercent ? 'on' : 'off')
              }
              data-type={end <= 50 ? 'safe' : end > 70 ? 'danger' : 'warn'}
              key={i}
            />
          );
        })}
      </div>
      <p>{label}</p>
      {loginUser?.realType! <= UserTypeAdmin && showResetButton ? (
        <div className={classes.btnReset}>
          <ConfirmDelete
            message="本当にリセットしてよいですか？"
            buttonMessage="リセット"
            onConfirm={() => {
              reset();
            }}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              積算電力量リセット
            </button>
          </ConfirmDelete>
        </div>
      ) : (
        <div className={classes.noButton} />
      )}
    </div>
  );
};

export default Switcher;
