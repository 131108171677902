import { CompanyUserType } from './ClientRoute';

const generateClientRoutePrefix = (currentNormalCompanyId: string | number) =>
  `/curt_nml_co/${currentNormalCompanyId}`;

export const route = {
  login: '/login',
  logger: '/logger',
  siteLogManagement: '/site-log-management',
  agentCompanies: {
    list: `/agent_companies/list`,
    create: `/agent_companies/create`,
    show: (agentCompanyId: string | number) =>
      `/agent_companies/${agentCompanyId}/show`,
    edit: (agentCompanyId: string | number) =>
      `/agent_companies/${agentCompanyId}/edit`,
    users: {
      list: (agentCompanyId: string | number) =>
        `/agent_companies/${agentCompanyId}/users/list`,
      create: (agentCompanyId: string | number) =>
        `/agent_companies/${agentCompanyId}/users/create`,
      show: (agentCompanyId: string | number, userId: string | number) =>
        `/agent_companies/${agentCompanyId}/users/${userId}/show`,
      edit: (agentCompanyId: string | number, userId: string | number) =>
        `/agent_companies/${agentCompanyId}/users/${userId}/edit`,
    },
    normalCompanies: {
      list: (agentCompanyId: string | number) =>
        `/agent_companies/${agentCompanyId}/normal_companies/list`,
      create: (agentCompanyId: string | number) =>
        `/agent_companies/${agentCompanyId}/normal_companies/create`,
      show: (agentCompanyId: string | number, companyId: string | number) =>
        `/agent_companies/${agentCompanyId}/normal_companies/${companyId}/show`,
      edit: (agentCompanyId: string | number, companyId: string | number) =>
        `/agent_companies/${agentCompanyId}/normal_companies/${companyId}/edit`,
      generalManagerUsers: {
        create: (agentCompanyId: string | number, companyId: string | number) =>
          `/agent_companies/${agentCompanyId}/normal_companies/${companyId}/general_manager_users/create`,
        show: (
          agentCompanyId: string | number,
          companyId: string | number,
          generalManagerUserId: string | number
        ) =>
          `/agent_companies/${agentCompanyId}/normal_companies/${companyId}/general_manager_users/${generalManagerUserId}/show`,
      },
      modules: {
        show: (
          agentCompanyId: string | number,
          companyId: string | number,
          moduleId: string | number
        ) =>
          `/agent_companies/${agentCompanyId}/normal_companies/${companyId}/modules/${moduleId}/show`,
      },
    },
    modules: {
      list: (agentCompanyId: string | number) =>
        `/agent_companies/${agentCompanyId}/modules/list`,
    },
    generalManagerUsers: {
      list: (agentCompanyId: string | number) =>
        `/agent_companies/${agentCompanyId}/general_managers/list`,
      create: (agentCompanyId: string | number) =>
        `/agent_companies/${agentCompanyId}/general_managers/create`,
      show: (
        agentCompanyId: string | number,
        generalManagerUserId: string | number
      ) =>
        `/agent_companies/${agentCompanyId}/general_managers/${generalManagerUserId}/show`,
    },
  },
  companies: {
    normalCompanies: {
      list: `/normal_companies/list`,
    },
  },
  modules: {
    list: `/modules/list`,
    powerChart: (moduleId: number) => `/modules/${moduleId}/power-chart`,
    powerChartMonth: (moduleId: number) => `/modules/${moduleId}/power-chart/month`,
    powerChartDay: (moduleId: number) => `/modules/${moduleId}/power-chart/day`,
  },
  clientRoute: {
    generalManagerUsers: {
      list: (currentNormalCompanyId: string | number) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/general_manager_users/list`,
      create: (currentNormalCompanyId: string | number) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/general_manager_users/create`,
      show: (
        currentNormalCompanyId: string | number,
        generalManagerUserId: string | number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/general_manager_users/${generalManagerUserId}/show`,
      edit: (
        currentNormalCompanyId: string | number,
        generalManagerUserId: string | number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/general_manager_users/${generalManagerUserId}/edit`,
    },
    siteManagerUsers: {
      list: (currentNormalCompanyId: string | number) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/site_manager_users/list`,
      create: (currentNormalCompanyId: string | number) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/site_manager_users/create`,
      show: (
        currentNormalCompanyId: string | number,
        siteManagerUserId: string | number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/site_manager_users/${siteManagerUserId}/show`,
      edit: (
        currentNormalCompanyId: string | number,
        siteManagerUserId: string | number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/site_manager_users/${siteManagerUserId}/edit`,
    },
    companyUsers: {
      list: (
        currentNormalCompanyId: string | number,
        type: keyof typeof CompanyUserType
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/users/${type}/list`,
    },
    modules: {
      list: (currentNormalCompanyId: string | number) =>
        generateClientRoutePrefix(currentNormalCompanyId) + `/modules/list`,
      create: (currentNormalCompanyId: string | number) =>
        generateClientRoutePrefix(currentNormalCompanyId) + `/modules/create`,
      show: (
        currentNormalCompanyId: string | number,
        moduleId: string | number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/modules/${moduleId}/show`,
      edit: (
        currentNormalCompanyId: string | number,
        moduleId: string | number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/modules/${moduleId}/edit`,
    },
    sites: {
      list: (currentNormalCompanyId: string | number) =>
        generateClientRoutePrefix(currentNormalCompanyId) + `/sites/list`,
      create: (currentNormalCompanyId: string | number) =>
        generateClientRoutePrefix(currentNormalCompanyId) + `/sites/create`,
      show: (
        currentNormalCompanyId: string | number,
        siteId: string | number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/sites/${siteId}/show`,
      edit: (
        currentNormalCompanyId: string | number,
        siteId: string | number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/sites/${siteId}/edit`,
      operationPanel: (
        currentNormalCompanyId: string | number,
        siteId: string | number,
        moduleId?: number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/sites/${siteId}/operation_pannel` +
        (moduleId ? `/${moduleId}` : ''),
      operationLog: (
        currentNormalCompanyId: string | number,
        siteId: string | number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/sites/${siteId}/operation_log/list`,
      powerConsumptionPanel: (
        currentNormalCompanyId: string | number,
        siteId: string | number,
        moduleId?: number
      ) =>
        generateClientRoutePrefix(currentNormalCompanyId) +
        `/sites/${siteId}/power_consumption_pannel` +
        (moduleId ? `/${moduleId}` : ''),
      modules: {
        list: (
          currentNormalCompanyId: string | number,
          siteId: string | number
        ) =>
          generateClientRoutePrefix(currentNormalCompanyId) +
          `/sites/${siteId}/modules/list`,
        show: (
          currentNormalCompanyId: string | number,
          siteId: string | number,
          moduleId: string | number
        ) =>
          generateClientRoutePrefix(currentNormalCompanyId) +
          `/sites/${siteId}/modules/${moduleId}/show`,
      },
      schedules: {
        list: (
          currentNormalCompanyId: string | number,
          siteId: string | number
        ) =>
          generateClientRoutePrefix(currentNormalCompanyId) +
          `/sites/${siteId}/schedules/list`,
        create: (
          currentNormalCompanyId: string | number,
          siteId: string | number
        ) =>
          generateClientRoutePrefix(currentNormalCompanyId) +
          `/sites/${siteId}/schedules/create`,
        edit: (
          currentNormalCompanyId: string | number,
          siteId: string | number,
          scheduleId: string | number
        ) =>
          generateClientRoutePrefix(currentNormalCompanyId) +
          `/sites/${siteId}/schedules/${scheduleId}/edit`,
      },
    },
  },
  loginAccount: {
    user: {
      show: `/login_account/user/show`,
      edit: `/login_account/user/edit`,
      changePassword: `/login_account/user/change_password`,
    },
    company: {
      show: `/login_account/company/show`,
      edit: `/login_account/company/edit`,
    },
  },
  operator: {
    operationPanel: (siteId: number) => {
      return `/operator_site/${siteId}/operation_pannel`;
    },
    operationLog: (siteId: number) => {
      return `/operator_site/${siteId}/operation_log`;
    },
    modules: (siteId: number) => {
      return `/operator_site/${siteId}/modules`;
    },
    schedules: (siteId: number) => {
      return `/operator_site/${siteId}/schedules`;
    },
  },
  notFoundSiteManaged: `not_found_site_managed`,
};
