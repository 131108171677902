import React from 'react'
import styles from './styles.module.scss'
import { Box } from '@material-ui/core'

type ButtonListProps = {
  children: React.ReactNode[]
}

const ButtonList: React.FC<ButtonListProps> = ({ children }) => {
  return (
    <Box display="flex" justifyContent="center" className={styles.container}>
      {children
        .filter(child => !!child)
        .map((child, index) => (
          <Box mx={'.3rem'} key={index}>
            {child}
          </Box>
        ))}
    </Box>
  )
}

export default ButtonList
