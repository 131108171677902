import CompaniesNormalCompaniesBreadCrumb from '@/components/ecosystem/CompaniesNormalCompanies/BreadCrumb';
import CompaniesNormalCompaniesList from '@/components/ecosystem/CompaniesNormalCompanies/List';
import CompaniesNormalCompaniesListBreadCrumb from '@/components/ecosystem/CompaniesNormalCompanies/List/BreadCrumb';

export type companiesarams = {
  agent_company: string;
};

export type agentCompaniesUsersRouteParams = {
  user: string;
};

export type CompaniesRouteParams = {
  company_id: string;
};

export type CompaniesNormalCompaniesRouteParams = {
  normal_company_id: string;
};

export const companiesRoute = {
  url: '/normal_companies',
  breadCrumbs: [CompaniesNormalCompaniesBreadCrumb],
  childMaps: [
    {
      url: '/list',
      component: CompaniesNormalCompaniesList,
      breadCrumbs: [CompaniesNormalCompaniesListBreadCrumb],
    },
  ],
};
