import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/app/rootReducer';
import { useParams } from 'react-router-dom';
import { setLoading } from '@/slicers/loading';
import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import { selectorSchedulesBySiteIdFactory } from '@/selector/schedule';
import sitesSchedulesRequest from '@/api/sites_schedules';
import { Box, Switch } from '@material-ui/core';
import moment from 'moment';
import {
  weeks,
  WeekDecoration,
} from '@/components/ecosystem/CompaniesSites/Detail';

import classes from './styles.module.scss';

const OperatorScheduleList: React.FC = () => {
  const dispatch = useDispatch();
  const { site: siteId } = useParams<{ site: string }>();

  const selectorSchedulesBySiteId = useSelector((state: RootState) =>
    selectorSchedulesBySiteIdFactory(state)
  );
  const schedules = selectorSchedulesBySiteId(Number(siteId));

  const getInitialData = useCallback(async () => {
    dispatch(setLoading(true));
    await dispatch(sitesSchedulesRequest.index(siteId).request());
    dispatch(setLoading(false));
  }, [dispatch, siteId]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const updateSitesScheduleValidStatus = useCallback(
    async (scheduleId: number, isValid: boolean) => {
      await dispatch(setLoading(true));
      await dispatch(
        sitesSchedulesRequest
          .putIsValid(siteId, scheduleId.toString())
          .request({ is_valid: Number(isValid) })
      );
      await dispatch(setLoading(false));
    },
    [dispatch, siteId]
  );

  const data = useMemo(() => {
    return schedules.map((item) => ({ ...item }));
    // eslint-disable-next-line
  }, [JSON.stringify(schedules)]);

  return (
    <>
      <CustomMaterialTable
        title="スケジュール"
        options={{
          pageSizeOptions: [5],
        }}
        columns={[
          {
            field: 'id',
            title: 'ID',
          },
          {
            field: 'name',
            title: 'スケジュール名',
          },
          {
            field: 'is_valid',
            title: '有効',
            render: ({ is_valid, id }: any) => (
              <Box>
                <Switch
                  checked={!!is_valid}
                  color="primary"
                  onClick={() => updateSitesScheduleValidStatus(id, !is_valid)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Box>
            ),
          },
          {
            field: 'state',
            title: '実行内容',
            render: ({ state }) => (
              <div
                className={[
                  classes.switchState,
                  state ? classes.off : classes.on,
                ].join(' ')}
              >
                {state ? 'OFF' : 'ON'}
              </div>
            ),
          },
          {
            field: 'action_at',
            title: '時刻',
            render: ({ action_at }) => (
              <div>{moment(action_at, 'HH:mm:ss').format('HH:mm')}</div>
            ),
          },

          {
            field: 'week',
            title: '曜日',
            render: (record) => (
              <Box style={{ minWidth: '15rem' }}>
                {(Object.keys(weeks) as (keyof typeof weeks)[]).map((week) => (
                  <span key={week} className={classes.weekContainer}>
                    <WeekDecoration valid={!!record[week]} week={week} />
                  </span>
                ))}
              </Box>
            ),
          },
        ]}
        data={data}
      />
    </>
  );
};

export default OperatorScheduleList;
