import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { FormControlLabel, Switch } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';

import { User, UserTypeAdmin, UserTypeSiteManager } from '@/slicers/users';
import {
  deviceDisconnected,
  PowerSwitchState,
  responseStateSuccess,
  switchSuccess,
  ModuleWith,
  SwitchingState,
} from '@/slicers/operation_page';
import { Camera } from '@/slicers/cameras';
import { Site } from '@/slicers/sites';

import operationPageRequest from '@/api/operation_page';
import { useAgent } from '@/hooks';

import Modal, { ModalFunction } from '@/components/atoms/Modal';
import Circle from '@/components/atoms/Circle';
import EarthquakeAlert from '@/components/organisms/EarthquakeAlert';

import Switches from './Switches';

import classes from './styles.module.scss';
import { ModuleDTO } from '../OperationB16Channel';

const payload = (eventName: string, data: Object = {}) =>
  JSON.stringify({
    event: eventName,
    data,
  });

const parseWsMessage = (message: string) => {
  try {
    const res = JSON.parse(message);
    return {
      event: res?.event,
      data: res?.data,
    };
  } catch (error) {
    return {
      event: message,
      data: '',
    };
  }
};

const transformModuleWithIntoModuleDTO = (moduleWith: ModuleWith) => {
  let moduleDTO: ModuleDTO = { deviceCode: moduleWith.deviceCode, units: [] };
  Object.values(moduleWith.unitsByNumber).forEach((powerUnit) => {
    moduleDTO.units.push({
      number: powerUnit.number,
      switches: Object.values(powerUnit.switchesByNumber),
    });
  });

  return moduleDTO;
};

const createSwitchRequest = (moduleWith: ModuleWith) => {
  return payload('module-switch', transformModuleWithIntoModuleDTO(moduleWith));
};

const socketHeatbeat = 5 * 1000;

type OperationB64ChannelProps = {
  site: Site;
  loginUser: User;
  firstSiteUser?: User | null;
  moduleWithByDeviceCode: Record<
    string,
    ModuleWith & {
      cameras: Camera[];
    }
  >;
  moduleId?: string;
  showOverlay: () => void;
  hideOverlay: () => void;
};

export default function OperationB64Channel({
  showOverlay,
  hideOverlay,
  moduleId,
  site,
  loginUser,
  firstSiteUser,
  moduleWithByDeviceCode,
}: OperationB64ChannelProps) {
  const refEarthquakeModal = useRef<ModalFunction>();
  const isMobile = useAgent();
  const siteId = site.id.toString();
  const dispatch = useDispatch();

  const revertModuleRef = useRef<Record<string, ModuleWith>>(
    useMemo(() => cloneDeep(moduleWithByDeviceCode), [moduleWithByDeviceCode])
  );
  const instance = useRef<{
    heatbeatId: NodeJS.Timeout | null;
  }>({
    heatbeatId: null,
  });

  const modulesContinuumTestTimer = useRef<Record<string, NodeJS.Timeout>>({});
  const [modulesContinuumTest, setModulesContinuumTest] = useState<
    Record<string, boolean>
  >({});

  const [isCheckedSocketAndDevice, setCheckedSocketAndDevice] = useState(false);
  const [isConnectedDevice, setConnectedDevice] = useState<
    Record<string, boolean>
  >({});
  const [ws, setWs] = useState<WebSocket>();

  const onCloseEvent = async () => {
    setWs(undefined);
    setConnectedDevice({});
    setCheckedSocketAndDevice(true);
    await dispatch(deviceDisconnected(null));
    console.log('ws closed');
  };

  useEffect(() => {
    if (!firstSiteUser && !loginUser) return;
    if (instance.current.heatbeatId) {
      clearInterval(instance.current.heatbeatId);
      instance.current.heatbeatId = null;
    }
    function run() {
      if (ws) {
        const _modulesContinuumTestTimer = modulesContinuumTestTimer.current;
        if (
          !Object.keys(_modulesContinuumTestTimer).some(
            (deviceCode) => _modulesContinuumTestTimer[deviceCode] != null
          )
        ) {
          ws.send(payload('module-request-state-64ch'));
        }
      } else {
        createWs();
      }
    }
    run();
    instance.current.heatbeatId = setInterval(run, socketHeatbeat);
    return () => {
      // eslint-disable-next-line
      if (instance.current.heatbeatId) {
        // eslint-disable-next-line
        clearInterval(instance.current.heatbeatId);
        // eslint-disable-next-line
        instance.current.heatbeatId = null;
      }
      if (ws) {
        ws.removeEventListener('close', onCloseEvent);
        ws.close();
      }
    };
    // eslint-disable-next-line
  }, [JSON.stringify(firstSiteUser), JSON.stringify(loginUser), ws]);

  const handleRevertLockSwitch = (ws: any, futureModuleWith: ModuleWith) => {
    const refModuleWith = revertModuleRef.current[futureModuleWith.deviceCode];
    const moduleWith = cloneDeep(refModuleWith);

    if (!moduleWith) {
      return;
    }

    if (futureModuleWith.switchingState === SwitchingState.SWITCHING) {
      return;
    }

    for (const _unitNumber in moduleWith.unitsByNumber) {
      const futureUnit = futureModuleWith.unitsByNumber[_unitNumber];
      if (!futureUnit) {
        delete moduleWith.unitsByNumber[_unitNumber];
        continue;
      }
      const powerUnit = moduleWith.unitsByNumber[_unitNumber];
      for (const _switchNumber in powerUnit.switchesByNumber) {
        const futureSwitch = futureUnit.switchesByNumber[_switchNumber];
        if (!futureUnit.switchesByNumber[_switchNumber]) {
          delete powerUnit.switchesByNumber[_switchNumber];
          continue;
        }
        const sw = powerUnit.switchesByNumber[_switchNumber];
        sw.state =
          sw.state === PowerSwitchState.UNKNOWN
            ? PowerSwitchState.OFF
            : sw.state;

        if (
          !sw.isLock ||
          futureSwitch.state === PowerSwitchState.UNKNOWN ||
          sw.state === futureSwitch.state
        ) {
          if (!sw.isLock) {
            refModuleWith.unitsByNumber[_unitNumber].switchesByNumber[
              _switchNumber
            ].state = futureSwitch.state; // update revertModule
          }
          delete powerUnit.switchesByNumber[_switchNumber];
          continue;
        }
      }
      if (!Object.keys(powerUnit.switchesByNumber).length) {
        delete moduleWith.unitsByNumber[_unitNumber];
        continue;
      }
    }

    if (!Object.keys(moduleWith.unitsByNumber).length) {
      return;
    }

    if (ws) {
      ws.send(createSwitchRequest(moduleWith));
    }
  };

  const createWs = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const ws = new WebSocket(
      `${protocol}://${
        process.env.REACT_APP_WEB_SOCKET_URL || 'localhost:3004'
      }/ws/`
    );
    ws.onopen = () => {
      setCheckedSocketAndDevice(false);
      let targetId;
      if (loginUser?.type! < UserTypeSiteManager) {
        targetId = firstSiteUser?.id;
      } else {
        targetId = loginUser?.id;
      }
      if (targetId) {
        console.log('ws opened with user ', targetId);
        ws.send(
          payload('client-user-login', {
            token: localStorage.getItem('accessToken'),
            userId: targetId,
          })
        );
      }
    };

    ws.onclose = onCloseEvent;

    ws.onmessage = (res) => {
      const { data, event } = parseWsMessage(res.data);
      console.log(
        `[64ch] OperationPanel receive ${event}`,
        JSON.parse(JSON.stringify(data))
      );
      switch (event) {
        case 'ready':
          setWs(ws);
          ws.send(payload('module-request-state-64ch'));
          break;
        case 'earthquakeNotify':
          if (!(window as any).isShowedEearthquakeAlert) {
            refEarthquakeModal.current!.open();
          }
          break;
        case 'moduleResponseState':
          //Update revert unit/switch
          for (const { deviceCode, unitsByNumber } of data) {
            const revertModule = revertModuleRef.current[deviceCode];
            if (!revertModule) {
              continue;
            }
            for (const unitNumber of Object.keys(unitsByNumber)) {
              const unit = unitsByNumber[unitNumber];
              const revertUnit = revertModule.unitsByNumber[unitNumber as any];
              if (revertUnit) {
                for (const switchNumber of Object.keys(unit.switchesByNumber)) {
                  const sw = unit.switchesByNumber[switchNumber];
                  const revertSwitch =
                    revertUnit.switchesByNumber[switchNumber as any];
                  if (revertSwitch) {
                    revertSwitch.state = sw.state;
                  }
                }
              }
            }
          }

          setCheckedSocketAndDevice(true);
          const connectedDevices = data.reduce(
            (hs: Record<string, boolean>, mdl: ModuleWith) => {
              hs[mdl.deviceCode] = true;
              return hs;
            },
            {}
          );

          setConnectedDevice(connectedDevices);

          dispatch(responseStateSuccess(data as ModuleWith[]));
          break;
        case 'moduleSwitched64CH':
          const mdlWith = data as ModuleWith;
          handleRevertLockSwitch(ws, mdlWith);
          setConnectedDevice((prev) => {
            if (data) {
              return {
                ...prev,
                [data.deviceCode]: true,
              };
            }
            return prev;
          });
          dispatch(switchSuccess(mdlWith));
          break;
        case 'moduleDisconnected':
          dispatch(deviceDisconnected(data));
          break;
        default:
          break;
      }
    };
  };

  const toggleAllSwitches = ({
    deviceCode,
    state,
  }: {
    deviceCode: string;
    state: PowerSwitchState;
  }) => {
    let moduleWith = cloneDeep(moduleWithByDeviceCode[deviceCode]);

    for (const _unitNumber in moduleWith.unitsByNumber) {
      const powerUnit = moduleWith.unitsByNumber[_unitNumber];
      for (const _switchNumber in powerUnit.switchesByNumber) {
        if (powerUnit.switchesByNumber[_switchNumber].isLock) {
          delete powerUnit.switchesByNumber[_switchNumber];
          continue;
        }
        powerUnit.switchesByNumber[_switchNumber].state = state;
      }

      if (!Object.keys(powerUnit.switchesByNumber).length) {
        delete moduleWith.unitsByNumber[_unitNumber];
        continue;
      }
    }

    if (!Object.keys(moduleWith.unitsByNumber).length) {
      return;
    }

    if (ws) {
      ws.send(createSwitchRequest(moduleWith));
      console.log('toggleAllSwitches send switch request', moduleWith);
    }
  };

  const handleChangeSwitchesState = (
    deviceCode: string,
    unitNumber: number,
    state: PowerSwitchState.ON | PowerSwitchState.OFF,
    switchNumbers: number[]
  ) => {
    if (!ws) return;

    const mdl = moduleWithByDeviceCode[deviceCode];
    const unit = mdl.unitsByNumber[unitNumber];
    const moduleWith: ModuleWith = {
      ...mdl,
      unitsByNumber: {
        [unitNumber]: {
          ...unit,
          switchesByNumber: switchNumbers.reduce(
            (ob, num) => ({
              ...ob,
              [num]: { ...unit.switchesByNumber[num], state },
            }),
            {}
          ),
        },
      },
    };

    ws.send(createSwitchRequest(moduleWith));
  };

  const handleSaveModuleName = useCallback(
    async (moduleId, newName) => {
      showOverlay();
      const api = operationPageRequest.updateModuleName(siteId, moduleId);
      await dispatch(api.request(newName));
      hideOverlay();
    },
    [showOverlay, hideOverlay, dispatch, siteId]
  );

  const opened = !!ws;

  useEffect(() => {
    if (!opened) {
      return;
    }

    const toggle = (deviceCode: string, state: PowerSwitchState) => {
      toggleAllSwitches({
        deviceCode,
        state,
      });
    };

    Object.keys(modulesContinuumTest).forEach((deviceCode) => {
      if (!isConnectedDevice[deviceCode]) {
        return;
      }

      const timer = modulesContinuumTestTimer.current[deviceCode];
      if (modulesContinuumTest[deviceCode]) {
        if (timer) {
          return;
        }

        let continuumState = PowerSwitchState.ON;

        toggle(deviceCode, continuumState);

        modulesContinuumTestTimer.current[deviceCode] = setInterval(() => {
          continuumState =
            continuumState === PowerSwitchState.ON
              ? PowerSwitchState.OFF
              : PowerSwitchState.ON;

          toggle(deviceCode, continuumState);
        }, 15000);
      } else {
        if (timer) {
          delete modulesContinuumTestTimer.current[deviceCode];
          clearInterval(timer);
        }
      }
    });
    // eslint-disable-next-line
  }, [
    opened,
    // eslint-disable-next-line
    JSON.stringify(isConnectedDevice),
    // eslint-disable-next-line
    JSON.stringify(modulesContinuumTest),
  ]);

  const modulesToSwitch = useMemo(() => {
    const modules = Object.values(moduleWithByDeviceCode);
    if (moduleId) {
      return modules.filter((mdl) => mdl.id === Number(moduleId));
    }

    return modules;
  }, [moduleWithByDeviceCode, moduleId]);

  return (
    <>
      <Modal ref={refEarthquakeModal}>
        <EarthquakeAlert />
      </Modal>
      {loginUser!.type! >= UserTypeSiteManager || !!firstSiteUser ? (
        modulesToSwitch.map((commModule, i) => {
          const ConnectStatus = ({ inline }: any) => (
            <div
              className={[
                classes.connectStatus,
                inline ? classes.inline : '',
              ].join(' ')}
            >
              <span>接続状態: </span>
              {isCheckedSocketAndDevice ? (
                <>
                  <Circle
                    type={
                      opened && isConnectedDevice[commModule.deviceCode]
                        ? 'success'
                        : 'gray'
                    }
                  />
                  {opened ? (
                    <span>
                      {!isConnectedDevice[commModule.deviceCode]
                        ? '未接続（指令盤)'
                        : '正常'}
                    </span>
                  ) : (
                    <span>未接続（ソケット)</span>
                  )}
                </>
              ) : (
                <>
                  <Circle type={'gray'} />
                  <span>接続中...</span>
                </>
              )}
            </div>
          );

          return (
            <React.Fragment key={commModule.deviceCode}>
              {!isMobile && (
                <>
                  {loginUser?.realType === UserTypeAdmin && (
                    <div className={classes.topTool}>
                      <FormControlLabel
                        className={classes.switch}
                        control={
                          <Switch
                            disabled={
                              !(
                                opened &&
                                isConnectedDevice[commModule.deviceCode]
                              ) ||
                              (!modulesContinuumTest[commModule.deviceCode] &&
                                commModule.switchingState ===
                                  SwitchingState.SWITCHING)
                            }
                            checked={
                              !!modulesContinuumTest[commModule.deviceCode]
                            }
                            onChange={(e) =>
                              setModulesContinuumTest((prev) => ({
                                ...prev,
                                [commModule.deviceCode]: e.target.checked,
                              }))
                            }
                            color="primary"
                          />
                        }
                        label="連続運転"
                        labelPlacement="start"
                      />
                    </div>
                  )}
                </>
              )}

              <Switches
                connectStatusInMobile={
                  isMobile ? <ConnectStatus inline /> : null
                }
                handleSaveModuleName={handleSaveModuleName}
                disabled={!!modulesContinuumTest[commModule.deviceCode]}
                isConnected={opened}
                isDeviceConnected={
                  opened && isConnectedDevice[commModule.deviceCode]
                }
                site={site}
                onChangeSwitchesState={handleChangeSwitchesState}
                mdl={commModule}
                isHeader={i === 0}
                connectStatus={<ConnectStatus />}
              />
              <div
                className={
                  i === modulesToSwitch.length - 1
                    ? classes.lastLengthDeviceInformation
                    : classes.deviceInformation
                }
              >
                <span>SocketID:</span>
                {commModule.socketId || '_ _'}
                <span>&nbsp;&nbsp;&nbsp;&nbsp;PID:</span>
                {commModule.deviceCode || ''}
                <span>&nbsp;&nbsp;&nbsp;&nbsp;管理番号:</span>
                {commModule.control_no || ''}
              </div>
            </React.Fragment>
          );
        })
      ) : (
        <div className={classes.header}>
          <div>
            <div>現場管理者または使用者を1名は作成してください。</div>
          </div>
        </div>
      )}
    </>
  );
}
