import OperatorModuleList from '@/components/ecosystem/OperatorSite/ModuleList';
import OperatorModuleListBreadCrumb from '@/components/ecosystem/OperatorSite/ModuleList/BreadCrumb';

import OperatorScheduleList from '@/components/ecosystem/OperatorSite/ScheduleList';
import OperatorScheduleListBreadCrumb from '@/components/ecosystem/OperatorSite/ScheduleList/BreadCrumb';
import CompaniesSiteManagerUsersOperationPannel from '@/components/ecosystem/CompaniesSiteManagers/OperationPannel';
import OperationPannelBreadCrumb from '@/components/ecosystem/CompaniesSiteManagers/OperationPannel/BreadCrumb';
import SiteOperationLogsList from '@/components/ecosystem/SiteOperationLogs/List';

export const operatorRoute = {
  url: '/operator_site',
  childMaps: [
    {
      url: '/:site',
      breadCrumbs: [],
      childMaps: [
        {
          url: '/operation_pannel',
          component: CompaniesSiteManagerUsersOperationPannel,
          breadCrumbs: [OperationPannelBreadCrumb],
        },
        {
          url: '/modules',
          component: OperatorModuleList,
          breadCrumbs: [OperatorModuleListBreadCrumb],
        },
        {
          url: '/schedules',
          component: OperatorScheduleList,
          breadCrumbs: [OperatorScheduleListBreadCrumb],
        },
        {
          url: '/operation_log',
          component: SiteOperationLogsList,
        },
      ],
    },
  ],
};
