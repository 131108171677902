import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: '2vh 0',
      },
      logfile: {
        fontSize: '.9rem',
        lineHeight: '.9rem',
        '&:not(:first-child)': {
          marginTop: '1.5vh',
        },
        cursor: 'pointer',
        color: '#39c',
      },
      rangeDate: {
        '& $fields': {
          display: 'flex',
          alignItems: 'center',
          '& > div:first-child': {
            marginRight: 10,
          },
          '& > div:last-child': {
            marginLeft: 10,
          },
        },
      },
      fileList: {
        marginTop: '2vh',
        height: '70vh',
        overflow: 'auto',
        overflowX: 'hidden',
      },
      fields: {},
    })
  );

export default useStyles;
