import { AppThunk } from '@/app/store';
import { parseAxiosResponse } from '@/utils/parse_axios_response';
import { setResponse, ErrorPosition } from '@/slicers/response';
import { setFlashError, setFlashSuccess } from '@/slicers/flash';
import {
  clearCompanyUsers,
  companyUserDeletes,
  companyUserGetsSucces,
  companyUserSetPagingOptions,
} from '@/slicers/companyUsers';
import { MainApiCaller, TApiCaller } from '@/api-service';

@MainApiCaller('/normal_companies')
class CompaniesUsersRequest {
  caller!: TApiCaller;

  index(companyId: number) {
    return {
      request:
        (type: number, page?: number, keyword?: string): AppThunk =>
        async (dispatch) => {
          const query = {
            page: page || 1,
            search: keyword,
            type,
          };
          if (query.page === 1) {
            dispatch(clearCompanyUsers());
          }

          const response = await this.caller.GET(
            `/${companyId}/all_users`,
            query
          );

          if (response.status === 200) {
            const { data, total, per_page } = response.data;
            dispatch(clearCompanyUsers());
            dispatch(
              companyUserSetPagingOptions({
                total,
                perPage: per_page,
              })
            );
            dispatch(companyUserGetsSucces(data));
            dispatch(setResponse(parseAxiosResponse(response)));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('会社の取得に失敗しました'));
          }
        },
    };
  }

  post(companyId: number) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.POST(
            `/${companyId}/general_manager_users`,
            data
          );

          if (response.status === 201) {
            dispatch(companyUserGetsSucces([response.data]));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('作成しました。'));
          } else {
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashError('作成が失敗しました。'));
          }
        },
    };
  }

  put(companyId: number, userId: string) {
    return {
      request:
        (data: Record<string, any>): AppThunk =>
        async (dispatch) => {
          const response = await this.caller.PUT(
            `/${companyId}/general_manager_users/${userId}`,
            data
          );

          if (response.status === 200) {
            dispatch(companyUserGetsSucces([response.data]));
            dispatch(setResponse(parseAxiosResponse(response)));
            dispatch(setFlashSuccess('更新しました'));
          } else {
            dispatch(
              setResponse({
                ...parseAxiosResponse(response),
                position: ErrorPosition.DRAWR,
              })
            );
            dispatch(setFlashError('総括者の取得に失敗しました。'));
          }
        },
    };
  }

  delete(companyId: number, userId: string) {
    return {
      request: (): AppThunk => async (dispatch, getState) => {
        const response = await this.caller.DEL(
          `/${companyId}/general_manager_users/${userId}`
        );

        dispatch(setResponse(parseAxiosResponse(response)));

        if (response.status === 200) {
          dispatch(setFlashSuccess('削除しました。'));
          dispatch(companyUserDeletes([Number(userId)]));
        } else {
          dispatch(setFlashError('削除が失敗しました。'));
        }
      },
    };
  }
}

const companiesUsersRequest = new CompaniesUsersRequest();

export default companiesUsersRequest;
