import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import styles from './styles.module.scss'
import clsx from 'clsx'
import AntSwitch from '@/components/atoms/AntSwitch'
import {
  ModuleWith,
  PowerSwitchState,
  PowerUnit,
  PowerSwitch,
  PowerUnitState
} from '@/slicers/operation_page'
import { Button as MaterialButton } from '@material-ui/core'
import { CustomButton } from '@/components/atoms/CustomButton'

export type HandleClickSwitchButtonProps = {
  event: React.MouseEvent<HTMLButtonElement>
  deviceCode: string
  unitNumber: number
  switchNumber: number
  state: PowerSwitchState.ON | PowerSwitchState.OFF | PowerSwitchState.UNKNOWN
}

export type SwitchPannelProps = {
  handleClickSwitchButton: (args: HandleClickSwitchButtonProps) => void
  moduleWith: ModuleWith
}

const SwitchPannelForDeviceSimurator: React.FC<SwitchPannelProps> = ({
  handleClickSwitchButton,
  moduleWith
}) => {
  const switchContainer = (powerUnit: PowerUnit, powerSwitch: PowerSwitch) => {
    if (powerSwitch.state === PowerSwitchState.UNKNOWN) {
      return <div>不明</div>
    }

    const nextState =
      powerSwitch.state === PowerSwitchState.ON
        ? PowerSwitchState.OFF
        : PowerSwitchState.ON

    return (
      <AntSwitch
        checked={powerSwitch.state === PowerSwitchState.ON}
        onClick={event =>
          handleClickSwitchButton({
            event,
            deviceCode: moduleWith.deviceCode,
            state: nextState,
            unitNumber: powerUnit.number,
            switchNumber: powerSwitch.number
          })
        }
      />
    )
  }

  return (
    <FormGroup>
      <div className={styles.container}>
        {/* <div>PID:{moduleWith.deviceCode}</div> */}
        <div className={styles.module_container}>
          {Object.values(moduleWith.unitsByNumber).map(powerUniit => (
            <div
              className={styles.unit_wrapper}
              key={`unit_${powerUniit.number}`}
            >
              <div className={styles.unit_container}>
                {powerUniit.state === PowerUnitState.LOADING ? (
                  <div className={styles.power_unit_state_loading_container}>
                    <div>接続待機中</div>
                  </div>
                ) : (
                  Object.values(powerUniit.switchesByNumber).map(
                    powerSwitch => (
                      <div
                        className={styles.switch_wrapper}
                        key={`switch_${powerSwitch.number}`}
                      >
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <div className={styles.switch_button_wrapper}>
                                {switchContainer(powerUniit, powerSwitch)}
                              </div>
                              <div className={styles.switch_button_container}>
                                <CustomButton
                                  color="error"
                                  className={styles.switch_button}
                                  onClick={event =>
                                    handleClickSwitchButton({
                                      deviceCode: moduleWith.deviceCode,
                                      unitNumber: powerUniit.number,
                                      switchNumber: powerSwitch.number,
                                      event,
                                      state: PowerSwitchState.ON
                                    })
                                  }
                                >
                                  ON
                                </CustomButton>
                                <CustomButton
                                  color="success"
                                  className={styles.switch_button}
                                  onClick={event =>
                                    handleClickSwitchButton({
                                      deviceCode: moduleWith.deviceCode,
                                      unitNumber: powerUniit.number,
                                      switchNumber: powerSwitch.number,
                                      event,
                                      state: PowerSwitchState.OFF
                                    })
                                  }
                                >
                                  OFF
                                </CustomButton>
                                <MaterialButton
                                  variant="contained"
                                  className={clsx(
                                    styles.switch_button,
                                    styles.switch_button_unknows
                                  )}
                                  onClick={event =>
                                    handleClickSwitchButton({
                                      deviceCode: moduleWith.deviceCode,
                                      unitNumber: powerUniit.number,
                                      switchNumber: powerSwitch.number,
                                      event,
                                      state: PowerSwitchState.UNKNOWN
                                    })
                                  }
                                >
                                  不明
                                </MaterialButton>
                              </div>
                            </Grid>
                          </Grid>
                        </Typography>
                      </div>
                    )
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </FormGroup>
  )
}

export default SwitchPannelForDeviceSimurator
