import React from 'react'
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem'
import BreadCrumbs from '@/components/molecules/BreadCrumbs'

const PowerChartBreadCrumb: React.FC = () => {
  return (
    <BreadCrumbs>
      <BreadCrumbItem title="指令盤使用状況" />
    </BreadCrumbs>
  )
}

export default PowerChartBreadCrumb
