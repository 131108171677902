import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCompanyUserType, useGetClientRouteParam } from '@/hooks';
import { setLoading } from '@/slicers/loading';
import {
  UserTypeSuperAdmin,
  UserTypeAdmin,
  UserTypeAgent,
  UserTypeGeneralManager,
  UserTypeSiteManager,
  UserTypeOperator,
} from '@/slicers/users';
import { selectorFindUsersByCompanyId } from '@/selector/companyUsers';
import { RootState } from '@/app/rootReducer';
import CustomMaterialTable from '@/components/organisms/CustomMaterialTable';
import { CustomButton } from '@/components/atoms/CustomButton';
import { Box, TablePagination } from '@material-ui/core';
import companiesUsersRequest from '@/api/companies_users';
import { UserFormData } from '@/components/organisms/UserForm';
import Drawer from '@/components/atoms/Drawer';
import Detail from '../Detail';
import AgentUserForm from '@/components/organisms/AgentUserForm';

import classes from './styles.module.scss';
import { CompanyUserType } from '@/routes/ClientRoute';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

const CompaniesUsersList: React.FC = () => {
  const [overlayLoader, { showOverlay, hideOverlay }] = useOverlayLoading();
  const dispatch = useDispatch();

  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState<null | Record<string, any>>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [currentPage, setCurrentPage] = useState(1);
  const [currentKw, setCurrentKw] = useState('');

  const { companyId } = useGetClientRouteParam();
  const type = useCompanyUserType();

  const pagingOptions = useSelector(
    (state: RootState) => state.companyUsersState.pagingOptions
  );

  const users = useSelector((state: RootState) =>
    selectorFindUsersByCompanyId(state)
  )(Number(companyId));

  const getUsers = useCallback(
    async (page?: number, keyword?: string) => {
      await dispatch(setLoading(true));
      await dispatch(
        companiesUsersRequest
          .index(Number(companyId))
          .request(CompanyUserType[type], page, keyword)
      );
      await dispatch(setLoading(false));
    },
    [companyId, type, dispatch]
  );

  const onSubmit = (closeDrawer: Function) => async (e: any) => {
    e.preventDefault();
    const form = e.target;
    const fields = ['name', 'password', 'email'];

    const data: Record<string, any> = {};
    const errors = fields.reduce((errors: Record<string, string>, field) => {
      const input = form[field];
      if (input) {
        if (input.hasAttribute('required')) {
          if (!input.value) {
            errors[field] = '必須入力項目です';
          }
        }
        data[field] = input.value;
      }

      return errors;
    }, {});

    setErrors(errors);

    if (!Object.keys(errors).length) {
      showOverlay();
      if (editData) {
        const api = companiesUsersRequest.put(Number(companyId), editData.id);
        await dispatch(api.request(data as UserFormData));
        setEditData({ ...data, id: editData.id });
      } else {
        const api = companiesUsersRequest.post(Number(companyId));
        await dispatch(api.request(data as UserFormData));
      }
      hideOverlay();
      closeDrawer();
    }
  };

  const onDelete = async (id: string) => {
    showOverlay();
    const api = companiesUsersRequest.delete(Number(companyId), id);
    await dispatch(api.request());
    setEditData(null);
    setOpenDrawer(false);
    hideOverlay();
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    if (!isOpenDrawer) {
      setErrors({});
      setEditData(null);
    }
  }, [isOpenDrawer]);

  const listTitle =
    CompanyUserType[type] === UserTypeGeneralManager
      ? '企業管理者'
      : CompanyUserType[type] === UserTypeSiteManager
      ? '現場管理者'
      : '使用者';

  /**
   * 企業管理者の場合、所属現場名が表示されません
   */
  const displayColumns =
    CompanyUserType[type] !== UserTypeGeneralManager
      ? [
          {
            field: 'name',
            title: `${listTitle}名`,
          },
          {
            field: 'email',
            title: 'Email',
          },
          {
            field: 'type',
            title: '権限',
            render: ({ type }: any) => {
              switch (type) {
                case UserTypeSuperAdmin:
                case UserTypeAdmin:
                  return 'クェスタ管理者';
                case UserTypeAgent:
                  return '代理店管理者';
                case UserTypeGeneralManager:
                  return '企業管理者';
                case UserTypeOperator:
                  return '使用者';
                default:
                  return '現場管理者';
              }
            },
          },
          {
            field: 'site',
            title: '所属現場名',
            render: ({ site }: any) => {
              return site ? (
                site.name
              ) : (
                <span className={classes.noSite}>未所属</span>
              );
            },
          },
          {
            title: '操作',
            render: (row: any) => (
              <Box style={{ minWidth: '240px' }}>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setOpenDrawer(true);
                  }}
                >
                  {listTitle}編集
                </CustomButton>
              </Box>
            ),
          },
        ]
      : [
          {
            field: 'name',
            title: '企業管理者名',
          },
          {
            field: 'email',
            title: 'Email',
          },
          {
            field: 'type',
            title: '権限',
            render: ({ type }: any) => {
              switch (type) {
                case UserTypeSuperAdmin:
                case UserTypeAdmin:
                  return 'クェスタ管理者';
                case UserTypeAgent:
                  return '代理店管理者';
                case UserTypeGeneralManager:
                  return '企業管理者';
                case UserTypeOperator:
                  return '使用者';
                default:
                  return '現場管理者';
              }
            },
          },
          {
            title: '操作',
            render: (row: any) => (
              <Box style={{ minWidth: '240px' }}>
                <CustomButton
                  color="primary"
                  onClick={() => {
                    setEditData(row);
                    setOpenDrawer(true);
                  }}
                >
                  企業管理者編集
                </CustomButton>
              </Box>
            ),
          },
        ];

  return (
    <>
      {overlayLoader}
      <CustomMaterialTable
        onSearchChange={(text) => {
          setCurrentKw(text);
          setCurrentPage(1);
          getUsers(1, text);
        }}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              rowsPerPageOptions={[]}
              rowsPerPage={5}
              page={currentPage - 1}
              count={pagingOptions.total}
              onChangePage={(e, page) => {
                setCurrentPage(page + 1);
                getUsers(page + 1, currentKw);
              }}
            />
          ),
        }}
        title={`${listTitle}一覧`}
        options={{
          pageSizeOptions: [5],
        }}
        columns={displayColumns}
        data={users.map((item) => ({ ...item }))}
        addButton={
          CompanyUserType[type] === UserTypeGeneralManager ? (
            <CustomButton
              color="grey"
              style={{ background: '#56ccf2', color: 'white' }}
              onClick={() => setOpenDrawer(true)}
            >
              企業管理者作成
            </CustomButton>
          ) : undefined
        }
      />
      <Drawer isOpen={isOpenDrawer} onChange={setOpenDrawer}>
        {editData ? (
          <Detail
            onConfirmDelete={onDelete}
            errors={errors}
            initialData={editData}
            onSubmit={onSubmit}
            onCancel={() => setOpenDrawer(false)}
          />
        ) : (
          <AgentUserForm
            errors={errors}
            onCancel={() => setOpenDrawer(false)}
            onSubmit={onSubmit(() => setOpenDrawer(false))}
          />
        )}
      </Drawer>
    </>
  );
};

export default CompaniesUsersList;
