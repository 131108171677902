import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  RouteProps,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '@/app/rootReducer';
import { getLoginUserAndCompanySelector } from './selector/users';
import { ErrorPosition } from './slicers/response';

import RouteRecursive from './routes/RouteRecursive';
import { route } from './routes/url-generator';
import { routeMaps } from './routes';

import Common from '@/components/Common';
import FlashMessageSystem from '@/components/ecosystem/FlashMessageSystem';
import ErrorMessage from '@/components/ecosystem/ErrorMessage';
import WebSocketClientModule64CH from './components/ecosystem/WebSocketClientModule64CH';
import WebSocketClientModule from '@/components/ecosystem/WebSocketClientModule';
import WebSocketClientSwitch from '@/components/ecosystem/WebSocketClientSwitch';
import LoginPage from '@/components/ecosystem/LoginPage';
import AppContainer from '@/components/ecosystem/AppContainer';

export default function AuthExample() {
  return (
    <Router>
      <div>
        <Common />
        <Switch>
          <Route path={route.login}>
            <React.Fragment>
              <FlashMessageSystem />
              <ErrorMessage position={ErrorPosition.MAIN} />
              <LoginPage />
            </React.Fragment>
          </Route>
          <PrivateRoute>
            <AppContainer>
              <RouteRecursive routeMaps={routeMaps} />
              <Route
                exact
                path={'/web-socket-client-module'}
                component={WebSocketClientModule}
              />
              <Route
                exact
                path={'/web-socket-client-module-64ch'}
                component={WebSocketClientModule64CH}
              />
              <Route
                exact
                path={'/web-socket-client-switch'}
                component={WebSocketClientSwitch}
              />
            </AppContainer>
          </PrivateRoute>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
  console.log('private route');

  const location = useLocation();
  const history = useHistory();

  const isLogin = useSelector(
    (state: RootState) => getLoginUserAndCompanySelector(state).isLogin
  );

  if (location.pathname === '/') {
    history.push(route.login);
  }

  return isLogin ? (
    <Route render={() => children} />
  ) : (
    <div>ユーザー情報取得中</div>
  );
};

export function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
