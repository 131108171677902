import { ReadOperationLog } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OperationLogState = {
  operationLogsById: Record<number, ReadOperationLog>;
};

export const initialStateOperationLog: OperationLogState = {
  operationLogsById: {},
};

const operationLog = createSlice({
  name: 'operationLog',
  initialState: initialStateOperationLog,
  reducers: {
    operationLogGetsSuccess(
      state,
      { payload }: PayloadAction<ReadOperationLog[]>
    ) {
      let index = 0;
      for (const log of payload) {
        state.operationLogsById[index] = log;
        index++;
      }
    },
    initOperationLogState(state) {
      state.operationLogsById = {};
    },
  },
});

export const { operationLogGetsSuccess, initOperationLogState } =
  operationLog.actions;

export default operationLog.reducer;
