import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Circle from '@/components/atoms/Circle';

import { RootState } from '@/app/rootReducer';

import { getLoginUserSelector } from '@/selector/users';

import { UserTypeSiteManager } from '@/slicers/users';
import {
  deviceDisconnected,
  ModuleWith,
  PowerSwitchState,
} from '@/slicers/operation_page';
import { ModuleChannelType, Units } from '@/slicers/modules';

import SitesUsersRequest from '@/api/sites_users';

import classes from './styles.module.scss';

const payload = (eventName: string, data: Object = {}) =>
  JSON.stringify({
    event: eventName,
    data,
  });

const parseWsMesssage = (message: string) => {
  try {
    const res = JSON.parse(message);
    return {
      event: res?.event,
      data: res?.data,
    };
  } catch (error) {
    return {
      event: message,
      data: '',
    };
  }
};

const alphabets = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
];

export interface TestConnectPopup {
  mdlType?: ModuleChannelType;
  units: Units[];
  deviceCode: string;
  siteId: number;
}

const TestConnectPopup: React.FC<TestConnectPopup> = ({
  mdlType = ModuleChannelType.B16Channel,
  deviceCode,
  siteId,
  units,
}) => {
  const dispatch = useDispatch();
  const refWS = useRef<WebSocket | null>();

  const [unitsWithLabel] = useState(() => {
    if (mdlType === ModuleChannelType.B64Channel) {
      return units.map((unit) => {
        return {
          ...unit,
          label: `${alphabets[unit.number - 1]}グループ`,
        };
      });
    }
    return units
      .map((unit) => {
        return {
          ...unit,
          label: `${alphabets[4 - unit.number]}グループ`,
        };
      })
      .reverse();
  });

  const [isLoggedToSocket, setLoggedToSocket] = useState(false);
  const [isConnectedToDevice, setConnectedToDevice] = useState(false);
  const [switchesState, setSwitchesState] = useState<
    Record<string, PowerSwitchState>
  >({});

  const loggedUser = useSelector(getLoginUserSelector);
  const firstSiteUser = useSelector(
    (state: RootState) => state.siteUsersState.firstUser
  );

  const loginType = loggedUser!.type;
  const loginId = loggedUser!.id;
  const getFirstUser = useCallback(async () => {
    if (loginType <= UserTypeSiteManager) {
      if (siteId) {
        dispatch(SitesUsersRequest.firstUser(siteId.toString()).request());
      }
    }
  }, [dispatch, siteId, loginType]);

  useEffect(() => {
    getFirstUser();
  }, [getFirstUser]);

  const connectSocket = useCallback(() => {
    if (refWS.current || !firstSiteUser) {
      return;
    }
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const ws = new WebSocket(
      `${protocol}://${
        process.env.REACT_APP_WEB_SOCKET_URL || 'localhost:3004'
      }/ws/`
    );
    ws.onopen = () => {
      setLoggedToSocket(false);
      let targetId;
      if (loginType < UserTypeSiteManager) {
        targetId = firstSiteUser?.id;
      } else {
        targetId = loginId;
      }
      if (targetId) {
        console.log('ws opened with user ', targetId);
        ws.send(
          payload('client-user-login', {
            token: localStorage.getItem('accessToken'),
            userId: targetId,
          })
        );
        ws.send(
          payload('test-connection', {
            deviceCode,
          })
        );
      }
    };

    ws.onclose = async () => {
      setLoggedToSocket(false);
      setConnectedToDevice(false);
      await dispatch(deviceDisconnected(null));
      console.log('ws closed');
      refWS.current = null;
    };

    ws.onmessage = (res) => {
      const { data, event } = parseWsMesssage(res.data);
      switch (event) {
        case 'ready':
          console.log(
            `[${mdlType}] Connected to socket and checking device state...`
          );
          ws.send(
            payload(
              mdlType === ModuleChannelType.B64Channel
                ? 'module-request-state-64ch'
                : 'module-request-state'
            )
          );
          break;
        case 'moduleResponseState':
          console.log('Device connected');
          setLoggedToSocket(true);

          const mdl: ModuleWith = data.find(
            (mdl: ModuleWith) => mdl.deviceCode === deviceCode
          );
          if (mdl) {
            setConnectedToDevice(true);
            setSwitchesState(
              Object.values(mdl.unitsByNumber).reduce<
                Record<string, PowerSwitchState>
              >((states, unit) => {
                for (const sw of Object.values(unit.switchesByNumber)) {
                  states[`${unit.number}-${sw.number}`] = sw.state;
                }
                return states;
              }, {})
            );
          }

          break;
        case 'moduleSwitched':
        case 'moduleSwitched64CH':
          console.log('Device state change');
          setConnectedToDevice(true);
          setSwitchesState(
            Object.values((data as ModuleWith).unitsByNumber).reduce<
              Record<string, PowerSwitchState>
            >((states, unit) => {
              for (const sw of Object.values(unit.switchesByNumber)) {
                states[`${unit.number}-${sw.number}`] = sw.state;
              }
              return states;
            }, {})
          );
          break;
        case 'moduleDisconnected':
          setConnectedToDevice(false);
          break;
        default:
          break;
      }
    };

    refWS.current = ws;
  }, [dispatch, loginId, loginType, firstSiteUser, deviceCode, mdlType]);

  useEffect(() => {
    connectSocket();
  }, [connectSocket]);

  return (
    <div className={classes.testConnectPopup}>
      <div className={classes.statusMessage}>
        {isLoggedToSocket ? (
          <>
            {isConnectedToDevice ? (
              <>
                <Circle type="success" />
                <span>指令盤接続状態：正常</span>
              </>
            ) : (
              <>
                <Circle type="warning" />
                <span>未接続（指令盤)</span>
              </>
            )}
          </>
        ) : (
          <>
            <Circle type="gray" />
            <span>接続中...</span>
          </>
        )}
      </div>
      {isLoggedToSocket && isConnectedToDevice && (
        <div className={classes.connectInfo}>
          {unitsWithLabel.map((unit) => (
            <div key={unit.number} className={classes.unitInfo}>
              <div className={classes.unitName}>{unit.label}</div>
              <div className={classes.switches}>
                {Object.values(unit.switchesByNumber).map((sw) => (
                  <div key={sw.number}>
                    {sw.name}:{' '}
                    {switchesState[`${unit.number}-${sw.number}`] ===
                    PowerSwitchState.UNKNOWN ? (
                      <span className={classes.fail}>状態不明</span>
                    ) : (
                      <span className={classes.success}>確認OK</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TestConnectPopup;
