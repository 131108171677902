import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@/components/atoms/List';

import { RootState } from '@/app/rootReducer';

import Select from '@/components/atoms/Select';
import { CustomButton } from '@/components/atoms/CustomButton';

import { setLoading } from '@/slicers/loading';
import { selectorFindAllSwitchesByModuleId } from '@/selector/module';

import { selectorUnAttachSwitchesByModuleId } from '@/selector/companySwitches';

import modulesRequest from '@/api/modules';
import companySwitchesRequest from '@/api/company_switches';

import classes from './styles.module.scss';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

interface ListModuleProps {
  companyId: number;
  moduleId: number;
}

export default function Switch({ companyId, moduleId }: ListModuleProps) {
  const [overlayLoader, { handleTrigger, showOverlay, hideOverlay }] =
    useOverlayLoading();
  const dispatch = useDispatch();
  const [selectedSwitchForAttach, setSelectedSwitchForAttach] = useState('');

  const { loading } = useSelector((state: RootState) => state.loadingState);

  const switches = useSelector((state: RootState) =>
    selectorFindAllSwitchesByModuleId(moduleId)(state)
  );

  const unAttachSwitches = useSelector((state: RootState) =>
    selectorUnAttachSwitchesByModuleId(companyId)(state)
  );

  const getInitialData = useCallback(async () => {
    await dispatch(setLoading(true));
    await dispatch(
      modulesRequest.getSwitchesByModuleId(moduleId.toString()).request()
    );
    await dispatch(companySwitchesRequest.getByCompanyId(companyId).request());
    await dispatch(setLoading(false));
  }, [dispatch, companyId, moduleId]);

  const onAttach = useCallback(async () => {
    showOverlay();
    await dispatch(
      modulesRequest
        .attachSwitchesToModule(moduleId.toString(), companyId)
        .request({
          switch_id: Number(selectedSwitchForAttach),
        })
    );
    hideOverlay();
  }, [
    dispatch,
    companyId,
    moduleId,
    selectedSwitchForAttach,
    showOverlay,
    hideOverlay,
  ]);

  const onDetach = useCallback(
    async (switchId) => {
      showOverlay();
      await dispatch(
        modulesRequest.detachSwitchesModule(moduleId, switchId).request()
      );
      await dispatch(
        companySwitchesRequest.getByCompanyId(companyId).request()
      );
      hideOverlay();
    },
    [dispatch, showOverlay, hideOverlay, moduleId, companyId]
  );

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const unAttachOptions = useMemo(() => {
    return unAttachSwitches.map((item) => ({
      label: item.pid,
      value: item.id,
    }));
  }, [unAttachSwitches]);

  return (
    <div className={classes.switchContainer}>
      {overlayLoader}
      <List
        loading={loading}
        subHeading={
          <div className={classes.attachSection}>
            <div className={classes.select}>
              <Select
                onChange={setSelectedSwitchForAttach}
                options={unAttachOptions}
                placeholder="PID"
              />
            </div>
            <CustomButton
              disabled={!selectedSwitchForAttach}
              color="primary"
              className={classes.attachButton}
              onClick={handleTrigger(onAttach)}
            >
              配置
            </CustomButton>
          </div>
        }
        showSearch={false}
        data={switches}
        columns={[
          {
            field: 'name',
            title: 'スイッチ名',
            width: '50%',
          },
          {
            field: 'pid',
            title: 'PID',
            width: '30%',
          },
        ]}
        title=""
        tooltip={{
          options: [
            {
              key: 'delete',
              title: '削除',
              isConfirmDelete: true,
            },
          ],
          onClick: (_, record) => {
            onDetach(record.id);
          },
        }}
      />
    </div>
  );
}
