import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { TextField, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { SaveButton, BackButton } from '@/components/atoms/Buttons';
import { useHistory } from 'react-router-dom';
import ButtonList from '@/components/atoms/ButtonList';
import { route } from '@/routes/url-generator';

export type FormData = {
  current_password: string;
  new_password: string;
  new_password_confirm: string;
};

export type LoginFormProps = {
  className?: string;
  increment: number;
  onSubmit: (formData: FormData) => Promise<void> | void;
};

const UserChangePasswordForm: React.FC<LoginFormProps> = ({
  increment,
  onSubmit: _onSubmit,
}) => {
  const { register, handleSubmit, errors, setError, reset, getValues } =
    useForm<FormData>();

  const history = useHistory();
  const handleClickBackButton = () => {
    history.push(route.login);
  };

  const onSubmit = handleSubmit((props) => {
    const { new_password, new_password_confirm } = getValues();
    if (new_password !== new_password_confirm) {
      return setError(
        'new_password_confirm',
        'notMatch',
        '新しいパスワードと同じにしてください'
      );
    }
    _onSubmit(props);
  });

  const FooterButton = () => {
    return (
      <Box display="flex" justifyContent="flex-end" width="100%">
        <ButtonList>
          <BackButton onClick={handleClickBackButton} />
          <SaveButton type="submit" />
        </ButtonList>
      </Box>
    );
  };

  useEffect(() => {
    reset();
  }, [reset, increment]);

  return (
    <form onSubmit={onSubmit}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <TextField
            required
            label="現在のパスワード"
            name="current_password"
            inputRef={register({ required: true })}
            type="password"
            // InputProps={inputProps}
          />
          {errors.current_password && 'name is required.'}
          <TextField
            required
            label="新しいパスワード"
            name="new_password"
            type="password"
            inputRef={register({ required: true })}
            // InputProps={inputProps}
          />
          {errors.new_password && 'name is required.'}
          <TextField
            required
            label="新しいパスワード(確認用)"
            name="new_password_confirm"
            type="password"
            inputRef={register({ required: true })}
            // InputProps={inputProps}
          />
          <Box color="red">
            {errors.new_password_confirm && errors.new_password_confirm.message}
          </Box>
        </div>
      </div>
      <div className={styles.button_container}>
        <FooterButton />
      </div>
    </form>
  );
};

export default UserChangePasswordForm;
