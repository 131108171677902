import React, { Fragment, useState } from 'react';
import Input from '@/components/atoms/Input';
import { CustomButton } from '@/components/atoms/CustomButton';
import { Radio, RadioGroup } from '@material-ui/core';
import { Button as MaterialButton } from '@material-ui/core';
import { makeUid } from '@/utils/random';
import { ModuleChannelType } from '@/slicers/modules';

import classes from './styles.module.scss';

interface ModuleCreateFormProps {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  errors: Record<string, string>;
  initialData?: Record<string, any>;
  onCancel: () => void;
}

export default function ModuleCreateForm({
  initialData = {},
  errors,
  onSubmit,
  onCancel,
}: ModuleCreateFormProps) {
  const [selectedChannel, setSelectedChannel] = useState<ModuleChannelType>(
    ModuleChannelType.B16Channel
  );
  const [code, setCode] = useState<string>();

  return (
    <div className={classes.moduleCreateForm}>
      <form noValidate onSubmit={onSubmit}>
        <RadioGroup
          className={classes.radioGroup}
          defaultValue={ModuleChannelType.B16Channel.toString()}
          onChange={(e) => setSelectedChannel(parseInt(e.target.value))}
          name="type"
        >
          <label>
            <Radio value={ModuleChannelType.B16Channel.toString()} />
            16 ch
          </label>
          <label>
            <Radio value={ModuleChannelType.B1Channel.toString()} />1 ch
          </label>
          <label>
            <Radio value={ModuleChannelType.B64Channel.toString()} />
            64 ch
          </label>
        </RadioGroup>
        <div className={classes.inputs}>
          <div className={classes.input}>
            {selectedChannel === ModuleChannelType.B16Channel ||
            selectedChannel === ModuleChannelType.B64Channel ? (
              <Input
                required
                label="デバイスコード (PID) ※ "
                name="device_code"
                defaultValue={initialData.device_code}
                error={errors.device_code}
              />
            ) : (
              <Fragment>
                <Input
                  key={code}
                  required
                  label="デバイスコード ※ "
                  name="device_code"
                  defaultValue={code}
                  maxLength={10}
                  error={errors.device_code}
                />
                <MaterialButton
                  className={classes.generateCodeButton}
                  onClick={() => setCode(makeUid(10))}
                  variant="contained"
                >
                  自動作成
                </MaterialButton>
              </Fragment>
            )}
          </div>
        </div>
        <div className={classes.buttons}>
          <CustomButton color="grey" onClick={onCancel}>
            戻る
          </CustomButton>
          <CustomButton
            type="submit"
            color="primary"
            className={classes.submitButton}
          >
            保存
          </CustomButton>
        </div>
      </form>
    </div>
  );
}
