import React from 'react'
import ReactDatetime, { DatetimepickerProps } from 'react-datetime'
import { TextField } from '@material-ui/core'
// import moment from 'moment'
require('moment/locale/ja')

type DatetimeProps = ReactDatetime.DatetimepickerProps & {
  renderInput?: (
    props: any,
    openCalendar: () => void,
    closeCalendar: () => void
  ) => void
  closeOnTab?: boolean
}

const TypedDateTime = ReactDatetime as React.ComponentType<DatetimeProps>

export const Datetime: React.FC<DatetimeProps> = ({ ...props }) => (
  <TypedDateTime {...props} />
)

export default Datetime

export const DatetimeTextField: React.FC<DatetimeProps> = ({ ...props }) => {
  return (
    <Datetime
      {...props}
      renderInput={({ ...props }) => <TextField {...props} />}
    />
  )
}
