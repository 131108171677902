import React from 'react';
import BreadCrumbItem from '@/components/molecules/BreadCrumbItem';
import BreadCrumbs from '@/components/molecules/BreadCrumbs';

const SiteLogManagementListBreadCrumb: React.FC = () => {
  return (
    <BreadCrumbs>
      <BreadCrumbItem title="Site Log Management" />
    </BreadCrumbs>
  );
};

export default SiteLogManagementListBreadCrumb;
