import { NodeApiCaller, TApiCaller } from '@/api-service';

@NodeApiCaller('')
class LoggerRequest {
  caller!: TApiCaller;
  get(date?: string) {
    return {
      request: () =>
        this.caller.GET('/logger', {
          range: date,
        }),
    };
  }

  getContent(downloadPath: string) {
    return {
      request: () =>
        this.caller.GET('/log-content', {
          downloadPath,
        }),
    };
  }
}

const loggerRequest = new LoggerRequest();
export default loggerRequest;
