import React from 'react'
import useStyles from './styles'

type AspectRatioProps = {
  /** example: 16/9 */
  ratio: number
}

const AspectRatio: React.FC<AspectRatioProps> = ({ children, ratio }) => {
  const classes = useStyles(ratio)()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.inner}>{children}</div>
      </div>
    </div>
  )
}

export default AspectRatio
