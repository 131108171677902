import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: '2vh 0',
      },
      siteItem: {
        width: '20vw',
        fontSize: '.9rem',
        lineHeight: '.9rem',
        '&:not(:first-child)': {
          marginTop: '1.5vh',
        },
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',

        '& > span': {
          display: 'block',
          flex: 1,
        },
      },
      rangeDate: {},
      fileList: {
        marginTop: '2vh',
        height: '50vh',
        overflow: 'auto',
        overflowX: 'hidden',
      },
    })
  );

export default useStyles;
