import { RootState } from '@/app/rootReducer';
import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';
import { ModuleWithCameras } from '@/slicers/cameras';

export const selectorFindAllModulesByCompanyId = (companyId: number) =>
  createSelector(
    (state: RootState) => state.modulesState.modulesById,
    (modulesById) =>
      Object.values(modulesById).filter(
        (module) => module.company_id === companyId
      )
  );

export const selectorUnAttachModulesByCompanyId = (companyId: number) =>
  createSelector(
    (state: RootState) => state.modulesState.unAttachModules,
    (state: RootState) => state.modulesState.modulesById,
    (unAttachModules, siteModulesById) =>
      (unAttachModules[companyId] || []).filter(
        (mdl) => !siteModulesById[mdl.id]
      )
  );

export const selectorFindAllModulesByOwnerId = (ownerId: number) =>
  createSelector(
    (state: RootState) => state.modulesState.modulesById,
    (modulesById) =>
      Object.values(modulesById).filter((module) => module.owner_id === ownerId)
  );

export const selectorFindAllModulesBySiteId = (siteId: number) =>
  createSelector(
    (state: RootState) => state.modulesState.modulesById,
    (modulesById) =>
      Object.values(modulesById).filter((module) => module.site_id === siteId)
  );

export const selectorFindAllUnoccupiedModules = () =>
  createSelector(
    (state: RootState) => state.modulesState.modulesById,
    (modulesById) =>
      Object.values(modulesById).filter((module) => module.company_id === null)
  );

export const selectorFindAllModulesByIdBySiteId = (siteId: number) =>
  createSelector(
    (state: RootState) => state.modulesState.modulesById,
    (modulesById) =>
      Object.fromEntries(
        Object.values(modulesById)
          .filter((commModule) => commModule.site_id === siteId)
          .map((commModule) => [commModule.id, commModule])
      )
  );

export const selectorFindAllModules = createSelector(
  (state: RootState) => state.modulesState.modulesById,
  (modulesById) => Object.values(modulesById)
);

export const findModuleWithCamerasSelectorFactory = createSelector(
  (state: RootState) => state.modulesState.modulesById,
  (state: RootState) => state.cameraState.camerasById,
  (modulesById, camerasById) =>
    memoize((id: number) => {
      const commModule = modulesById[id];
      if (!commModule) return null;

      return {
        ...commModule,
        cameras: Object.values(camerasById).filter(
          (camera) => camera.module_id === id
        ),
      } as ModuleWithCameras;
    })
);

export const selectorFindAllSwitchesByModuleId = (moduleId: number) =>
  createSelector(
    (state: RootState) => state.modulesState.switchesById,
    (switchesById) =>
      Object.values(switchesById).filter(
        (swc: any) => swc.module_id === moduleId
      )
  );
