import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagingOptions } from '@/interfaces';
import { Camera } from '../cameras';

export type ModuleCamerasState = {
  moduleCamerasById: Record<number, Camera>;
  pagingOptions: PagingOptions;
};

export const initialState: ModuleCamerasState = {
  moduleCamerasById: {},
  pagingOptions: {
    total: 0,
    perPage: 5,
  },
};

const moduleCamerasSlice = createSlice({
  name: 'moduleCamerasSlice',
  initialState,
  reducers: {
    clearModuleCameras(state) {
      const deleteIds = Object.keys(state.moduleCamerasById);
      deleteIds.forEach(id => {
        delete state.moduleCamerasById[id as any];
      });
      state.pagingOptions.total = 0;
    },
    moduleCameraSetPagingOptions(state, action: PayloadAction<PagingOptions>) {
      state.pagingOptions.perPage = action.payload.perPage;
      state.pagingOptions.total = action.payload.total;
    },
    moduleCameraGetsSucces(state, action: PayloadAction<Camera[]>) {
      action.payload.forEach(moduleCamera => {
        state.moduleCamerasById[moduleCamera.id] = moduleCamera;
      });
    },
    moduleCamerasDeleteById(state, { payload }: PayloadAction<number[]>) {
      payload.forEach(moduleCameraId => {
        delete state.moduleCamerasById[moduleCameraId];
      });
    },
  },
});

export const {
  moduleCameraGetsSucces,
  moduleCamerasDeleteById,
  clearModuleCameras,
  moduleCameraSetPagingOptions,
} = moduleCamerasSlice.actions;

export default moduleCamerasSlice.reducer;
