import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { route } from '@/routes/url-generator';
import { useHistory } from 'react-router-dom';
import { useGetClientRouteParam } from '@/hooks';
import { useDispatch, useSelector } from 'react-redux';
import List from '@/components/atoms/List';

import { setLoading } from '@/slicers/loading';
import { RootState } from '@/app/rootReducer';

import sitesModulesRequest from '@/api/sites_modules';
import {
  selectorFindAllModulesBySiteId,
  selectorUnAttachModulesBySiteId,
} from '@/selector/siteModules';
import Select from '@/components/atoms/Select';
import { CustomButton } from '@/components/atoms/CustomButton';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import classes from './styles.module.scss';
import { useOverlayLoading } from '@/components/atoms/OverlayLoading';

import { ModuleChannelType } from '@/slicers/modules';

interface ListModuleProps {
  siteId: number;
  handleClickSwitch?: (id: number, isPowerConsumption?: boolean) => void;
}

export default function ListModule({
  siteId,
  handleClickSwitch = () => {},
}: ListModuleProps) {
  const [overlayLoader, { handleTrigger, showOverlay, hideOverlay }] =
    useOverlayLoading();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedModuleForAttach, setSelectedModuleForAttach] = useState('');
  const [filter, setFilter] = useState({
    ch1: false,
    ch16: false,
    ch64: false,
  });

  const { loading } = useSelector((state: RootState) => state.loadingState);
  const modules = useSelector((state: RootState) =>
    selectorFindAllModulesBySiteId(siteId)(state)?.map((item) => ({
      ...item,
      typeLabel:
        item.type === ModuleChannelType.B16Channel
          ? '16ch'
          : item.type === ModuleChannelType.B1Channel
          ? '1ch'
          : '64ch',
    }))
  );

  const unAttachModules = useSelector((state: RootState) =>
    selectorUnAttachModulesBySiteId(siteId)(state)
  );

  const { companyId } = useGetClientRouteParam();

  const getInitialData = useCallback(async () => {
    await dispatch(setLoading(true));
    await dispatch(sitesModulesRequest.index(siteId.toString()).request());
    await dispatch(sitesModulesRequest.unAttach(siteId).request());
    await dispatch(setLoading(false));
  }, [dispatch, siteId]);

  const onAttach = useCallback(async () => {
    await dispatch(
      sitesModulesRequest
        .put(siteId.toString())
        .request(true, selectedModuleForAttach)
    );
  }, [dispatch, siteId, selectedModuleForAttach]);

  const onDetach = useCallback(
    async (moduleId) => {
      showOverlay();
      await dispatch(
        sitesModulesRequest.put(siteId.toString()).request(false, moduleId)
      );
      hideOverlay();
    },
    [showOverlay, hideOverlay, dispatch, siteId]
  );

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const unAttachOptions = useMemo(() => {
    return unAttachModules.map((item) => ({
      label: item.device_code,
      value: item.id,
    }));
  }, [unAttachModules]);

  /**
   * 電力量と普通の操作画面を切り替えるために
   * オオツカで使用予定のPIDだけ1ch操作盤に遷移する
   * 荒井課長許可済
   *
   * 000B3Dはテスト用
   *
   * 3/22 追加
   * 0237A9
   * 023794
   * 0237AD
   */
  const handleSwitchScreen = useCallback(
    (id: number, deviceCode: string) => {
      const isPowerConsumption =
        deviceCode === '000B3D' ||
        deviceCode === '0237A1' ||
        deviceCode === '02378E' ||
        deviceCode === '0237A9' ||
        deviceCode === '023794' ||
        deviceCode === '0237AD'
          ? true
          : false;
      handleClickSwitch(id, isPowerConsumption);
    },
    [handleClickSwitch]
  );

  const handleFilterModules = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    });
  };

  const filteredModules = useMemo(() => {
    const filterTypes: ModuleChannelType[] = [];
    if (filter.ch1) filterTypes.push(ModuleChannelType.B1Channel);
    if (filter.ch16) filterTypes.push(ModuleChannelType.B16Channel);
    if (filter.ch64) filterTypes.push(ModuleChannelType.B64Channel);

    if (filterTypes.length) {
      return modules.filter((mdl) => filterTypes.includes(mdl.type!));
    }
    return modules;
  }, [modules, filter]);

  return (
    <>
      {overlayLoader}
      <List
        loading={loading}
        subHeading={
          <div className={classes.attachSection}>
            <FormGroup
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingRight: 20,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="ch64"
                    onChange={handleFilterModules}
                    checked={filter.ch64}
                  />
                }
                label="64ch"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="ch16"
                    onChange={handleFilterModules}
                    checked={filter.ch16}
                  />
                }
                label="16ch"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="ch1"
                    onChange={handleFilterModules}
                    checked={filter.ch1}
                  />
                }
                label="1ch"
              />
            </FormGroup>
            <div className={classes.select}>
              <Select
                onChange={setSelectedModuleForAttach}
                options={unAttachOptions}
                placeholder="デバイスコード"
              />
            </div>
            <CustomButton
              disabled={!selectedModuleForAttach}
              color="primary"
              className={classes.attachButton}
              onClick={handleTrigger(onAttach)}
            >
              設置
            </CustomButton>
          </div>
        }
        showSearch={false}
        data={filteredModules}
        columns={[
          {
            field: 'name',
            title: '指令盤名',
            width: '40%',
          },
          {
            field: 'typeLabel',
            title: 'タイプ',
            width: '15%',
          },
          {
            field: 'control_no',
            title: '資産番号',
            width: '20%',
          },
          {
            field: 'device_code',
            title: 'デバイスコード',
            width: '20%',
          },
        ]}
        title=""
        tooltip={{
          options: (item) => [
            {
              key: 'switch',
              title: 'スイッチ操作',
            },
            {
              key: 'edit',
              title: '指令盤の編集',
            },
            {
              key: 'delete',
              title: '指令盤の撤去',
              isConfirmDelete: true,
              confirmProps: {
                message: '本当に撤去しますか',
                buttonMessage: '撤去',
              },
            },
            ...((item.type === ModuleChannelType.B1Channel
              ? [
                  {
                    key: 'power-chart',
                    title: '使用状況を見る',
                  },
                ]
              : []) as any[]),
          ],
          onClick: (key, record) => {
            if (key === 'edit') {
              history.push(
                route.clientRoute.modules.show(companyId, record.id)
              );
            } else if (key === 'delete') {
              onDetach(record.id);
            } else if (key === 'switch') {
              handleSwitchScreen(record.id, record.device_code);
            } else if (key === 'power-chart') {
              history.push(route.modules.powerChart(record.id));
            }
          },
        }}
      />
    </>
  );
}
